import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { MANUFYEAR_PLANUR_EMPLOYMENT, PERSONNEL_EMPLOYMENT, PRIORITY_DOMAIN } from 'src/constants';
import {
  PersonnelSurveyDiffFormData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';

//-------------------------------------------------------------
// Props
//-------------------------------------------------------------
interface EnqueteAnswerCreatePriorityDomainDiffFormProps {
  // form定義
  form: PersonnelSurveyDiffFormData;
  // 差異合計
  diffValue: number;
  // 入力データ
  diffFormData: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 必須チェックフラグ
  isEmptyError: boolean;
  // 入力データの設定方法
  setDiffFormFunction: Function;
  // バリデーションチェック(全体)の設定方法
  setErrorDiffFormFunction: Function;
}

interface EnqueteAnswerDiffFormProps {
  // form定義
  form: PersonnelSurveyDiffFormData;
  // タイトル
  title: string;
  // 項目タイトル
  labelTitle: string;
  // 行
  lines: number;
  // 差異合計
  diffValue: number;
  // 入力の行データ
  row: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData;
  // 入力データ
  diffFormData: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 入力データの設定方法
  setDiffFormFunction: Function;
  // 必須チェックフラグ
  isEmptyError: boolean;
}

//-------------------------------------------------------------
// 画面共通:差異人数および理由（重点分野別人員）
//-------------------------------------------------------------
export const EnqueteAnswerCreatePriorityDomainDiffForm: React.FC<EnqueteAnswerCreatePriorityDomainDiffFormProps> = (props) => {
  const { form, diffValue, diffFormData, isEmptyError, setDiffFormFunction, setErrorDiffFormFunction } = props;
  // 言語情報
  const useJudg = useJudgmentCurrentLanguage();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [resultPlanRows, setResultPlanRows] = useState<any[]>([]);
  // 読み込み中フラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 差異フォーム行数
  let lines = 0;
  // 差異フォームタイトル
  const title = undefined !== form.title ? useJudg.getText(form.title as languageDataTypes) : '';
  // 差異フォームサブタイトル
  const labelTitle = undefined !== form.labelTitle ? useJudg.getText(form.labelTitle as languageDataTypes) : '';

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    // Rowデータを作成する
    refreshRowsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.diffFormData]);

  useEffect(() => {
    // バリデーションチェック(全体)
    setErrorDiffFormFunction(false);
    resultPlanRows.forEach((row) => {
      // 半角の+-数値ではないかチェック
      const checkTargetValue = row.diffMemberInputValue !== null ? row.diffMemberInputValue.toString() : null;
      const matchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/[+-][0-9]*/) : null;

      if (checkTargetValue !== null && checkTargetValue !== '' && checkTargetValue !== matchFieldValue?.input) {
        setErrorDiffFormFunction(true);
      } else {
        // プラスマイナス記号チェック
        const secondMatchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]/) : null;
        if (secondMatchFieldValue === null && checkTargetValue !== null && checkTargetValue !== '') {
          setErrorDiffFormFunction(true);
        } else {
          // 先頭に半角の + または -のみ
          const checkHeadOnlyValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]$/) : null;
          if (checkHeadOnlyValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
            setErrorDiffFormFunction(true);
          } else {
            // 不正な値(+0 / -0)
            const checkZeroValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[0]+$/) : null;
            if (checkZeroValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
              setErrorDiffFormFunction(true);
            } else {
              // 不正な形式(+10++0 / ++100 / +010)
              const checkFormatValueError: RegExpMatchArray | null =
                checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[1-9]{1}[0-9]{0,4}$/) : null;
              if (checkFormatValueError === null && checkTargetValue !== null && checkTargetValue !== '') {
                setErrorDiffFormFunction(true);
              }
            }
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultPlanRows]);

  //-------------------------------------------------------------
  // gridの行データの作成
  //-------------------------------------------------------------
  const refreshRowsData = async () => {
    // ロード中のコンポーネントを表示
    setIsLoading(true);

    lines = undefined !== form.defaultLine ? form.defaultLine : 0;
    // 入力データ作成
    const resultAndPlanRows =
      // 初期値がありません
      diffFormData === undefined || diffFormData.length === 0
        ? [...Array(lines)].map((_, i) => {
            return {
              // 行番号
              count: i,
              // 雇用形態
              employment: '',
              // 重点分野
              priorityDomain: '',
              // 差異人数
              diffMemberInputValue: '',
              diffMemberReasonSelectValue: '',
              // 差異理由
              diffMemberReasonInputValue: '',
            };
          })
        : // 初期値があります
          [...Array(lines)].map((_, i) => {
            if (diffFormData[i]) {
              // データが存在する場合は読み込み
              return {
                // 行番号
                count: i,
                // 雇用形態
                employment: diffFormData[i]['employment'],
                // 重点分野
                priorityDomain: diffFormData[i]['priorityDomain'],
                // 差異人数
                diffMemberInputValue: diffFormData[i]['diffMemberInputValue'],
                diffMemberReasonSelectValue: diffFormData[i]['diffMemberReasonSelectValue'],
                // 差異理由
                diffMemberReasonInputValue: diffFormData[i]['diffMemberReasonInputValue'],
              };
            } else {
              // データが存在しない場合は空データを挿入
              return {
                // 行番号
                count: i,
                // 雇用形態
                employment: '',
                // 重点分野
                priorityDomain: '',
                // 差異人数
                diffMemberInputValue: '',
                diffMemberReasonSelectValue: '',
                // 差異理由
                diffMemberReasonInputValue: '',
              };
            }
          });

    // このコンポーネントのステータス値を設定します
    await setResultPlanRows(resultAndPlanRows);

    // ロード中のコンポーネントを表示されない
    setIsLoading(false);
  };

  return (
    <>
      <LoadableComponent isLoading={isLoading}>
        {/* ラベル */}
        <Box sx={{ pb: '6px' }} style={{ whiteSpace: 'pre-line' }}>
          <h4>{useJudg.getText(props.form['headline'] as languageDataTypes)}</h4>
        </Box>

        {/* 差異人数および理由の行コンポーネント */}
        {resultPlanRows.length &&
          resultPlanRows.map((row) => (
            <EnqueteAnswerDiffPlanAndPlanForm
              form={props.form}
              title={title}
              labelTitle={labelTitle}
              key={'diffResultAndPlanForm' + row.count}
              lines={lines}
              diffValue={diffValue}
              row={row}
              diffFormData={diffFormData}
              setDiffFormFunction={setDiffFormFunction}
              isEmptyError={isEmptyError}
            />
          ))}
      </LoadableComponent>
    </>
  );
};

interface InputText {
  value: string;
}

//-------------------------------------------------------------
// 差異人数および理由の行コンポーネント
//-------------------------------------------------------------
const EnqueteAnswerDiffPlanAndPlanForm: React.FC<EnqueteAnswerDiffFormProps> = (props) => {
  const { title, labelTitle, lines, diffValue, row, diffFormData, setDiffFormFunction, isEmptyError } = props;
  const { t } = useTranslation();
  const { currentLang } = useLocales();

  // nameを定義
  // 雇用状態
  const employmentName = 'employment';
  // 重点分野
  const priorityDomainName = 'priorityDomain';
  // 差異人数
  const diffMemberInputName = 'diffMemberInputValue';
  const diffMemberSelectReasonName = 'diffMemberReasonSelectValue';
  // 差異理由
  const diffMemberInputReasonName = 'diffMemberReasonInputValue';

  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  // 雇用状態
  const [employment, setEmployment] = useState<string>('');
  // 重点分野
  const [priorityDomain, setPriorityDomain] = useState<string>('');
  // 差異人数
  const [diffMemberInputValue, setDiffMemberInputValue] = useState<InputText>({ value: '' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [diffMemberSelectReasonValue, setDiffMemberSelectReasonValue] = useState<InputText>({ value: '' });
  // 差異理由
  const [diffMemberInputReasonValue, setDiffMemberInputReasonValue] = useState<InputText>({ value: '' });
  // 半角の+-数値ではないフラグ
  const [isInputValueError, setIsInputValueError] = useState(false);
  // 差異人数は設定されていませんフラグ
  const [isEmptyValueError, setIsEmptyValueError] = useState(false);
  // 先頭に半角の + または -が無いフラグ
  const [isInputHeadError, setIsInputHeadError] = useState(false);
  // 先頭に半角の + または -のみフラグ
  const [isHeadOnlyValueError, setIsHeadOnlyValueError] = useState(false);
  // 不正な値(+0 / -0)フラグ
  const [isZeroValueError, setIsZeroValueError] = useState(false);
  // 不正な形式(+10++0 / ++100 / +010)フラグ
  const [isFormatValueError, setIsFormatValueError] = useState(false);
  // 差異理由は設定されていませんフラグ
  const [isEmptyReasoneError, setIsEmptyReasoneError] = useState(false);
  // 雇用状態は設定されていませんフラグ
  const [isEmptyEmploymentError, setIsEmptyEmploymentError] = useState(false);
  // 重点分野は設定されていませんフラグ
  const [isEmptyPriorityDomainError, setIsEmptyPriorityDomainError] = useState(false);

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    const checkTargetValue = row.diffMemberInputValue !== null ? row.diffMemberInputValue.toString() : null;
    // 半角の+-数値ではない
    const matchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/[+\-0-9]*/) : null;

    if (matchFieldValue !== null && matchFieldValue?.input !== '' && matchFieldValue[0] !== matchFieldValue?.input) {
      // 半角の+-数値ではないフラグを設定する
      setIsInputValueError(true);
    } else {
      setIsInputValueError(false);

      // 先頭に半角の + または -が無い
      const secondMatchFieldValue: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]/) : null;

      if (secondMatchFieldValue === null && checkTargetValue !== null && checkTargetValue !== '') {
        // 先頭に半角の + または -が無いフラグを設定する
        setIsInputHeadError(true);
      } else {
        setIsInputHeadError(false);

        // 先頭に半角の + または -のみ
        const checkHeadOnlyValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]$/) : null;
        if (checkHeadOnlyValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
          // 先頭に半角の + または -のみフラグを設定する
          setIsHeadOnlyValueError(true);
        } else {
          setIsHeadOnlyValueError(false);

          // 不正な値(+0 / -0)
          const checkZeroValueError: RegExpMatchArray | null = checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[0]+$/) : null;
          if (checkZeroValueError !== null && checkTargetValue !== null && checkTargetValue !== '') {
            // 不正な値(+0 / -0)フラグを設定する
            setIsZeroValueError(true);
          } else {
            setIsZeroValueError(false);

            // 不正な形式(+10++0 / ++100 / +010)
            const checkFormatValueError: RegExpMatchArray | null =
              checkTargetValue !== null ? checkTargetValue.match(/^[+-]{1}[1-9]{1}[0-9]{0,4}$/) : null;

            if (checkFormatValueError === null && checkTargetValue !== null && checkTargetValue !== '') {
              // 不正な形式(+10++0 / ++100 / +010)フラグを設定する
              setIsFormatValueError(true);
            } else {
              setIsFormatValueError(false);
            }
          }
        }
      }
    }

    // 空白エラーが有効になっている場合、編集したらエラーを解除する
    if (isEmptyError) {
      // チェック用差異理由
      const checkTargetInputReasonValue = row.diffMemberReasonInputValue !== null ? row.diffMemberReasonInputValue : null;
      // チェック用雇用形態
      const checkEmployment = row.employment && row.employment !== '' ? row.employment : null;
      // チェック用重点分野
      const checkPriorityDomain = row.priorityDomain && row.priorityDomain !== '' ? row.priorityDomain : null;
      // 差異人数が存在する
      if (checkTargetValue !== null && checkTargetValue.length !== 0) {
        // 差異人数エラーを解除する
        setIsEmptyValueError(false);
      }
      // 差異理由が存在する
      if (checkTargetInputReasonValue !== null && checkTargetInputReasonValue.length !== 0) {
        // 差異理由エラーを解除する
        setIsEmptyReasoneError(false);
      }
      // 雇用形態が存在する
      if (checkEmployment) {
        // 雇用形態エラーを解除する
        setIsEmptyEmploymentError(false);
      }
      // 重点分野が存在する
      if (checkPriorityDomain) {
        // 重点分野エラーを解除する
        setIsEmptyPriorityDomainError(false);
      }
      // 差異人数、差異理由、雇用形態、重点分野が設定されていない場合
      if (
        (checkTargetValue === null || checkTargetValue.length === 0) &&
        (checkTargetInputReasonValue === null || checkTargetInputReasonValue.length === 0) &&
        !checkEmployment &&
        !checkPriorityDomain
      ) {
        // 差異人数エラーを解除する
        setIsEmptyValueError(false);
        // 差異理由エラーを解除する
        setIsEmptyReasoneError(false);
        // 雇用形態エラーを解除する
        setIsEmptyEmploymentError(false);
        // 重点分野エラーを解除する
        setIsEmptyPriorityDomainError(false);
      }
    }

    // 雇用形態ステータス値の設定
    setEmployment(row.employment);
    // 重点分野ステータス値の設定
    setPriorityDomain(row.priorityDomain);
    // 差異人数ステータス値の設定
    setDiffMemberInputValue({ value: row.diffMemberInputValue });
    setDiffMemberSelectReasonValue({ value: row.diffMemberReasonSelectValue });
    // 差異理由ステータス値の設定
    setDiffMemberInputReasonValue({ value: row.diffMemberReasonInputValue ? row.diffMemberReasonInputValue : '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.diffMemberInputValue, row.diffMemberReasonSelectValue, row.diffMemberReasonInputValue, row.employment, row.priorityDomain]);

  useEffect(() => {
    if (isEmptyError) {
      // チェック用差異人数
      const checkTargetValue = row.diffMemberInputValue !== null ? row.diffMemberInputValue.toString() : null;
      // チェック用差異理由
      const checkTargetInputReasonValue = row.diffMemberReasonInputValue !== null ? row.diffMemberReasonInputValue : null;
      // チェック用雇用形態
      const checkEmployment = row.employment && row.employment !== '' ? row.employment : null;
      // チェック用重点分野
      const checkPriorityDomain = row.priorityDomain && row.priorityDomain !== '' ? row.priorityDomain : null;
      if (
        (checkTargetValue !== null && checkTargetValue.length !== 0) ||
        (checkTargetInputReasonValue !== null && checkTargetInputReasonValue.length !== 0) ||
        checkEmployment !== null ||
        checkPriorityDomain !== null
      ) {
        // 差異人数が空白の場合
        if (checkTargetValue === null || checkTargetValue.length === 0) {
          // 差異人数エラーを設定する
          setIsEmptyValueError(true);
        } else {
          setIsEmptyValueError(false);
        }
        // 差異理由が空白の場合
        if (checkTargetInputReasonValue === null || checkTargetInputReasonValue.length === 0) {
          // 差異理由人数エラーを設定する
          setIsEmptyReasoneError(true);
        } else {
          setIsEmptyReasoneError(false);
        }
        // 雇用形態が空白の場合
        if (checkEmployment === null) {
          // 雇用形態エラーを設定する
          setIsEmptyEmploymentError(true);
        } else {
          setIsEmptyEmploymentError(false);
        }
        // 重点分野が空白の場合
        if (checkPriorityDomain === null) {
          // 重点分野エラーを設定する
          setIsEmptyPriorityDomainError(true);
        } else {
          setIsEmptyPriorityDomainError(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyError]);

  //-------------------------------------------------------------
  // 切り替えを選択する場合
  //-------------------------------------------------------------
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    // 項目名
    const fieldName: string = event.target.name;
    // 項目値
    const fieldValue: string = event.target.value;

    // 項目名は雇用形態の場合
    if (employmentName === fieldName) {
      // 雇用形態の設定
      setEmployment(fieldValue);
      // 項目名は重点分野の場合
    } else if (priorityDomainName === fieldName) {
      //  重点分野の設定
      setPriorityDomain(fieldValue);
    }

    // 親のstateをアップデート
    let newFormData =
      // 初期値があります
      diffFormData && diffFormData.length
        ? _.cloneDeep(diffFormData)
        : // 初期値がありません
          [...Array(lines)].map((_, i) => {
            return {
              // 行番号
              count: i,
              // 雇用形態
              employment: '',
              // 重点分野
              priorityDomain: '',
              // 差異人数
              diffMemberInputValue: '',
              diffMemberReasonSelectValue: '',
              // 差異理由
              diffMemberReasonInputValue: '',
            };
          });

    // 初期データ
    let newResultAndPlan: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData = _.cloneDeep(row);

    // 画面入力データの保存
    // @ts-ignore
    newResultAndPlan[fieldName] = fieldValue;

    // 該当行のデータを設定
    newFormData[row.count] = newResultAndPlan;

    // 入力データを再設定
    setDiffFormFunction(newFormData);
  };

  // 入力内容を反映させる
  const handleInputDiffChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 項目名
    const fieldName: string = event.target.name;
    // 項目値
    const fieldValue: string = event.target.value;

    // フィールドをアップデート
    if (fieldName === diffMemberInputName) {
      // 全角数字だと入力制限を突破できるので対応
      setDiffMemberInputValue({ value: fieldValue.substring(0, 6) });
    } else if (fieldName === diffMemberSelectReasonName) {
      setDiffMemberSelectReasonValue({ value: fieldValue });
    } else if (fieldName === diffMemberInputReasonName) {
      setDiffMemberInputReasonValue({ value: fieldValue });
    }

    // 親のstateをアップデート
    let newFormData =
      diffFormData && diffFormData.length
        ? // 初期値があります
          _.cloneDeep(diffFormData)
        : // 初期値がありません
          [...Array(lines)].map((_, i) => {
            return {
              // 行番号
              count: i,
              // 雇用形態
              employment: '',
              // 重点分野
              priorityDomain: '',
              // 差異人数
              diffMemberInputValue: '',
              diffMemberReasonSelectValue: '',
              // 差異理由
              diffMemberReasonInputValue: '',
            };
          });

    // 初期データ
    let newResultAndPlan: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData = _.cloneDeep(row);

    // 画面入力データの保存
    if (fieldName === diffMemberInputName) {
      // 差異人数6桁を切り取る
      newResultAndPlan[fieldName] = fieldValue.substring(0, 6);
    } else {
      // 残りはそのままで保存する
      // @ts-ignore
      newResultAndPlan[fieldName] = fieldValue;
    }

    // 該当行のデータを設定
    newFormData[row.count] = newResultAndPlan;

    // 入力データを再設定
    setDiffFormFunction(newFormData);
  };

  return (
    <Box component={'div'} sx={{ mb: 1, pb: '3px' }}>
      <Grid item xs={12} md={12} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={1.3}>
          <Box style={currentLang.value === 'ja' ? {} : { whiteSpace: 'pre-line', fontSize: '10px' }}>{row.count === 0 ? title : ''}</Box>
        </Grid>

        <Grid item xs={12} md={1.7}>
          {row.count === 0 && <TextField disabled label={labelTitle} value={diffValue} size="small" variant="standard" />}
        </Grid>

        {/* 雇用形態 */}
        <Grid item xs={6} md={1.7}>
          <FormControl fullWidth size="small" error={isEmptyError && isEmptyEmploymentError}>
            {/* ラベル雇用形態 */}
            <InputLabel id="employmentSelect">{t('enqueteAnswerInput.textfield.employment.label')}</InputLabel>
            <Select
              name={employmentName}
              labelId="employmentSelect"
              id="employmentSelect"
              value={employment}
              onChange={handleSelectChange}
              disabled={!isEnqueteAnswerOpen}
              input={<OutlinedInput label={t('enqueteAnswerInput.textfield.employment.label')} />}>
              <MenuItem value={''} key={0}>
                <em>None</em>
              </MenuItem>
              {(props.form.tabValue.includes('personnel') ? PERSONNEL_EMPLOYMENT : MANUFYEAR_PLANUR_EMPLOYMENT).map((item, i) => (
                <MenuItem value={item.VALUE} key={i + 1}>
                  {t(item.LABEL)}
                </MenuItem>
              ))}
            </Select>
            {/* 雇用状態は設定されていません */}
            <FormHelperText>{isEmptyError && isEmptyEmploymentError ? t('enqueteAnswerInput.textfield.employment.helperText1') : ''}</FormHelperText>
          </FormControl>
        </Grid>

        {/* 重点分野 */}
        <Grid item xs={6} md={1.5}>
          <FormControl fullWidth size="small" error={isEmptyError && isEmptyPriorityDomainError}>
            {/* ラベル重点分野 */}
            <InputLabel id="priorityDomainSelect">{t('enqueteAnswerInput.textfield.priorityDomain.label')}</InputLabel>
            <Select
              name={priorityDomainName}
              labelId="priorityDomainSelect"
              id="priorityDomainSelect"
              value={priorityDomain}
              onChange={handleSelectChange}
              disabled={!isEnqueteAnswerOpen}
              input={<OutlinedInput label={t('enqueteAnswerInput.textfield.priorityDomain.label')} />}>
              <MenuItem value={''} key={0}>
                <em>None</em>
              </MenuItem>
              {PRIORITY_DOMAIN.map((item, i) => (
                <MenuItem value={item.VALUE} key={i + 1}>
                  {t(item.LABEL)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {/* 重点分野は設定されていません */}
              {isEmptyError && isEmptyPriorityDomainError ? t('enqueteAnswerInput.textfield.priorityDomain.helperText1') : ''}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* 差異人数 */}
        <Grid item xs={6} md={1}>
          <TextField
            error={isInputValueError || isInputHeadError || isHeadOnlyValueError || isZeroValueError || isFormatValueError || isEmptyValueError}
            name={diffMemberInputName}
            // ラベル差異人数
            label={t('enqueteAnswerInput.textfield.differenceCount.label')}
            size="small"
            fullWidth={true}
            // 最大長は 6 文字です
            inputProps={{ maxLength: 6 }}
            value={diffMemberInputValue.value}
            onChange={handleInputDiffChange}
            helperText={
              // 半角の+-数値ではない
              isInputValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText1')
                : // 先頭に半角の + または -が無い
                isInputHeadError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText2')
                : // 先頭に半角の + または -のみ
                isHeadOnlyValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText6')
                : // 不正な値(+0 / -0)
                isZeroValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText4')
                : // 不正な形式(+10++0 / ++100 / +010)
                isFormatValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText5')
                : // 差異人数は設定されていません
                isEmptyValueError
                ? t('enqueteAnswerInput.textfield.differenceCount.helperText6')
                : ''
            }
            disabled={!isEnqueteAnswerOpen}
          />
        </Grid>
        {/* 差異理由 */}
        <Grid item xs={8} md={2.3}>
          <TextField
            error={isEmptyError && isEmptyReasoneError}
            name={diffMemberInputReasonName}
            fullWidth={true}
            // ラベル差異理由
            label={t('enqueteAnswerInput.textfield.differenceReason.label')}
            size="small"
            // 200 文字を超えたら、入力できない
            inputProps={{ maxLength: 200 }}
            value={diffMemberInputReasonValue.value}
            onChange={handleInputDiffChange}
            // 差異理由は設定されていません
            helperText={isEmptyError && isEmptyReasoneError ? t('enqueteAnswerInput.textfield.differenceReason.helperText1') : ''}
            disabled={!isEnqueteAnswerOpen}
          />
        </Grid>

        <Grid item xs={12} md={1.4} />
      </Grid>
    </Box>
  );
};
