import { Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { PageLayout } from 'src/components/app-components/PageLayout';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { FORM_TYPE, REQUEST_STATUS, SURVEY_TYPE } from 'src/constants';
import EnquetePageCommonGrid from 'src/features/general/enquete-create/components/pages/create/components/EnquetePageCommonGrid';
import { TAB_VALUE, TabValue } from 'src/features/general/enquete-create/constants';
import { setEnqueteData, toDisabledSaveButton, toEnabledSaveButton } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { changeFormType } from 'src/features/general/enquete-create/utils/changeFormType';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useEnqueteCreate } from '../hooks/useEnqueteCreate';
import useEnqueteCreateLayout from '../hooks/useEnqueteCreateLayout';
import { EnqueteCreateFormTypeSwitcher } from './EnqueteCreateFormTypeSwitcher';

export interface EnqueteCreateLayoutProps {
  id: string | undefined;
  pageTitle: string;
  breadCrumbs: HeaderBreadcrumbsProps;
  selectTabValue: TabValue;
}

export const EnqueteCreateLayout: React.FC<EnqueteCreateLayoutProps> = (props) => {
  const { id, pageTitle, breadCrumbs } = props;
  const { t } = useTranslation();

  // use store
  const dispatch = useDispatch();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const surveyType = enqueteData.surveyDetail?.surveyHeader?.type;
  const isValid = surveyType === SURVEY_TYPE.SPECIAL || REQUEST_STATUS.BEFORE_REQUEST === requestStatus;
  dispatch(isValid ? toEnabledSaveButton() : toDisabledSaveButton());

  // use hooks
  const { isNewCreateMode, isOverviewCreateMode, initializeStore } = useEnqueteCreate();
  const { isLoading, getEnqueteData } = useEnqueteCreateLayout();
  const { isBonsaiAdmin, isLoadingPermission, isBonsaiCreator, isSurveyOwner, isRespondentManager, isRespondentManagerOnly } = usePermission();

  const isAllowedByMode = isNewCreateMode ? isBonsaiCreator : isSurveyOwner || isRespondentManager; // memo: mode に応じて許可者を切り替え
  const isAllowed = isBonsaiAdmin || isAllowedByMode;

  useEffect(() => {
    if (id !== undefined) {
      getEnqueteData(id);
    }
    if (isNewCreateMode) {
      // 概況表の場合はグローバルステートを更新
      // enqueteDataの初期値として汎用向けのFORM_TYPE等が設定されているため
      if (isOverviewCreateMode) {
        dispatch(setEnqueteData(changeFormType(enqueteData, SURVEY_TYPE.SPECIAL, FORM_TYPE.OVERVIEW)));
      }
      toDisabledSaveButton();
    }
    return () => {
      initializeStore();
    };
  }, []); // eslint-disable-line

  if (isLoading || isLoadingPermission) {
    return <StyledLoading isOpen={isLoading || isLoadingPermission} />;
  }

  if (!isAllowed) {
    return (
      <Typography variant={'h4'} sx={{ mt: 3, textAlign: 'center' }}>
        {t('enqueteCreate.message.haveNotSurveyOwnerAuthority')}
      </Typography>
    );
  }

  return (
    <PageLayout title={pageTitle} breadcrumbs={breadCrumbs}>
      {/** ページ共通コンポーネント */}
      <EnquetePageCommonGrid />
      {/** ページコンテンツコンポーネント */}
      <Paper variant="elevation" square>
        <EnqueteCreateFormTypeSwitcher
          id={props.id}
          // memo: ここで '2' は依頼先タブを示す
          selectedTabValue={isRespondentManagerOnly ? TAB_VALUE.RESPONDENT : props.selectTabValue}
          isNewCreateMode={isNewCreateMode}
        />
      </Paper>
    </PageLayout>
  );
};
