import {
  ColDef,
  ColGroupDef,
  ColumnGroupOpenedEvent,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  ITooltipParams,
  ValueGetterFunc,
  ValueGetterParams,
} from 'ag-grid-community';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessRoleType, AccessRoleTypeEnum } from 'src/@types/accessRoleType';
import { useUserAgGridColDef } from 'src/components/app-components/bonsai/hooks/useUserAgGridColDef';
import { ModalGridProps } from 'src/components/app-components/bonsai/SelectEditorModal';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { VISIBLE_TYPE } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { invisibleConditionOrganization } from 'src/utils/gridValueUtils';
import { SelectedRowDataTypes } from '../type/division-region-editor.type';

interface HandlesInvisibleConditionOrganizationTypes {
  valueGetter: string | ValueGetterFunc<any> | undefined;
  tooltipValueGetter: ((params: ITooltipParams<any, any>) => any) | undefined;
}

interface Props {
  selectedRowData?: SelectedRowDataTypes[];
  setSelectedRowData?: Dispatch<SetStateAction<SelectedRowDataTypes[]>>;
}

export const useDivisionRegionGrid = (props: Props) => {
  const { selectedRowData = [], setSelectedRowData = () => {} } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const headerNameKey = 'enqueteCreate.divisionRegionEditor.gridColdef';

  const { defaultColDef, userAgGridColDef } = useUserAgGridColDef({
    companyCodeColName: 'userCompanyCode',
    emailColDefSetting: { key: 'email' },
    fullNameColDefSetting: { key: 'userName' },
    companyNameColDefSetting: { key: 'userCompanyName', columnGroupShowValue: 'open' },
    companyAbbreviationColDefSetting: { key: 'userCompanyAbbreviation', columnGroupShowValue: 'open' },
    assignedCompanyColDefSetting: { key: 'assignedCompanyName', columnGroupShowValue: 'open' },
    divisionNameColDefSetting: { key: 'userDivisionName', columnGroupShowValue: 'open' },
    sbuNameColDefSetting: { key: 'userSbuName', columnGroupShowValue: 'open' },
    departmentNameColDefSetting: { key: 'userDepartmentName', columnGroupShowValue: 'open' },
    groupNameColDefSetting: { key: 'userGroupName', columnGroupShowValue: 'open' },
  });

  // グリッドAPI
  const gridApi = useRef<GridApi | null>(null);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [openEditorColumnGroup, setOpenEditorColumnGroup] = useState<boolean>(false);
  const [openDivisionRegionFilterColumnGroup, setOpenDivisionRegionFilterColumnGroup] = useState<boolean>(false);
  const accessRoleTypeList: AccessRoleType[] = useAppSelector((state: RootState) => state.accessRoleType.accessRoleTypeList);
  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // グリッドの表示準備完了時の処理
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  // Grid定義 日英文言切り替え
  const makeColDefIncludeLangSwitch = (
    key: string,
    minWidth: number,
    columnGroupShowValue?: 'open' | 'closed' | 'not',
    isInvisible?: boolean,
    isVisibleType?: boolean,
  ): ColDef | ColGroupDef => {
    const columnGroup = columnGroupShowValue !== undefined || columnGroupShowValue !== 'not' ? { columnGroupShow: columnGroupShowValue } : {};

    const handleInvisibleConditionOrganization: HandlesInvisibleConditionOrganizationTypes = isInvisible
      ? {
          valueGetter: isVisibleType
            ? (params) => viewVisibleName(params, key, true)
            : (params) => invisibleConditionOrganization(params, 'userCompanyCode', `${key}NameJpn`, `${key}NameEng`, isJapanese),
          tooltipValueGetter: isVisibleType
            ? (params) => viewVisibleName(params, key, true)
            : (params) => invisibleConditionOrganization(params, 'userCompanyCode', `${key}NameJpn`, `${key}NameEng`, isJapanese),
        }
      : {
          valueGetter: isVisibleType ? (params) => viewVisibleName(params, key, true) : undefined,
          tooltipValueGetter: isVisibleType ? (params) => viewVisibleName(params, key, true) : (params) => params.value,
        };

    return {
      ...{
        field: isJapanese ? `${key}NameJpn` : `${key}NameEng`,
        headerName: ['region', 'country', 'assignedCompany'].includes(key) ? t(`common.${key}`) : t(`${headerNameKey}.${key}Name`),
        headerTooltip: ['region', 'country', 'assignedCompany'].includes(key) ? t(`common.${key}`) : t(`${headerNameKey}.${key}Name`),
        minWidth: minWidth,
        suppressMenu: true,
        sortable: false,
        tooltipValueGetter: (params) => params.value,
        filter: true,
        filterParams: {
          applyMiniFilterWhileTyping: true,
        },
        floatingFilter: true,
      },
      ...columnGroup,
      ...handleInvisibleConditionOrganization,
    };
  };

  const viewVisibleName = (params: ValueGetterParams | ITooltipParams, key: string, hasLangKey?: boolean) => {
    if (VISIBLE_TYPE.ALL === params.data['visibleType']) {
      return '-'; // 担当種別が全て許可の場合は「-」を表示
    }
    if (hasLangKey) {
      // 言語指定でkeyが変わるパターンをこちらで拾う
      return isJapanese
        ? params.data[`${key}NameJpn`] || params.data[`${key}NameEng`] || '-'
        : params.data[`${key}NameEng`] || params.data[`${key}NameJpn`] || '-';
    }
    return params.data[key];
  };

  const makeColDefAccessRoleIdFormatter = (key: string, minWidth: number, columnGroupShowValue?: 'open' | 'closed'): ColDef | ColGroupDef => {
    const colDef: ColDef = makeColDefFormatter(key, minWidth, columnGroupShowValue);
    colDef.valueGetter = (params) => {
      // 本部/極のアクセス権限の画面出力値変更
      const roleType = accessRoleTypeList.find((val) => val.id === params.data[key]);
      const role = Object.values(AccessRoleTypeEnum).find((val) => val.roleName === roleType?.roleName);
      return t(role?.viewName ?? '');
    };
    return colDef;
  };

  const makeColDefVisibleTypeFormatter = (key: string, minWidth: number, columnGroupShowValue?: 'open' | 'closed'): ColDef | ColGroupDef => {
    const colDef: ColDef = makeColDefFormatter(key, minWidth, columnGroupShowValue);
    colDef.valueGetter = (params) => {
      // 担当種別の画面出力値変更
      return t(`${'enqueteCreate.divisionRegionEditor.visibleType'}.${params.data[key]}`);
    };
    return colDef;
  };

  const makeColDefViewFormatter = (key: string, minWidth: number, columnGroupShowValue?: 'open' | 'closed'): ColDef | ColGroupDef => {
    const colDef: ColDef = makeColDefFormatter(key, minWidth, columnGroupShowValue);
    colDef.valueGetter = (params) => {
      return viewVisibleName(params, key);
    };
    return colDef;
  };

  // Grid定義 オブジェクト生成
  const makeColDefFormatter = (key: string, minWidth: number, columnGroupShowValue?: 'open' | 'closed'): ColDef | ColGroupDef => {
    const columnGroup = columnGroupShowValue !== undefined ? { columnGroupShow: columnGroupShowValue } : {};

    return {
      ...{
        field: key,
        headerName: t(`${headerNameKey}.${key}`),
        headerTooltip: t(`${headerNameKey}.${key}`),
        minWidth: minWidth,
        suppressMenu: true,
        sortable: false,
        tooltipValueGetter: (params) => params.value,
        filter: true,
        filterParams: {
          applyMiniFilterWhileTyping: true,
        },
        floatingFilter: true,
      },
      ...columnGroup,
    };
  };

  // 列グルーピング処理
  const onColumnGroupOpened = (event: ColumnGroupOpenedEvent) => {
    const states = event.columnApi.getColumnGroupState();
    setOpenEditorColumnGroup(states[0].open);
    setOpenDivisionRegionFilterColumnGroup(states[1].open);
  };

  // Context Menu
  const contextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.delete'),
        action: () => {
          setSelectedRowData(selectedRowData.filter((v, i) => i !== params.node?.rowIndex));
        },
      },
    ];
  };

  const grid: ModalGridProps<SelectedRowDataTypes> = {
    colDefs: [
      {
        headerName: t(`${headerNameKey}.${'editorNameGroup'}`),
        openByDefault: openEditorColumnGroup,
        children: [makeColDefAccessRoleIdFormatter('accessRoleId', 130), ...userAgGridColDef],
      },
      {
        headerName: t(`${headerNameKey}.${'divisionRegionNameGroup'}`),
        openByDefault: openDivisionRegionFilterColumnGroup,
        children: [
          makeColDefVisibleTypeFormatter('visibleType', 150),
          makeColDefViewFormatter('divisionCode', 100, 'open'),
          makeColDefIncludeLangSwitch('division', 220, 'not', false, true),
          makeColDefViewFormatter('sbuCode', 100, 'open'),
          makeColDefIncludeLangSwitch('sbu', 220, 'not', false, true),
          makeColDefViewFormatter('departmentCode', 100, 'open'),
          makeColDefIncludeLangSwitch('department', 220, 'not', false, true),
          makeColDefViewFormatter('groupCode', 100, 'open'),
          makeColDefIncludeLangSwitch('group', 220, 'not', false, true),
          makeColDefIncludeLangSwitch('region', 150, 'not', false, true),
          makeColDefIncludeLangSwitch('country', 150, 'not', false, true),
        ],
      },
      {
        field: '-',
        headerName: '',
        cellRenderer: ThreeDotMenuRenderer,
        editable: false,
        minWidth: 80,
        width: 50,
        filter: false,
        pinned: 'right',
      },
    ],
    rowData: selectedRowData,
    onGridReady: onGridReady,
    onColumnGroupOpened: onColumnGroupOpened,
    contextMenu: contextMenuItems,
  };

  return { defaultColDef, grid };
};
