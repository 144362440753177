import { DuplicateWarnMessage } from 'src/@types/DuplicateWarnMessage';
import { OverviewEnqueteOptionData } from 'src/@types/overviewEnquete';
import { SeriesEnqueteOptionData } from 'src/@types/seriesEnquete';
import { DisclosureLevel } from 'src/constants';
import { SurveyFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { initSurveyHeader, SurveyHeaderEntity } from './useSurveyHeaderApi';

type EnqueteSentStatus = 'SENT' | 'SENT_FAILED' | 'PROCESSING';

export interface SurveyDetailEntity {
  id: string;
  version: number;
  surveyFormData?: SurveyFormData;
  surveyFormOptionData?: SeriesEnqueteOptionData | OverviewEnqueteOptionData;
  surveyHeader?: SurveyHeaderEntity;
  surveyHeaderId: string;
  disclosureLevel?: DisclosureLevel | null;
  duplicateWarnMessage?: DuplicateWarnMessage | null;
  sentStatus?: EnqueteSentStatus | null;
  sentAt?: Date | null;
  createdBy: string;
  updatedBy: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  isDeleted: boolean;
}

export const initSurveyDetail: SurveyDetailEntity = {
  id: '',
  version: 0,
  surveyHeader: initSurveyHeader,
  surveyHeaderId: '',
  duplicateWarnMessage: null,
  createdBy: '',
  updatedBy: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  isDeleted: false,
};

/**
 * SurveyDetail API
 *
 * @returns
 */
export const useSurveyDetailApi = () => {
  return {};
};
