import { Box, Grid, Stack } from '@mui/material';
import { GetContextMenuItems, GetContextMenuItemsParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RespondentPersonEntity, RespondentsListEntity, useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { UserEntity } from 'src/api/useUserApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { FORM_TYPE } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { SelectRespondentsModal } from '../../../../modal/select-respondent/components/SelectRespondentsModal';
import { SelectRespondentsAgGrid } from './SelectRespondentsAgGrid';

interface PageProps {
  respondentRequestId: string;
  respondentTargetId: string;
  organizationId: string;
}

const ChangeRespondentComponent: React.FC<PageProps> = (props) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { enqueueSnackbar } = useSnackbar();
  const enqueteAnswerInputApi = useEnqueteAnswerInputApi();
  const { t } = useTranslation();
  const { user } = useAuth();
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);
  const formType = surveyDetailData?.surveyHeader?.formType;
  const { isAnswerReader, isAdmin, isSurveyOwner, isConfirmor, isRespondentPerson } = usePermission();
  const isOnlyAnswerReader = isAnswerReader && !isSurveyOwner && !isAdmin && !isConfirmor && !isRespondentPerson;

  const [rowData, setRowData] = useState<RespondentsListEntity[]>([]);
  const [offsetHeight, setOffsetHeight] = useState<string>('100vh');
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  // grid api
  const gridApi = useRef<GridApi | null>(null);

  // ユーザー情報宣言
  const userEmail = null !== user ? user.email : '';

  useEffect(() => {
    // calc data table height
    const appHeader = document.getElementsByTagName('header');
    const pageHeader = document.getElementById('page-header');
    if (pageHeader && appHeader.length > 0) {
      setOffsetHeight(`calc(100vh - ${pageHeader?.clientHeight}px - ${appHeader[0]?.clientHeight}px)`);
    }
    // rowdata refresh
    refreshRowData();
  }, []); //eslint-disable-line

  //-------------------------------------------------------------
  // GridApiを取得するための関数
  //-------------------------------------------------------------
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //-------------------------------------------------------------
  // RowData取得
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    if (!props.respondentRequestId || !props.respondentTargetId) return;

    // ローディング開始
    setIsLoading(true);

    // 回答者のリストを取得
    const respondents = await enqueteAnswerInputApi.getRespondentsList(props.respondentRequestId, props.respondentTargetId);
    if (respondents === undefined || respondents.length === 0) {
      // ローディング終了
      setIsLoading(false);
      return;
    }

    // RowDataの更新
    setRowData(respondents);

    // ローディング終了
    setIsLoading(false);
  };

  //-------------------------------------------------------------
  // 追加するRowDataのバリデーションを行う処理
  //-------------------------------------------------------------
  const onValidation = (values: UserEntity[]) => {
    // 追加行を１つずつ走査
    for (const value of values) {
      // 既にページ用のAgGridのRowDataに存在していないか確認
      for (const row of rowData) {
        if (value.email === row.email) return false;
      }
    }

    return true;
  };

  //-------------------------------------------------------------
  // RowDataに行データの追加を行う処理
  //-------------------------------------------------------------
  const onModalOk = async (values: UserEntity[]) => {
    if (!props.respondentRequestId || !props.respondentTargetId || !props.organizationId) return;

    // モーダルを閉じる
    setIsOpenAddModal(false);

    // メール送信アニメーション開始
    setIsSending(true);

    // 登録用データを作成
    const data: RespondentPersonEntity[] = [];
    for (const value of values) {
      if (value.email) {
        data.push({
          email: value.email,
          bluePageEmailAddress: value.bluePageEmailAddress ?? '',
          userNameJpn: value.fullNameJpn ?? '',
          userNameEng: value.fullNameEng ?? '',
          companyCode: value.companyCode ?? '',
          assignedCompanyNameJpn: value.assignedCompanyNameJpn ?? '',
          assignedCompanyNameEng: value.assignedCompanyNameEng ?? '',
          divisionCode: value.divisionCode ?? '',
          sbuCode: value.sbuCode ?? '',
          departmentCode: value.departmentCode ?? '',
          groupCode: value.groupCode ?? '',
        });
      } else {
        return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.message.canNotRegistered'), { variant: 'error' });
      }
    }

    // 登録
    await enqueteAnswerInputApi.registerRespondents(props.respondentRequestId, props.respondentTargetId, props.organizationId, data);

    // メール送信アニメーション終了
    setIsSending(false);

    // リストのリフレッシュ
    await refreshRowData();
  };

  //-------------------------------------------------------------
  // 回答者の情報を削除する処理
  //-------------------------------------------------------------
  const onRowDelete = async (params: RespondentsListEntity | undefined) => {
    if (!params) return;
    if (!params.email) return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.message.canNotRegistered'), { variant: 'error' });
    if (params.email === userEmail) return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.message.cantDeleteOwn'), { variant: 'error' });

    // 削除
    await enqueteAnswerInputApi.deleteRespondents(props.respondentTargetId, [params.respondentPersonId]);

    // 一覧のリフレッシュ
    await refreshRowData();
  };

  //-------------------------------------------------------------
  // コンテキストメニュー
  //-------------------------------------------------------------
  const contextMenu: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.delete'),
        action: () => {
          if (params.node) {
            onRowDelete(params.node.data);
          }
        },
        disabled: rowData.length <= 1 || isOnlyAnswerReader || (isConfirmor && formType !== FORM_TYPE.SPECIAL),
      },
    ];
  };

  return (
    <>
      {/* header action space*/}
      <Grid container>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'right'} spacing={1}>
            <StyledButton onClick={() => setIsOpenAddModal(true)} isDisabled={isOnlyAnswerReader || (isConfirmor && formType !== FORM_TYPE.SPECIAL)}>
              {t('button.add')}
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>

      {/* data table space */}
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box
            component={'div'}
            sx={{ width: '100%', height: offsetHeight }}
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            data-testid={'aggrid'}>
            <LoadableComponent isLoading={isLoading} zIndex={1000}>
              <SelectRespondentsAgGrid rowData={rowData} onGridReady={onGridReady} onRowDelete={onRowDelete} contextMenu={contextMenu} />
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>

      {/* add modal */}
      <SelectRespondentsModal
        respondentTargetId={props.respondentTargetId}
        open={isOpenAddModal}
        onClose={() => setIsOpenAddModal(false)}
        onOk={onModalOk}
        onValidation={onValidation}
      />

      {/** mail sending */}
      <StyledLoading isOpen={isSending} />
    </>
  );
};

export default ChangeRespondentComponent;
