import { useTranslation } from 'react-i18next';
import { SeriesSurveyFormAggregationData, SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

import _ from 'lodash';
import { useSnackbar } from 'notistack5';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { useEnqueteCommon } from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteCommon';
import {
  SeriesEnqueteRowData,
  useEnqueteModal,
} from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteModal';
import { useSeriesEnqueteCreateModalValidation } from './useSeriesEnqueteCreateModalValidation';

interface Props {
  selectedModalData: SeriesEnqueteRowData;
  selectedModalRowData: SeriesSurveyFormAggregationData[];
  beforeEditModalData: SeriesEnqueteRowData;
}

export const useSeriesEnqueteCreateModal = (props: Props) => {
  const { selectedModalData, selectedModalRowData, beforeEditModalData } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // 検索用リスト
  const { closeEditModal } = useEnqueteModal();
  const { saveSurveyDetailData } = useEnqueteCreateGrid();
  const { concatAndSort } = useEnqueteCommon();
  const { isDuplication } = useSeriesEnqueteCreateModalValidation();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  /**
   * 登録イベント処理
   * モーダルの追加データを登録データを合わせ、APIをコールする
   */
  const onRegister = async () => {
    const surveyDetail = enqueteData.surveyDetail;
    const aggregationList = (surveyDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    const questionDescriptionJpn = (surveyDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionJpn || '';
    const questionDescriptionEng = (surveyDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionEng || '';

    let list = _.cloneDeep(aggregationList);

    selectedModalRowData.forEach((data) => {
      list = concatAndSort(list, data);
    });

    const surveyFormData = {
      enqueteType: '',
      formData: {
        pastRespondentRequestId: '',
        tabData: [
          {
            questionDescriptionJpn: questionDescriptionJpn ?? '',
            questionDescriptionEng: questionDescriptionEng ?? '',
            aggregationList: list,
          },
        ],
      },
    };
    const saveEnquete: SurveyDetailEntity = {
      ...surveyDetail,
      surveyFormData: surveyFormData,
    };

    const result = await saveSurveyDetailData(saveEnquete);
    if (result) {
      const surveyDetail = {
        ...saveEnquete,
        updatedAt: (result.data as SurveyDetailEntity).updatedAt,
      };
      dispatch(setEnqueteData({ ...enqueteData, surveyDetail }));
    }

    closeEditModal();
  };

  /**
   * 更新用イベント処理
   */
  const onUpdate = async (): Promise<void> => {
    // 更新前と更新後の値が同じかチェック
    if (selectedModalData.sourceSbuCode === beforeEditModalData.sourceSbuCode) {
      // APIはコールせずモーダルを閉じる
      enqueueSnackbar(t('message.save'), { variant: 'success' });
      closeEditModal();
      return;
    }

    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    const questionDescriptionJpn = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionJpn || '';
    const questionDescriptionEng = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.questionDescriptionEng || '';

    // 登録済みデータと重複チェック
    if (isDuplication(selectedModalData, aggregationList)) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadyRegistered'), { variant: 'warning' });
      return;
    }
    // 登録データをコピーする
    const newAggregationList: SeriesSurveyFormAggregationData[] = _.cloneDeep(aggregationList);
    // 変更前SBU集約先を削除し、変更SBU集約先を設定する
    for (const aggregation of newAggregationList) {
      if (aggregation.divisionCode === selectedModalData.divisionCode) {
        for (const sbu of aggregation.sbu) {
          if (sbu.destination.sbuCode === selectedModalData.destinationSbuCode) {
            for (const [i, source] of sbu.source.entries()) {
              if (source.sbuCode === beforeEditModalData.sourceSbuCode) {
                // 変更前のものを削除
                sbu.source.splice(i, 1);
                // 変更後を追加
                sbu.source.push({
                  sbuCode: selectedModalData.sourceSbuCode,
                  sbuNameJpn: selectedModalData.sourceSbuNameJpn,
                  sbuNameEng: selectedModalData.sourceSbuNameEng,
                  organizationId: selectedModalData.sourceSbuOrganizationId,
                });
              }
            }
            break;
          }
        }
        break;
      }
    }
    const surveyFormData = {
      enqueteType: '',
      formData: {
        pastRespondentRequestId: '',
        tabData: [
          {
            questionDescriptionJpn: questionDescriptionJpn,
            questionDescriptionEng: questionDescriptionEng,
            aggregationList: newAggregationList,
          },
        ],
      },
    };
    const saveEnquete: SurveyDetailEntity = {
      ...survayDetail,
      surveyFormData: surveyFormData,
    };

    const result = await saveSurveyDetailData(saveEnquete);
    if (result) {
      const surveyDetail = {
        ...saveEnquete,
        updatedAt: (result.data as SurveyDetailEntity).updatedAt,
      };
      dispatch(setEnqueteData({ ...enqueteData, surveyDetail }));
    }

    closeEditModal();
  };

  return {
    onRegister,
    onUpdate,
  };
};
