import { Button, Divider, Grid, Stack } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { initOverviewSurveyHeader, initSurveyHeader, SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';
import { DisclousureLevelForm } from 'src/components/app-components/bonsai/survey-information/components/DisclousureLevelForm';
import { RespondentRequestForm } from 'src/components/app-components/bonsai/survey-information/components/RespondentRequestForm';
import { SurveyOwnerSelectBox } from 'src/components/app-components/bonsai/survey-information/components/SurveyOwnerSelectBox';
import { useEnqueteCreate } from 'src/features/general/enquete-create/components/pages/create/hooks/useEnqueteCreate';
import { useAppSelector } from 'src/redux/store';
import { useInformationForm } from '../hooks/useInformationForm';
import { EnqueteAnswerUnitForm } from './EnqueteAnswerUnitForm';
import { EnqueteOwnerOrganizationForm } from './EnqueteOwnerOrganizationForm';
import { EnqueteQuestionFormatForm } from './EnqueteQuestionFormatForm';
import { EnqueteTypeForm } from './EnqueteTypeForm';
import { OrganizationBaseDateForm } from './OrganizationBaseDateForm';

interface PropsEntity {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methods: UseFormReturn;
}

export const SurveyCreateForm: React.FC<PropsEntity> = (props) => {
  const { setIsModalOpen, methods } = props;
  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData);
  const { isGeneralCreateMode, isOverviewCreateMode } = useEnqueteCreate();
  const { t } = useTranslation();

  // memo: アンケート種別が未定義（新規作成）の場合を考慮し、初期値をセット
  let enqueteTypeData: SurveyHeaderEntity;
  if (enqueteData.surveyDetail?.surveyHeader) {
    enqueteTypeData = enqueteData.surveyDetail?.surveyHeader;
  } else {
    enqueteTypeData = isOverviewCreateMode ? initOverviewSurveyHeader : initSurveyHeader;
  }
  const { onClickSaveData } = useInformationForm(enqueteTypeData, enqueteData);

  return (
    <Stack component="form">
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
        {/* 主管部 */}
        <EnqueteOwnerOrganizationForm methods={methods} isOverviewCreateMode={isOverviewCreateMode} />
        {/* アンケートタイプ */}
        <EnqueteTypeForm methods={methods} enqueteTypeData={enqueteTypeData} />
        {isGeneralCreateMode && (
          <>
            {/* 質問形式 */}
            <EnqueteQuestionFormatForm methods={methods} formType={enqueteTypeData.formType} />
            {/* 回答単位 */}
            <EnqueteAnswerUnitForm methods={methods} answerUnit={enqueteTypeData.answerUnit} />
            <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
              <Divider variant="middle" />
            </Grid>
          </>
        )}
        {/** 組織基準日 */}
        <OrganizationBaseDateForm answerUnit={methods.watch('enqueteAnswerUnit')} methods={methods} />
        {/** アンケートタイトル、依頼期間 */}
        <RespondentRequestForm enqueteData={enqueteData} methods={methods} />

        {/** 共同管理者 */}
        <SurveyOwnerSelectBox methods={methods} isActive={true} />

        {/* Data-Insight公開区分 */}
        <DisclousureLevelForm methods={methods} disclosureLevel={enqueteData.surveyDetail.disclosureLevel} />

        {/* ボタン */}
        <Grid item xs={12} md={1} sx={{ mr: 3, width: '100%', textAlign: 'right' }}>
          {/* キャンセルボタン */}
          <Button variant={'contained'} onClick={() => setIsModalOpen(false)} children={t('button.cancel')} color="error" />
          {/* 保存ボタン */}
          <Button
            variant={'contained'}
            onClick={async () => {
              await methods.handleSubmit(onClickSaveData)();
              setIsModalOpen(false);
            }}
            children={enqueteData.surveyDetail?.id ? t('button.save') : t('button.setting')}
            sx={{ ml: 2 }}
            disabled={!methods.formState.isValid}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
