import { Grid, Stack } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessRoleType, useAccessRoleTypeApi } from 'src/api/useAccessRoleTypeApi';
import { DivisionRegionEditorDataTypes, ResponseVisibleConditionTypes, useVisibleConditionsApi } from 'src/api/useVisibleConditionsApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { SelectDivisionRegionEditorModal } from 'src/features/general/enquete-create/components/modals/select-division-region-editor/components/SelectDivisionRegionEditorModal';
import { useDivisionRegionEditor } from 'src/features/general/enquete-create/hooks/useDivisionRegionEditor';
import { useDivisionRegionGrid } from 'src/features/general/enquete-create/hooks/useDivisionRegionGrid';
import { initDivisionRegionEditorData } from 'src/features/general/enquete-create/type/division-region-editor.type';
import { setAccessRoleType } from 'src/features/general/store/accessRoleTypeSlice';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector, useDispatch } from 'src/redux/store';
import DivisionRegionEditorGrid from './DivisionRegionEditorGrid';

interface PageComponentTypes {
  surveyDetailId: string;
}

export const DivisionRegionEditorTab: React.FC<PageComponentTypes> = memo((props) => {
  const { surveyDetailId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isViewerAdmin, isSurveyOwner } = usePermission();
  const { getVisibleConditionList } = useVisibleConditionsApi();
  const { getAccessRoleTypeList } = useAccessRoleTypeApi();

  // Hooks
  const { startLoading, stopLoading, openEditModal, resoleveUserNames } = useDivisionRegionEditor();

  // グリッド整形定義
  const { defaultColDef, grid } = useDivisionRegionGrid({});

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const refreshCounter = useAppSelector((state) => state.divisionRegionEditor.refreshCounter);
  const isOpenEditModal = useAppSelector((state) => state.divisionRegionEditor.isOpenEditModal);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [rowData, setRowData] = useState<DivisionRegionEditorDataTypes[] | undefined>([]);
  const [targetData, setTargetData] = useState<DivisionRegionEditorDataTypes>(initDivisionRegionEditorData);

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // 一覧更新処理
  const refreshRowData = async () => {
    // グリッドデータ読み込み
    if (!surveyDetailId) return;

    startLoading();
    // アクセス権限のロール一覧取得
    const roleTypeList: AccessRoleType[] = (await getAccessRoleTypeList()) ?? [];
    dispatch(setAccessRoleType(roleTypeList));

    // レコード取得
    const conditionList: ResponseVisibleConditionTypes[] | undefined = await getVisibleConditionList(surveyDetailId);
    if (!conditionList || conditionList.length === 0) {
      setRowData([]);
      stopLoading();
      return;
    }

    // ユーザー名解決
    const list = await resoleveUserNames(conditionList);

    setRowData(list);
    stopLoading();
  };

  // 編集処理
  const editRowCallback = (data: DivisionRegionEditorDataTypes) => {
    if (isViewerAdmin && !isSurveyOwner) return;

    setTargetData(data);
    openEditModal();
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    refreshRowData();
  }, [surveyDetailId, refreshCounter]); // eslint-disable-line

  // モーダルクローズ処理
  useEffect(() => {
    if (isOpenEditModal) return;
    setTargetData(initDivisionRegionEditorData);
  }, [isOpenEditModal]);

  return (
    <>
      <Stack spacing={1}>
        {/** 追加ボタン **/}
        <Grid container direction={'row'} justifyContent={'flex-end'}>
          <Grid item xs={1} textAlign={'right'}>
            <StyledButton
              onClick={() => {
                setTargetData(initDivisionRegionEditorData);
                openEditModal();
              }}
              isDisabled={isViewerAdmin && !isSurveyOwner}>
              {t('button.add')}
            </StyledButton>
          </Grid>
        </Grid>

        {/** グリッド **/}
        <DivisionRegionEditorGrid
          defaultColDef={defaultColDef}
          grid={grid}
          rowData={rowData}
          surveyDetailId={surveyDetailId}
          refreshRowData={refreshRowData}
          editRowCallback={(data: DivisionRegionEditorDataTypes) => editRowCallback(data)}
        />
      </Stack>

      {/** モーダル **/}
      <SelectDivisionRegionEditorModal surveyDetailId={surveyDetailId} registeredData={rowData} targetData={targetData} />
    </>
  );
});
