import { FC, useEffect, useState } from 'react';
import { FetchPermissionProps, usePermissionApi } from 'src/api/usePermissionApi';
import { setPermission } from 'src/redux/slices/pageSlice';
import { dispatch } from 'src/redux/store';
import { StyledLoading } from '../StyledLoading';

export interface EnquetePageProps {
  permissionParam: FetchPermissionProps;
  children: React.ReactNode;
}
// Enquete単位のページで共通的に行いたい処理を記述するコンポーネント
export const EnquetePageComponent: FC<EnquetePageProps> = ({ permissionParam, children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { checkPermission } = usePermissionApi();
  useEffect(() => {
    const fetchPermission = async () => {
      // アンケートの新規作成時等は権限のチェックは不要なのでIDが存在しない場合は行わない
      if (permissionParam.surveyDetailId || permissionParam.respondentRequestId || permissionParam.respondentTargetId) {
        // 下位ComponentでPermissionを使用するためにfetchした時のパラメータをStateにセットする
        const result = await checkPermission(permissionParam);
        dispatch(setPermission(result));
      }
      setIsLoading(false);
    };

    fetchPermission();

    return () => {
      // ページを離れる際にStateをリセットする
      dispatch(setPermission(undefined));
    };
  }, []); //eslint-disable-line

  if (isLoading) {
    return <StyledLoading isOpen={isLoading} />;
  }

  return <>{children}</>;
};
