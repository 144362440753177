import { SearchCondition } from 'src/api/useSurveyEditApi';

// memo: 他の画面でも使用する場合はディレクトリを見直す必要があります
export const makeDivisionCondition = (): SearchCondition => {
  return { orgLevelType: 'DIVISION' };
};

export const makeSbuCondition = (divisionCode?: string | null): SearchCondition => {
  return {
    orgLevelType: 'SBU',
    divisionCode: divisionCode || undefined,
  };
};

export const makeDepartmentCondition = (divisionCode?: string | null, sbuCode?: string | null): SearchCondition => {
  return {
    orgLevelType: 'DEPARTMENT',
    divisionCode: divisionCode || undefined,
    sbuCode: sbuCode || undefined,
  };
};

export const makeGroupCondition = (divisionCode?: string | null, sbuCode?: string | null, departmentCode?: string | null): SearchCondition => {
  return {
    orgLevelType: 'GROUP',
    divisionCode: divisionCode || undefined,
    sbuCode: sbuCode || undefined,
    departmentCode: departmentCode || undefined,
  };
};
