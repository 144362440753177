import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SurveyInformationEntity } from 'src/@types/generalEnquete';
import { CopySurveyEntity, useCreatedSurveyApi } from 'src/api/useCreatedSurvey';
import { UserEntity } from 'src/api/useUserApi';
import { FORM_TYPE, SURVEY_TYPE } from 'src/constants';
import { NotificateStateTypes } from 'src/features/general/enquete-create/pages/EnqueteCreatePage';
import { ENQUETE_CREATE_PATH } from 'src/features/general/enquete-create/routes/path';
import useLocales from 'src/hooks/useLocales';
import { setPermission } from 'src/redux/slices/pageSlice';
import { dispatch } from 'src/redux/store';
import { conversionDateWithLocalHour } from 'src/utils/formatDateTime';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { EnqueteTargetDateForm } from './EnqueteTargetDateForm';
import { OrganizationBaseDateForm } from './OrganizationBaseDateForm';
import { RespondentRequestForm } from './RespondentRequestForm';
import { SurveyOwnerSelectBox } from './SurveyOwnerSelectBox';

//コピーオプションエンティティ
type DuplicateOptionEntity = {
  duplicateRespondents: boolean;
  duplicateSurveyOwners: boolean;
  duplicateRespondentManagers: boolean;
  duplicateDivisionRegionEditor: boolean;
  duplicateTemporaryOrganization: boolean;
};

//アンケート複製編集モーダルエンティティ
type SurveyDuplicateFormProps = {
  enqueteData: SurveyInformationEntity;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SurveyDuplicateForm: React.FC<SurveyDuplicateFormProps> = (props) => {
  const { setIsModalOpen, enqueteData } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const { duplicateSurvey } = useCreatedSurveyApi();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onChange' });
  //State
  const [inProgress, setInProgress] = useState<boolean>(false);
  //コピーオプション管理
  const [duplicateOption, setDuplicateOption] = useState<DuplicateOptionEntity>({
    duplicateRespondents: true,
    duplicateSurveyOwners: true,
    duplicateRespondentManagers: true,
    duplicateDivisionRegionEditor: true,
    duplicateTemporaryOrganization: true,
  });
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);

  //アンケート複製 → アンケート編集画面遷移
  const onClickDuplicate = async (data: FieldValues) => {
    const getTargetDate = (): Date | null => {
      if (enqueteData.type === SURVEY_TYPE.GENERAL || enqueteData.formType === FORM_TYPE.OVERVIEW) {
        return new Date(conversionDateWithLocalHour(data.openedAt));
      }
      if (data.targetDate !== null) {
        return conversionDateWithLocalHour(data.targetDate);
      }
      return null;
    };
    const requestData: CopySurveyEntity = {
      sourceSurveyDetailId: enqueteData.surveyDetailId,
      isKeepRespondents: duplicateOption.duplicateRespondents,
      isKeepOwners: duplicateOption.duplicateSurveyOwners,
      isKeepRespondentManagers: duplicateOption.duplicateRespondentManagers,
      isKeepVisibleConditions: duplicateOption.duplicateDivisionRegionEditor,
      isKeepTemporaryOrganizations: duplicateOption.duplicateTemporaryOrganization,
      openedAt: new Date(data.openedAt),
      closedAt: new Date(data.closedAt),
      titleJpn: data.titleJpn,
      titleEng: data.titleEng,
      organizationBaseDate: conversionDateWithLocalHour(new Date(data.organizationBaseDate.getFullYear(), data.organizationBaseDate.getMonth(), 1)),
      targetDate: getTargetDate(),
      surveyOwnerEmails: data.surveyOwnerEmails.map((surveyOwner: UserEntity) => surveyOwner.email),
    };

    setInProgress(true);
    const responseIds = await duplicateSurvey(requestData);
    setInProgress(false);

    if (responseIds)
      navigate(ENQUETE_CREATE_PATH.EDIT + '/' + responseIds.respondentRequestId, { state: { duplicated: true } as NotificateStateTypes });
  };

  useEffect(() => {
    enqueteData &&
      Object.entries(enqueteData).forEach(([key, value]) => {
        if (value) {
          if (['titleJpn', 'titleEng'].includes(key)) {
            methods.setValue(key, value);
          } else if (['openedAt', 'closedAt'].includes(key)) {
            methods.setValue(key, new Date(value));
          }
        }
      });
  }, [enqueteData, methods]);

  useEffect(() => {
    return () => {
      // ページを離れる際にStateをリセットする
      dispatch(setPermission(undefined));
    };
  }, []);

  return (
    <form>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
        {/** アンケート種別 */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }} children={t('enqueteCreate.editEnqueteModal.label')} />
          </Grid>
          <Grid item xs={12} md={8}>
            {isJapanese ? enqueteData.labelJpn : enqueteData.labelEng}
          </Grid>
        </Grid>

        {/** 組織基準日 */}
        <OrganizationBaseDateForm answerUnit={enqueteData.answerUnit} methods={methods} />
        {/** アンケートタイトル 依頼期間 */}
        <RespondentRequestForm enqueteData={enqueteData} requestStatus={requestStatus} methods={methods} />

        {/** 対象年月 */}
        {enqueteData.type !== SURVEY_TYPE.GENERAL && enqueteData.formType !== FORM_TYPE.OVERVIEW && (
          <EnqueteTargetDateForm methods={methods} targetDate={enqueteData.targetDate} />
        )}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography
              variant="body1"
              sx={{ wordBreak: 'keep-all', textAlign: 'center' }}
              children={t('enqueteRequest.surveyDuplicationModal.copyContent')}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormGroup row={true} sx={{ pl: 1 }}>
              <Grid item xs={6} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={duplicateOption.duplicateRespondents} />}
                  label={t('enqueteRequest.surveyDuplicationModal.sendTo')}
                  labelPlacement="end"
                  onChange={(_e: SyntheticEvent, checked: boolean) =>
                    setDuplicateOption((state) => {
                      return { ...state, ...{ duplicateRespondents: checked, duplicateTemporaryOrganization: true } };
                    })
                  }
                  sx={{ width: 'auto' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={duplicateOption.duplicateSurveyOwners} />}
                  label={t('enqueteRequest.surveyDuplicationModal.owners')}
                  labelPlacement="end"
                  onChange={(_e: SyntheticEvent, checked: boolean) =>
                    setDuplicateOption((state) => {
                      return { ...state, ...{ duplicateSurveyOwners: checked } };
                    })
                  }
                  sx={{ width: 'auto' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={duplicateOption.duplicateRespondentManagers} />}
                  label={t('common.respondentManager')}
                  labelPlacement="end"
                  onChange={(_: SyntheticEvent, checked: boolean) =>
                    setDuplicateOption((state) => {
                      return { ...state, ...{ duplicateRespondentManagers: checked } };
                    })
                  }
                  sx={{ width: 210 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={duplicateOption.duplicateDivisionRegionEditor} />}
                  label={t('enqueteRequest.surveyDuplicationModal.divisionRegionEditor')}
                  labelPlacement="end"
                  onChange={(e: SyntheticEvent, checked: boolean) =>
                    setDuplicateOption((state) => {
                      return { ...state, ...{ duplicateDivisionRegionEditor: checked, duplicateTemporaryOrganization: true } };
                    })
                  }
                  sx={{ width: 210 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={duplicateOption.duplicateTemporaryOrganization} />}
                  label={t('enqueteRequest.surveyDuplicationModal.temporaryOrganization')}
                  labelPlacement="end"
                  onChange={(_: SyntheticEvent, checked: boolean) =>
                    setDuplicateOption((state) => {
                      return { ...state, ...{ duplicateTemporaryOrganization: checked } };
                    })
                  }
                  sx={{ width: 210 }}
                  disabled={duplicateOption.duplicateRespondents || duplicateOption.duplicateDivisionRegionEditor}
                />
              </Grid>
            </FormGroup>
          </Grid>
          <SurveyOwnerSelectBox methods={methods} isActive={!duplicateOption.duplicateSurveyOwners} />
        </Grid>
        {inProgress && (
          <Grid item sx={{ textAlign: 'center' }}>
            <FontAwesomeIcon icon={faSpinner} spin={true} size={'2x'} />
          </Grid>
        )}

        <Grid item xs={12} md={1} sx={{ mr: 3, width: '100%', textAlign: 'right' }}>
          {/* キャンセルボタン */}
          <Button variant={'contained'} onClick={() => setIsModalOpen(false)} children={t('button.cancel')} color="error" disabled={inProgress} />
          {/* 作成ボタン */}
          <Button
            variant={'contained'}
            onClick={methods.handleSubmit(onClickDuplicate)}
            children={t('button.create')}
            sx={{ ml: 2 }}
            disabled={inProgress || !methods.formState.isValid}
          />
        </Grid>
      </Grid>
    </form>
  );
};
