import { Box, Grid, Stack } from '@mui/material';
import { isAfter } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { SeriesEnqueteCreateModal } from 'src/features/general/enquete-create/components/modals/enquete-create/components/SeriesEnqueteCreateModal';
import { setQuestionDescriptionEng, setQuestionDescriptionJpn } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { replaceURLWithHTMLLinks } from 'src/utils/replaceURLWithHTMLLinks';
import { EnqueteDescriptionForm } from '../../../../../common/components/enquete-description-form/components/EnqueteDescriptionForm';
import { SeriesEnqueteRowData, useEnqueteModal } from '../hooks/useEnqueteModal';
import { useSaveEnqueteDescription } from '../hooks/useSaveEnqueteDescription';
import EnqueteCreateForm from './EnqueteCreateForm';

const EnqueteCreateTab: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const { isAdmin, isSurveyOwner } = usePermission();
  // アンケートクローズ状態の確認
  const isClosed = isAfter(new Date(), new Date(enqueteData.closedAt));
  const isDisabled = isClosed || !(isAdmin || isSurveyOwner);

  const [targetData, setTargetData] = useState<SeriesEnqueteRowData>();

  const { modalNormalMode, openEditModal } = useEnqueteModal();
  const { onSaveEnqueteDescription } = useSaveEnqueteDescription();
  useEffect(() => {
    if (!enqueteData.surveyDetailId) {
      dispatch(setQuestionDescriptionJpn(''));
      dispatch(setQuestionDescriptionEng(''));
    } else {
      const tabData = enqueteData.surveyDetail?.surveyFormData?.formData.tabData[0] as SeriesSurveyFormTabData;
      dispatch(setQuestionDescriptionJpn(tabData?.questionDescriptionJpn ?? ''));
      dispatch(setQuestionDescriptionEng(tabData?.questionDescriptionEng ?? ''));
    }
  }, [dispatch, enqueteData]);

  return (
    <>
      <Stack spacing={1}>
        <Grid container direction={'row'} justifyContent={'flex-end'}>
          {/** 保存ボタン **/}
          <Grid item xs={1} textAlign={'right'}>
            <StyledButton
              onClick={() => {
                onSaveEnqueteDescription();
              }}
              children={t('button.save')}
              isDisabled={isDisabled}
            />
          </Grid>
          <EnqueteDescriptionForm />
          <Grid item xs={11} textAlign={'left'}>
            <Label color="info" sx={{ whiteSpace: 'pre-wrap', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}>
              <Box style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                {replaceURLWithHTMLLinks(t('enqueteCreate.seriesEnquete.infoBox'))}
              </Box>
            </Label>
          </Grid>
          {/** 追加ボタン **/}
          <Grid item xs={1} textAlign={'right'}>
            <StyledButton
              onClick={() => {
                modalNormalMode();
                openEditModal();
              }}
              children={t('button.add')}
              isDisabled={isDisabled}
            />
          </Grid>
        </Grid>

        {/** グリッド **/}
        <EnqueteCreateForm setTargetData={setTargetData} />
      </Stack>

      {/** モーダル **/}
      <SeriesEnqueteCreateModal surveyDetailId={enqueteData.surveyDetailId} targetData={targetData} />
    </>
  );
};
export default EnqueteCreateTab;
