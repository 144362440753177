import { useTranslation } from 'react-i18next';
import { SeriesSurveyFormAggregationData, SeriesSurveyFormTabData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

import { useSnackbar } from 'notistack5';
import { Dispatch, SetStateAction } from 'react';
import { RootState, useAppSelector } from 'src/redux/store';
import { SeriesEnqueteRowData } from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteModal';

export const useSeriesEnqueteCreateModalValidation = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  /**
   * 集約先として設定された際のバリデーションを行う
   * @param sbuCode 入力パラメタ
   * @param selectedModalData 追加データ
   * @param selectedModalRowData 既に追加済みのデータ
   * @returns バリデーションエラー時 true
   */
  const isDestinationValid = (
    sbuCode: string | undefined,
    selectedModalData: SeriesEnqueteRowData,
    selectedModalRowData: SeriesSurveyFormAggregationData[],
  ): boolean => {
    if (!sbuCode) return false;
    // すでにモーダル内で他の集約元に設定しているか
    if (selectedModalData.sourceSbuCode === sbuCode) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t('enqueteCreate.seriesEnquete.source') }), {
        variant: 'warning',
      });
      return true;
    }
    // すでに追加データ内で他の集約元に設定しているか
    let validSbu = validAddData(selectedModalRowData, sbuCode, false);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    // すでに登録データ内で他の集約元に設定しているか
    validSbu = validAddData(aggregationList, sbuCode, false);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceRegistered', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    return false;
  };

  /**
   * 集約もととして設定された際のバリデーションを行う
   * @param sbuCode 入力パラメタ
   * @param selectedModalData モーダルに追加するデータ
   * @param selectedModalRowData 既にモーダルに追加済みのデータ

   * @returns バリデーションエラー時 true
   */
  const isSourceValid = (
    sbuCode: string | undefined,
    selectedModalData: SeriesEnqueteRowData,
    selectedModalRowData: SeriesSurveyFormAggregationData[],
  ): boolean => {
    if (!sbuCode) return false;
    // すでにモーダル内で集約先で設定しているか
    if (selectedModalData.destinationSbuCode === sbuCode) {
      enqueueSnackbar(
        t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t('enqueteCreate.seriesEnquete.destination') }),
        {
          variant: 'warning',
        },
      );
      return true;
    }
    // すでに追加データで集約元に設定していないか
    let validSbu = validAddData(selectedModalRowData, sbuCode, false);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceSelected', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    const survayDetail = enqueteData.surveyDetail;
    const aggregationList = (survayDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];

    // すでに登録済みデータで集約元に設定していないか
    validSbu = validAddData(aggregationList, sbuCode);
    if (validSbu) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySourceRegistered', { target: t(validSbu) }), {
        variant: 'warning',
      });
      return true;
    }
    return false;
  };

  /**
   * SBU集約情報にSBUCodeが存在するかチェックを行い存在する場合は、存在する集約元、集約先の文字列を返却する
   * @param rows SBU集約情報
   * @param sbuCode 存在チェックを行うSBUCode
   * @param isSource
   * @returns SBU集約情報にSBUCodeが存在しない場合 undefined 存在する場合は、存在する集約元、集約先の文字列を返却
   */
  const validAddData = (rows: SeriesSurveyFormAggregationData[], sbuCode: string, isSource: boolean = true) => {
    // すでに集約先で設定されているか、確認
    let result: string | undefined = undefined;
    for (const row of rows) {
      for (const sbu of row.sbu) {
        if (sbu.destination.sbuCode === sbuCode && isSource) {
          // 集約元の判定の時のみ、集約先に設定されているかチェック
          result = 'enqueteCreate.seriesEnquete.destination';
          break;
        }
        // すでに集約元で設定されているか、確認
        for (const source of sbu.source) {
          if (source.sbuCode === sbuCode) {
            result = 'enqueteCreate.seriesEnquete.source';
            break;
          }
        }
      }
      if (result) {
        break;
      }
    }
    return result;
  };

  // 追加On/Off
  // 未選択バリデーション
  /**
   *
   * @param selectedModalData バリデーションチェック対象データ
   * @param setIsEnableSelect set関数
   */
  const checkSelectedValue = (selectedModalData: SeriesEnqueteRowData, setIsEnableSelect: Dispatch<SetStateAction<boolean>>) => {
    // モーダルにすべての値がセットされていること
    const isEnable = !!selectedModalData.divisionCode && !!selectedModalData.destinationSbuCode && !!selectedModalData.sourceSbuCode;
    setIsEnableSelect(isEnable);
  };

  /**
   * 重複チェックを行う
   * @param aggregationData 重複チェック対象データ
   * @param aggregationDataList 重複確認を行うデータリスト
   * @returns 重複がある場合 true
   */
  const isDuplication = (aggregationData: SeriesEnqueteRowData, aggregationDataList: SeriesSurveyFormAggregationData[]) => {
    let isDuplication = false;
    aggregationDataList
      .filter((data) => data.divisionCode === aggregationData.divisionCode)
      .filter(({ sbu }) =>
        sbu
          .filter(({ destination }) => aggregationData.destinationSbuCode === destination.sbuCode)
          .forEach(({ source }) => (isDuplication = source.some((v) => v.sbuCode === aggregationData.sourceSbuCode))),
      );
    return isDuplication;
  };

  return {
    isSourceValid,
    isDestinationValid,
    checkSelectedValue,
    isDuplication,
  };
};
