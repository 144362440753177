import { Box } from '@mui/material';
import React from 'react';
import { DuplicateWarnTemporaryOrganization } from 'src/@types/DuplicateWarnMessage';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { useOutsideTemporaryOrganizationsGrid } from '../hooks/useOutsideTemporaryOrganizationsGrid';

interface Props {
  outsideTemporaryOrganizations: DuplicateWarnTemporaryOrganization[];
}

const OutsideTemporaryOrganizationsGrid: React.FC<Props> = (props) => {
  const { outsideTemporaryOrganizations } = props;
  const { isLight, isLoading, defaultColDef, duplicateWarnTemporaryOrganizationColDef } = useOutsideTemporaryOrganizationsGrid();

  return (
    <Box
      component={'div'}
      sx={{ width: '100%', height: '480px' }}
      className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
      data-testid={'aggrid'}>
      <LoadableComponent isLoading={isLoading} zIndex={1000}>
        <StyledAgGrid defaultColDef={defaultColDef} coldef={duplicateWarnTemporaryOrganizationColDef} rows={outsideTemporaryOrganizations} />
      </LoadableComponent>
    </Box>
  );
};

export default OutsideTemporaryOrganizationsGrid;
