import { FormControl, Grid } from '@material-ui/core';
import { SelectChangeEvent } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrgLevelType } from 'src/constants';
import { ORG_LEVEL_TYPE, TOrgLevelType } from '../hooks/useOrganizationDetail';
import { SelectControl } from './SelectControl';

type Props = {
  isGroupCompany: boolean;
  isEditMode: boolean;
  isLoading: boolean;
  methods: UseFormReturn;
  changeUpperOrgLevelType: (_orgLevelType: TOrgLevelType) => 'COMPANY' | 'DIVISION' | 'SBU' | 'DEPARTMENT';
  organizationBaseDate: Date;
  getOrganizations: (orgLevelType: OrgLevelType, organizationBaseDate: Date, isS500Only?: boolean) => Promise<void>;
};

export const OrgLevelTypeSelect = (props: Props) => {
  const { isGroupCompany, isEditMode, isLoading, methods, changeUpperOrgLevelType, organizationBaseDate, getOrganizations } = props;
  const { t } = useTranslation();
  const { setValue } = methods;

  const changeOrgLevelType = useCallback(
    (e, name) => {
      setValue(name, e.target.value as TOrgLevelType);
      const filter = name === 'orgLevelType' ? changeUpperOrgLevelType(e.target.value) : e.target.value;
      getOrganizations(filter, organizationBaseDate);
    },
    [changeUpperOrgLevelType, getOrganizations, organizationBaseDate, setValue],
  );

  const orgLevelTypeSelectItems = ORG_LEVEL_TYPE.filter((x) => x !== 'COMPANY').map((x) => {
    return { key: x, name: t(`complementaryOrganizationDetail.orgLevelType.${x.toLowerCase()}`) };
  });
  const managedOrgLevelTypeSelectItems = ORG_LEVEL_TYPE.filter((x) => x !== 'COMPANY').map((x) => {
    return { key: x, name: t(`complementaryOrganizationDetail.managedOrgLevelType.${x.toLowerCase()}`) };
  });

  const orgLevelTypeSelect = useMemo(() => {
    const name = isGroupCompany ? 'managedOrgLevelType' : 'orgLevelType';
    return (
      <FormControl sx={{ width: '50%' }} size="small">
        <SelectControl
          label={t(`complementaryOrganizationDetail.helpText.${name}`)}
          control={methods.control}
          name={name}
          items={isGroupCompany ? managedOrgLevelTypeSelectItems : orgLevelTypeSelectItems}
          disabled={isEditMode}
          onChange={(e: SelectChangeEvent<string>) => {
            changeOrgLevelType(e, name);
          }}
          isLoading={isLoading}
        />
      </FormControl>
    );
  }, [isGroupCompany, t, methods.control, managedOrgLevelTypeSelectItems, orgLevelTypeSelectItems, isEditMode, isLoading, changeOrgLevelType]);

  return (
    <Grid item xs={12}>
      {orgLevelTypeSelect}
    </Grid>
  );
};
