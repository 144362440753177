import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const temporaryOrganizationSlice = createSlice({
  name: 'temporaryOrganization',
  initialState: {
    isOpenEditModal: false,
    isLoading: false,
    refreshCounter: 0,
    isErrorCompanyNames: false,
    isErrorDivisionNames: false,
    isErrorDivisionPullDown: false,
    isErrorSbuNames: false,
    isErrorSbuPullDown: false,
    isErrorDepartmentNames: false,
    isErrorDepartmentPullDown: false,
    isErrorGroupNames: false,
    isErrorGroupPullDown: false,
    isErrorRegionPullDown: false,
    isErrorCountryPullDown: false,
    isErrorRankPullDown: false,
  },
  reducers: {
    setIsOpenEditModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenEditModal = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateRefreshCounter: (state) => {
      state.refreshCounter++;
    },
    setIsErrorCompanyNames: (state, action: PayloadAction<boolean>) => {
      state.isErrorCompanyNames = action.payload;
    },
    setIsErrorDivisionNames: (state, action: PayloadAction<boolean>) => {
      state.isErrorDivisionNames = action.payload;
    },
    setIsErrorDivisionPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorDivisionPullDown = action.payload;
    },
    setIsErrorSbuNames: (state, action: PayloadAction<boolean>) => {
      state.isErrorSbuNames = action.payload;
    },
    setIsErrorSbuPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorSbuPullDown = action.payload;
    },
    setIsErrorDepartmentNames: (state, action: PayloadAction<boolean>) => {
      state.isErrorDepartmentNames = action.payload;
    },
    setIsErrorDepartmentPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorDepartmentPullDown = action.payload;
    },
    setIsErrorGroupNames: (state, action: PayloadAction<boolean>) => {
      state.isErrorGroupNames = action.payload;
    },
    setIsErrorGroupPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorGroupPullDown = action.payload;
    },
    setIsErrorRegionPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorRegionPullDown = action.payload;
    },
    setIsErrorCountryPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorCountryPullDown = action.payload;
    },
    setIsErrorRankPullDown: (state, action: PayloadAction<boolean>) => {
      state.isErrorRankPullDown = action.payload;
    },
    resetError: (state) => {
      state.isErrorCompanyNames = false;
      state.isErrorDivisionNames = false;
      state.isErrorDivisionPullDown = false;
      state.isErrorSbuNames = false;
      state.isErrorSbuPullDown = false;
      state.isErrorDepartmentNames = false;
      state.isErrorDepartmentPullDown = false;
      state.isErrorGroupNames = false;
      state.isErrorGroupPullDown = false;
      state.isErrorRegionPullDown = false;
      state.isErrorCountryPullDown = false;
      state.isErrorRankPullDown = false;
    },
  },
});

export const { resetError } = temporaryOrganizationSlice.actions;
