import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import lodash from 'lodash';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { ParentCustomer } from 'src/@types/seriesEnquete';
import { apiGateway } from 'src/config';
import { SeriesWarningCode } from 'src/constants';
import {
  ConfirmHistoryEntity,
  ConfirmRequestEntity,
  EnqueteAnswerEntity,
  enqueteAnswerSlice,
  RespondentRequestEntity,
  RespondentTargetEntity,
  SurveyDetailEntity,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { setInitialState } from 'src/features/general/enquete-answer/store/sendTextModalSlice';
import { SeriesErrorCode } from 'src/features/general/enquete-create/hooks/useImportExcelValidate';
import { useAppDispatch } from '../redux/store';
import { useBearerToken } from './useBearerToken';
import { UserEntity } from './useUserApi';

//回答者一覧エンティティ
export interface RespondentsListEntity {
  targetOrganizationId: string;
  rank: string;
  companyCode: string;
  targetCompanyNameJpn: string;
  targetCompanyNameEng: string;
  targetDivisionCode: string;
  targetDivisionNameJpn: string;
  targetDivisionNameEng: string;
  targetSbuCode: string;
  targetSbuNameJpn: string;
  targetSbuNameEng: string;
  targetGroupCode: string;
  targetGroupNameJpn: string;
  targetGroupNameEng: string;
  targetDepartmentCode: string;
  targetDepartmentNameJpn: string;
  targetDepartmentNameEng: string;
  regionCode: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryNameJpn: string;
  countryNameEng: string;
  targetOrgIsDeleted: string;
  personCompanyCode: string;
  personOrganizationId: string;
  personCompanyNameJpn: string;
  personCompanyNameEng: string;
  personDivisionCode: string;
  personDivisionNameJpn: string;
  personDivisionNameEng: string;
  personSbuCode: string;
  personSbuNameJpn: string;
  personSbuNameEng: string;
  personDepartmentCode: string;
  personDepartmentNameJpn: string;
  personDepartmentNameEng: string;
  userNameJpn: string;
  userNameEng: string;
  email: string;
  bluePageEmailAddress: string;
  assignedCompanyNameJpn: string;
  assignedCompanyNameEng: string;
  respondentPersonId: string;
  createdAt: string;
  sendedAt: string;
  respondentTargetId: string;
}

//回答者配列エンティティ
export interface RespondentPersonEntity {
  email: string | null;
  bluePageEmailAddress: string | null;
  userNameJpn: string | null;
  userNameEng: string | null;
  companyCode: string | null;
  assignedCompanyNameJpn: string | null;
  assignedCompanyNameEng: string | null;
  divisionCode: string | null;
  sbuCode: string | null;
  departmentCode: string | null;
  groupCode: string | null;
}

//回答者追加エンティティ
export interface AddRespondentsEntity {
  respondentRequestId: string;
  organizationId: string;
  respondentPersons: RespondentPersonEntity[];
}

export interface requestReversionData {
  respondentTargetId: string;
  requesterName: string;
  requesterNameEng: string;
  requesterEmail: string;
  requestComment: string;
  surveyOwnerInfo: UserEntity[];
}

export interface respondent {
  userEmail: string;
  userName: string;
  userNameEng: string;
}

export interface executeReversionData {
  executorName: string;
  executorNameEng: string;
  executorEmail: string;
  executeComment: string;
  respondents: respondent[];
}

export interface SeriesAnswerEntity {
  warnCodes: SeriesImportResult[];
  answer: EnqueteAnswerEntity;
}

export interface SeriesImportResult {
  code: SeriesWarningCode | SeriesErrorCode;
  detail: number | string;
}

export const useEnqueteAnswerInputApi = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * (組織単位での)回答結果エクスポート
   *
   * @param respondentRequestId
   * @returns
   */
  const exportAnswerResult = async (respondentRequestId: string, respondentTargetId: string, lang: 'jpn' | 'eng'): Promise<boolean | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      await axios({
        url: apiGateway + `/enquete-answer-input/download/${respondentRequestId}/${respondentTargetId}?lang=${lang}`,
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: token },
      }).then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = format(new Date(), 'yyyyMMddHHmmss') + '_answer_list.xlsx';
        a.click();
        a.remove();
      });

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useEnqueteStatusPageApi.failedExport'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * (組織単位での)回答インポート
   *
   * @param respondentRequestId
   * @returns
   */
  const importAnswer = async (respondentRequestId: string, respondentTargetId: string, file: FormData): Promise<AxiosResponse<any> | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios({
        url: apiGateway + `/enquete-answer-input/upload/${respondentRequestId}/${respondentTargetId}`,
        method: 'POST',
        data: file,
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      } else {
        throw error;
      }
    }
  };

  /**
   * 過去の回答データ取得
   *
   * @param respondentRequestId
   * @param organizationId
   * @returns
   */
  const getPastAnswer = async (respondentTargetId: string): Promise<EnqueteAnswerEntity | AxiosResponse<any> | undefined> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.get<EnqueteAnswerEntity>(
        `${apiGateway}/enquete-answer-input/previous-answer/${respondentTargetId}`,
        {
          headers: { Authorization: token },
          timeout: 60000,
        },
      );

      if (!response.data) return;

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答者一覧情報取得
   *
   * @param respondentRequestId
   * @param respondentTargetId
   * @returns
   */
  const getRespondentsList = async (respondentRequestId: string, respondentTargetId: string): Promise<RespondentsListEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.get(
        apiGateway +
          '/enquete-answer-input/respondent-person?respondentRequestId=' +
          respondentRequestId +
          '&respondentTargetId=' +
          respondentTargetId,
        { headers: { Authorization: token }, timeout: 60000 },
      );

      if (response.data.length === 0) return [];

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useEnqueteAnswerPageApi.failedGetRespondentsList'), { variant: 'error' });
        return [];
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答者を登録
   *
   * @param respondentRequestId
   * @param respondentTargetId
   * @param organizationId
   * @param registerRespondentPersonsList
   * @returns
   */
  const registerRespondents = async (
    respondentRequestId: string,
    respondentTargetId: string,
    organizationId: string,
    registerRespondentPersonsList: RespondentPersonEntity[],
  ): Promise<boolean | undefined> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.post(
        apiGateway + '/enquete-answer-input/respondent-person',
        {
          respondentTargetsFromClient: [
            {
              respondentRequestId: respondentRequestId,
              organizationId: organizationId,
              respondentPersons: registerRespondentPersonsList,
            },
          ],
          respondentTargetId: respondentTargetId,
        },
        { headers: { Authorization: token }, timeout: 60000 },
      );

      if (response.data.length === 0) return false;

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.message === 'E610030') {
          enqueueSnackbar(t('apiExceptionMessage.useEnqueteAnswerPageApi.failedSendRegisterRespondentsMail'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('apiExceptionMessage.useEnqueteAnswerPageApi.failedRegisterRespondents'), { variant: 'error' });
        }

        return false;
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答者を削除
   *
   * @param respondentTargetId
   * @param respondentPersonIds
   * @returns
   */
  const deleteRespondents = async (respondentTargetId: string, respondentPersonIds: string[]): Promise<boolean | undefined> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.put(
        apiGateway + '/enquete-answer-input/respondent-person?respondentTargetId=' + respondentTargetId,
        respondentPersonIds,
        { headers: { Authorization: token }, timeout: 60000 },
      );

      if (response.data.length === 0) return false;
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useEnqueteAnswerPageApi.failedDeleteRespondents'), { variant: 'error' });
        return false;
      } else {
        throw error;
      }
    }
  };

  // do not export, this is a common method within these hooks.
  const get = async <T>(path: string, params?: {}): Promise<T | undefined> => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<T>(`${apiGateway}/enquete-answer-input/${path}`, {
        headers: { Authorization: token },
        params: params,
        timeout: 60000,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: path }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  // do not export, this is a common method within these hooks.
  const getByRespondentTargetId = async <T>(resource: string, respondentTargetId: string): Promise<T | undefined> => {
    try {
      return await get<T>(resource, { respondentTargetId: respondentTargetId });
    } catch (error) {
      throw error;
    }
  };

  const getRespondentTarget = async (respondentTargetId: string): Promise<RespondentTargetEntity | undefined> => {
    try {
      return await getByRespondentTargetId('respondent-target', respondentTargetId);
    } catch (error) {
      throw error;
    }
  };

  const getRespondentRequest = async (respondentTargetId: string): Promise<RespondentRequestEntity | undefined> => {
    try {
      return await getByRespondentTargetId('respondent-request', respondentTargetId);
    } catch (error) {
      throw error;
    }
  };

  const getSurveyDetail = async (respondentTargetId: string): Promise<SurveyDetailEntity | undefined> => {
    try {
      return await getByRespondentTargetId('survey-detail-header', respondentTargetId);
    } catch (error) {
      throw error;
    }
  };

  const getAnswer = async (respondentTargetId: string): Promise<EnqueteAnswerEntity | undefined> => {
    try {
      return await getByRespondentTargetId('answer', respondentTargetId);
    } catch (error) {
      throw error;
    }
  };

  const getConfirmRequest = async (confirmRequestId: string): Promise<ConfirmRequestEntity | undefined> => {
    try {
      return await get(`confirm-request/${confirmRequestId}`);
    } catch (error) {
      throw error;
    }
  };

  const getConfirmHistoryData = async (answerId: string): Promise<ConfirmHistoryEntity[]> => {
    try {
      const response = await get<ConfirmHistoryEntity[]>(`confirm-requests/${answerId}`);
      // 確認依頼日時順（降順）
      const sortedList = lodash.orderBy(response, (obj) => new Date(obj.requestedDate), 'desc');
      return sortedList;
    } catch (error) {
      throw error;
    }
  };

  /**
   * 差戻依頼メールとその通知メールを送信
   * @param respondentTargetId
   * @param comment
   */
  const postRequestReversion = async (respondentTargetId: string, comment: string) => {
    try {
      const token = await getBearerToken();
      await axios.post(
        apiGateway + `/enquete-answer-input/request-reversion/${respondentTargetId}`,
        { comment },
        {
          headers: { Authorization: token },
        },
      );
      enqueueSnackbar(t('message.send'), { variant: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedSend', { target: t('enqueteAnswerInput.modal.requestReversion.title') }), {
          variant: 'error',
        });
      } else {
        throw error;
      }
    } finally {
      dispatch(setInitialState());
    }
  };

  /**
   * respondentTargetIdで回答者を取得
   * @param respondentTargetId
   * @returns
   */
  const getRespondentPersonByRespondentTargetId = async (respondentTargetId: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<RespondentPersonEntity[]>(`${apiGateway}/enquete-answer-input/respondent/${respondentTargetId}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.surveyOwner') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * currencyUnit,currentYearで指定された年度の決算レートを取得
   * @param currencyUnit
   * @param currentYear
   * @returns
   */
  const getRate = async (currencyUnit: string, currentYear: number) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get(`${apiGateway}/enquete-answer-input/rate/${currencyUnit}/${currentYear}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.surveyOwner') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 差戻メールとその通知メールを送信
   * @param respondentTargetId
   * @param comment
   */
  const executeReversion = async (respondentTargetId: string, comment: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios.post(
        apiGateway + `/enquete-answer-input/execute-reversion/${respondentTargetId}`,
        { comment },
        {
          headers: { Authorization: token },
        },
      );
      dispatch(enqueteAnswerSlice.actions.setEnqueteAnswerData(response.data));
      enqueueSnackbar(t('message.returned'), { variant: 'success' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedSend', { target: t('enqueteAnswerInput.modal.reversion.title') }), { variant: 'error' });
      } else {
        throw error;
      }
    } finally {
      dispatch(setInitialState());
    }
  };

  /**
   * 初期値提案値を取得
   * @param data
   */
  const initialValueProposalAll = async (respondentTargetId: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<ParentCustomer[]>(apiGateway + `/enquete-answer-input/proposal-initial-value/${respondentTargetId}`, {
        headers: { Authorization: token },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteAnswerInput.seriesSurvey.initialValueProposal') }), {
          variant: 'error',
        });
      } else {
        throw error;
      }
    }
  };

  /**
   * 初期値提案値を取得
   * @param data
   */
  const initialValueProposalCustomer = async (respondentTargetId: string, customers: string[]) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<ParentCustomer[]>(apiGateway + `/enquete-answer-input/proposal-initial-value/${respondentTargetId}`, {
        headers: { Authorization: token },
        params: { customers },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('enqueteAnswerInput.seriesSurvey.initialValueProposal') }), {
          variant: 'error',
        });
      } else {
        throw error;
      }
    }
  };

  return {
    exportAnswerResult,
    importAnswer,
    getPastAnswer,
    getRespondentsList,
    registerRespondents,
    deleteRespondents,
    getRespondentTarget,
    getRespondentRequest,
    getSurveyDetail,
    getAnswer,
    getConfirmRequest,
    getConfirmHistoryData,
    postRequestReversion,
    getRespondentPersonByRespondentTargetId,
    getRate,
    executeReversion,
    initialValueProposalAll,
    initialValueProposalCustomer,
  };
};
