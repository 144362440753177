import { Box } from '@mui/material';
import { ColDef, GetContextMenuItems, GetContextMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DivisionRegionEditorDataTypes, useVisibleConditionsApi } from 'src/api/useVisibleConditionsApi';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { ModalGridProps } from 'src/components/app-components/bonsai/SelectEditorModal';
import { useDivisionRegionEditor } from 'src/features/general/enquete-create/hooks/useDivisionRegionEditor';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';

////// Interface //////
// Page Component Types
interface PageComponentTypes<T> {
  defaultColDef?: ColDef;
  grid: ModalGridProps<T>;
  rowData: DivisionRegionEditorDataTypes[] | undefined;
  surveyDetailId: string | undefined;
  refreshRowData: () => void;
  editRowCallback: (data: DivisionRegionEditorDataTypes) => void;
}

const DivisionRegionEditorGrid = <T,>(props: PageComponentTypes<T>) => {
  const { defaultColDef, grid, rowData, surveyDetailId, refreshRowData, editRowCallback } = props;
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { isViewerAdmin, isSurveyOwner } = usePermission();

  const { deleteVisibleCondition } = useVisibleConditionsApi();
  const { startLoading } = useDivisionRegionEditor();

  /***********************************************************************************
   * Store
   ***********************************************************************************/
  const isLoading = useAppSelector((state) => state.divisionRegionEditor.isLoading);

  /***********************************************************************************
   * Use Status
   ***********************************************************************************/
  const [offsetHeight, setOffsetHeight] = useState<string>('100vh');

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // 編集処理を定義
  const editRowData = (rowData: DivisionRegionEditorDataTypes) => editRowCallback(rowData);
  const onRowDoubleClickedCallback = (e: RowDoubleClickedEvent) => editRowCallback(e.data);

  // 行情報を削除
  const deleteRow = async (row: DivisionRegionEditorDataTypes) => {
    if (!row || !surveyDetailId) return;
    startLoading();

    // 削除
    await deleteVisibleCondition(surveyDetailId, row.id);

    // 一覧のリフレッシュ(本関数内でローディング解除)
    await refreshRowData();
  };

  /***********************************************************************************
   * Context Menu
   ***********************************************************************************/
  const contextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.edit'),
        action: () => {
          if (params.node) editRowData(params.node.data);
        },
        disabled: isViewerAdmin && !isSurveyOwner,
      },
      {
        name: t('button.delete'),
        action: () => {
          if (params.node) deleteRow(params.node.data);
        },
        disabled: isViewerAdmin && !isSurveyOwner,
      },
    ];
  };

  /***********************************************************************************
   * Use Effect
   ***********************************************************************************/
  useEffect(() => {
    // calc data table height
    const appHeader = document.getElementsByTagName('header');
    const pageHeader = document.getElementById('page-header');
    if (pageHeader && appHeader.length > 0) {
      setOffsetHeight(`calc(100vh - ${pageHeader?.clientHeight}px - ${appHeader[0]?.clientHeight}px)`);
    }
  }, []); //eslint-disable-line

  return (
    <Box
      component={'div'}
      sx={{ width: '100%', height: offsetHeight }}
      className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
      data-testid={'aggrid'}>
      <LoadableComponent isLoading={isLoading} zIndex={1000}>
        <StyledAgGrid
          defaultColDef={defaultColDef}
          coldef={grid.colDefs}
          rows={rowData}
          contextMenu={contextMenuItems}
          onRowDoubleClickedCallback={onRowDoubleClickedCallback}
        />
      </LoadableComponent>
    </Box>
  );
};
export default DivisionRegionEditorGrid;
