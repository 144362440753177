import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColDef, ColGroupDef, EditableCallback, ValueParserFunc } from 'ag-grid-community';
import { SurveyFormDataType } from 'src/@types/common';
import { GeneralEnquete } from 'src/@types/generalEnquete';
import { OverviewEnquete, OverviewSurveyFormAnswerData } from 'src/@types/overviewEnquete';
import { SeriesAnswerEnquete } from 'src/@types/seriesEnquete';
import { SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';
import { AnswerStatus, DisclosureLevel, OrgLevelType, SentStatus } from 'src/constants';
import { languageDataTypes } from 'src/hooks/useLocales';

export interface EnqueteAnswersListEntity {
  surveyHeaderId: string;
  surveyDetailId: string;
  respondentRequestId: string;
  respondentTargetId: string;
  respondentPersonId: string;
  requestedDate: string;
  labelJpn: string;
  labelEng: string;
  type: string;
  titleJpn: string;
  titleEng: string;
  ansId: string;
  orgId: string;
  answerStatus: string;
  companyCode?: string;
  companyNameJpn?: string;
  companyNameEng?: string;
  sbuCode?: string;
  sbuNameJpn?: string;
  sbuNameEng?: string;
  divisionCode?: string;
  divisionNameJpn?: string;
  divisionNameEng?: string;
  departmentCode?: string;
  departmentNameJpn?: string;
  departmentNameEng?: string;
  groupCode?: string;
  groupNameJpn?: string;
  groupNameEng?: string;
  openedAt?: string;
  closedAt?: string;
  createdCompany?: string;
  createdCompanyNameJpn?: string;
  createdCompanyNameEng?: string;
  createdDivision?: string;
  createdDivisionNameJpn?: string;
  createdDivisionNameEng?: string;
  userId: string;
  version: number;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}
export interface EnqueteAnswerColDefEntity {
  field: string;
  headerName: string;
  headerTooltip: string;
  tooltipField: string;
  sortable?: boolean;
  editable?: boolean | EditableCallback;
  minWidth: number;
  aggFunc?: string;
  valueParser?: string | ValueParserFunc;
  children?: (ColDef | ColGroupDef)[];
}
export interface EnqueteAnswerInputEntity {
  id: string;
  subItem?: string | languageDataTypes;
  subSubItem?: string | languageDataTypes;
  inputItem: string | languageDataTypes;
  previousYearPlan?: number | undefined;
  resultForecast?: number;
  yearPlan?: number;
  midTermPlan?: number;
}
export interface EnqueteAnswerLoadInputEntity {
  id: string;
  resultForecast?: number;
  yearPlan?: number;
  midTermPlan?: number;
}
export interface EnqueteAnswerEntity {
  id?: string;
  userId: string;
  userName: string | null;
  userNameEng: string | null;
  status?: AnswerStatus;
  respondentTargetId: string;
  answerData: SurveyFormAnswerData;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface OrganizationEntity {
  id?: string;
  companyCode: string;
  companyNameJpn: string;
  companyNameEng: string;
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  sbuCode: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  departmentCode: string;
  departmentNameJpn: string;
  departmentNameEng: string;
  groupCode: string;
  groupNameJpn: string;
  groupNameEng: string;
  countryCode?: string;
  countryNameJpn?: string;
  countryNameEng?: string;
  regionCode?: string;
  regionNameJpn?: string;
  regionNameEng?: string;
  rank?: string;
  orgLevelType?: OrgLevelType;
  isFuture: boolean;
  isTemporary: boolean;
  isMultiSegment: boolean;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}
export interface RespondentTargetEntity {
  id: string;
  respondentRequestId: string;
  organizationId: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string | null;
  isDeleted: boolean;
  organization?: OrganizationEntity;
}
export interface RespondentRequestEntity {
  id: string;
  titleJpn: string;
  titleEng: string;
  targetDate: string | null;
  requestedDate: string;
  openedAt: string;
  closedAt: string;
  createdOrganizationId: string;
  previousRespondentRequestId: string | null;
  organizationBaseDate: string;
  surveyDetailId: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string | null;
  isDeleted: boolean;
}
export interface SurveyDetailEntity {
  id: string;
  version: number;
  surveyFormData: SurveyFormData;
  disclosureLevel: DisclosureLevel;
  sentStatus: SentStatus;
  sentAt: string | null;
  surveyHeader?: SurveyHeaderEntity;
  surveyHeaderId: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string | null;
  isDeleted: boolean;
}
export interface ConfirmRequestEntity {
  id: string;
  requesterId: string;
  requesterName: string;
  confirmerId: string;
  confirmerName: string;
  answerId: string;
  status: string;
  requestedDate: string;
  confirmedDate: string;
  requestComment: string;
  confirmComment: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string | null;
  isDeleted: boolean;
  requesterEmail: string;
  confirmerEmail: string;
}
export interface ConfirmRequestPostEntity {
  id: string;
  requesterId?: string;
  requesterName?: string;
  confirmerId?: string;
  confirmerName?: string;
  answerId?: string;
  status?: string;
  requestedDate?: string;
  confirmedDate?: string;
  requestComment?: string;
  confirmComment?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string | null;
  updatedAt?: string | null;
  isDeleted?: boolean;
  requesterEmail?: string;
  confirmerEmail?: string;
}
export interface ConfirmedRequestDto {
  confirmRequestId: string;
  message: string;
}

export interface ConfirmHistoryEntity {
  id: string;
  confirmRequestId?: string;
  requesterId: string;
  requesterName: string | null;
  requesterNameEng: string | null;
  requesterEmail: string;
  requestedDate: string;
  requestComment: string;
  confirmerId: string | null;
  confirmerName: string | null;
  confirmerNameEng: string | null;
  confirmerEmail: string | null;
  confirmedDate: string | null;
  confirmComment: string | null;
  answerId: string;
  status: AnswerStatus;
  createdBy: string;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string | null;
  isDeleted: boolean;
}

//  共通項目
export interface SurveyFormData {
  enqueteType?: string;
  formData: SurveyFormDataType;
}
// アンケート種別：系列別調査
export interface SeriesSurveyFormData {
  pastRespondentRequestId?: string;
  tabData: SeriesSurveyFormTabData[];
}

// タブ：系列別調査
export interface SeriesSurveyFormTabData {
  questionDescriptionJpn: string;
  questionDescriptionEng: string;
  aggregationList: SeriesSurveyFormAggregationData[];
}

// 系列別調査：集計情報定義
export interface SeriesSurveyFormAggregationData {
  divisionCode: string;
  divisionNameJpn: string;
  divisionNameEng: string;
  organizationId: string;
  sbu: SeriesAggregationSbuInfo[];
}
// 系列別調査票:SBU集計情報
export interface SeriesAggregationSbuInfo {
  destination: SeriesAggregationData;
  source: SeriesAggregationData[];
}

// 系列別調査：集計依頼情報
export interface SeriesAggregationData {
  sbuCode: string;
  sbuNameJpn: string;
  sbuNameEng: string;
  organizationId: string;
}

// アンケート種別：人員調査
export interface PersonnelSurveyFormData {
  pastRespondentRequestId?: string;
  tabData: (
    | PersonnelEmploymentTabData
    | PersonnelFunctionTabData
    | ManufyearPlanurEmploymentTabData
    | ManufyearPlanurEmploymentPriorityDomainTabData
    | PersonnelPriorityDomainTabData
  )[];
  // (上)人件費人員タブの表示名
  personnelTabTitle?: string | languageDataTypes;
  // (上)人件費人員タブの幅
  personnelTabWidth?: {
    en: number;
    ja: number;
  };
  // (上)人件費人員タブの色
  personnelTabStyle?: {
    backgroundColorLight: string;
    backgroundColorDark: string;
  };
  // (上)製造原価人員タブの表示名
  manufyearPlanurTabTitle?: string | languageDataTypes;
  // (上)製造原価人員タブの幅
  manufyearPlanurTabWidth?: {
    en: number;
    ja: number;
  };
  // (上)製造原価人員タブの色
  manufyearPlanurTabStyle?: {
    backgroundColorLight: string;
    backgroundColorDark: string;
  };
}
// タブ：人件費人員（雇用形態別）定義
export interface PersonnelEmploymentTabData {
  id: string;
  tabTitle: string | languageDataTypes;
  tabFormData: (PersonnelSurveyGridFormData | PersonnelSurveyDiffFormData)[];
}
// タブ：人件費人員（機能別）定義
export interface PersonnelFunctionTabData {
  id: string;
  tabTitle: string | languageDataTypes;
  tabFormData: (PersonnelSurveyGridFormData | PersonnelSurveyTextFormData)[];
}
// タブ：人件費人員（重点分野）定義
export interface PersonnelPriorityDomainTabData {
  id: string;
  tabTitle: string | languageDataTypes;
  tabFormData: (PersonnelSurveyGridFormData | PersonnelSurveyDiffFormData)[];
}
// タブ：製造原価人員(雇用形態別)定義
export interface ManufyearPlanurEmploymentTabData {
  id: string;
  tabTitle: string | languageDataTypes;
  tabFormData: (PersonnelSurveyGridFormData | PersonnelSurveyDiffFormData)[];
}
// タブ：製造原価人員(重点分野)定義
export interface ManufyearPlanurEmploymentPriorityDomainTabData {
  id: string;
  tabTitle: string | languageDataTypes;
  tabFormData: (PersonnelSurveyGridFormData | PersonnelSurveyDiffFormData)[];
}
// 人員調査：ガイドライン定義
export interface GridInputGuideLineTypes {
  label: languageDataTypes;
  baseGuideLine: languageDataTypes;
  extendTTCGuideLine: languageDataTypes;
}

// 人員調査：Grid定義
export interface PersonnelSurveyGridFormData {
  id: string;
  tabTitle: string;
  tabValue: string;
  headline: string | languageDataTypes;
  referenceHeadline: string | languageDataTypes;
  title: string | languageDataTypes;
  gridTotalName: string | languageDataTypes;
  referenceGridTotalName: string | languageDataTypes;
  type: string;
  gridHeight: string;
  data: PersonnelSurveyColDef[];
  initialValue: PersonnelSurveyInitialValue[];
  gridInputGuideLine: GridInputGuideLineTypes;
  totalGridInitialValue: string | languageDataTypes;
  totalGridGridTotalName: string | languageDataTypes;
  totalGridHeadline: string | languageDataTypes;
  dataStyle?: {
    backgroundColorLight?: string;
    backgroundColorDark?: string;
  };
  referenceNotTotalHeadline: string | languageDataTypes;
}
// 人員調査：ColDef定義
export interface PersonnelSurveyColDef {
  field: string;
  headerName: string | languageDataTypes;
  headerTooltip: string | languageDataTypes;
  tooltipField: string;
  sortable?: boolean;
  lockPosition: string;
  cellClass: string;
  minWidth: number;
  cellStyle?: {
    backgroundColor?: string;
    textAlign?: string;
  };
  function?: {
    editableCallbackByInput?: boolean;
    valueFormatterByInput?: boolean;
    numberValue?: boolean;
  };
}
// 人員調査：Grid初期値定義
interface PersonnelSurveyInitialValue {
  id: string;
  subItem?: string | languageDataTypes;
  subSubItem?: string | languageDataTypes;
  inputItem: string | languageDataTypes;
  dataStyle?: {
    backgroundColorLight?: string;
    backgroundColorDark?: string;
  };
}
// 人員調査：差異入力フォーム定義
export interface PersonnelSurveyDiffFormData {
  id: string;
  tabTitle: string | languageDataTypes;
  tabValue: string;
  headline: string | languageDataTypes;
  title: string | languageDataTypes;
  type: string;
  labelTitle?: string | languageDataTypes;
  defaultLine?: number;
  minline?: number;
  maxLine?: number;
}
// 人員調査：テキスト定義
export interface PersonnelSurveyTextFormData {
  id: string;
  tabTitle: string;
  tabValue: string;
  title: string | languageDataTypes;
  type: string;
  text?: {
    placeholder: string | languageDataTypes;
    helperText: string | languageDataTypes;
  };
  defaultValue?: string;
  label?: string;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
}
//  [回答]共通項目
export interface SurveyFormAnswerData {
  enqueteType?: string;
  answerInputData: PersonnelSurveyFormAnswerData | GeneralPurposeFormData | SeriesSurveyFormAnswerData | OverviewSurveyFormAnswerData;
}
// アンケート種別：系列別調査票アンケート
export interface SeriesSurveyFormAnswerData {
  pastRespondentRequestId?: string;
  tabData: SeriesAnswerEnquete[];
}

// アンケート種別：汎用アンケート
export interface GeneralPurposeFormData {
  pastRespondentRequestId?: string;
  tabData: GeneralEnquete[];
}

// アンケート種別：人員調査
export interface PersonnelSurveyFormAnswerData {
  tabData: (PersonnelEmploymentTabAnswerData | PersonnelFunctionTabAnswerData | ManufyearPlanurEmploymentTabAnswerData | {})[];
}

// タブ：[回答]人件費人員（雇用形態別）定義
export interface PersonnelEmploymentTabAnswerData {
  tabId: string;
  tabTitle: string;
  tabInputData: (PersonnelSurveyGridAnswerData | PersonnelSurveyDiffFormAnswerData)[];
}
// タブ：[回答]人件費人員（機能別）定義
export interface PersonnelFunctionTabAnswerData {
  tabId: string;
  tabTitle: string;
  tabInputData: (PersonnelSurveyGridAnswerData | PersonnelSurveyTextFormAnswerData)[];
}
// タブ：[回答]人件費人員（重点分野）定義
export interface PersonnelPriorityDomainTabAnswerData {
  tabId: string;
  tabTitle: string;
  tabInputData: (PersonnelSurveyGridAnswerData | PersonnelSurveyDiffFormAnswerData)[];
}
// タブ：[回答]製造原価人員(雇用形態別)定義
export interface ManufyearPlanurEmploymentTabAnswerData {
  tabId: string;
  tabTitle: string;
  tabInputData: (PersonnelSurveyGridAnswerData | PersonnelSurveyDiffFormAnswerData)[];
}
// タブ：[回答]製造原価人員(重点分野)定義
export interface ManufyearPlanurEmploymentPriorityDomainTabAnswerData {
  tabId: string;
  tabTitle: string;
  tabInputData: (PersonnelSurveyGridAnswerData | PersonnelSurveyDiffFormAnswerData)[];
}

// 人員調査：[回答]Grid定義
export interface PersonnelSurveyGridAnswerData {
  id: string;
  type: string;
  title: string;
  tabValue: string;
  data: EnqueteAnswerInputEntity[] | EnqueteAnswerLoadInputEntity[];
}
// 人員調査：[回答]差異入力フォーム定義
export interface PersonnelSurveyDiffFormAnswerData {
  id: string;
  type: string;
  tabValue: string;
  data: PersonnelSurveyDiffFormAnswerInputData[] | TempPersonnelSurveyDiffFormAnswerInputData[];
}
// 人員調査：[回答]差異入力内容定義(送信時のみ使用)
export interface PersonnelSurveyDiffFormAnswerInputData {
  count: number;
  diffMemberInputValue: number | null;
  diffMemberReasonInputValue: string;
  diffMemberReasonSelectValue: string;
}
// 人員調査：[回答]差異入力内容定義(フロントで使用)
export interface TempPersonnelSurveyDiffFormAnswerInputData {
  count: number;
  diffMemberInputValue: string;
  diffMemberReasonInputValue: string;
  diffMemberReasonSelectValue: string;
}
// 人員調査：[回答]差異入力内容定義(重点分野)(送信時のみ使用)
export interface PersonnelSurveyPriorityDomainDiffFormAnswerInputData {
  count: number;
  diffMemberInputValue: number | null;
  diffMemberReasonInputValue: string;
  diffMemberReasonSelectValue: string;
  employment: string; // 雇用形態
  priorityDomain: string; // 重点分野
}
// 人員調査：[回答]差異入力内容定義(重点分野)(フロントで使用)
export interface TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData {
  count: number;
  diffMemberInputValue: string;
  diffMemberReasonInputValue: string;
  diffMemberReasonSelectValue: string;
  employment: string; // 雇用形態
  priorityDomain: string; // 重点分野
}
// 人員調査：[回答]テキスト定義
export interface PersonnelSurveyTextFormAnswerData {
  id: string;
  tabValue: string;
  type: string;
  data: string;
}

// アンケート種別：取引先概況表
export interface OverviewSurveyFormData {
  pastRespondentRequestId?: string;
  tabData: OverviewEnquete[];
}
// アンケート回答を自分宛かを判別するステータス

export const enqueteAnswerSlice = createSlice({
  name: 'enqueteAnswer',
  initialState: {
    hasError: false,
    hasErrorOtherTextForm: false,
    hasErrorPersonnelEmploymentGrid: false,
    hasErrorPersonnelEmploymentResultAndPlanDiffForm: false,
    hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm: false,
    hasErrorPersonnelEmploymentPlanAndPlanDiffForm: false,
    hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm: false,
    hasErrorPersonnelCorporateGrid: false,
    hasErrorPersonnelGridNotMatchTotal: false,
    hasErrorPersonnelCorporateGridTotalOver: false,
    hasErrorPersonnelSalesGridTotalOver: false,
    // 人件費人員(重点分野)：年計-実見 エラー状態
    hasErrorPersonnelPriorityDomainResultAndPlanDiffForm: false,
    // 人件費人員(重点分野)：年計-実見 空エラー状態
    hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm: false,
    // 人件費人員(重点分野)：中計-年計 エラー状態
    hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm: false,
    // 人件費人員(重点分野)：中計-年計 空エラー状態
    hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm: false,
    // 人件費人員(重点分野)1.正社員 バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal: false,
    // 人件費人員(重点分野)2.TTCからの出向受入 バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal: false,
    // 人件費人員(重点分野)3.2以外の出向受入 バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal: false,
    // 人件費人員(重点分野)4.派遣、嘱託、パート、アルバイト バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal: false,
    hasErrorManufyearPlanurEmploymentGrid: false,
    hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm: false,
    hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm: false,
    hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm: false,
    hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm: false,
    // 製造原価人員(重点分野)：年計-実見 エラー状態
    hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm: false,
    // 製造原価人員(重点分野)：年計-実見 空エラー状態
    hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm: false,
    // 製造原価人員(重点分野)：中計-年計 エラー状態
    hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm: false,
    // 製造原価人員(重点分野)：中計-年計
    hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm: false,
    // 製造原価人員(重点分野)1.正社員 バリデーションエラータイプ
    hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal: false,
    // 製造原価人員(重点分野)2.1以外の従業員/派遣/その他 バリデーションエラータイプ
    hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal: false,
    message: '' as string,
    isBeforeRequestTerm: false, // 依頼期間前か
    isEnqueteAnswerOpen: false, // 依頼期間中か
    isAfterRequestTerm: false, // 依頼期間後か
    isConfirmationCommentsModalOpen: false, // 確認コメントモーダルの開閉状態
    enqueteAnswerslistData: [] as EnqueteAnswersListEntity[],
    enqueteAnswerslimitedlistData: [] as EnqueteAnswersListEntity[],
    hasEdited: false,
    enqueteAnswerDataEdit: {
      personnelEmploymentRowData: undefined,
      personnelEmploymentResultAndPlanDiffForm: undefined,
      personnelEmploymentPlanAndPlanDiffForm: undefined,
      personnelCorporateRowData: undefined,
      personnelCorporateOtherFormTextValue: undefined,
      personnelSalesRowData: undefined,
      // 人件費人員（重点分野別）1.正社員の行データ
      personnelPriorityDomainRegularEmployeeRowData: undefined,
      // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
      personnelPriorityDomainSecondmentFromTTCRowData: undefined,
      // 人件費人員（重点分野別）3.2以外の出向受入の行データ
      personnelPriorityDomainSecondmentFromOtherRowData: undefined,
      // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
      personnelPriorityDomainDispatchEtcRowData: undefined,
      // 人件費人員（重点分野別）年計 - 実見のデータ
      personnelPriorityDomainResultAndPlanDiffForm: undefined,
      // 人件費人員（重点分野別）中計 - 年計のデータ
      personnelPriorityDomainPlanAndPlanDiffForm: undefined,
      manufyearPlanurEmploymentRowData: undefined,
      manufyearPlanurEmploymentResultAndPlanDiffForm: undefined,
      manufyearPlanurEmploymentPlanAndPlanDiffForm: undefined,
      // 製造原価人員（重点分野別）1.正社員の行データ
      manufyearPlanurPriorityDomainRegularEmployeeRowData: undefined,
      // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
      manufyearPlanurPriorityDomainOtherEmployeesRowData: undefined,
      // 製造原価人員（重点分野別）年計 - 実見のデータ
      manufyearPlanurPriorityDomainResultAndPlanDiffForm: undefined,
      // 製造原価人員（重点分野別）中計 - 年計のデータ
      manufyearPlanurPriorityDomainPlanAndPlanDiffForm: undefined,
    },
    personnelEmploymentTotal: {} as EnqueteAnswerInputEntity,
    corporateGridTotal: {} as EnqueteAnswerInputEntity,
    salesGridTotal: {} as EnqueteAnswerInputEntity,
    //人件費人員(重点分野)：1.正社員のTotal
    personnelPriorityDomainRegularEmployeeGridTotal: {} as EnqueteAnswerInputEntity,
    // 人件費人員(重点分野)：2.TTCからの出向受入のTotal
    personnelPriorityDomainSecondmentFromTTCGridTotal: {} as EnqueteAnswerInputEntity,
    // 人件費人員(重点分野)：3.2.以外の出向受入のTotal
    personnelPriorityDomainSecondmentFromOtherGridTotal: {} as EnqueteAnswerInputEntity,
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトのTotal
    personnelPriorityDomainDispatchEtcGridTotal: {} as EnqueteAnswerInputEntity,
    // 製造原価人員(重点分野)：1.正社員のTotal
    manufyearPlanurPriorityDomainRegularEmployeeGridTotal: {} as EnqueteAnswerInputEntity,
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他のTotal
    manufyearPlanurPriorityDomainOtherEmployeesGridTotal: {} as EnqueteAnswerInputEntity,
    surveyDetailData: {} as SurveyDetailEntity,
    confirmRequestData: {} as ConfirmRequestEntity,
    respondentTargetData: {} as RespondentTargetEntity,
    respondentRequestData: {} as RespondentRequestEntity,
    enqueteAnswerData: {} as EnqueteAnswerEntity,
    pastEnqueteAnswerData: [] as EnqueteAnswerEntity[],
    confirmHistoryData: [] as ConfirmHistoryEntity[],
    isImporting: false,
    isAll: true,
    page: 1 as number,
  },
  reducers: {
    hasError(state, action: PayloadAction<boolean>) {
      state.hasError = action.payload;
    },
    hasErrorOtherTextForm(state, action: PayloadAction<boolean>) {
      state.hasErrorOtherTextForm = action.payload;
    },
    hasErrorPersonnelEmploymentGrid(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelEmploymentGrid = action.payload;
    },
    hasErrorPersonnelEmploymentResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelEmploymentResultAndPlanDiffForm = action.payload;
    },
    hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm = action.payload;
    },
    hasErrorPersonnelEmploymentPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelEmploymentPlanAndPlanDiffForm = action.payload;
    },
    hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm = action.payload;
    },
    hasErrorPersonnelCorporateGrid(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelCorporateGrid = action.payload;
    },
    hasErrorPersonnelGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelGridNotMatchTotal = action.payload;
    },
    hasErrorPersonnelCorporateGridTotalOver(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelCorporateGridTotalOver = action.payload;
    },
    hasErrorPersonnelSalesGridTotalOver(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelSalesGridTotalOver = action.payload;
    },
    // 人件費人員(重点分野)：年計-実見　エラー状態
    hasErrorPersonnelPriorityDomainResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelPriorityDomainResultAndPlanDiffForm = action.payload;
    },
    // 人件費人員(重点分野)：年計-実見　空エラー状態
    hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm = action.payload;
    },
    // 人件費人員(重点分野)：中計-年計　エラー状態
    hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm = action.payload;
    },
    // 人件費人員(重点分野)：中計-年計　空エラー状態
    hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm = action.payload;
    },
    // 人件費人員(重点分野)正社員バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal = action.payload;
    },
    // 人件費人員(重点分野)TTCからの出向受入バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal = action.payload;
    },
    // 人件費人員(重点分野)2以外の出向受入バリデーションエラータイプ
    hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal = action.payload;
    },
    // 人件費人員(重点分野)派遣、嘱託、パート、アルバイトバリデーションエラータイプ
    hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal = action.payload;
    },
    hasErrorManufyearPlanurEmploymentGrid(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurEmploymentGrid = action.payload;
    },
    hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm = action.payload;
    },
    hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm = action.payload;
    },
    hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm = action.payload;
    },
    hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm = action.payload;
    },
    // 製造原価人員(重点分野)：年計-実見　エラー状態
    hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm = action.payload;
    },
    // 製造原価人員(重点分野)：年計-実見　空エラー状態
    hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm = action.payload;
    },
    // 製造原価人員(重点分野)：中計-年計　エラー状態
    hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm = action.payload;
    },
    // 製造原価人員(重点分野)：中計-年計　空エラー状態
    hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(state, action: PayloadAction<boolean>) {
      state.hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm = action.payload;
    },
    // 製造原価人員(重点分野)正社員バリデーションエラータイプ
    hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal = action.payload;
    },
    // 製造原価人員(重点分野)1以外の従業員/派遣/その他バリデーションエラータイプ
    hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal(state, action: PayloadAction<boolean>) {
      state.hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal = action.payload;
    },
    showMessage: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.message = action.payload;
    },
    setBeforeRequestTerm: (state, action: PayloadAction<boolean>) => {
      state.isBeforeRequestTerm = action.payload;
    },
    setEnqueteAnswerOpen: (state, action: PayloadAction<boolean>) => {
      state.isEnqueteAnswerOpen = action.payload;
    },
    setAfterRequestTerm: (state, action: PayloadAction<boolean>) => {
      state.isAfterRequestTerm = action.payload;
    },
    setConfirmationCommentsModalMode: (state, action: PayloadAction<boolean>) => {
      state.isConfirmationCommentsModalOpen = action.payload;
    },
    initEnqueteAnswersListData: (state, action: PayloadAction<EnqueteAnswersListEntity[]>) => {
      state.enqueteAnswerslistData = action.payload;
    },
    initEnqueteAnswersLimitedListData: (state, action: PayloadAction<EnqueteAnswersListEntity[]>) => {
      state.enqueteAnswerslimitedlistData = action.payload;
    },
    setEnqueteAnswerData: (state, action: PayloadAction<EnqueteAnswerEntity>) => {
      state.enqueteAnswerData = action.payload;
    },
    setPastEnqueteAnswerData: (state, action: PayloadAction<EnqueteAnswerEntity[]>) => {
      state.pastEnqueteAnswerData = action.payload;
    },
    setPersonnelEmploymentTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.personnelEmploymentTotal = action.payload;
    },
    setCorporateGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.corporateGridTotal = action.payload;
    },
    setSalesGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.salesGridTotal = action.payload;
    },
    // 人件費人員(重点分野)：1.正社員のTotalを設定
    setPersonnelPriorityDomainRegularEmployeeGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.personnelPriorityDomainRegularEmployeeGridTotal = action.payload;
    },
    // 人件費人員(重点分野)：2.TTCからの出向受入のTotalを設定
    setPersonnelPriorityDomainSecondmentFromTTCGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.personnelPriorityDomainSecondmentFromTTCGridTotal = action.payload;
    },
    // 人件費人員(重点分野)：3.2.以外の出向受入のTotalを設定
    setPersonnelPriorityDomainSecondmentFromOtherGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.personnelPriorityDomainSecondmentFromOtherGridTotal = action.payload;
    },
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトのTotalを設定
    setPersonnelPriorityDomainDispatchEtcGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.personnelPriorityDomainDispatchEtcGridTotal = action.payload;
    },
    // 製造原価人員(重点分野)：1.正社員のTotalを設定
    setManufyearPlanurPriorityDomainRegularEmployeeGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.manufyearPlanurPriorityDomainRegularEmployeeGridTotal = action.payload;
    },
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他のTotalを設定
    setManufyearPlanurPriorityDomainOtherEmployeesGridTotal: (state, action: PayloadAction<EnqueteAnswerInputEntity>) => {
      state.manufyearPlanurPriorityDomainOtherEmployeesGridTotal = action.payload;
    },

    setSurveyDetailData: (state, action: PayloadAction<SurveyDetailEntity>) => {
      state.surveyDetailData = action.payload;
    },
    setConfirmRequestData: (state, action: PayloadAction<ConfirmRequestEntity>) => {
      state.confirmRequestData = action.payload;
    },
    setConfirmHistoryData: (state, action: PayloadAction<ConfirmHistoryEntity[]>) => {
      state.confirmHistoryData = action.payload;
    },
    setRespondentTargetData: (state, action: PayloadAction<RespondentTargetEntity>) => {
      state.respondentTargetData = action.payload;
    },
    setRespondentRequestData: (state, action: PayloadAction<RespondentRequestEntity>) => {
      state.respondentRequestData = action.payload;
    },

    editAfterSave: (state, action) => {
      state.hasEdited = action.payload;
    },
    setPersonnelEmploymentRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelEmploymentRowData: action.payload });
    },
    setPersonnelEmploymentResultAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelEmploymentResultAndPlanDiffForm: action.payload });
    },
    setPersonnelEmploymentPlanAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelEmploymentPlanAndPlanDiffForm: action.payload });
    },
    setPersonnelCorporateRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelCorporateRowData: action.payload });
    },
    setPersonnelCorporateOtherFormTextValue: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelCorporateOtherFormTextValue: action.payload });
    },
    setPersonnelSalesRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelSalesRowData: action.payload });
    },
    setManufyearPlanurEmploymentRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { manufyearPlanurEmploymentRowData: action.payload });
    },
    setManufyearPlanurEmploymentResultAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        manufyearPlanurEmploymentResultAndPlanDiffForm: action.payload,
      });
    },
    setManufyearPlanurEmploymentPlanAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { manufyearPlanurEmploymentPlanAndPlanDiffForm: action.payload });
    },
    // 人件費人員(重点分野)：1.正社員のRowDataを設定
    setPersonnelPriorityDomainRegularEmployeeRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelPriorityDomainRegularEmployeeRowData: action.payload });
    },
    // 人件費人員(重点分野)：2.TTCからの出向受入のRowDataを設定
    setPersonnelPriorityDomainSecondmentFromTTCRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        personnelPriorityDomainSecondmentFromTTCRowData: action.payload,
      });
    },
    // 人件費人員(重点分野)：3.2以外の出向受入のRowDataを設定
    setPersonnelPriorityDomainSecondmentFromOtherRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        personnelPriorityDomainSecondmentFromOtherRowData: action.payload,
      });
    },
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトのRowDataを設定
    setPersonnelPriorityDomainDispatchEtcRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelPriorityDomainDispatchEtcRowData: action.payload });
    },
    // 人件費人員(重点分野)：年計-実見のRowDataを設定
    setPersonnelPriorityDomainResultAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelPriorityDomainResultAndPlanDiffForm: action.payload });
    },
    // 人件費人員(重点分野)：中計-年計のRowDataを設定
    setPersonnelPriorityDomainPlanAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, { personnelPriorityDomainPlanAndPlanDiffForm: action.payload });
    },
    // 製造原価人員(重点分野)：1.正社員のRowDataを設定
    setManufyearPlanurPriorityDomainRegularEmployeeRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        manufyearPlanurPriorityDomainRegularEmployeeRowData: action.payload,
      });
    },
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他のRowDataを設定
    setManufyearPlanurPriorityDomainOtherEmployeesRowData: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        manufyearPlanurPriorityDomainOtherEmployeesRowData: action.payload,
      });
    },
    // 製造原価人員(重点分野)：年計-実見のRowDataを設定
    setManufyearPlanurPriorityDomainResultAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        manufyearPlanurPriorityDomainResultAndPlanDiffForm: action.payload,
      });
    },
    // 製造原価人員(重点分野)：中計-年計のRowDataを設定
    setManufyearPlanurPriorityDomainPlanAndPlanDiffForm: (state, action) => {
      state.enqueteAnswerDataEdit = Object.assign({}, state.enqueteAnswerDataEdit, {
        manufyearPlanurPriorityDomainPlanAndPlanDiffForm: action.payload,
      });
    },
    resetEnqueteData: (state) => {
      state.personnelEmploymentTotal = {} as EnqueteAnswerInputEntity;
      state.enqueteAnswerData = {} as EnqueteAnswerEntity;
      state.respondentTargetData = {} as RespondentTargetEntity;
      state.respondentRequestData = {} as RespondentRequestEntity;
      state.surveyDetailData = {} as SurveyDetailEntity;
      state.confirmRequestData = {} as ConfirmRequestEntity;
      state.pastEnqueteAnswerData = [];
      state.confirmHistoryData = [];
    },
    setIsImporting: (state, action: PayloadAction<boolean>) => {
      state.isImporting = action.payload;
    },
    setReturnAllAnswer: (state, action) => {
      state.isAll = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const {
  setRespondentTargetData,
  setRespondentRequestData,
  setSurveyDetailData,
  setEnqueteAnswerData,
  setConfirmRequestData,
  setConfirmHistoryData,
  setBeforeRequestTerm,
  setEnqueteAnswerOpen,
  setAfterRequestTerm,
  resetEnqueteData,
  setIsImporting,
} = enqueteAnswerSlice.actions;
