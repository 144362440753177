import { Box } from '@mui/material';
import { RefObject } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ScrollToTopButton } from 'src/components/app-components/bonsai/ScrollToTopButton';
import { EnqueteAnswerGeneralQuestionSaveButtons } from 'src/features/general/enquete-answer/components/pages/input/components/header-box/components/EnqueteAnswerGeneralQuestionSaveButtons';
import { useElementOnScreen } from 'src/hooks/useElementOnScreen';

type EnqueteAnswerGeneralQuestionFloatingSaveButtonsProps = {
  targetRef: RefObject<Element>;
};

export const EnqueteAnswerGeneralQuestionFloatingSaveButtons = ({ targetRef }: EnqueteAnswerGeneralQuestionFloatingSaveButtonsProps) => {
  const respondentTargetId = useParams().id;
  const confirmRequestId: string | null = new URLSearchParams(useLocation().search).get('confirmRequestId');

  const isVisible = useElementOnScreen(targetRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  if (isVisible) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', bottom: 10, right: 2, mr: 6, mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'baseline',
        }}>
        <EnqueteAnswerGeneralQuestionSaveButtons respondentTargetId={respondentTargetId ?? ''} confirmRequestId={confirmRequestId ?? ''} />
        <Box sx={{ ml: 2 }}>
          <ScrollToTopButton />
        </Box>
      </Box>
    </Box>
  );
};
