import { LoadingButton } from '@mui/lab';
import { Autocomplete, Container, FormControl, Grid, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CountryEntity, OmitField } from 'src/api/survey-edit/useComplementaryOrganizationApi';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import RHFDeskTopDatePicker from 'src/components/react-hook-form/RHFDeskTopDatePicker';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import useLocales from 'src/hooks/useLocales';
import { yearMonthFormat } from 'src/utils/formatDateTime';
import { useComplementaryOrganizationDetail } from '../hooks/useComplementaryOrganizationDetail';
import { useOrganizationDetail } from '../hooks/useOrganizationDetail';
import { useOrganizationDetailSave } from '../hooks/useOrganizationDetailSave';
import { useValidator } from '../hooks/useValidator';
import { CompanyGrid } from './CompanyGrid';
import { OrgLevelTypeSelect } from './OrgLevelTypeSelect';
import { RankSelect } from './RankSelect';
import { SelectControl } from './SelectControl';

export type Organization = { key: string; entity: Omit<OrganizationEntity, OmitField> };
export type Country = { key: string | null; entity: CountryEntity };

interface Props {
  setTitle: (sufix: string) => void;
}

export const ComplementaryOrganizationDetail: React.FC<Props> = (props) => {
  const { setTitle } = props;
  const { t } = useTranslation();
  const { id } = useParams();
  const { currentLang } = useLocales();
  const isEditMode = Boolean(id);

  const [options, setOptions] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [tmpCountryCode, setTmpCountryCode] = useState<string>('');

  const [inputOrganization, setInputOrganization] = useState<string>('');
  const [inputCompany, setInputCompany] = useState<string>('');
  const [inputCountry, setInputCountry] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [organizationBaseDate, setOrganizationBaseDate] = useState<Date>(new Date());

  const hookDetail = useComplementaryOrganizationDetail();

  //React-Hook-Form
  const methods = useForm({});
  const { getValues, setValue } = methods;

  // hooks
  const {
    isGroupCompany,
    regionsItems,
    rankItems,
    targetCompanyList,
    targetCountryList,
    targetOrganizationList,
    countriesOptions,
    getDetail,
    setOrganizationDate,
    changeCountry,
    changeUpperOrgLevelType,
    onChangeOrganizationBaseDate,
    getOrganizations,
    getCompanyOrganizations,
  } = useOrganizationDetail(methods, setIsLoading, setOptions, setInputOrganization, setCompanies, setInputCompany, organizationBaseDate);

  // submitイベント
  const { onSubmit } = useOrganizationDetailSave(methods, targetOrganizationList, targetCountryList, targetCompanyList, isGroupCompany);

  // 動的コンポーネントの共通ラベル部
  const organizationLabel = isEditMode ? getValues('managedOrgLevelType')?.toLowerCase() : getValues('orgLevelType')?.toLowerCase();
  // 動的に変わるコンポーネントのラベル群
  const labels = {
    organizationBaseDate: t('common.organizationBaseDate'),
    upperOrg: isGroupCompany ? t(`complementaryOrganizationDetail.helpText.managedOrg`) : t(`complementaryOrganizationDetail.helpText.upperOrg`),
    companyCode: t(`complementaryOrganizationDetail.label.companyCode`),
    code: t(`complementaryOrganizationDetail.label.${organizationLabel || 'division'}Code`),
    jpn: t(`complementaryOrganizationDetail.label.${organizationLabel || 'division'}NameJpn`),
    eng: t(`complementaryOrganizationDetail.label.${organizationLabel || 'division'}NameEng`),
  };

  // Validator
  const {
    applyStartDateRule,
    organizationBaseDateValidation,
    requiredValidation,
    requiredAndLengthValidation,
    codeValidation,
    requiredAndAutoCompleteValidation,
    applyEndDateRule,
  } = useValidator(methods, isEditMode);
  // 各コンポーネントのバリデーション群
  const rules = {
    organizationBaseDate: isEditMode ? undefined : organizationBaseDateValidation(t('common.organizationBaseDate')),
    jpn: !isEditMode && !isGroupCompany ? requiredAndLengthValidation : undefined,
    eng: !isEditMode && !isGroupCompany ? requiredAndLengthValidation : undefined,
    code: !isEditMode && !isGroupCompany ? codeValidation : undefined,
    upperOrg: isEditMode ? undefined : requiredAndAutoCompleteValidation,
    companyCode: !isEditMode && isGroupCompany ? requiredAndAutoCompleteValidation : undefined,
    regionCode: requiredValidation,
    country: requiredAndAutoCompleteValidation,
    applyStartDate: isEditMode ? undefined : applyStartDateRule(),
    applyEndDate: applyEndDateRule(),
  };

  // 初回起動
  useEffect(() => {
    const init = async () => {
      await hookDetail.getRegions();
      await hookDetail.getRanks();
      if (id) {
        // 編集画面時のパターン
        await getDetail(id);
      } else {
        const defaultOrgLevelType = isGroupCompany ? 'COMPANY' : 'DIVISION';
        setValue('orgLevelType', defaultOrgLevelType);
        const defaultManagedOrgLevelType = 'SBU';
        setValue('managedOrgLevelType', defaultManagedOrgLevelType);
        setValue('organizationBaseDate', organizationBaseDate);
        await getOrganizations(isGroupCompany ? defaultManagedOrgLevelType : changeUpperOrgLevelType(defaultOrgLevelType), organizationBaseDate);
        await getCompanyOrganizations(organizationBaseDate);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setTitle(
      t(`complementaryOrganizationDetail.${isEditMode ? 'pageEditTitle' : 'pageCreateTitle'}`) +
        (isGroupCompany ? t('complementaryOrganizationList.companyType.group') : t('complementaryOrganizationList.companyType.ttc')),
    );
    changeCountry(getValues('regionCode'));
    const country = targetCountryList.find((x: any) => x.key === getValues('country'));
    if (country) {
      setTmpCountryCode(country.entity.countryCode ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang.value, isGroupCompany, setTitle, t]);

  useEffect(() => {
    const newCountry = targetCountryList.find((x: Country) => x.entity.countryCode === tmpCountryCode);
    if (newCountry) {
      setValue('country', newCountry.key);
    }
  }, [setValue, targetCountryList, tmpCountryCode]);

  // 組織種別のコンポーネント
  return (
    <Container maxWidth={false}>
      <form
        onSubmit={methods.handleSubmit(() => {
          onSubmit();
        })}>
        {/* 組織基準日 */}
        <Grid container spacing={2}>
          {isEditMode ? (
            <></>
          ) : (
            <Grid item xs={3}>
              <RHFDeskTopDatePicker
                name="organizationBaseDate"
                label={labels.organizationBaseDate}
                control={methods.control}
                mask="____/__/__"
                views={['year', 'month']}
                defaultValue={null}
                openTo="year"
                rules={rules.organizationBaseDate}
                inputFormat={yearMonthFormat}
                type="month"
                disabled={Boolean(id)}
                onChange={(newValue: Date | null) => {
                  if (newValue && isNaN(newValue.getTime())) {
                    return;
                  }
                  onChangeOrganizationBaseDate(newValue as Date);
                  setOrganizationBaseDate(newValue as Date);
                }}
              />
            </Grid>
          )}
          {/* 追加する組織種別 */}
          <OrgLevelTypeSelect
            isGroupCompany={isGroupCompany}
            isEditMode={isEditMode}
            isLoading={isLoading}
            methods={methods}
            changeUpperOrgLevelType={changeUpperOrgLevelType}
            organizationBaseDate={organizationBaseDate}
            getOrganizations={getOrganizations}
          />
          {/* 追加する組織 */}
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <Controller
                name="upperOrg"
                control={methods.control}
                rules={rules.upperOrg ? rules.upperOrg(labels.upperOrg, options) : undefined}
                render={({ field, fieldState }) =>
                  isLoading ? (
                    <Skeleton variant="text" width={400} height={40} />
                  ) : (
                    <Autocomplete
                      {...field}
                      inputValue={inputOrganization}
                      options={options}
                      isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
                      onChange={(_event: React.SyntheticEvent, newValue: string | null) => {
                        setValue('upperOrg', newValue);
                      }}
                      onInputChange={(_event: React.SyntheticEvent, newInputValue) => {
                        setInputOrganization(newInputValue);
                        setOrganizationDate(newInputValue);
                      }}
                      disabled={
                        // NOTE: orgLevelTypeが「DIVISION」の時、上位組織は「豊田通商」のみでありoptionsの要素は1つで「-」となる。その場合、disabledとする。
                        Boolean(id) || options[0] === '-'
                      }
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            name="upperOrg"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            label={labels.upperOrg}
                          />
                        </>
                      )}
                    />
                  )
                }
              />
            </FormControl>
          </Grid>
          {isGroupCompany ? (
            <>
              {/* 会社 */}
              <CompanyGrid
                isEditMode={isEditMode}
                isLoading={isLoading}
                methods={methods}
                inputCompany={inputCompany}
                companies={companies}
                setInputCompany={setInputCompany}
                rule={rules.companyCode}
                label={labels.companyCode}
              />
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <RHFTextField
                  control={methods.control}
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="code"
                  label={labels.code}
                  defaultValue=""
                  rules={rules.code ? rules.code(labels.code) : undefined}
                  disabled={isLoading || Boolean(id)}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  control={methods.control}
                  size="small"
                  variant="outlined"
                  name="jpn"
                  fullWidth
                  defaultValue=""
                  rules={rules.jpn ? rules.jpn(labels.jpn) : undefined}
                  label={labels.jpn}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  control={methods.control}
                  size="small"
                  variant="outlined"
                  fullWidth
                  rules={rules.eng ? rules.eng(labels.eng) : undefined}
                  defaultValue=""
                  name="eng"
                  label={labels.eng}
                  disabled={isLoading}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <SelectControl
                control={methods.control}
                name="regionCode"
                label={t('common.region')}
                items={regionsItems}
                isLoading={isLoading}
                onChange={(e) => {
                  const regionCode = e.target.value as string;
                  setValue('regionCode', regionCode);
                  setInputCountry('');
                  setValue('country', '');
                  changeCountry(regionCode);
                }}
                rules={rules.regionCode(t('common.region'))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <Controller
                name="country"
                control={methods.control}
                rules={rules.country(t(`common.country`), countriesOptions)}
                render={({ field, fieldState }) =>
                  isLoading ? (
                    <Skeleton variant="text" width={400} height={40} />
                  ) : (
                    <Autocomplete
                      {...field}
                      isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
                      inputValue={inputCountry}
                      options={countriesOptions}
                      onChange={(event, newValue) => {
                        setValue('country', newValue);
                      }}
                      onInputChange={(e, newInputValue, reason) => {
                        if (reason === 'reset' && !newInputValue) {
                          return;
                        }
                        setInputCountry(newInputValue);
                      }}
                      disabled={!getValues('regionCode')}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            name="country"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            label={t('common.country')}
                          />
                        </>
                      )}
                    />
                  )
                }
              />
            </FormControl>
          </Grid>
          {isGroupCompany ? (
            <Grid item xs={12} md={4}>
              <RankSelect isLoading={isLoading} methods={methods} rankItems={rankItems} />
            </Grid>
          ) : (
            <Grid item xs={12} md={4} />
          )}

          <Grid item xs={6} md={6}>
            <RHFDeskTopDatePicker
              name="applyStartDate"
              label={t('common.applyStartDate')}
              control={methods.control}
              mask="____/__/__"
              views={['year', 'month']}
              defaultValue={isEditMode ? getValues('applyStartDate') : null}
              openTo="year"
              rules={rules.applyStartDate}
              inputFormat={yearMonthFormat}
              type="month"
              disabled={Boolean(id)}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <RHFDeskTopDatePicker
              name="applyEndDate"
              label={t('common.applyEndDate')}
              control={methods.control}
              mask="____/__/__"
              views={['year', 'month']}
              defaultValue={isEditMode ? getValues('applyEndDate') : null}
              openTo="year"
              rules={rules.applyEndDate}
              inputFormat={yearMonthFormat}
              type="month"
            />
          </Grid>

          <Grid item xs={12} md={12} spacing={2}>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              {t('button.save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
