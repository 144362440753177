import { FormControl, SelectChangeEvent } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectControl } from './SelectControl';
type Props = {
  isLoading: boolean;
  methods: UseFormReturn;
  rankItems: {
    key: string;
    name: string;
  }[];
};

export const RankSelect = (props: Props) => {
  const { isLoading, methods, rankItems } = props;
  const {
    formState: { errors },
    setValue,
  } = methods;
  const { t } = useTranslation();
  return (
    <FormControl fullWidth size="small">
      <SelectControl
        control={methods.control}
        name="rank"
        label={t(`complementaryOrganizationDetail.label.rank`)}
        items={rankItems}
        isLoading={isLoading}
        onChange={(e: SelectChangeEvent<string>) => {
          setValue('rank', e.target.value);
        }}
      />
    </FormControl>
  );
};
