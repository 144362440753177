import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { getBearerToken } from 'src/utils/getBearerToken';
import { OrganizationEntity } from './useOrganizationApi';

export const useEnqueteCreateeApi = () => {
  const PAGE_PATH = '/enquete-create';
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * アンケート管理部署の一覧を取得
   *
   * @returns Organization[]
   */
  const getDepartmentOrganization = async (): Promise<OrganizationEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<OrganizationEntity[] | undefined>(apiGateway + `${PAGE_PATH}/owner-organizations`, {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.organization') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return { getDepartmentOrganization };
};
