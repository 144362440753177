import { ColGroupDef, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessRoleType, useAccessRoleTypeApi } from 'src/api/useAccessRoleTypeApi';
import { useDivisionRegionGrid } from 'src/features/general/enquete-create/hooks/useDivisionRegionGrid';
import { setAccessRoleType } from 'src/features/general/store/accessRoleTypeSlice';
import useSettings from 'src/hooks/useSettings';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';

export const useOutsideSurveyVisibleConditionsGrid = () => {
  const { t } = useTranslation();
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { defaultColDef, grid } = useDivisionRegionGrid({});
  const { getAccessRoleTypeList } = useAccessRoleTypeApi();

  let duplicateWarnSurveyVisibleConditionColDef: ColGroupDef[] = [
    {
      headerName: t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.title'),
      openByDefault: false,
      children: [
        {
          field: 'invalidationReason',
          headerName: t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.title'),
          headerTooltip: t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.title'),
          minWidth: 100,
          tooltipValueGetter: (params: ITooltipParams) => {
            return params.data.invalidationReason === 1
              ? t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.surveyVisibleConditionsOffOrganizationBaseDate')
              : t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.surveyVisibleConditionsInvalidEditor');
          },
          valueGetter: (params: ValueGetterParams) => {
            return params.data.invalidationReason === 1
              ? t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.surveyVisibleConditionsOffOrganizationBaseDate')
              : t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.surveyVisibleConditionsInvalidEditor');
          },
          suppressMenu: true,
          sortable: false,
          filter: true,
          filterParams: {
            applyMiniFilterWhileTyping: true,
          },
          floatingFilter: true,
        },
      ],
    },
    ...(grid.colDefs as ColGroupDef[]),
  ];

  // 担当者列のメールアドレス以降を削除
  duplicateWarnSurveyVisibleConditionColDef[1].children.splice(2);
  // 3点ドット列を削除
  duplicateWarnSurveyVisibleConditionColDef.pop();

  const isLoading = useAppSelector((state: RootState) => state.divisionRegionEditor.isLoading);

  const getAccessRoleType = async () => {
    // アクセス権限のロール一覧取得
    const roleTypeList: AccessRoleType[] = (await getAccessRoleTypeList()) ?? [];
    dispatch(setAccessRoleType(roleTypeList));
  };

  useEffect(() => {
    getAccessRoleType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLight,
    isLoading,
    defaultColDef,
    duplicateWarnSurveyVisibleConditionColDef,
  };
};
