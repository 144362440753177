import { HotTable } from '@handsontable/react';
import { useSnackbar } from 'notistack5';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GeneralEnquete, TableData } from 'src/@types/generalEnquete';
import { usePermissionApi } from 'src/api/usePermissionApi';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { useSurveyTableCreateDataService } from 'src/features/general/enquete-create/components/tab-containers/type-general-table/components/tabs/table/hooks/useSurveyTableCreateDataService';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { ENQUETE_CREATE_PATH } from 'src/features/general/enquete-create/routes/path';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { setSettings, TotalMainTablesType } from 'src/features/general/enquete-create/store/tableSettingSlice';
import { MAX_DATA_SIZE } from 'src/generalConstants';
import { useSurveyTables } from 'src/hooks/useSurveyTables';
import { setPermission } from 'src/redux/slices/pageSlice';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';

export const useTableSave = (hotRef: RefObject<HotTable>[], hotRefForSubTable: RefObject<HotTable>[]) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { saveNewEnqueteData, saveSurveyDetailData } = useEnqueteCreateGrid();
  const { formatCreateMainTableData } = useSurveyTables();
  const { formatSurveyData } = useSurveyTableCreateDataService();
  const { checkPermission } = usePermissionApi();

  // ストア
  const questionDescriptionJpn = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionJpn);
  const questionDescriptionEng = useAppSelector((state: RootState) => state.enqueteCreate.questionDescriptionEng);
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  // saveMode === 'db' の場合、DB登録を行う。'nodb'　の場合DB登録は行はない。
  const saveAllSettings = async (saveMode: string) => {
    // HotTableインスタンスからデータを抽出
    const currentTotalMainTables: TotalMainTablesType[] = [];
    for (let i = 0; i < hotRef.length; i++) {
      if (hotRef[i].current === null) break;
      currentTotalMainTables.push(formatCreateMainTableData(hotRef[i]));
    }

    // サブ表定義
    let subDatas: TableData[] = [];
    hotRefForSubTable.forEach((subTable) => {
      if (subTable.current) {
        subDatas.push(subTable.current?.hotInstance?.getSourceData() as TableData);
      }
    });

    // 現状をストアに格納
    dispatch(
      setSettings({
        subDatas, // サブ表格納
        totalMainTables: currentTotalMainTables, // メイン表格納
      }),
    );

    // DB保存処理
    if (saveMode === 'db') {
      // アンケート情報を取得し、現在の値を保存
      const newEnqueteData: RespondentRequestEntity = JSON.parse(JSON.stringify(enqueteData));
      const tabData = newEnqueteData?.surveyDetail?.surveyFormData?.formData.tabData[0] as GeneralEnquete;
      const requestSurveyData = formatSurveyData(currentTotalMainTables);

      tabData.questionDescriptionJpn = questionDescriptionJpn; // 日本語説明文
      tabData.questionDescriptionEng = questionDescriptionEng; // 英語説明文
      tabData.tables = { ...requestSurveyData, subDatas }; // テーブル情報

      // データ容量を検証し、問題なければサーバにリクエスト
      if (JSON.stringify(tabData).length > MAX_DATA_SIZE.SURVEY_FORM) {
        enqueueSnackbar(t('message.exceptionDataSizeOver', { target: t('common.question') }), { variant: 'error' });
      } else {
        if (!newEnqueteData?.surveyDetailId) {
          // 新規作成処理
          const result = await saveNewEnqueteData(newEnqueteData, 'general');
          if (result) {
            dispatch(setEnqueteData({ ...result.respondentRequest, surveyDetail: { ...result.surveyDetail, surveyHeader: result.surveyHeader } }));
            // 下位ComponentでPermissionを使用するためにfetchした時のパラメータをStateにセットする
            await checkPermission({ respondentRequestId: result.respondentRequest.id }).then((result) => {
              dispatch(setPermission(result));
            });
            nav(ENQUETE_CREATE_PATH.EDIT + '/' + result?.respondentRequest.id);
          }
        } else {
          // 更新処理
          const survayDetail = newEnqueteData.surveyDetail;
          const result = await saveSurveyDetailData(survayDetail as SurveyDetailEntity);
          if (result.data === undefined) return;

          newEnqueteData.surveyDetail = {
            ...result.data,
            surveyHeader: newEnqueteData.surveyDetail?.surveyHeader, // memo: 更新後の結果に SurveyHeader が含まれないため、明示的にセット
          } as SurveyDetailEntity;
          dispatch(setEnqueteData({ ...newEnqueteData }));
        }
      }
    }
  };

  return { saveAllSettings };
};
