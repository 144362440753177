// mui コンポーネント
import { Box, Grid } from '@mui/material';
// ag-grid
import {
  CellPosition,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  RowStyle,
  TabToNextCellParams,
} from 'ag-grid-community';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { EnqueteAnswerInputEntity, PersonnelSurveyGridFormData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerTranslation } from '../../../../hooks/useEnqueteAnswerTranslation';
import {
  DarkRowStyleByPersonnelSurvey,
  NotInputDarkRowStyleByPersonnelSurvey,
  NotInputRowStyleByPersonnelSurvey,
  RowStyleByPersonnelSurvey,
} from '../../../../utils/RowStyleByPersonnelSurvey';
//-------------------------------------------------------------
// Props
//-------------------------------------------------------------
export interface CreatePriorityDomainTotalGridProps {
  // 各行の定義を各グリッド定義から取得する
  form: PersonnelSurveyGridFormData[];
  // 各行のデータは各グリッドの合計値から取得する
  totalRowData?: EnqueteAnswerInputEntity[] | undefined;
  // 年計 - 実見の差異合計設定
  setResultAndPlanDiffValue?: Function | undefined;
  // 中計 - 年計の差異合計設定
  setPlanAndPlanDiffValue?: Function | undefined;
}

//-------------------------------------------------------------
// 画面共通：重点分野別人員の合計欄
//-------------------------------------------------------------
export const CreatePriorityDomainTotalGrid: React.FC<CreatePriorityDomainTotalGridProps> = (props) => {
  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  // Grid全体の高さを定義
  const gridHeight = props.form.length > 2 ? '264px' : '180px';

  // 言語情報
  const { currentLang } = useLocales();
  const useJudg = useJudgmentCurrentLanguage();
  const useAnswerTranslate = useEnqueteAnswerTranslation();

  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  // 列の定義
  const [colDef, setColDef] = useState<(ColDef | ColGroupDef)[]>([]);
  // 行データ
  const [rowData, setRowData] = useState<EnqueteAnswerInputEntity[] | undefined>(undefined);
  // gridのapi
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  // セルの位置
  const [cellPosition, setCellPosition] = useState<CellPosition | undefined>(undefined);
  // 読み込み中のフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // 閉まりのフラグ
  const [isDestroyed, setIsDestroyed] = useState(false);

  //-------------------------------------------------------------
  // タブを押した後の選択ボックスの移動
  //-------------------------------------------------------------
  const tabToNextCell = (params: TabToNextCellParams): CellPosition => {
    // 入力Cell情報取得
    const previousCell = params.previousCellPosition;
    // GridのColDef情報を取得
    const gridColDef = params.api.getColumnDefs();
    // ColDefのColIdを取得
    const gridRowColIds =
      undefined !== gridColDef
        ? gridColDef.map((_: ColDef, i) => {
            return undefined !== _.colId ? _.colId : '';
          })
        : [];
    // 入力CellのColIdを取得
    const colId = previousCell.column.getId();
    // ColIdより現在のIndexを取得
    const colIdIndex = undefined !== gridRowColIds ? gridRowColIds?.indexOf(colId) : 0;
    // 移動先のColIdIndexを取得
    const nextColIdIndex = params.backwards ? colIdIndex - 1 : colIdIndex + 1;
    // 入力CellのColIdIndexを取得
    const lastRowIndex = previousCell.rowIndex;
    // 現在のIndexが存在の場合
    if (undefined !== gridRowColIds && gridRowColIds.length > 0) {
      const colKey = undefined !== gridRowColIds[nextColIdIndex] ? gridRowColIds[nextColIdIndex] : '';
      params.api.setFocusedCell(lastRowIndex, colKey);
    }
    // 移動先の位置情報
    const nextCell = null !== params.nextCellPosition ? params.nextCellPosition : previousCell;
    const result = {
      rowIndex: lastRowIndex,
      column: nextCell.column,
      rowPinned: nextCell.rowPinned,
    };
    // 位置の設定
    setCellPosition(result);

    return result;
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //-------------------------------------------------------------
  // gridの各種設定情報
  //-------------------------------------------------------------
  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    getRowStyle: isEnqueteAnswerOpen
      ? isLight
        ? RowStyleByPersonnelSurvey
        : DarkRowStyleByPersonnelSurvey
      : isLight
      ? NotInputRowStyleByPersonnelSurvey
      : NotInputDarkRowStyleByPersonnelSurvey,
    tabToNextCell,
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  // 新規作成を考慮してformを監視対象にしてRowを作成する
  useEffect(() => {
    // Row定義を作成する
    refreshColDefData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnqueteAnswerOpen, currentLang, themeMode]);

  useEffect(() => {
    // Rowデータを作成する
    refreshRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form, props.totalRowData]);

  useEffect(() => {
    // グリッドを再描画する
    destroyGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLight, isEnqueteAnswerOpen]);

  //-------------------------------------------------------------
  // テーマ変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    setIsDestroyed(true);
    setTimeout(() => recreateGrid(), 0);
  };

  const recreateGrid = () => {
    setIsDestroyed(false);
  };
  //-------------------------------------------------------------
  // 「編集できない」と設定する
  //-------------------------------------------------------------
  const colDefReset = (orgColDef: ColDef[]) => {
    // フォームがない場合
    if (props.form.length === 0) {
      return orgColDef;
    }
    orgColDef.forEach((element) => {
      // 「編集できない」と設定する
      element.editable = false;
    });
    return orgColDef;
  };
  //-------------------------------------------------------------
  // スタイル再設定
  //-------------------------------------------------------------
  const rowStyleReset = (params: RowClassParams): RowStyle => {
    // フォームがない場合
    if (props.form.length === 0) {
      // 背景色を設定しない
      return { backgroundColor: '' };
    }
    // スタイルを初期化
    let style = undefined;
    // 照会モードではない
    if (isEnqueteAnswerOpen) {
      style = isLight ? RowStyleByPersonnelSurvey(params) : DarkRowStyleByPersonnelSurvey(params);
    } else {
      // 照会モードの場合
      style = isLight ? NotInputRowStyleByPersonnelSurvey(params) : NotInputDarkRowStyleByPersonnelSurvey(params);
    }
    // dataStyleによって、行のスタイルを設定する
    if (params.rowIndex < props.form.length) {
      // ライトモード
      const backColorLight = props.form[params.rowIndex].dataStyle?.backgroundColorLight;
      // ダークモード
      const backColorDark = props.form[params.rowIndex].dataStyle?.backgroundColorDark;
      // ライトモードとダークモード両方が存在する場合
      if (backColorLight && backColorDark) {
        return {
          ...style,
          // モードによって背景色を設定する
          backgroundColor: isLight ? backColorLight : backColorDark,
        };
      }
      // 合計行のスタイルを設定する
    } else {
      return {
        ...style,
        // 背景色
        backgroundColor: isLight ? '#F4F6F8' : '#0b0907',
        // 文字サイズ
        fontWeight: 'bold',
        // 位置
        textAlign: 'end',
      };
    }
    // 背景色を設定しない
    return { backgroundColor: '' };
  };

  //-------------------------------------------------------------
  // テーブルの更新
  //-------------------------------------------------------------
  const refreshColDefData = async () => {
    // ロード中のコンポーネントを表示
    setIsLoading(true);
    // 列定義の作成
    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = await useAnswerTranslate.formatColDef(props.form.length > 0 ? props.form[0] : undefined);
    await setColDef(colDefReset(enqueteAnswerColDef));
    // 0.1秒待つ
    await new Promise((resolve) => setTimeout(resolve, 100));
    // ロード中のコンポーネントを表示されない
    setIsLoading(false);
  };

  //-------------------------------------------------------------
  // gridの行データの作成
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    if (gridApi && cellPosition) {
      gridApi.setFocusedCell(cellPosition.rowIndex, cellPosition.column.getColId());
    }

    // AnswerJsonから回答情報を取得する
    let answerRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.totalRowData) {
      props.totalRowData.forEach((row: any) => {
        answerRowData.push(row);
      });
    }

    // FormJsonからグリッドの列項目を取得
    let baseData: any[] = [];

    props.form.forEach((item) => {
      if (item.totalGridInitialValue) {
        // 行データを追加
        baseData.push({
          id: item.id,
          inputItem: item.totalGridInitialValue,
        });
      }
    });

    // FromとAnswerを合体させてRowDataを作成する。
    let tempRowData: EnqueteAnswerInputEntity[] = [];
    // インデックスの初期値
    let i: number = 0;
    if (undefined !== baseData && undefined !== answerRowData) {
      baseData.forEach((row: any) => {
        if (i < props.form.length) {
          const pushRowData: EnqueteAnswerInputEntity = {
            // ID
            id: row.id as string,
            inputItem: row.inputItem as string,
            // 計画
            previousYearPlan:
              answerRowData[i] && answerRowData[i].previousYearPlan !== undefined && answerRowData[i].previousYearPlan !== null
                ? answerRowData[i].previousYearPlan
                : 0,
            // 実見
            resultForecast:
              answerRowData[i] && answerRowData[i].resultForecast !== undefined && answerRowData[i].resultForecast !== null
                ? answerRowData[i].resultForecast
                : 0,
            // 年計
            yearPlan:
              answerRowData[i] && answerRowData[i].yearPlan !== undefined && answerRowData[i].yearPlan !== null ? answerRowData[i].yearPlan : 0,
            // 中計
            midTermPlan:
              answerRowData[i] && answerRowData[i].midTermPlan !== undefined && answerRowData[i].midTermPlan !== null
                ? answerRowData[i].midTermPlan
                : 0,
          };
          const _row = useAnswerTranslate.formatRowData(pushRowData);
          tempRowData.push(_row as EnqueteAnswerInputEntity);
          // インデックス++
          i++;
        }
      });
    }
    // RowDataの中身をコピーして編集できるようにする。
    let newRowData = _.cloneDeep(tempRowData);
    // 計画の合計
    let previousYearPlanTotal: number = 0;
    //　実見の合計
    let resultForecastTotal: number = 0;
    //　年計の合計
    let yearPlanTotal: number = 0;
    //　中計の合計
    let midTermPlanTotal: number = 0;

    // 総合計を計算する
    newRowData.forEach((data: any) => {
      // 計画の合計を計算する
      previousYearPlanTotal += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
      // 実見の合計を計算する
      resultForecastTotal += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
      // 年計の合計を計算する
      yearPlanTotal += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      // 中計の合計を計算する
      midTermPlanTotal += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
    });

    // 年計-実見の差異値をセットする
    if (undefined !== props.setResultAndPlanDiffValue) {
      const resultAndPlanDiffValue = yearPlanTotal - resultForecastTotal;
      await props.setResultAndPlanDiffValue(resultAndPlanDiffValue);
    }

    // 中計-年計の差異値をセットする
    if (undefined !== props.setPlanAndPlanDiffValue) {
      const planAndPlanDiffValue = midTermPlanTotal - yearPlanTotal;
      await props.setPlanAndPlanDiffValue(planAndPlanDiffValue);
    }
    // 合計行の設定
    const totalRowData: EnqueteAnswerInputEntity = {
      // ID
      id: 'sumTotal',
      inputItem: useJudg.getText(props.form[0]['totalGridGridTotalName'] as languageDataTypes),
      // 計画の合計
      previousYearPlan: previousYearPlanTotal,
      // 実見の合計
      resultForecast: resultForecastTotal,
      // 年計の合計
      yearPlan: yearPlanTotal,
      // 中計の合計
      midTermPlan: midTermPlanTotal,
    };
    // 合計行を加える
    newRowData.push(totalRowData);

    await setRowData(newRowData);
  };

  return (
    <Box component={'div'} sx={{ mb: 1, pb: '3px' }}>
      <Grid container spacing={3} sx={{ paddingLeft: '16px !important', marginTop: '-24px !important' }}>
        <Grid item xs={12} md={12}>
          <Box sx={{ pb: '2px' }}>
            {/* ラベル */}
            <h4>{props.form[0] ? useJudg.getText(props.form[0]['totalGridHeadline'] as languageDataTypes) : ''}</h4>
          </Box>

          <Box
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            sx={{
              height: gridHeight,
              width: '79.2%',
              transition: 'height 0.5s',
            }}>
            {/* 入力グリッド */}
            <LoadableComponent isLoading={isLoading}>
              {!isDestroyed && (
                <StyledAgGrid getRowStyle={rowStyleReset} coldef={colDef} rows={rowData} gridOptions={gridOptions} onGridReady={onGridReady} />
              )}
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
