import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

/**
 * 組織マスタ検索結果用のインターフェース
 */
export interface OrganizationEntity {
  id: string;
  companyCode: string; // companySCd
  companyNameJpn: string | null; // companyDisplayName
  companyNameEng: string | null; // companyOrgEngName
  divisionCode: string | null; // headquarterOrganizationId
  divisionNameJpn: string | null; // headquarterDisplayName
  divisionNameEng: string | null; // headquarterOrgEngName
  sbuCode: string | null; // sbuOrganizationId
  sbuNameJpn: string | null; // sbuDisplayName
  sbuNameEng: string | null; // sbuOrgEngName
  departmentCode: string | null; // departmentOrganizationId
  departmentNameJpn: string | null; // departmentDisplayName
  departmentNameEng: string | null; // departmentOrgEngName
  groupCode: string | null; // groupOrganizationId
  groupNameJpn: string | null; // groupDisplayName
  groupNameEng: string | null; // groupOrgEngName
  regionCode: string | null;
  regionNameJpn: string | null; // areaName
  regionNameEng: string | null; // areaEnName
  countryCode: string | null; // countryCd
  countryNameJpn: string | null; // countryName
  countryNameEng: string | null; // countryEnName
  companyAbbreviation: string | null; // companyAbbreviation
  rank: string | null; //rank
  orgLevelType: 'COMPANY' | 'DIVISION' | 'SBU' | 'DEPARTMENT' | 'GROUP' | null; // orgType
  isFuture: boolean;
  isDeleted: boolean;
  applyStartDate: string;
  applyEndDate: string;
}

/**
 * SBU検索結果用のインターフェース
 */
export interface SbuEntity {
  divisionCode: string | null;
  divisionNameJpn: string | null;
  divisionNameEng: string | null;
  sbuCode: string | null;
  sbuNameJpn: string | null;
  sbuNameEng: string | null;
}

/**
 * 組織情報検索用インターフェース
 */
export interface searchDataEntity {
  companyCode: string | null;
  divisionCode: string | null;
  sbuCode: string | null;
  departmentCode: string | null;
  groupCode: string | null;
  isFuture?: boolean;
  isMultiSegment?: boolean;
}

export const useOrganizationApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * ポータルから取得した組織情報をBonsai情報から検索・更新する
   *
   * @param condition
   * @returns
   */
  const getResolveOrganizationData = async (condition: searchDataEntity) => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<OrganizationEntity[]>(apiGateway + '/organization/resolve', {
        headers: { Authorization: token },
        params: { ...condition },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useOrganizationApi.failedGetResolveOrganizationData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getResolveOrganizationData,
  };
};
