import { Box, Grid } from '@mui/material';
import { GetContextMenuItems, GridApi, GridOptions, GridReadyEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { RespondentsListEntity } from 'src/api/useEnqueteAnswerInputApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { AnswerUnit, ANSWER_UNIT, REQUEST_STATUS } from 'src/constants';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { initializeRespondentSetting, setTempSelectedRespondentList } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector, useDispatch } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import useEnqueteCreateAddressColDef from '../../../../../hooks/useEnqueteCreateAddressColDef';

interface PageComponentProps {
  answerUnit?: AnswerUnit;
  setRespondentTarget: Dispatch<SetStateAction<RespondentsListEntity | undefined>>;
}

export const EnqueteCreateGrid: React.FC<PageComponentProps> = (props): React.ReactElement => {
  const { answerUnit, setRespondentTarget } = props;
  const listData = useAppSelector((state) => state.enqueteCreate.requestListData);
  const tempSelectedRespondentList = useAppSelector((state) => state.respondentSetting.tempSelectedRespondentList);
  const enqueteData = useAppSelector((state) => state.enqueteCreate.enqueteData);

  const dispatch = useDispatch();
  const { getRequestAnswerList, setIsOpenSelectPersonModal, setIsOpenChangeRespondentModal, setIsOpenSelectPersonModalAddMode, deleteRespondent } =
    useEnqueteCreateGrid();
  const { id } = useParams();
  const { t } = useTranslation();
  const search = useLocation().search;
  const updatedList = new URLSearchParams(search).get('updatedList');

  const gridApi = useRef<GridApi | null>(null);

  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const [isLoading, setIsLoading] = useState<boolean>(false);

  let gridHeight = '100vh';
  const appHeader = document.getElementsByTagName('header');
  const pageHeader = document.getElementById('page-header');
  if (pageHeader && appHeader.length > 0) {
    gridHeight = `calc(100vh - ${pageHeader?.clientHeight}px - ${appHeader[0]?.clientHeight}px)`;
  }

  const { defaultColDef, surveyEditGridColDef } = useEnqueteCreateAddressColDef();

  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const isComplete = requestStatus === REQUEST_STATUS.COMPLETE;

  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [];
    if (answerUnit !== ANSWER_UNIT.PERSONAL) {
      menuItems.push(
        {
          name: t('enqueteCreate.selectPersonTitle'),
          action: () => {
            setRespondentTarget(params.node?.data);
            setIsOpenSelectPersonModal(true);
            setIsOpenSelectPersonModalAddMode(false);
          },
          disabled: isComplete,
        },
        {
          name: t('enqueteCreate.changeRespondentTitle'),
          action: () => {
            setRespondentTarget(params.node?.data);
            setIsOpenChangeRespondentModal(true);
          },
          disabled: isComplete,
        },
      );
    }
    menuItems.push({
      name: t('button.delete'),
      action: async () => {
        dispatch(initializeRespondentSetting());
        await deleteRespondent(params.node?.data.respondentPersonId, id);
      },
      disabled: isComplete,
    });

    return menuItems;
  };

  //グリッドの表示準備完了時の処理
  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  //初回グリッドがレンダーされた際に行選択を反映
  const firstApplySelectedRow = () => {
    gridApi.current?.forEachNode((node, index) => {
      Object.keys(tempSelectedRespondentList).forEach((rowIndex: string) => {
        if (index === Number(rowIndex)) node.setSelected(true);
      });
    });
  };

  //チェックイベント毎に選択中の行データを格納
  const checkedRow = (e: SelectionChangedEvent) => {
    dispatch(setTempSelectedRespondentList(gridApi.current?.getSelectedNodes() as RowNode[]));
  };

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onSelectionChanged: checkedRow,
  };

  const fetchRequestAnswerList = useCallback(
    async (id: string) => {
      setIsLoading(true);
      await getRequestAnswerList(id);
      setIsLoading(false);
    },
    [getRequestAnswerList],
  );

  useEffect(() => {
    // data injection
    if (id !== undefined) {
      fetchRequestAnswerList(id);
    }
  }, [id, updatedList]); // eslint-disable-line

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={{ height: gridHeight, width: '100%', transition: 'height 0.5s' }}>
          <LoadableComponent isLoading={isLoading} zIndex={1000}>
            <StyledAgGrid
              defaultColDef={defaultColDef}
              coldef={surveyEditGridColDef}
              rows={listData}
              contextMenu={contextMenu}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              onFirstDataRendered={firstApplySelectedRow}
            />
          </LoadableComponent>
        </Box>
      </Grid>
    </Grid>
  );
};
