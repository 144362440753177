import { ColumnState } from 'ag-grid-community';
import { useState } from 'react';
import { SearchCondition, SearchFilter, SortCondition, useComplementaryOrganizationApi } from 'src/api/survey-edit/useComplementaryOrganizationApi';
import { LIST_STATE_KEY_PREFIX } from 'src/constants';
import { dispatch } from 'src/redux/store';
import { deleteRowData, initListData } from '../stores/complementaryOrganizationSlice';

type FilterModel = { [key: string]: any };

const BLOCK_SIZE = 100;

// NOTE: Filter 時に利用可能なキー
const allowedFilterKeys: (keyof SearchFilter)[] = [
  'orgLevelType',
  'companyCode',
  'companyNameJpn',
  'companyNameEng',
  'divisionCode',
  'divisionNameJpn',
  'divisionNameEng',
  'sbuCode',
  'sbuNameJpn',
  'sbuNameEng',
  'departmentCode',
  'departmentNameJpn',
  'departmentNameEng',
  'groupCode',
  'groupNameJpn',
  'groupNameEng',
  'regionCode',
  'regionNameJpn',
  'regionNameEng',
  'countryCode',
  'countryNameJpn',
  'countryNameEng',
  'rank',
  'applyStartDate',
  'applyEndDate',
  'createdBy',
  'updatedBy',
];

// NOTE: Sort 時に利用可能なキー
//   これを実装した時点では allowedFilterKeys の内容と完全に合致しているが、
//   今後 Filter / Sort が同値になる保証がないため、別配列で定義しておく
//   二重管理になるが、 fetch 時に必ず必要なモノであり、実装者の見落としリスクは低いと判断
const allowedSortKeys: (keyof SortCondition)[] = [
  'orgLevelType',
  'companyCode',
  'companyNameJpn',
  'companyNameEng',
  'divisionCode',
  'divisionNameJpn',
  'divisionNameEng',
  'sbuCode',
  'sbuNameJpn',
  'sbuNameEng',
  'departmentCode',
  'departmentNameJpn',
  'departmentNameEng',
  'groupCode',
  'groupNameJpn',
  'groupNameEng',
  'regionCode',
  'regionNameJpn',
  'regionNameEng',
  'countryCode',
  'countryNameJpn',
  'countryNameEng',
  'rank',
  'applyStartDate',
  'applyEndDate',
  'createdBy',
  'updatedBy',
];

export const useComplementaryOrganizationListGrid = () => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1); // NOTE: Pagination の最大ページ数
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const storageKey = 'ComplementaryOrganizationListGrid';

  const { getComplementaryOrganizations, deleteComplementaryOrganization } = useComplementaryOrganizationApi();

  const makeOrderByConditon = (colStates: ColumnState[]) => {
    const cond = {} as SortCondition;
    colStates.forEach((colState) => {
      if (colState.sort) {
        const colId = checkKeyType<keyof SortCondition>(allowedSortKeys, colState.colId);
        if (colId !== undefined) {
          cond[colId] = colState.sort;
        }
      }
    });
    return Object.keys(cond).length === 0 ? undefined : cond;
  };

  const makeSearchFilter = (filter: FilterModel) => {
    const where = {} as SearchFilter;
    for (const filterKey of Object.keys(filter)) {
      const key = checkKeyType<keyof SearchFilter>(allowedFilterKeys, filterKey);
      if (key === undefined || !filter[key]) continue;
      where[key] = filter[key];
    }
    return Object.keys(where).length === 0 ? undefined : where;
  };

  // NOTE: Grid で定義された FilterModel / ColState のキーが SearchFilter / SrotCondition のキーと
  //   完全にリンクしているか保証できないため、型ガードを用意
  const checkKeyType = <T>(allowedKeys: T[], key: string) => {
    // NOTE: inclueds の判定で型エラーが出るため、一度 string[] に変換
    //   配列に存在した場合は、 keyof T への変換が保証される
    const checker = [...allowedKeys] as unknown as string[];
    if (checker.includes(key)) {
      return key as unknown as T;
    }
    return undefined;
  };

  const fetchList = async (page: number) => {
    setIsLoading(true);
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = JSON.parse(filterModel as string) ?? {};
    const where = makeSearchFilter(filter);

    const columnModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.COLUMN}${storageKey}`);
    const column = JSON.parse(columnModel as string) ?? [];
    const orderBy = makeOrderByConditon(column);

    const condition: SearchCondition = { where, orderBy, skip: page, take: BLOCK_SIZE };
    const data = await getComplementaryOrganizations(condition);
    if (data?.list && 0 < data?.list.length) {
      dispatch(initListData(data.list));
      setPage(page);
      setCount(data.count <= BLOCK_SIZE ? 1 : Math.ceil(data.count / BLOCK_SIZE));
      setIsLoading(false);
      return;
    }
    // NOTE: 検索結果が0件の場合は、表示が壊れないように初期化
    setPage(1);
    setCount(1);
    setIsLoading(false);
  };

  const deleteRow = async (id: string) => {
    try {
      await deleteComplementaryOrganization(id);
      dispatch(deleteRowData(id));
    } catch {
      // NOTE　失敗した場合は、グローバルステートから削除しない
    }
  };

  return {
    page,
    setPage,
    count,
    isLoading,
    fetchList,
    deleteRow,
  };
};
