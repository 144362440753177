import { format, isValid, isWithinInterval, setSeconds } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { conversionDateWithoutLocalHour } from 'src/utils/formatDateTime';

export const useValidator = (methods: UseFormReturn, isEditMode: boolean) => {
  const { t } = useTranslation();

  const organizationBaseDateValidation = (targetName: string) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: targetName }),
      },
      validate: {
        isValidDate: (value: string) => {
          if (!isValid(new Date(value))) {
            return `${t('validateError.format.date')}`;
          }
          // 2020/04/01 ≦ 操作日付 ではない場合エラー
          if (!isWithinInterval(new Date(value), { start: conversionDateWithoutLocalHour(new Date('2020-04-01')), end: new Date() })) {
            return `${t('validateError.organizationBaseDate')}`;
          }
          return true;
        },
      },
    };
  };

  const codeValidation = (targetName: string) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: targetName }),
      },
      pattern: {
        value: /^A....0$/g,
        message: t('validateError.format.code'),
      },
    };
  };

  const requiredValidation = (targetName: string) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: targetName }),
      },
    };
  };

  const requiredAndLengthValidation = (targetName: string) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: targetName }),
      },
      maxLength: {
        value: 100,
        message: t('validateError.maxLength', { max: 100 }),
      },
    };
  };

  const requiredAndAutoCompleteValidation = (targetName: string, list: string[]) => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: targetName }),
      },
      validate: {
        isValidKey: (value: string) => {
          return Boolean(
            list.find((v) => {
              return v === value;
            }),
          )
            ? true
            : t('validateError.autoComplete', { target: targetName });
        },
      },
    };
  };

  const applyStartDateRule = () => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: t('common.applyStartDate') }),
      },
      validate: {
        isValidDate: (value: string) => {
          if (!isValid(new Date(value))) {
            return `${t('validateError.format.date')}`;
          }
          return true;
        },
        isValidPeriod: (value: string) => {
          const startDate = new Date(value);
          const endDate = setSeconds(new Date(methods.getValues().applyEndDate), 0);
          methods.setValue('applyEndDate', endDate);
          // 適用終了日の入力がない場合はバリデーションをかけない
          if (!isValid(endDate)) {
            return true;
          }
          if (isPastDate(startDate, endDate)) {
            // 相関チェックエラーのみクリアする
            if (methods.getFieldState('applyEndDate').error?.type === 'isValidPeriod') {
              methods.clearErrors('applyEndDate');
            }
            return true;
          } else {
            return `${t('validateError.before', { start: t('common.applyStartDate'), end: t('common.applyEndDate') })}`;
          }
        },
      },
    };
  };

  const applyEndDateRule = () => {
    return {
      required: {
        value: true,
        message: t('validateError.required', { target: t('common.applyEndDate') }),
      },
      validate: {
        isValidDate: (value: string) => {
          if (!isValid(new Date(value))) {
            return `${t('validateError.format.date')}`;
          }
          return true;
        },
        isValidPeriod: (value: string) => {
          const startDate = isEditMode
            ? setSeconds(new Date(methods.getValues('updateData').applyStartDate), 0)
            : setSeconds(methods.getValues().applyStartDate, 0);
          const endValue = new Date(value);
          methods.setValue('applyStartDate', startDate);
          // 適用開始日の入力がない場合はバリデーションをかけない
          if (!isValid(startDate)) {
            return true;
          }
          if (isPastDate(startDate, endValue)) {
            // 相関チェックエラーのみクリアする
            if (methods.getFieldState('applyStartDate').error?.type === 'isValidPeriod') {
              methods.clearErrors('applyStartDate');
            }
            return true;
          } else {
            return `${t('validateError.after', { start: t('common.applyEndDate'), end: t('common.applyStartDate') })}`;
          }
        },
      },
    };
  };

  /**
   * 比較先が設定されていない or 比較元が比較先の過去の年月または同月の場合true、違う場合falseを返却する
   * @param source 比較元
   * @param destination 比較先
   * @returns 比較先が設定されていない or 比較元が比較先の過去の年月の場合True
   */
  const isPastDate = (source: Date, destination: Date) => {
    const startYearMonth = format(source, 'yyyy/MM');
    const endYearMonth = format(destination, 'yyyy/MM');

    // 文字列比較だが、フォーマット、文字数が同じため問題なし
    return startYearMonth <= endYearMonth;
  };

  return {
    organizationBaseDateValidation,
    codeValidation,
    requiredValidation,
    requiredAndLengthValidation,
    requiredAndAutoCompleteValidation,
    applyStartDateRule,
    applyEndDateRule,
  };
};
