import PersonIcon from '@material-ui/icons/Person';
type ThreadAvatarProps = {
  userName: string | undefined;
  textSize?: string;
};

export const ThreadAvatar = ({ userName, textSize = '16px' }: ThreadAvatarProps) => {
  const avatarString = userName ? userName.replaceAll(/[　 ]/g, '').substring(0, 2) : undefined;
  return (
    // @mui/materialのAvatarを使用した際にnode_module内でエラーが起きており、影響範囲、作業時間等を考慮した結果として以下のように自作での実装となっている。
    // また上記を採用にするに当たってバージョンアップ等を考慮したが、パッケージ名が既に変更されており、現在使っているパッケージにおいては最新に近いものでバージョンの変更が困難であった
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        fontSize: textSize,
        backgroundColor: 'darkgray',
        textAlign: 'center',
        padding: '7px',
        color: 'darkslategray',
      }}>
      {avatarString || <PersonIcon />}
    </a>
  );
};
