import { GetContextMenuItems, GetContextMenuItemsParams, GridReadyEvent } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { usePermission } from 'src/hooks/usePermission';
import { StyledAgGrid } from '../StyledAgGrid';
import { useSelectUserAgGrid } from './hooks/useSelectUserAgGrid';

export interface Props<T> {
  rowData: T[];
  onGridReady?: (params: GridReadyEvent) => void;
  onRowDelete?: (params: T | undefined) => Promise<void>;
  // コンテキストメニュー
  contextMenu?: GetContextMenuItems | undefined;
}

export function SelectUserAgGrid<T extends {}>(props: Props<T>) {
  // Trasration
  const { t } = useTranslation();

  // Viewer Admin
  const { isViewerAdmin, isSurveyOwner } = usePermission();

  // グリッド定義
  const { defaultColDef, columnDefs } = useSelectUserAgGrid();

  // モーダル外ではこっちを呼び出す。
  const contextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.delete'),
        action: async () => {
          if (props.onRowDelete && params.node) {
            await props.onRowDelete(params.node.data);
          }
        },
        disabled: isViewerAdmin && !isSurveyOwner,
      },
    ];
  };

  return (
    <StyledAgGrid
      defaultColDef={defaultColDef}
      coldef={columnDefs}
      rowSelection={'multiple'}
      rows={props.rowData}
      onGridReady={props.onGridReady}
      contextMenu={props.contextMenu ? props.contextMenu : contextMenuItems}
    />
  );
}
