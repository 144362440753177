import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { ComplementaryOrganizationEntity } from 'src/api/survey-edit/useComplementaryOrganizationApi';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import useLocales from 'src/hooks/useLocales';
import { dateFormat, formatterDateTime } from 'src/utils/formatDateTime';
import { switchLabel } from 'src/utils/labelUtils';
import { ORG_LEVEL_TYPE, TOrgLevelType } from './useOrganizationDetail';

export const SORT_RANK = ['A', 'A-', 'AS', 'B', 'B-'] as const;
export type TSortRank = typeof SORT_RANK[number];

export const useComplementaryOrganizationListColDef = () => {
  const { t } = useTranslation();
  const { isJapanese } = useLocales();

  const coldef: (ColDef<ComplementaryOrganizationEntity> | ColGroupDef)[] = [
    {
      field: 'serialNumber',
      tooltipField: 'serialNumber',
      headerName: 'No.',
      headerTooltip: 'No.',
      valueGetter: 'node.rowIndex + 1',
      minWidth: 20,
      width: 70,
      suppressMenu: true,
      flex: 0,
    },
    {
      field: 'orgLevelType',
      tooltipField: 'orgLevelType',
      headerName: t('complementaryOrganizationList.gridColDef.orgLevelType'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.orgLevelType'),
      minWidth: 30,
      width: 120,
      filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        // NOTE prismaのorgLevelType型（Enum）順に定義
        values: ORG_LEVEL_TYPE,
      },
      floatingFilter: true,
      flex: 0,
      comparator: (orgLevelTypeA: TOrgLevelType, orgLevelTypeB: TOrgLevelType) => {
        // NOTE prismaのorgLevelType型（Enum）順に定義
        const order = ORG_LEVEL_TYPE;
        return order.indexOf(orgLevelTypeA) - order.indexOf(orgLevelTypeB);
      },
    },
    {
      field: 'companyCode',
      tooltipField: 'companyCode',
      headerName: t('complementaryOrganizationList.gridColDef.companyCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.companyCode'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'companyNameJpn' : 'companyNameEng',
      tooltipField: isJapanese ? 'companyNameJpn' : 'companyNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.companyNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.companyNameJpn'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.companyNameJpn ?? '', params.data?.companyNameEng ?? '', isJapanese),
    },
    {
      field: 'divisionCode',
      tooltipField: 'divisionCode',
      headerName: t('complementaryOrganizationList.gridColDef.divisionCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.divisionCode'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'divisionNameJpn' : 'divisionNameEng',
      tooltipField: isJapanese ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.divisionNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.divisionNameJpn'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.divisionNameJpn ?? '', params.data?.divisionNameEng ?? '', isJapanese),
    },

    {
      field: 'sbuCode',
      tooltipField: 'sbuCode',
      headerName: t('complementaryOrganizationList.gridColDef.sbuCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.sbuCode'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'sbuNameJpn' : 'sbuNameEng',
      tooltipField: isJapanese ? 'sbuNameJpn' : 'sbuNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.sbuNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.sbuNameJpn'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.sbuNameJpn ?? '', params.data?.sbuNameEng ?? '', isJapanese),
    },
    {
      field: 'departmentCode',
      tooltipField: 'departmentCode',
      headerName: t('complementaryOrganizationList.gridColDef.departmentCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.departmentCode'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'departmentNameJpn' : 'departmentNameEng',
      tooltipField: isJapanese ? 'departmentNameJpn' : 'departmentNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.departmentNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.departmentNameJpn'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.departmentNameJpn ?? '', params.data?.departmentNameEng ?? '', isJapanese),
    },
    {
      field: 'groupCode',
      tooltipField: 'groupCode',
      headerName: t('complementaryOrganizationList.gridColDef.groupCode'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.groupCode'),
      minWidth: 30,
      width: 200,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'groupNameJpn' : 'groupNameEng',
      tooltipField: isJapanese ? 'groupNameJpn' : 'groupNameEng',
      headerName: t('complementaryOrganizationList.gridColDef.groupNameJpn'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.groupNameJpn'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.groupNameJpn ?? '', params.data?.groupNameEng ?? '', isJapanese),
    },
    {
      field: 'regionCode',
      tooltipField: 'regionCode',
      headerName: t('common.code.region'),
      headerTooltip: t('common.code.region'),
      minWidth: 30,
      width: 200,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'regionNameJpn' : 'regionNameEng',
      tooltipField: isJapanese ? 'regionNameJpn' : 'regionNameEng',
      headerName: t('common.region'),
      headerTooltip: t('common.region'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.regionNameJpn ?? '', params.data?.regionNameEng ?? '', isJapanese),
    },
    {
      field: 'countryCode',
      tooltipField: 'countryCode',
      headerName: t('common.code.country'),
      headerTooltip: t('common.code.country'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: isJapanese ? 'countryNameJpn' : 'countryNameEng',
      tooltipField: isJapanese ? 'countryNameJpn' : 'countryNameEng',
      headerName: t('common.country'),
      headerTooltip: t('common.country'),
      minWidth: 150,
      width: 240,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
      valueGetter: (params) => switchLabel(params.data?.countryNameJpn ?? '', params.data?.countryNameEng ?? '', isJapanese),
    },
    {
      field: 'rank',
      tooltipField: 'rank',
      headerName: t('complementaryOrganizationList.gridColDef.rank'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.rank'),
      minWidth: 30,
      width: 120,
      filter: 'agSetColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
        values: SORT_RANK, // NOTE 文字列順
      },
      floatingFilter: true,
      flex: 0,
      comparator: (rankA: TSortRank, rankB: TSortRank) => {
        const order = SORT_RANK; // NOTE 文字列順
        return order.indexOf(rankA) - order.indexOf(rankB);
      },
    },
    {
      field: 'applyStartDate',
      tooltipValueGetter: (params) => formatterDateTime(params.data?.applyStartDate, dateFormat),
      headerName: t('common.applyStartDate'),
      headerTooltip: t('common.applyStartDate'),
      valueGetter: (params) => {
        return formatterDateTime(params.data?.applyStartDate, dateFormat);
      },
      minWidth: 30,
      width: 120,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: [
          {
            displayKey: 'inRange',
            displayName: 'inRange',
            numberOfInputs: 2,
            predicate: ([fv1, fv2]: [Date, Date], cellValue: string) => {
              if (cellValue == null) return true;
              const cellDate = new Date(cellValue);
              fv2.setDate(fv2.getDate() + 1);
              return cellDate.getTime() >= fv1.getTime() && cellDate.getTime() <= fv2.getTime();
            },
          },
        ],
        inRangeInclusive: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'applyEndDate',
      tooltipValueGetter: (params) => formatterDateTime(params.data?.applyEndDate, dateFormat),
      headerName: t('common.applyEndDate'),
      headerTooltip: t('common.applyEndDate'),
      valueGetter: (params) => {
        return formatterDateTime(params.data?.applyEndDate, dateFormat);
      },
      minWidth: 30,
      width: 120,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: [
          {
            displayKey: 'inRange',
            displayName: 'inRange',
            numberOfInputs: 2,
            predicate: ([fv1, fv2]: [Date, Date], cellValue: string) => {
              if (cellValue == null) return true;
              const cellDate = new Date(cellValue);
              fv2.setDate(fv2.getDate() + 1);
              return cellDate.getTime() >= fv1.getTime() && cellDate.getTime() <= fv2.getTime();
            },
          },
        ],
        inRangeInclusive: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'createdBy',
      tooltipField: 'createdBy',
      headerName: t('complementaryOrganizationList.gridColDef.createdBy'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.createdBy'),
      minWidth: 30,
      width: 250,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: 'updatedBy',
      tooltipField: 'updatedBy',
      headerName: t('complementaryOrganizationList.gridColDef.updatedBy'),
      headerTooltip: t('complementaryOrganizationList.gridColDef.updatedBy'),
      minWidth: 30,
      width: 120,
      filter: 'text',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      floatingFilter: true,
      flex: 0,
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  return { coldef };
};
