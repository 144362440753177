import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack5';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeriesAnswerEntity, SeriesImportResult, useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { ErrorMessageEntity, ErrorReportEntity } from 'src/api/useEnqueteStatusPageApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { FORM_TYPE, IMPORT_WARNING_CODE } from 'src/constants';
import { useProposalInitValue } from 'src/features/general/enquete-answer/components/tab-containers/series-survey/components/tabs/components/common/hooks/useProposalInitValue';
import useEnqueteAnswer from 'src/features/general/enquete-answer/hooks/useEnqueteAnswer';
import { SeriesSurveyFormAnswerData, setIsImporting } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { AMOUNT_SOURCE_TYPE, setCurrency, setCustomerGroups, setIsUsePastData } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import ErrorReportContent from 'src/features/general/enquete-create/components/tab-containers/shared-tabs/respondent-tab/components/ErrorReportContent';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { useUser } from 'src/hooks/useUser';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

interface Props {
  respondentTargetId: string | null;
  isDisabled: boolean;
}

export const EnqueteAnswerHeaderExcelButtons: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { currentLang } = useLocales();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputApi = useEnqueteAnswerInputApi();
  const { fetchAnswer } = useEnqueteAnswer();
  const { enqueueSnackbar } = useSnackbar();
  const { userNameJpn, userNameEng } = useUser();
  const { setCustomerGroup } = useProposalInitValue();
  const isLangJpn = currentLang.value === 'ja';
  const [isOpenErrorNotificationModal, setIsOpenErrorNotificationModal] = useState<boolean>(false);
  const [errorReport, setErrorReport] = useState<ErrorMessageEntity[] | SeriesImportResult[] | []>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { isEnqueteAnswerOpen, isBeforeRequestTerm, respondentRequestData, surveyDetailData } = useAppSelector((state) => state.enqueteAnswer);
  const { currency, customers } = useAppSelector((state) => state.seriesAnswer);
  const { isAdmin, isSurveyOwner, isRespondentPerson, isConfirmor, isAnswerEditor } = usePermission();
  // NOTE: 編集可能（インポート可能）なユーザーの権限・ロール
  const canEdit = isAdmin || isSurveyOwner || isRespondentPerson || isConfirmor || isAnswerEditor;
  const respondentRequestId = respondentRequestData.id ?? null;
  const formType = surveyDetailData.surveyHeader?.formType;

  //-------------------------------------------------------------
  // 回答結果をエクスポート
  //-------------------------------------------------------------
  const handleExportExcel = async () => {
    if (respondentRequestId && props.respondentTargetId) {
      setInProgress(true);
      //単一エクスポート
      await inputApi.exportAnswerResult(respondentRequestId, props.respondentTargetId, isLangJpn ? 'jpn' : 'eng');
      setInProgress(false);
    }
  };

  //-------------------------------------------------------------
  // 回答をインポート
  //-------------------------------------------------------------
  const handleImportExcel = async (e: ChangeEvent<any>) => {
    //FormData定義
    let formData = new FormData();

    //ファイル情報が未定義であれば終了
    if (inputRef.current !== null && inputRef.current?.files?.length === 0 && inputRef.current?.files !== null) return;
    //Filesデータがあれば、FileDataに格納
    if (inputRef.current !== null && inputRef.current?.files !== null) {
      formData.append('file', inputRef.current?.files[0]);
    }

    //ユーザー名を格納
    formData.append('userNameJpn', userNameJpn);
    formData.append('userNameEng', userNameEng);
    formData.append('currencyUnit', currency);

    //RespondentRequestIdとRespondentTargetIdがなければエラー、あればインポート
    if (respondentRequestId === null || props.respondentTargetId === null) {
      enqueueSnackbar(t('apiExceptionMessage.useRespondentRequestApi.failedImport'), { variant: 'error' });
      return;
    }
    setInProgress(true);

    //単一インポート
    const res: AxiosResponse<any> | undefined = await inputApi.importAnswer(respondentRequestId, props.respondentTargetId, formData);

    //エラー分岐
    const clientErrorPattern = /^4[0-9]{2}$/;
    const serverErrorPattern = /^5[0-9]{2}$/;
    if (res !== undefined && res.status !== undefined && res.status === 400) {
      if (formType === FORM_TYPE.SPECIAL || res.data.hasOwnProperty('message')) {
        const error: ErrorReportEntity = res.data;
        setErrorReport(error.message);
      } else if (formType === FORM_TYPE.SERIES) {
        const seriesError: SeriesImportResult = {
          code: res.data.code,
          detail: res.data.detail,
        };
        setErrorReport([seriesError]);
      }
      setIsOpenErrorNotificationModal(true);
    } else if (res !== undefined && res.status !== undefined && res.status === 406) {
      enqueueSnackbar(t('message.exceptionInvalidId'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && clientErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && serverErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedServerSide'), { variant: 'error' });
    } else {
      //グリッド情報更新
      await fetchAnswer(props.respondentTargetId);
      //エラーレスポンスリセット
      setErrorReport([]);
      //スナックバー通知
      enqueueSnackbar(t('errorReport.message.succeedImport'), { variant: 'success' });
      if (res !== undefined && formType === FORM_TYPE.SERIES) {
        dispatch(setIsImporting(true));
        const resData = res.data as SeriesAnswerEntity;
        const counterparties = (resData.answer.answerData?.answerInputData as SeriesSurveyFormAnswerData)?.tabData[0]?.counterparties;
        if (!customers) return enqueueSnackbar(t('apiExceptionMessage.useCustomerApi.failedGetData'), { variant: 'error' });
        const { customerGroups, arraySourceType, defaultCurrency } = await setCustomerGroup(counterparties);
        dispatch(setCustomerGroups(customerGroups));
        dispatch(setIsUsePastData(arraySourceType.includes(AMOUNT_SOURCE_TYPE.PRE_ANSWERED)));
        dispatch(setCurrency(defaultCurrency));

        for (const warning of resData.warnCodes) {
          switch (warning.code) {
            // 削除済みの取引先の場合、かつ過去データ未登録の場合
            case IMPORT_WARNING_CODE.DELETED_CUSTOMER:
              enqueueSnackbar(t('enqueteAnswerInput.message.importDeletedSeriesWarning', { code: warning.detail }), { variant: 'warning' });
              break;
            case IMPORT_WARNING_CODE.OUT_OF_SERIES_CUSTOMER:
              enqueueSnackbar(t('enqueteAnswerInput.message.importOutOfSeriesCustomerWarning', { code: warning.detail }), { variant: 'warning' });
              break;
          }
        }
      }
    }
    setInProgress(false);
    if (formType === FORM_TYPE.SERIES) {
      dispatch(setIsImporting(false));
    }
  };

  //-------------------------------------------------------------
  // ファイル選択ダイアログ表示
  //-------------------------------------------------------------
  const openDialog = () => {
    if (inputRef.current === null) return;

    inputRef.current.click();
  };

  return (
    <>
      <Grid container alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ textAlign: 'right' }}>
        <Grid item xs={12}>
          <StyledButton
            onClick={() => handleExportExcel()}
            sx={{ marginLeft: '4px', marginTop: '10px' }}
            isDisabled={!isEnqueteAnswerOpen && isBeforeRequestTerm}>
            <FontAwesomeIcon icon={faDownload} />
            {t('enqueteStatus.menu.excelExport')}
          </StyledButton>
          <StyledButton
            onClick={openDialog}
            sx={{ marginLeft: '4px', marginTop: '10px' }}
            isDisabled={!isEnqueteAnswerOpen || props.isDisabled || !canEdit || (formType === FORM_TYPE.SERIES && !currency)}>
            <FontAwesomeIcon icon={faUpload} />
            {t('enqueteStatus.menu.excelImport')}
            <input
              data-testid="importInput"
              type="file"
              value=""
              hidden
              ref={inputRef}
              onChange={handleImportExcel}
              onClick={(e) => {
                if (inputRef.current !== null && inputRef.current?.files !== null) {
                  inputRef.current.files = null;
                }
              }}
              accept={'.xlsx'}
            />
          </StyledButton>
          <StyledButton
            onClick={() => setIsOpenErrorNotificationModal(true)}
            sx={{ marginLeft: '4px', marginTop: '10px' }}
            isDisabled={!isEnqueteAnswerOpen || props.isDisabled || !canEdit || (formType === FORM_TYPE.SERIES && currency === '')}>
            {t('errorReport.title')}
          </StyledButton>
        </Grid>
      </Grid>

      {/** エラーレポートモーダル */}
      <StyledModal
        isOpen={isOpenErrorNotificationModal}
        onCloseFunc={() => setIsOpenErrorNotificationModal(false)}
        modalTitle={t('errorReport.title')}
        children={<ErrorReportContent errorReport={errorReport} formType={formType} onCloseFunc={() => setIsOpenErrorNotificationModal(false)} />}
      />

      {/** Excel処理ローディング */}
      <StyledLoading isOpen={inProgress} />
    </>
  );
};
