import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerHeaderInfo } from '../hooks/useEnqueteAnswerHeaderInfo';

export const EnqueteAnswerHeaderInfo: React.FC = () => {
  const { t } = useTranslation();
  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);
  const { enqueteAnswerStatus, enqueteTitle, requestStatusTerm, enqueteAnswerOrganization, organizationBaseDate } =
    useEnqueteAnswerHeaderInfo(respondentRequestData);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item container xs={12} md={12}>
          <Stack>
            <Grid item>
              <Typography variant="caption">
                <Box sx={{ fontWeight: 'bold' }}>{t('enqueteAnswerInput.headerTable.surveyTitle')}</Box>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{enqueteTitle}</Typography>
            </Grid>
          </Stack>
        </Grid>
        <Grid item container xs={12} md={12}>
          <Grid item xs={2} md={2}>
            <Typography variant="caption">
              <Box sx={{ fontWeight: 'bold' }}>{t('enqueteAnswerInput.headerTable.answerStatus')}</Box>
            </Typography>
            <Typography variant="body1">{enqueteAnswerStatus}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography variant="caption">
              <Box sx={{ fontWeight: 'bold' }}>{t('common.organizationBaseDate')}</Box>
            </Typography>
            <Typography variant="body1">{organizationBaseDate}</Typography>
          </Grid>
          <Grid item xs={8} md={8}>
            <Typography variant="caption">
              <Box sx={{ fontWeight: 'bold' }}>{t('enqueteAnswerInput.headerTable.enqueteRequestTerm')}</Box>
            </Typography>
            <Typography variant="body1">{requestStatusTerm}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12}>
          <Stack>
            <Grid item>
              <Typography variant="caption">
                <Box sx={{ fontWeight: 'bold' }}>{t('enqueteAnswerInput.headerTable.respondingOrganization')}</Box>
              </Typography>
              <Typography variant="body1">{enqueteAnswerOrganization}</Typography>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
