import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useBearerToken } from './useBearerToken';

export interface CreateRespondentManagersEntityData {
  createRespondentManagers: CreateRespondentManagerEntityData[];
}

export interface CreateRespondentManagerEntityData {
  userId: string;
  bluePageEmailAddress: string | null;
}

export interface RespondentManagerEntity {
  id: string;
  userId: string;
  bluePageEmailAddress: string | null;
  surveyDetailId: string;
  createdBy: string;
  updatedBy: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export const useRespondentManagerApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 依頼先管理者検索用のAPI
   * @param surveyDetailId
   * @returns
   */
  const get = async (surveyDetailId: string, userId?: string) => {
    try {
      const token = await getBearerToken();
      const response = await axios.get<RespondentManagerEntity[]>(`${apiGateway}/survey-edit/respondent-managers/${surveyDetailId}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        params: { userId },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.respondentManager') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * surveyDetailIdによる検索
   * @param surveyDetailId
   * @returns
   */
  const getBySurveyDetailId = async (surveyDetailId: string) => {
    return await get(surveyDetailId);
  };

  /**
   * Uniqueキーによる検索
   * @param surveyDetailId
   * @param email
   * @returns
   */
  const getByUnique = async (surveyDetailId: string, userId: string) => {
    return await get(surveyDetailId, userId);
  };

  /**
   * 依頼先管理者追加用のAPI
   * @param surveyDetailId
   * @param data
   * @returns
   */
  const add = async (surveyDetailId: string, data: CreateRespondentManagersEntityData) => {
    try {
      const token = await getBearerToken();
      return await axios.post(apiGateway + `/survey-edit/respondent-managers/${surveyDetailId}`, data, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('common.respondentManager') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 依頼先管理者削除用のAPI
   * @param surveyDetailId
   * @param respondentManagerId
   * @returns
   */
  const removeById = async (surveyDetailId: string, respondentManagerId: string) => {
    try {
      const token = await getBearerToken();
      await axios.delete(apiGateway + `/survey-edit/respondent-managers/${surveyDetailId}`, {
        timeout: 60000,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        params: {
          respondentManagerId: respondentManagerId,
        },
      });
    } catch (error) {
      enqueueSnackbar(t('apiExceptionMessage.common.failedDelete', { target: t('common.respondentManager') }), { variant: 'error' });
    }
  };

  return {
    get,
    getBySurveyDetailId,
    getByUnique,
    add,
    removeById,
  };
};
