import { useSnackbar } from 'notistack5';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';

export const useExportExcel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { download } = useSurveyEditApi();

  const downloadExcel = useCallback(
    async (selectedRowData: OrganizationEntity[], isLangJpn: boolean) => {
      try {
        setIsDownloading(true);
        const ids = selectedRowData ? selectedRowData.map((item) => item.id) : [];
        if (id) {
          await download(id, ids, isLangJpn ? 'jpn' : 'eng');
        }
      } catch {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedExport'), { variant: 'error' });
      } finally {
        setIsDownloading(false);
      }
    },
    [id, t, download, enqueueSnackbar],
  );

  return {
    isDownloading,
    downloadExcel,
  };
};
