import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { RootState, useAppSelector } from 'src/redux/store';
import InvalidRespondentPersonEmails from './InvalidRespondentPersonEmails';
import OutsideSurveyVisibleConditionsGrid from './OutsideSurveyVisibleConditionsGrid';
import OutsideTemporaryOrganizationsGrid from './OutsideTemporaryOrganizationsGrid';

interface Props {
  isOpen: boolean;
  setIsDuplicateWarnMessageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DuplicateWarnMessageModal: React.FC<Props> = (props) => {
  const { isOpen, setIsDuplicateWarnMessageModalOpen } = props;
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  const duplicateWarnMessage = enqueteData.surveyDetail.duplicateWarnMessage ? enqueteData.surveyDetail.duplicateWarnMessage : null;
  const { t } = useTranslation();

  const onClose = () => {
    setIsDuplicateWarnMessageModalOpen(false);
  };

  return (
    <StyledModal isOpen={isOpen} mode="general" onCloseFunc={onClose} modalTitle={t('enqueteCreate.duplicationWarnMessageModal.title')}>
      {duplicateWarnMessage && duplicateWarnMessage?.outsideSendTo && (
        <>
          <Typography variant="body1">
            {t('enqueteCreate.duplicationWarnMessageModal.outsideSendToMessage.1')}
            <strong>{t('enqueteCreate.duplicationWarnMessageModal.outsideSendToMessage.2')}</strong>
            {t('enqueteCreate.duplicationWarnMessageModal.outsideSendToMessage.3')}
          </Typography>
          <br />
        </>
      )}
      {duplicateWarnMessage && duplicateWarnMessage?.outsideTemporaryOrganizations.length > 0 && (
        <>
          <Typography variant="body1">
            {t('enqueteCreate.duplicationWarnMessageModal.outsideTemporaryOrganizationsMessage.1')}
            <strong>{t('enqueteCreate.duplicationWarnMessageModal.outsideTemporaryOrganizationsMessage.2')}</strong>
            {t('enqueteCreate.duplicationWarnMessageModal.outsideTemporaryOrganizationsMessage.3')}
          </Typography>
          <OutsideTemporaryOrganizationsGrid outsideTemporaryOrganizations={duplicateWarnMessage.outsideTemporaryOrganizations} />
          <br />
        </>
      )}
      {duplicateWarnMessage && duplicateWarnMessage?.outsideSurveyVisibleConditions.length > 0 && (
        <>
          <Typography variant="body1">
            {t('enqueteCreate.duplicationWarnMessageModal.outsideSurveyVisibleConditionsMessage.1')}
            <strong>{t('enqueteCreate.duplicationWarnMessageModal.outsideSurveyVisibleConditionsMessage.2')}</strong>
          </Typography>
          <OutsideSurveyVisibleConditionsGrid outsideSurveyVisibleConditions={duplicateWarnMessage.outsideSurveyVisibleConditions} />
          <br />
        </>
      )}
      {duplicateWarnMessage && duplicateWarnMessage?.invalidRespondentPersonEmails.length > 0 && (
        <>
          <Typography variant="body1">
            {t('enqueteCreate.duplicationWarnMessageModal.invalidRespondentPersonEmailsMessage.1')}
            <strong>{t('enqueteCreate.duplicationWarnMessageModal.invalidRespondentPersonEmailsMessage.2')}</strong>
          </Typography>
          <InvalidRespondentPersonEmails invalidRespondentPersonEmails={duplicateWarnMessage.invalidRespondentPersonEmails} />
          <br />
        </>
      )}
      <StyledButton onClick={onClose}>{t('button.close')}</StyledButton>
    </StyledModal>
  );
};
