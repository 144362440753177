import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { ANSWER_STATUS, AnswerStatus } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import { usePermission } from 'src/hooks/usePermission';
import { useAppSelector } from 'src/redux/store';
import { useCreateOverviewAnswerData } from '../hooks/useCreateOverviewAnswerData';
import useEnqueteAnswerSave from '../hooks/useEnqueteAnswerSave';
import { SwitchReversionModalLayout } from './common/components/SwitchReversionModalLayout';
import { REVERSION, REVERSION_REQUEST } from './common/constants';
import { useReturnedHandleClick } from './common/hooks/useReturnedHandleClick';
export interface EnqueteAnswerOverviewSaveButtonProps {
  respondentTargetId: string;
  confirmRequestId: string | null;
}

export const EnqueteAnswerOverviewSaveButton: React.FC<EnqueteAnswerOverviewSaveButtonProps> = (props): React.ReactElement => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isAnswered, commonSaveHandler } = useEnqueteAnswerSave(props.respondentTargetId);
  const answerOverviewEnquete = useCreateOverviewAnswerData();
  const { confirmRequestData, enqueteAnswerData, isEnqueteAnswerOpen } = useAppSelector((state) => state.enqueteAnswer);
  const { hasError } = useAppSelector((state) => state.enqueteOverviewAnswer);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleReversionType, isReversionRequest } = useReturnedHandleClick(setIsOpen);
  const userId = null !== user ? user.email : '';
  const { isAdmin, isViewerAdmin, isSurveyOwner, isRespondentPerson, isAnswerEditor } = usePermission();
  // NOTE: 編集可能（提出・一時保存）なユーザーの権限・ロール
  const canEdit = isAdmin || isSurveyOwner || isRespondentPerson || isAnswerEditor;
  const marginTopValue = props.confirmRequestId && confirmRequestData.confirmerId === userId ? '20px' : '50px';

  const submitHandler = async (answerStatus: AnswerStatus) => {
    await commonSaveHandler(answerOverviewEnquete, answerStatus);
  };

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ marginTop: marginTopValue }}>
        {(isSurveyOwner || isAdmin || isViewerAdmin) && (
          <StyledButton
            onClick={() => handleReversionType(REVERSION)}
            isDisabled={!isEnqueteAnswerOpen || enqueteAnswerData.status !== ANSWER_STATUS.ANSWERED || !(isSurveyOwner || isAdmin)}>
            {t('button.reversion')}
          </StyledButton>
        )}
        {(isRespondentPerson || isAdmin || isViewerAdmin) && (
          <StyledButton
            onClick={() => handleReversionType(REVERSION_REQUEST)}
            isDisabled={!isEnqueteAnswerOpen || enqueteAnswerData.status !== ANSWER_STATUS.ANSWERED || !(isRespondentPerson || isAdmin)}>
            {t('button.requestReversion')}
          </StyledButton>
        )}
        <StyledButton onClick={() => submitHandler(ANSWER_STATUS.SAVED)} isDisabled={isAnswered || !canEdit}>
          {t('button.temporarilySave')}
        </StyledButton>
        <StyledButton
          onClick={() => submitHandler(ANSWER_STATUS.ANSWERED)}
          isDisabled={!isEnqueteAnswerOpen || hasError || enqueteAnswerData.status === ANSWER_STATUS.ANSWERED || !canEdit}>
          {t(enqueteAnswerData.status === ANSWER_STATUS.RETURNED ? 'button.resubmission' : 'button.submit')}
        </StyledButton>
      </Stack>
      <SwitchReversionModalLayout isOpen={isOpen} setIsOpen={setIsOpen} isRequest={isReversionRequest} />
    </>
  );
};
