import { Box, Grid, Pagination } from '@mui/material';
import type { GetContextMenuItems, GetContextMenuItemsParams, RowDoubleClickedEvent } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import StyledDialog from 'src/components/app-components/StyledDialog';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useComplementaryOrganizationListColDef } from '../hooks/useComplementaryOrganizationListColDef';
import { useComplementaryOrganizationListGrid } from '../hooks/useComplementaryOrganizationListGrid';
import { ORGANIZATION_FUTURE_PATH } from '../routes/path';

export const ComplementaryOrganizationListGrid: React.FC = (): React.ReactElement => {
  const listData = useAppSelector((state) => state.complementaryOrganization.listData);
  const { page, setPage, count, isLoading, fetchList, deleteRow } = useComplementaryOrganizationListGrid();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin, isOrganizationEditor } = usePermission();
  // adminとorganizationEditorの権限を持つユーザーのみ操作可能
  const isOnlyViewerAdmin = !isAdmin && !isOrganizationEditor;

  const storageKey = 'ComplementaryOrganizationListGrid';

  //メッセージ定義
  const deleteConfirmDialogTitle = t('complementaryOrganizationList.message.deleteConfirmDialogTitle');
  const deleteConfirmDialogContent = t('complementaryOrganizationList.message.deleteConfirmDialogContent');

  // 削除ダイアログ
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<string>('');

  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const { coldef } = useComplementaryOrganizationListColDef();

  //ダブルクリック時の挙動定義
  const onRowDoubleClickedCallback = (event: RowDoubleClickedEvent) => {
    if (!isOnlyViewerAdmin) navigate(`${ORGANIZATION_FUTURE_PATH.INDEX}/${event.data.id}`);
  };

  //行を削除
  const onClickDelete = () => {
    deleteRow(deleteRowId);
    setIsOpen(false);
    setDeleteRowId('');
  };

  //コンテキストメニュー
  const contextMenu: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('complementaryOrganizationList.contextMenu.edit'),
        action: () => {
          navigate(`${ORGANIZATION_FUTURE_PATH.INDEX}/${params.node?.data.id}`);
        },
        disabled: isOnlyViewerAdmin,
      },
      {
        name: t('complementaryOrganizationList.contextMenu.delete'),
        action: () => {
          setDeleteRowId(params.node?.data.id);
          setIsOpen(true);
        },
        disabled: isOnlyViewerAdmin,
      },
    ];
    return menuItems;
  };

  useEffect(() => {
    fetchList(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            sx={{
              height: '95vh',
              width: '100%',
            }}>
            <LoadableComponent isLoading={isLoading}>
              <StyledAgGrid
                coldef={coldef}
                rows={listData}
                contextMenu={contextMenu}
                onFilterChangedCallback={() => fetchList(1)}
                onSortChangedCallback={() => fetchList(1)}
                onRowDoubleClickedCallback={onRowDoubleClickedCallback}
                storageKey={storageKey}
              />
            </LoadableComponent>
          </Box>
          <Pagination count={count} page={page} onChange={(_event, value) => setPage(value)} />;
        </Grid>
      </Grid>

      {/* 削除ダイアログ */}
      <StyledDialog
        dialogTitle={deleteConfirmDialogTitle}
        dialogContent={deleteConfirmDialogContent}
        isOpen={isOpen}
        disagreeCallback={() => setIsOpen(false)}
        agreeCallback={onClickDelete}
      />
    </>
  );
};
