import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  invalidRespondentPersonEmails: string[];
}

const InvalidRespondentPersonEmails: React.FC<Props> = (props) => {
  const { invalidRespondentPersonEmails } = props;

  return (
    <Box component={'div'} sx={{ width: '100%', height: '120px', overflow: 'auto' }}>
      {invalidRespondentPersonEmails.map((email: string, index: number) => (
        <Typography key={index} variant="body1">
          {email}
        </Typography>
      ))}
    </Box>
  );
};

export default InvalidRespondentPersonEmails;
