import { useCallback, useEffect, useState } from 'react';
import { useEnqueteCreateeApi } from 'src/api/useEnqueteCreateApi';
import { setSelectedOwnerOrganization } from 'src/features/general/enquete-create/store/enqueteInfoSlice';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { useDispatch } from 'src/redux/store';
import { addSuffix, switchLabel } from 'src/utils/labelUtils';

export const useOwnerOrganizationForm = () => {
  const { getDepartmentOrganization } = useEnqueteCreateeApi();
  const [conditions, setConditions] = useState<
    {
      key: string;
      label: string;
    }[]
  >([]);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const makeOwnerOrganizations = useCallback(async () => {
    const companies = (await getDepartmentOrganization()) ?? [];
    const conditions = companies.map((e) => {
      return {
        key: e.id,
        label: addSuffix(switchLabel(e.departmentNameJpn, e.departmentNameEng, isJapanese), e.departmentCode),
      };
    });
    setConditions(conditions);
    // ログインユーザーの部コードから引用符を削除
    const userDepartment = user?.department.replace(/"/g, '');
    // 部コードを比較、主管部のデフォルト値を取得する
    const companie = companies.find((e) => e.departmentCode === userDepartment);
    // デフォルト値をセット
    dispatch(
      setSelectedOwnerOrganization(
        companie
          ? {
              key: companie.id,
              label: addSuffix(switchLabel(companie.departmentNameJpn, companie.departmentNameEng, isJapanese), companie.departmentCode),
            }
          : { key: '', label: '' },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDepartmentOrganization, isJapanese]);

  useEffect(() => {
    const init = async () => {
      await makeOwnerOrganizations();
    };
    if (conditions.length === 0) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { conditions };
};
