import { ColumnState } from 'ag-grid-community';
import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { AnswerUnit, ANSWER_UNIT, DisclosureLevel, FormType, FORM_TYPE, SurveyType, SURVEY_TYPE } from 'src/constants';
import { NetworkAccessError } from 'src/errors/errors';
import { RespondentRequestEntity } from './useRespondentRequestApi';
import { useBearerToken } from './useBearerToken';

export interface CreatedSurveyEntity {
  id: string;
  titleJpn: string;
  titleEng: string;
  ownerDepartmentJpn: string | null;
  ownerDepartmentEng: string | null;
  organizationBaseDate: string | null;
  targetDate: string | null;
  requestedDate: string | null;
  openedAt: string;
  closedAt: string;
  labelJpn: string;
  labelEng: string;
  surveyDetailId: string;
  version: number;
  surveyHeaderId: string;
  answeredCount: number;
  requestedCount: number;
  createdBy: string;
  type: SurveyType;
  formType: FormType;
  answerUnit: AnswerUnit;
  disclosureLevel: DisclosureLevel | null;
}

export const initCreatedSurvey = {
  id: '',
  titleJpn: '',
  titleEng: '',
  targetDate: '',
  organizationBaseDate: '',
  ownerDepartmentJpn: '',
  ownerDepartmentEng: '',
  requestedDate: '',
  openedAt: '',
  closedAt: '',
  labelJpn: '',
  labelEng: '',
  surveyDetailId: '',
  version: 0,
  surveyHeaderId: '',
  answeredCount: 0,
  requestedCount: 0,
  createdBy: '',
  type: SURVEY_TYPE.GENERAL,
  formType: FORM_TYPE.QA,
  answerUnit: ANSWER_UNIT.ORGANIZATION,
  disclosureLevel: null,
};

export interface CopySurveyEntity {
  sourceSurveyDetailId: string;
  isKeepRespondents: boolean;
  isKeepOwners: boolean;
  isKeepRespondentManagers: boolean;
  isKeepVisibleConditions: boolean;
  isKeepTemporaryOrganizations: boolean;
  openedAt: Date;
  closedAt: Date;
  titleJpn: string;
  titleEng: string;
  targetDate: Date | null;
  organizationBaseDate: Date;
  surveyOwnerEmails?: string[];
}

export interface SurveyIdsTypes {
  respondentRequestId: string;
  surveyDetailId: string;
}

/**
 * CreatedSurvey API
 *
 * @returns
 */
export const useCreatedSurveyApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 条件付きデータ全件数取得
   *
   * @returns
   */
  const getCountLimitedList = async (filterModel: { [key: string]: any }, currentLang: string): Promise<number | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<number>(apiGateway + '/created-survey/count-limited-list', {
        headers: {
          Authorization: token,
        },
        params: {
          FilterModel: filterModel,
          CurrentLang: currentLang,
        },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useCreatedSurvey.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 条件付きデータ取得
   *
   * @returns
   */
  const getLimitedList = async (
    blockSize: number,
    startRow: number,
    sortModel: ColumnState[],
    filterModel: { [key: string]: any },
    currentLang: string,
  ): Promise<CreatedSurveyEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<CreatedSurveyEntity[] | undefined>(apiGateway + '/created-survey/limited-list', {
        headers: {
          Authorization: token,
        },
        params: {
          BlockSize: blockSize,
          StartRow: startRow,
          SortModel: sortModel,
          FilterModel: filterModel,
          CurrentLang: currentLang,
        },
        timeout: 60000,
      });
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useCreatedSurvey.failedGetData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * アンケート複製
   *
   * @param req
   * @returns
   */
  const duplicateSurvey = async (req: CopySurveyEntity): Promise<SurveyIdsTypes | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.post<SurveyIdsTypes | undefined>(apiGateway + '/created-survey/copy', req, {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // enqueueSnackbar(t('apiExceptionMessage.useCreatedSurvey.failedGetData'), { variant: 'error' });
        enqueueSnackbar('アンケートの複製に失敗しました', { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * データ削除
   *
   * @param id
   * @returns
   */
  const deleteData = async (surveyDetailId: string) => {
    try {
      const token = await getBearerToken();
      const result = await axios.delete<RespondentRequestEntity | undefined>(apiGateway + '/created-survey/' + surveyDetailId, {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });
      if (result === undefined) return;
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new NetworkAccessError(error.response?.status);
      }
      throw error;
    }
  };

  return {
    getLimitedList,
    getCountLimitedList,
    duplicateSurvey,
    deleteData,
  };
};
