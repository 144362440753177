import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IconButton, Tab, Tooltip } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeMode, setMode, setPermission } from 'src/features/general/enquete-status/store/overviewAggregateSupplementSlice';
import { MODE } from 'src/features/general/enquete-status/utils/constants';
import { usePermission } from 'src/hooks/usePermission';
import { dispatch } from 'src/redux/store';
import EnqueteStatusListTab from '../../shared-tabs/enquete-status-list/components/EnqueteStatusListTab';
import OverviewAggregateSupplementTab from './tabs/aggregate-supplement/components/OverviewAggregateSupplementTab';

export const OverviewEnqueteStatusTabContainer: React.FC = () => {
  const { t } = useTranslation();
  const { isBonsaiAdmin, isAdmin, isSurveyOwner, isDivisionReadRep, allowWriteDivisions } = usePermission();

  //タブ
  const [tabValue, setTabValue] = useState('1');

  //タブ切り替え関数
  const handleChange = (e: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  // モード切り替えボタン
  const renderModeChangeButton = () => {
    if (isBonsaiAdmin || isSurveyOwner) {
      return (
        <Tooltip title={t('enqueteStatus.aggregateSupplement.description.changeModeIcon') ?? ''} placement="left">
          <IconButton
            sx={{ bottom: 16, right: 16, position: 'absolute' }}
            onClick={() => {
              dispatch(changeMode());
            }}>
            <AutorenewSharpIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    dispatch(setPermission({ isAdmin, isSurveyOwner, allowWriteDivisions }));
    if (isBonsaiAdmin || isSurveyOwner || isDivisionReadRep) {
      dispatch(setMode(isBonsaiAdmin || isSurveyOwner ? MODE.OWNER : MODE.DIVISION_REP));
    }
  }, [isBonsaiAdmin, isSurveyOwner, isDivisionReadRep, isAdmin, allowWriteDivisions]);

  return (
    <TabContext value={tabValue}>
      <TabList onChange={handleChange}>
        <Tab label={t('enqueteStatus.tab.answerStatus')} value="1" />
        {isBonsaiAdmin || isSurveyOwner || isDivisionReadRep ? <Tab label={t('enqueteStatus.tab.aggregateSupplement')} value="2" /> : null}
      </TabList>
      <TabPanel value="1">
        <EnqueteStatusListTab />
      </TabPanel>
      <TabPanel value="2">
        <OverviewAggregateSupplementTab />
        {renderModeChangeButton()}
      </TabPanel>
    </TabContext>
  );
};
