import { Autocomplete, AutocompleteChangeReason, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOrganizationConditionTypes } from 'src/features/general/enquete-create/hooks/useSearchOrganizationConditions';
import { initAutocompleteValue } from '../hooks/useTemporaryOrganizationModal';

interface Props {
  id: string;
  label: string;
  keyCode: string;
  list: SearchOrganizationConditionTypes[];
  onChange: (value: SearchOrganizationConditionTypes, reason: AutocompleteChangeReason) => void;
  validationPullDown: boolean | null;
  forceDisabled?: boolean; // NOTE: true: どの条件でも常に非活性とする
}

const SegmentPullDown: React.FC<Props> = (props) => {
  const { id, label, keyCode, list, onChange, validationPullDown, forceDisabled } = props;
  const { t } = useTranslation();
  const disabled = forceDisabled === true ? true : list.length === 0;

  return (
    <>
      <Autocomplete
        id={id}
        options={list}
        value={list.find((e) => e.key === keyCode) ?? initAutocompleteValue}
        onChange={(e, v, r) => {
          onChange(v ?? initAutocompleteValue, r);
        }}
        isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {option.label}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label={label} size="small" fullWidth />}
        disabled={disabled}
      />

      {/* エラーメッセージ */}
      {validationPullDown && (
        <Typography sx={{ mt: 1, fontSize: 12, color: 'red' }} children={t('enqueteCreate.temporaryOrganization.error.required')} />
      )}
    </>
  );
};

export default SegmentPullDown;
