import { useTheme } from '@material-ui/core/styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Grid, Paper, Stack, Tab } from '@mui/material';
import { AxiosResponse } from 'axios';
import _, { isEmpty } from 'lodash';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import {
  EnqueteAnswerEntity,
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  ManufyearPlanurEmploymentPriorityDomainTabAnswerData,
  ManufyearPlanurEmploymentTabAnswerData,
  PersonnelEmploymentTabAnswerData,
  PersonnelFunctionTabAnswerData,
  PersonnelPriorityDomainTabAnswerData,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { tabCustom, tabStyles, topGridStyleSet, topTabCustom } from 'src/utils/tabStyles';
import { useEnqueteAnswerGrid } from '../../../../hooks/useEnqueteAnswerGrid';
import { EnqueteConfirmTab } from '../../shared-tabs/answer-confirm-status/components/EnqueteConfirmTab';
import ChangeRespondentComponent from '../../shared-tabs/change-respondent/components/ChangeRespondentComponent';
import OutsideSurveyPeriodTypography from '../../shared-tabs/outside-survey/components/OutsideSurveyPeriodTypography';
import { CreateUnderElementGridForm } from './tabs/common/components/CreateUnderElementGridForm';
import { EnqueteAnswerCreateDiffForm } from './tabs/common/components/EnqueteAnswerCreateDiffForm';
import { CreateCorporateGridForm } from './tabs/personnel-corporate/components/CreateCorporateGridForm';
import { CreateSalesGridForm } from './tabs/personnel-corporate/components/CreateSalesGridForm';
import { CreateTotalOnlyGrid } from './tabs/personnel-corporate/components/CreateTotalOnlyGrid';
import EnqueteAnswerTextForm from './tabs/personnel-corporate/components/EnqueteAnswerTextForm';
import { CreatePriorityDomainGrid } from './tabs/personnel-prioritydomain/components/CreatePriorityDomainGrid';
import { CreatePriorityDomainTotalGrid } from './tabs/personnel-prioritydomain/components/CreatePriorityDomainTotalGrid';
import { CreateReferenceAnotherGrid } from './tabs/personnel-prioritydomain/components/CreateReferenceAnotherGrid';
import { EnqueteAnswerCreatePriorityDomainDiffForm } from './tabs/personnel-prioritydomain/components/EnqueteAnswerCreatePriorityDomainDiffForm';

export interface TypePersonnelSurveyTabContainerProps {
  personnelEmploymentRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  setPersonnelEmploymentRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  personnelEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  setPersonnelEmploymentResultAndPlanDiffForm: React.Dispatch<React.SetStateAction<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>>;
  personnelEmploymentPlanAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  setPersonnelEmploymentPlanAndPlanDiffForm: React.Dispatch<React.SetStateAction<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>>;
  personnelCorporateRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  setPersonnelCorporateRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  personnelCorporateOtherFormTextValue: string | undefined;
  setOtherFormTextValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  personnelSalesRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  setPersonnelSalesRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  // 人件費人員（重点分野別）1.正社員の行データ
  personnelPriorityDomainRegularEmployeeRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）1.正社員　行データの設定方法
  setPersonnelPriorityDomainRegularEmployeeRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
  personnelPriorityDomainSecondmentFromTTCRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）2.TTCからの出向受入　行データの設定方法
  setPersonnelPriorityDomainSecondmentFromTTCRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  // 人件費人員（重点分野別）3.2以外の出向受入の行データ
  personnelPriorityDomainSecondmentFromOtherRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）3.2以外の出向受入　行データの設定方法
  setPersonnelPriorityDomainSecondmentFromOtherRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
  personnelPriorityDomainDispatchEtcRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト　行データの設定方法
  setPersonnelPriorityDomainDispatchEtcRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  manufyearPlanurEmploymentRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 人件費人員（重点分野別）年計 - 実見のデータ
  personnelPriorityDomainResultAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 人件費人員（重点分野別）年計 - 実見　データの設定方法
  setPersonnelPriorityDomainResultAndPlanDiffForm: React.Dispatch<
    React.SetStateAction<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>
  >;
  // 人件費人員（重点分野別）中計 - 年計のデータ
  personnelPriorityDomainPlanAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 人件費人員（重点分野別）中計 - 年計　データの設定方法
  setPersonnelPriorityDomainPlanAndPlanDiffForm: React.Dispatch<
    React.SetStateAction<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>
  >;
  setManufyearPlanurEmploymentRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  manufyearPlanurEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  setManufyearPlanurEmploymentResultAndPlanDiffForm: React.Dispatch<React.SetStateAction<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>>;
  manufyearPlanurEmploymentPlanAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined;
  setManufyearPlanurEmploymentPlanAndPlanDiffForm: React.Dispatch<React.SetStateAction<TempPersonnelSurveyDiffFormAnswerInputData[] | undefined>>;
  // 製造原価人員（重点分野別）1.正社員の行データ
  manufyearPlanurPriorityDomainRegularEmployeeRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 製造原価人員（重点分野別）1.正社員　行データの設定方法
  setManufPriorityDomainFullTimeEmployeeRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
  manufyearPlanurPriorityDomainOtherEmployeesRowData: EnqueteAnswerLoadInputEntity[] | undefined;
  // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他　行データの設定方法
  setManufPriorityDomainOtherRowData: React.Dispatch<React.SetStateAction<EnqueteAnswerLoadInputEntity[] | undefined>>;
  // 製造原価人員（重点分野別）年計 - 実見のデータ
  manufyearPlanurPriorityDomainResultAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 製造原価人員（重点分野別）年計 - 実見　データの設定方法
  setManufPriorityDomainResultAndPlanDiffForm: React.Dispatch<
    React.SetStateAction<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>
  >;
  // 製造原価人員（重点分野別）中計 - 年計のデータ
  manufyearPlanurPriorityDomainPlanAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined;
  // 製造原価人員（重点分野別）中計 - 年計　データの設定方法
  setManufPriorityDomainPlanAndPlanDiffForm: React.Dispatch<
    React.SetStateAction<TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined>
  >;
}

// Answerにデータが入っているかチェック
const enqueteAnswerInputCheck = (params: any, tabIndex: number, formIndex: number): boolean => {
  if (
    undefined !== params[tabIndex] &&
    undefined !== params[tabIndex].tabInputData &&
    params[tabIndex].tabInputData.length > 0 &&
    undefined !== params[tabIndex].tabInputData[formIndex] &&
    undefined !== params[tabIndex].tabInputData[formIndex].data
  ) {
    return true;
  }
  return false;
};

export const TypePersonnelSurveyTabContainer: React.FC<TypePersonnelSurveyTabContainerProps> = (props) => {
  const {
    personnelEmploymentRowData,
    setPersonnelEmploymentRowData,
    personnelEmploymentResultAndPlanDiffForm,
    setPersonnelEmploymentResultAndPlanDiffForm,
    personnelEmploymentPlanAndPlanDiffForm,
    setPersonnelEmploymentPlanAndPlanDiffForm,
    personnelCorporateRowData,
    setPersonnelCorporateRowData,
    personnelCorporateOtherFormTextValue,
    setOtherFormTextValue,
    personnelSalesRowData,
    setPersonnelSalesRowData,
    // 人件費人員（重点分野別）1.正社員の行データ
    personnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別）1.正社員　行データの設定方法
    setPersonnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
    personnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別）2.TTCからの出向受入　行データの設定方法
    setPersonnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別）3.2以外の出向受入の行データ
    personnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別）3.2以外の出向受入　行データの設定方法
    setPersonnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
    personnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト　行データの設定方法
    setPersonnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別）年計 - 実見のデータ
    personnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別）年計 - 実見　データの設定方法
    setPersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別）中計 - 年計のデータ
    personnelPriorityDomainPlanAndPlanDiffForm,
    // 人件費人員（重点分野別）中計 - 年計　データの設定方法
    setPersonnelPriorityDomainPlanAndPlanDiffForm,
    manufyearPlanurEmploymentRowData,
    setManufyearPlanurEmploymentRowData,
    manufyearPlanurEmploymentResultAndPlanDiffForm,
    setManufyearPlanurEmploymentResultAndPlanDiffForm,
    manufyearPlanurEmploymentPlanAndPlanDiffForm,
    setManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別）1.正社員の行データ
    manufyearPlanurPriorityDomainRegularEmployeeRowData,
    // 製造原価人員（重点分野別）1.正社員　行データの設定方法
    setManufPriorityDomainFullTimeEmployeeRowData,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
    manufyearPlanurPriorityDomainOtherEmployeesRowData,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他　行データの設定方法
    setManufPriorityDomainOtherRowData,
    // 製造原価人員（重点分野別）年計 - 実見のデータ
    manufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員（重点分野別）年計 - 実見　データの設定方法
    setManufPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員（重点分野別）中計 - 年計のデータ
    manufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別）中計 - 年計　データの設定方法
    setManufPriorityDomainPlanAndPlanDiffForm,
  } = props;
  const params = useLocation().search;
  const { t } = useTranslation();
  const { currentLang, isJapanese } = useLocales();
  const useJudg = useJudgmentCurrentLanguage();
  const enqueteAnswerInputApi = useEnqueteAnswerInputApi();
  // テーマ
  const theme = useTheme();

  const {
    setPastAnswer,
    setHasErrorPersonnelEmploymentGrid,
    setHasErrorPersonnelEmploymentResultAndPlanDiffForm,
    setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm,
    setHasErrorPersonnelEmploymentPlanAndPlanDiffForm,
    setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm,
    setHasErrorPersonnelCorporateGrid,
    setHasErrorPersonnelGridNotMatchTotal,
    setHasErrorPersonnelCorporateGridTotalOver,
    setHasErrorPersonnelSalesGridTotalOver,
    setHasErrorOtherTextForm,
    // 人件費人員(重点分野)年計-実見で入力されたデータの形のチェック
    setHasErrorPersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員(重点分野)中計-年計で入力されたデータの形のチェック
    setHasErrorPersonnelPriorityDomainPlanAndPlanDiffForm,
    // 人件費人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm,
    // 人件費人員(重点分野)：1.正社員の人員合計と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 人件費人員(重点分野)：2.TTCからの出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
    // 人件費人員(重点分野)：3.2.以外の出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトと（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
    setHasErrorManufyearPlanurEmploymentGrid,
    setHasErrorManufyearPlanurEmploymentResultAndPlanDiffForm,
    setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm,
    setHasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm,
    setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 製造原価人員(重点分野)年計-実見で入力されたデータの形のチェック
    setHasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員(重点分野)中計-年計で入力されたデータの形のチェック
    setHasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員(重点分野)：1.正社員の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
    setPersonnelEmploymentTotal,
    setCorporateGridTotal,
    setSalesGridTotal,
    // 人件費人員（重点分野別）1.正社員 合計行のデータ
    setPersonnelPriorityDomainRegularEmployeeGridTotal,
    // 人件費人員（重点分野別）2.TTCからの出向受入 合計行のデータ
    setPersonnelPriorityDomainSecondmentFromTTCGridTotal,
    // 人件費人員（重点分野別）3.2以外の出向受入 合計行のデータ
    setPersonnelPriorityDomainSecondmentFromOtherGridTotal,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト 合計行のデータ
    setPersonnelPriorityDomainDispatchEtcGridTotal,
    // 製造原価人員（重点分野別）1.正社員 合計行のデータ
    setManufyearPlanurPriorityDomainRegularEmployeeGridTotal,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他 合計行のデータ
    setManufyearPlanurPriorityDomainOtherEmployeesGridTotal,
    changePersonnelEmploymentRowData,
    changePersonnelEmploymentResultAndPlanDiffForm,
    changePersonnelEmploymentPlanAndPlanDiffForm,
    changePersonnelCorporateRowData,
    changePersonnelCorporateOtherFormTextValue,
    changePersonnelSalesRowData,
    changeManufyearPlanurEmploymentRowData,
    changeManufyearPlanurEmploymentResultAndPlanDiffForm,
    changeManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 人件費人員（重点分野別） 1.正社員 編集した値をdispatch方法
    changePersonnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別） 2.TTCからの出向受入 編集した値をdispatch方法
    changePersonnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別） 3.2以外の出向受入 編集した値をdispatch方法
    changePersonnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別） 4.派遣、嘱託、パート、アルバイト 編集した値をdispatch方法
    changePersonnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別） 年計-実見 編集した値をdispatch方法
    changePersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別） 中計-年計 編集した値をdispatch方法
    changePersonnelPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別） 1.正社員 編集した値をdispatch方法
    changeManufyearPlanurPriorityDomainRegularEmployeeRowData,
    // 製造原価人員（重点分野別） 2.1以外の従業員/派遣/その他 編集した値をdispatch方法
    changeManufyearPlanurPriorityDomainOtherEmployeesRowData,
    // 製造原価人員（重点分野別） 年計-実見 編集した値をdispatch方法
    changeManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員（重点分野別） 中計-年計 編集した値をdispatch方法
    changeManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    editAfterSave,
  } = useEnqueteAnswerGrid();

  // ユーザー情報宣言
  const { isAdmin, isViewerAdmin } = usePermission();

  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  // URLに含まれるTabパラメータを取得
  const tabParmsValue = new URLSearchParams(params).get('tab');
  const defaultTab = tabParmsValue ? tabParmsValue : 'PersonnelEmployment';
  // 人件費人員（重点分野別人員）　サブタブ
  const defaultSubTabPersonnelPriorityDomain = 'personnelPriorityDomainFullTimeEmployee';
  // 製造原価人員（重点分野別人員）　サブタブ
  const defaultSubTabManufPriorityDomain = 'manufyearPlanurEmploymentPriorityDomainFull';
  //依頼期間前かの情報を格納
  const isBeforeRequestTerm = useAppSelector((state) => state.enqueteAnswer.isBeforeRequestTerm);
  //依頼期間後かの情報を格納
  const isAfterRequestTerm = useAppSelector((state) => state.enqueteAnswer.isAfterRequestTerm);
  // 取得したSurveyDetailの情報を格納
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);
  // 取得したAnswerの情報を格納
  const enqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerData);
  // アンケートの各項目の入力データ
  const answerDataEdit = useAppSelector((state) => state.enqueteAnswer.enqueteAnswerDataEdit);
  // 取得したRespondentTargetの情報を格納
  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  // 取得したRespondentRequestの情報を格納
  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);
  // 取得した過去のAnswerの情報を格納(過去参照用)
  const pastEnqueteAnswerData = useAppSelector((state) => state.enqueteAnswer.pastEnqueteAnswerData);
  // 人件費人員(機能別)：コーポレート人員+営業人員合計エラー状態を取得
  const hasErrorPersonnelGridNotMatchTotal = useAppSelector((state) => state.enqueteAnswer.hasErrorPersonnelGridNotMatchTotal);
  // 人件費人員(雇用形態別)：年計-実見フォームのエラー状態を取得
  const hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm,
  );
  // 人件費人員(雇用形態別)：中計-年計フォームのエラー状態を取得
  const hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm,
  );
  // 人件費人員(重点分野)：年計-実見フォームのエラー状態を取得
  const hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm,
  );
  // 人件費人員(重点分野)：中計-年計フォームのエラー状態を取得
  const hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm,
  );
  // 製造原価人員(雇用形態別)：年計-実見フォームのエラー状態を取得
  const hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm,
  );
  // 製造原価人員(雇用形態別)：中計-年計フォームのエラー状態を取得
  const hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm,
  );
  // 製造原価人員(重点分野)：年計-実見フォームのエラー状態を取得
  const hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm,
  );
  // 製造原価人員(重点分野)：中計-年計フォームのエラー状態を取得
  const hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm = useAppSelector(
    (state) => state.enqueteAnswer.hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
  );

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [tabValue, setTabValue] = useState(defaultTab);
  // 人件費人員（重点分野別人員） サブタブ
  const [subTabValuePersonnelPriorityDomain, setSubTabValuePersonnelPriorityDomain] = useState(defaultSubTabPersonnelPriorityDomain);
  // 製造原価人員（重点分野別人員） サブタブ
  const [subTabValueManufPriorityDomain, setSubTabValueManufPriorityDomain] = useState(defaultSubTabManufPriorityDomain);

  // 設問情報の内容
  const [inputTabContent, setInputTabContent] = useState<any>([]);
  // 人件費人員(雇用形態別)：年計-実見の差異値
  const [personnelEmploymentResultAndPlanDiffValue, setPersonnelEmploymentResultAndPlanDiffValue] = useState<number>(0);
  // 人件費人員(雇用形態別)：中計-年計の差異値
  const [personnelEmploymentPlanAndPlanDiffValue, setPersonnelEmploymentPlanAndPlanDiffValue] = useState<number>(0);
  // 人件費人員(雇用形態別)：過去のグリッド回答情報
  const [pastPersonnelEmploymentRowData, setPastPersonnelEmploymentRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(機能別)：過去のコーポレートグリッド回答情報
  const [pastPersonnelCorporateRowData, setPastPersonnelCorporateRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(機能別)：過去の営業グリッド回答情報
  const [pastPersonnelSalesRowData, setPastPersonnelSalesRowData] = useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野) 1.正社員：過去のグリッド回答情報
  const [pastPersonnelPriorityDomainRegularEmployeeRowData, setPastPersonnelPriorityDomainRegularEmployeeRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野) 2.TTCからの出向受入：過去のグリッド回答情報
  const [pastPersonnelPriorityDomainSecondmentFromTTCRowData, setPastPersonnelPriorityDomainSecondmentFromTTCRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野) 3.2.以外の出向受入：過去のグリッド回答情報
  const [pastPersonnelPriorityDomainSecondmentFromOtherRowData, setPastPersonnelPriorityDomainSecondmentFromOtherRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野) 4.派遣、嘱託、パート、アルバイト：過去のグリッド回答情報
  const [pastPersonnelPriorityDomainDispatchEtcRowData, setPastPersonnelPriorityDomainDispatchEtcRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 人件費人員(重点分野)合計
  const [personnelPriorityDomainTotalRowData, setPersonnelPriorityDomainTotalRowData] = useState<EnqueteAnswerInputEntity[] | undefined>([]);
  // 人件費人員(重点分野)：年計-実見の差異値
  const [personnelPriorityDomainResultAndPlanDiffValue, setPersonnelPriorityDomainResultAndPlanDiffValue] = useState<number>(0);
  // 人件費人員(重点分野)：中計-年計の差異値
  const [personnelPriorityDomainPlanAndPlanDiffValue, setPersonnelPriorityDomainPlanAndPlanDiffValue] = useState<number>(0);
  // 製造原価人員(雇用形態別)：年計-実見の差異値
  const [manufyearPlanurEmploymentResultAndPlanDiffValue, setManufyearPlanurEmploymentResultAndPlanDiffValue] = useState<number>(0);
  // 製造原価人員(雇用形態別)：中計-年計の差異値
  const [manufyearPlanurEmploymentPlanAndPlanDiffValue, setManufyearPlanurEmploymentPlanAndPlanDiffValue] = useState<number>(0);
  // 製造原価人員(雇用形態別)：過去のグリッド回答情報
  const [pastManufyearPlanurEmploymentRowData, setPastManufyearPlanurEmploymentRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 製造原価人員(重点分野) 1.正社員：過去のグリッド回答情報
  const [pastManufPriorityDomainFullTimeEmployeeRowData, setPastManufPriorityDomainFullTimeEmployeeRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 製造原価人員(重点分野) 2.1以外の従業員/派遣/その他：過去のグリッド回答情報
  const [pastManufPriorityDomainOtherRowData, setPastManufPriorityDomainOtherRowData] =
    useState<EnqueteAnswerLoadInputEntity[] | undefined>(undefined);
  // 製造原価人員(重点分野)合計
  const [manufyearPlanurEmploymentPriorityDomainTotalRowData, setManufyearPlanurEmploymentPriorityDomainTotalRowData] = useState<
    EnqueteAnswerInputEntity[] | undefined
  >([]);
  // 製造原価人員(重点分野)：年計-実見の差異値
  const [manufyearPlanurEmploymentPriorityDomainResultAndPlanDiffValue, setManufyearPlanurEmploymentPriorityDomainResultAndPlanDiffValue] =
    useState<number>(0);
  // 製造原価人員(重点分野)：中計-年計の差異値
  const [manufyearPlanurEmploymentPriorityDomainPlanAndPlanDiffValue, setManufyearPlanurEmploymentPriorityDomainPlanAndPlanDiffValue] =
    useState<number>(0);

  // ①Reduxから、DBに保存された回答データ（編集前）とその入力データ（編集後）を取得する
  // ================================================================================

  // 編集前の1番目のタブの入力データ
  // ----------------------------------------
  const hasPropertyTabData0 = !isEmpty(enqueteAnswerData) && enqueteAnswerData.answerData.answerInputData.tabData[0].hasOwnProperty('tabInputData');
  const personnelEmploymentRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabData0
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[0].tabInputData[0].data
    : undefined;
  const personnelEmploymentResultAndPlanDiffFormSaved: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined = hasPropertyTabData0
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[0].tabInputData[1].data
    : undefined;
  const personnelEmploymentPlanAndPlanDiffFormSaved: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined = hasPropertyTabData0
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[0].tabInputData[2].data
    : undefined;

  // 編集前の2番目のタブの入力データ
  // ----------------------------------------
  const hasPropertyTabData1 = !isEmpty(enqueteAnswerData) && enqueteAnswerData.answerData.answerInputData.tabData[1].hasOwnProperty('tabInputData');
  const personnelCorporateRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabData1
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[1].tabInputData[0].data
    : undefined;
  const personnelCorporateOtherFormTextValueSaved: string | undefined = hasPropertyTabData1
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[1].tabInputData[1].data
    : undefined;
  const personnelSalesRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabData1
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[1].tabInputData[2].data
    : undefined;

  // 編集前の3番目のタブの入力データ
  // ----------------------------------------
  const hasPropertyTabData2 = !isEmpty(enqueteAnswerData) && enqueteAnswerData.answerData.answerInputData.tabData[2].hasOwnProperty('tabInputData');
  const manufyearPlanurEmploymentRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabData2
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[2].tabInputData[0].data
    : undefined;
  const manufyearPlanurEmploymentResultAndPlanDiffFormSaved: TempPersonnelSurveyDiffFormAnswerInputData | undefined = hasPropertyTabData2
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[2].tabInputData[1].data
    : undefined;
  const manufyearPlanurEmploymentPlanAndPlanDiffFormSaved: TempPersonnelSurveyDiffFormAnswerInputData | undefined = hasPropertyTabData2
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[2].tabInputData[2].data
    : undefined;

  // 編集前の人件費人員(重点分野)タブの入力データ
  // ----------------------------------------
  const hasPropertyTabDataPersonnelPriorityDomain =
    !isEmpty(enqueteAnswerData) &&
    !isEmpty(enqueteAnswerData.answerData) &&
    !isEmpty(enqueteAnswerData.answerData.answerInputData) &&
    !isEmpty(enqueteAnswerData.answerData.answerInputData.tabData[3]) &&
    enqueteAnswerData.answerData.answerInputData.tabData[3].hasOwnProperty('tabInputData');
  // 人件費人員(重点分野)：1.正社員
  const personnelPriorityDomainRegularEmployeeRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabDataPersonnelPriorityDomain
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[3].tabInputData[0].data
    : undefined;
  // 人件費人員(重点分野)：2.TTCからの出向受入
  const personnelPriorityDomainSecondmentFromTTCRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabDataPersonnelPriorityDomain
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[3].tabInputData[1].data
    : undefined;
  // 人件費人員(重点分野)：3.2.以外の出向受入
  const personnelPriorityDomainSecondmentFromOtherRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabDataPersonnelPriorityDomain
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[3].tabInputData[2].data
    : undefined;
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト
  const personnelPriorityDomainDispatchEtcRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabDataPersonnelPriorityDomain
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[3].tabInputData[3].data
    : undefined;
  // 人件費人員(重点分野)：年計-実見
  const personnelPriorityDomainResultAndPlanDiffFormSaved: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData | undefined =
    hasPropertyTabDataPersonnelPriorityDomain
      ? // @ts-ignore
        enqueteAnswerData?.answerData.answerInputData.tabData[3].tabInputData[4].data
      : undefined;
  // 人件費人員(重点分野)：中計-年計
  const personnelPriorityDomainPlanAndPlanDiffFormSaved: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData | undefined =
    hasPropertyTabDataPersonnelPriorityDomain
      ? // @ts-ignore
        enqueteAnswerData?.answerData.answerInputData.tabData[3].tabInputData[5].data
      : undefined;

  // 編集前の製造原価人員(重点分野)タブの入力データ
  // ----------------------------------------
  const hasPropertyTabDataManufPriorityDomain =
    !isEmpty(enqueteAnswerData) &&
    !isEmpty(enqueteAnswerData.answerData) &&
    !isEmpty(enqueteAnswerData.answerData.answerInputData) &&
    !isEmpty(enqueteAnswerData.answerData.answerInputData.tabData[4]) &&
    enqueteAnswerData.answerData.answerInputData.tabData[4].hasOwnProperty('tabInputData');
  // 製造原価人員(重点分野)：1.正社員
  const manufyearPlanurPriorityDomainRegularEmployeeRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabDataManufPriorityDomain
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[4].tabInputData[0].data
    : undefined;
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他
  const manufyearPlanurPriorityDomainOtherEmployeesRowDataSaved: EnqueteAnswerLoadInputEntity | undefined = hasPropertyTabDataManufPriorityDomain
    ? // @ts-ignore
      enqueteAnswerData?.answerData.answerInputData.tabData[4].tabInputData[1].data
    : undefined;
  // 製造原価人員(重点分野)：年計-実見
  const manufyearPlanurPriorityDomainResultAndPlanDiffFormSaved: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData | undefined =
    hasPropertyTabDataManufPriorityDomain
      ? // @ts-ignore
        enqueteAnswerData?.answerData.answerInputData.tabData[4].tabInputData[2].data
      : undefined;
  // 製造原価人員(重点分野)：中計-年計
  const manufyearPlanurPriorityDomainPlanAndPlanDiffFormSaved: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData | undefined =
    hasPropertyTabDataManufPriorityDomain
      ? // @ts-ignore
        enqueteAnswerData?.answerData.answerInputData.tabData[4].tabInputData[3].data
      : undefined;

  // （編集してないかもしれないが）編集後のデータ
  // ※○○DiffFormは数値を文字列化しているのでそこは数値に戻してやる必要がある
  // ----------------------------------------
  const personnelEmploymentRowDataEdit = answerDataEdit.personnelEmploymentRowData;
  const personnelEmploymentResultAndPlanDiffFormEdit = (
    answerDataEdit.personnelEmploymentResultAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const personnelEmploymentPlanAndPlanDiffFormEdit = (
    answerDataEdit.personnelEmploymentPlanAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const personnelCorporateRowDataEdit = answerDataEdit.personnelCorporateRowData;
  const personnelCorporateOtherFormTextValueEdit = answerDataEdit.personnelCorporateOtherFormTextValue;
  const personnelSalesRowDataEdit = answerDataEdit.personnelSalesRowData;
  // 人件費人員(重点分野)：1.正社員
  const personnelPriorityDomainRegularEmployeeRowDataEdit = answerDataEdit.personnelPriorityDomainRegularEmployeeRowData;
  // 人件費人員(重点分野)：2.TTCからの出向受入
  const personnelPriorityDomainSecondmentFromTTCRowDataEdit = answerDataEdit.personnelPriorityDomainSecondmentFromTTCRowData;
  // 人件費人員(重点分野)：3.2.以外の出向受入
  const personnelPriorityDomainSecondmentFromOtherRowDataEdit = answerDataEdit.personnelPriorityDomainSecondmentFromOtherRowData;
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト
  const personnelPriorityDomainDispatchEtcRowDataEdit = answerDataEdit.personnelPriorityDomainDispatchEtcRowData;
  // 人件費人員(重点分野)：年計-実見
  const personnelPriorityDomainResultAndPlanDiffFormEdit = (
    answerDataEdit.personnelPriorityDomainResultAndPlanDiffForm as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  // 人件費人員(重点分野)：中計-年計
  const personnelPriorityDomainPlanAndPlanDiffFormEdit = (
    answerDataEdit.personnelPriorityDomainPlanAndPlanDiffForm as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const manufyearPlanurEmploymentRowDataEdit = answerDataEdit.manufyearPlanurEmploymentRowData;
  const manufyearPlanurEmploymentResultAndPlanDiffFormEdit = (
    answerDataEdit.manufyearPlanurEmploymentResultAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  const manufyearPlanurEmploymentPlanAndPlanDiffFormEdit = (
    answerDataEdit.manufyearPlanurEmploymentPlanAndPlanDiffForm as TempPersonnelSurveyDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  // 製造原価人員(重点分野)：1.正社員
  const manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit = answerDataEdit.manufyearPlanurPriorityDomainRegularEmployeeRowData;
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他
  const manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit = answerDataEdit.manufyearPlanurPriorityDomainOtherEmployeesRowData;
  // 製造原価人員(重点分野)：年計-実見
  const manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit = (
    answerDataEdit.manufyearPlanurPriorityDomainResultAndPlanDiffForm as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });
  // 製造原価人員(重点分野)：中計-年計
  const manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit = (
    answerDataEdit.manufyearPlanurPriorityDomainPlanAndPlanDiffForm as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined
  )?.map((row) => {
    return Object.assign({}, row, {
      diffMemberInputValue: row !== undefined && row.diffMemberInputValue !== '' ? Number(row.diffMemberInputValue) : null,
    });
  });

  // フォームに入力があったらフラグを立てる
  /* eslint-disable */
  useEffect(() => {
    // ②編集前と編集後を比較する
    // ================================================================================
    const isEqualPersonnelEmploymentRowData: boolean = _.isEqual(personnelEmploymentRowDataSaved, personnelEmploymentRowDataEdit);
    const isEqualPersonnelEmploymentResultAndPlanDiffForm: boolean = _.isEqual(
      personnelEmploymentResultAndPlanDiffFormSaved,
      personnelEmploymentResultAndPlanDiffFormEdit,
    );
    const isEqualPersonnelEmploymentPlanAndPlanDiffForm: boolean = _.isEqual(
      personnelEmploymentPlanAndPlanDiffFormSaved,
      personnelEmploymentPlanAndPlanDiffFormEdit,
    );
    const isEqualPersonnelCorporateRowData: boolean = _.isEqual(personnelCorporateRowDataSaved, personnelCorporateRowDataEdit);
    const isEqualPersonnelCorporateOtherFormTextValue: boolean = _.isEqual(
      personnelCorporateOtherFormTextValueSaved,
      personnelCorporateOtherFormTextValueEdit,
    );
    const isEqualPersonnelSalesRowData: boolean = _.isEqual(personnelSalesRowDataSaved, personnelSalesRowDataEdit);
    // 人件費人員(重点分野)：1.正社員
    const isEqualPersonnelPriorityDomainRegularEmployeeRowData: boolean = _.isEqual(
      personnelPriorityDomainRegularEmployeeRowDataSaved,
      personnelPriorityDomainRegularEmployeeRowDataEdit,
    );
    // 人件費人員(重点分野)：2.TTCからの出向受入
    const isEqualPersonnelPriorityDomainSecondmentFromTTCRowData: boolean = _.isEqual(
      personnelPriorityDomainSecondmentFromTTCRowDataSaved,
      personnelPriorityDomainSecondmentFromTTCRowDataEdit,
    );
    // 人件費人員(重点分野)：3.2.以外の出向受入
    const isEqualPersonnelPriorityDomainSecondmentFromOtherRowData: boolean = _.isEqual(
      personnelPriorityDomainSecondmentFromOtherRowDataSaved,
      personnelPriorityDomainSecondmentFromOtherRowDataEdit,
    );
    // 人件費人員(重点分野)：1.正社員
    const isEqualPersonnelPriorityDomainDispatchEtcRowData: boolean = _.isEqual(
      personnelPriorityDomainDispatchEtcRowDataSaved,
      personnelPriorityDomainDispatchEtcRowDataEdit,
    );
    // 人件費人員(重点分野)：年計-実見
    const isEqualPersonnelPriorityDomainResultAndPlanDiffForm: boolean = _.isEqual(
      personnelPriorityDomainResultAndPlanDiffFormSaved,
      personnelPriorityDomainResultAndPlanDiffFormEdit,
    );
    // 人件費人員(重点分野)：中計-年計
    const isEqualPersonnelPriorityDomainPlanAndPlanDiffForm: boolean = _.isEqual(
      personnelPriorityDomainPlanAndPlanDiffFormSaved,
      personnelPriorityDomainPlanAndPlanDiffFormEdit,
    );
    const isEqualManufyearPlanurEmploymentRowData: boolean = _.isEqual(manufyearPlanurEmploymentRowDataSaved, manufyearPlanurEmploymentRowDataEdit);
    const isEqualManufyearPlanurEmploymentResultAndPlanDiffForm: boolean = _.isEqual(
      manufyearPlanurEmploymentResultAndPlanDiffFormSaved,
      manufyearPlanurEmploymentResultAndPlanDiffFormEdit,
    );
    const isEqualManufyearPlanurEmploymentPlanAndPlanDiffForm: boolean = _.isEqual(
      manufyearPlanurEmploymentPlanAndPlanDiffFormSaved,
      manufyearPlanurEmploymentPlanAndPlanDiffFormEdit,
    );
    // 製造原価人員(重点分野)：1.正社員
    const isEqualManufPriorityDomainFullTimeRowData: boolean = _.isEqual(
      manufyearPlanurPriorityDomainRegularEmployeeRowDataSaved,
      manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit,
    );
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他
    const isEqualManufPriorityDomainOtherRowData: boolean = _.isEqual(
      manufyearPlanurPriorityDomainOtherEmployeesRowDataSaved,
      manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit,
    );
    // 製造原価人員(重点分野)：年計-実見
    const isEqualManufPriorityDomainResultAndPlanDiffForm: boolean = _.isEqual(
      manufyearPlanurPriorityDomainResultAndPlanDiffFormSaved,
      manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit,
    );
    // 製造原価人員(重点分野)：中計-年計
    const isEqualManufPriorityDomainPlanAndPlanDiffForm: boolean = _.isEqual(
      manufyearPlanurPriorityDomainPlanAndPlanDiffFormSaved,
      manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit,
    );

    // ③どこか編集した部分があるかどうか
    // ================================================================================
    const hasNotDiff: boolean =
      isEqualPersonnelEmploymentRowData &&
      isEqualPersonnelEmploymentResultAndPlanDiffForm &&
      isEqualPersonnelEmploymentPlanAndPlanDiffForm &&
      isEqualPersonnelCorporateRowData &&
      isEqualPersonnelCorporateOtherFormTextValue &&
      isEqualPersonnelSalesRowData &&
      isEqualPersonnelPriorityDomainRegularEmployeeRowData &&
      isEqualPersonnelPriorityDomainSecondmentFromTTCRowData &&
      isEqualPersonnelPriorityDomainSecondmentFromOtherRowData &&
      isEqualPersonnelPriorityDomainDispatchEtcRowData &&
      isEqualPersonnelPriorityDomainResultAndPlanDiffForm &&
      isEqualPersonnelPriorityDomainPlanAndPlanDiffForm &&
      isEqualManufyearPlanurEmploymentRowData &&
      isEqualManufyearPlanurEmploymentResultAndPlanDiffForm &&
      isEqualManufyearPlanurEmploymentPlanAndPlanDiffForm &&
      isEqualManufPriorityDomainFullTimeRowData &&
      isEqualManufPriorityDomainOtherRowData &&
      isEqualManufPriorityDomainResultAndPlanDiffForm &&
      isEqualManufPriorityDomainPlanAndPlanDiffForm;
    editAfterSave(!hasNotDiff);
  }, [
    personnelEmploymentRowDataEdit,
    personnelEmploymentResultAndPlanDiffFormEdit,
    personnelEmploymentPlanAndPlanDiffFormEdit,
    personnelCorporateRowDataEdit,
    personnelCorporateOtherFormTextValueEdit,
    personnelSalesRowDataEdit,
    // 人件費人員（重点分野別）1.正社員の編集された行データ
    personnelPriorityDomainRegularEmployeeRowDataEdit,
    // 人件費人員（重点分野別）2.TTCからの出向受入の編集された行データ
    personnelPriorityDomainSecondmentFromTTCRowDataEdit,
    // 人件費人員（重点分野別）3.2以外の出向受入の編集された行データ
    personnelPriorityDomainSecondmentFromOtherRowDataEdit,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの編集された行データ
    personnelPriorityDomainDispatchEtcRowDataEdit,
    // 人件費人員（重点分野別）年計 - 実見の編集されたデータ
    personnelPriorityDomainResultAndPlanDiffFormEdit,
    // 人件費人員（重点分野別）中計 - 年計の編集されたデータ
    personnelPriorityDomainPlanAndPlanDiffFormEdit,
    manufyearPlanurEmploymentRowDataEdit,
    manufyearPlanurEmploymentResultAndPlanDiffFormEdit,
    manufyearPlanurEmploymentPlanAndPlanDiffFormEdit,
    // 製造原価人員（重点分野別）1.正社員の編集された行データ
    manufyearPlanurPriorityDomainRegularEmployeeRowDataEdit,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の編集された行データ
    manufyearPlanurPriorityDomainOtherEmployeesRowDataEdit,
    // 製造原価人員（重点分野別）年計 - 実見の編集されたデータ
    manufyearPlanurPriorityDomainResultAndPlanDiffFormEdit,
    // 製造原価人員（重点分野別）中計 - 年計の編集されたデータ
    manufyearPlanurPriorityDomainPlanAndPlanDiffFormEdit,
  ]);
  /* eslint-enable */

  //-------------------------------------------------------------
  // 初回読み込み時に合計値をセット(突貫工事)
  //-------------------------------------------------------------
  const setGridTotalDataByFirstLoad = async () => {
    //-------------------------------------------------------------
    // 人件費人員(雇用形態別)
    //-------------------------------------------------------------

    let previousYearPlanTotalA: number = 0;
    let resultForecastTotalA: number = 0;
    let yearPlanTotalA: number = 0;
    let midTermPlanTotalA: number = 0;

    // 人件費人員(雇用形態別)のグリッド回答情報が存在すれば合計値を計算する
    if (personnelEmploymentRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempEmploymentRowData = personnelEmploymentRowData.filter((x) => x.id !== 'notConsolidated');

      tempEmploymentRowData.forEach((data: any) => {
        previousYearPlanTotalA += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        resultForecastTotalA += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        yearPlanTotalA += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        midTermPlanTotalA += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const eploymentTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0]
            ? useJudg.getText(inputTabContent[0][0]['gridTotalName'] as languageDataTypes)
            : '',
        previousYearPlan: previousYearPlanTotalA,
        resultForecast: resultForecastTotalA,
        yearPlan: yearPlanTotalA,
        midTermPlan: midTermPlanTotalA,
      };
      // 人件費人員(雇用形態別)の合計行をセットする
      setPersonnelEmploymentTotal(eploymentTotalRowData);
    }

    //-------------------------------------------------------------
    // 人件費人員(機能別) コーポレート人員
    //-------------------------------------------------------------
    let previousYearPlanTotalB: number = 0;
    let resultForecastTotalB: number = 0;
    let yearPlanTotalB: number = 0;
    let midTermPlanTotalB: number = 0;

    // 人件費人員(機能別)のコーポレートグリッド回答情報が存在すれば合計値を計算する
    if (personnelCorporateRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempCorporateRowData = personnelCorporateRowData.filter((x) => x.id !== 'notConsolidated');

      tempCorporateRowData.forEach((data: any) => {
        previousYearPlanTotalB += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        resultForecastTotalB += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        yearPlanTotalB += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        midTermPlanTotalB += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const corporateTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[1] && undefined !== inputTabContent[1][0]
            ? useJudg.getText(inputTabContent[1][0]['gridTotalName'] as languageDataTypes)
            : '',
        previousYearPlan: previousYearPlanTotalB,
        resultForecast: resultForecastTotalB,
        yearPlan: yearPlanTotalB,
        midTermPlan: midTermPlanTotalB,
      };
      // 人件費人員(機能別)のコーポレート合計行をセットする
      setCorporateGridTotal(corporateTotalRowData);
    }

    //-------------------------------------------------------------
    // 人件費人員(機能別) 営業人員
    //-------------------------------------------------------------
    let previousYearPlanTotalC: number = 0;
    let resultForecastTotalC: number = 0;
    let yearPlanTotalC: number = 0;
    let midTermPlanTotalC: number = 0;

    // 意図的に０を入力していた場合の自動計算フラグ
    let resultForecastTotalEmptyFlg: boolean = true;
    let yearPlanTotalEmptyFlg: boolean = true;
    let midTermPlanTotalEmptyFlg: boolean = true;

    // 人件費人員(機能別)の営業グリッド回答情報が存在すれば合計値を計算する
    if (personnelSalesRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempSalesRowData = personnelSalesRowData.filter((x) => x.id !== 'notConsolidated');

      tempSalesRowData.forEach((data: any) => {
        previousYearPlanTotalC += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        resultForecastTotalC += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        yearPlanTotalC += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        midTermPlanTotalC += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      tempSalesRowData.forEach((data: any) => {
        resultForecastTotalEmptyFlg = resultForecastTotalEmptyFlg && isNaN(Number(data['resultForecast'] ?? NaN)) ? true : false;
        yearPlanTotalEmptyFlg = yearPlanTotalEmptyFlg && isNaN(Number(data['yearPlan'] ?? NaN)) ? true : false;
        midTermPlanTotalEmptyFlg = midTermPlanTotalEmptyFlg && isNaN(Number(data['midTermPlan'] ?? NaN)) ? true : false;
      });
    }
    // 自動計算するので値は必ず入る
    if (previousYearPlanTotalC === 0) {
      previousYearPlanTotalC = previousYearPlanTotalA - previousYearPlanTotalB;
    }
    if (resultForecastTotalC === 0 && resultForecastTotalEmptyFlg) {
      resultForecastTotalC = resultForecastTotalA - resultForecastTotalB;
    }
    if (yearPlanTotalC === 0 && yearPlanTotalEmptyFlg) {
      yearPlanTotalC = yearPlanTotalA - yearPlanTotalB;
    }
    if (midTermPlanTotalC === 0 && midTermPlanTotalEmptyFlg) {
      midTermPlanTotalC = midTermPlanTotalA - midTermPlanTotalB;
    }

    const salesTotalRowData: EnqueteAnswerInputEntity = {
      id: 'sumTotal',
      inputItem:
        undefined !== inputTabContent[1] && undefined !== inputTabContent[1][2]
          ? useJudg.getText(inputTabContent[1][2]['gridTotalName'] as languageDataTypes)
          : '',
      previousYearPlan: previousYearPlanTotalC,
      resultForecast: resultForecastTotalC,
      yearPlan: yearPlanTotalC,
      midTermPlan: midTermPlanTotalC,
    };
    // 人件費人員(機能別)の営業合計行をセットする
    setSalesGridTotal(salesTotalRowData);
    //-------------------------------------------------------------
    // 人件費人員(重点分野)の合計
    //-------------------------------------------------------------
    let personnelPriorityDomainTotalRowData: EnqueteAnswerInputEntity[] = [];
    //-------------------------------------------------------------
    // 人件費人員(重点分野)：1.正社員
    //-------------------------------------------------------------
    // 人件費人員(重点分野)：1.正社員 計画の合計
    let previousYearPlanTotalD: number = 0;
    // 人件費人員(重点分野)：1.正社員 実見の合計
    let resultForecastTotalD: number = 0;
    // 人件費人員(重点分野)：1.正社員 年計の合計
    let yearPlanTotalD: number = 0;
    // 人件費人員(重点分野)：1.正社員 中計の合計
    let midTermPlanTotalD: number = 0;
    // 人件費人員(重点分野)：1.正社員 過去年計の合計
    let previousYearPlanTotalOfPersonnelPriorityDomainRegularEmployee: number = 0;
    // 人件費人員(重点分野)：1.正社員 過去のグリッド回答情報が存在すれば合計値を計算する
    if (pastPersonnelPriorityDomainRegularEmployeeRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainRegularEmployeeRowData = pastPersonnelPriorityDomainRegularEmployeeRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );
      tempPersonnelPriorityDomainRegularEmployeeRowData.forEach((data: any) => {
        previousYearPlanTotalOfPersonnelPriorityDomainRegularEmployee += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      });
    }

    // 人件費人員(重点分野)：1.正社員 グリッド回答情報が存在すれば合計値を計算する
    if (personnelPriorityDomainRegularEmployeeRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainRegularEmployeeRowData = personnelPriorityDomainRegularEmployeeRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );

      tempPersonnelPriorityDomainRegularEmployeeRowData.forEach((data: any) => {
        // 人件費人員(重点分野)：1.正社員 計画の合計を計算する
        previousYearPlanTotalD += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        // 人件費人員(重点分野)：1.正社員 実見の合計を計算する
        resultForecastTotalD += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        // 人件費人員(重点分野)：1.正社員 年計の合計を計算する
        yearPlanTotalD += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        // 人件費人員(重点分野)：1.正社員 中計の合計を計算する
        midTermPlanTotalD += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const personnelPriorityDomainRegularEmployeeGridTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[3] && undefined !== inputTabContent[3][0]
            ? useJudg.getText(inputTabContent[3][0]['gridTotalName'] as languageDataTypes)
            : '',
        // 計画
        previousYearPlan: previousYearPlanTotalD,
        // 実見
        resultForecast: resultForecastTotalD,
        // 年計
        yearPlan: yearPlanTotalD,
        // 中計
        midTermPlan: midTermPlanTotalD,
      };
      // 人件費人員(重点分野)：1.正社員 合計行をセットする
      setPersonnelPriorityDomainRegularEmployeeGridTotal(personnelPriorityDomainRegularEmployeeGridTotalRowData);
      let newRowData = _.cloneDeep(personnelPriorityDomainRegularEmployeeGridTotalRowData);
      newRowData.previousYearPlan = previousYearPlanTotalOfPersonnelPriorityDomainRegularEmployee;
      // 合計に加える
      personnelPriorityDomainTotalRowData.push(newRowData);
      // データが存在しない場合
    } else {
      // 合計に加える
      // 空の行を行データとして使用する
      let defultPersonnelPriorityDomainTotalRowData: EnqueteAnswerInputEntity = {
        // 行ID
        id: 'defult',
        // 行名
        inputItem: '',
        // 前回の計画データ
        previousYearPlan: previousYearPlanTotalOfPersonnelPriorityDomainRegularEmployee,
      };
      personnelPriorityDomainTotalRowData.push(defultPersonnelPriorityDomainTotalRowData);
    }

    //-------------------------------------------------------------
    // 人件費人員(重点分野)：2.TTCからの出向受入
    //-------------------------------------------------------------
    // 人件費人員(重点分野)：2.TTCからの出向受入 計画の合計
    let previousYearPlanTotalE: number = 0;
    // 人件費人員(重点分野)：2.TTCからの出向受入 実見の合計
    let resultForecastTotalE: number = 0;
    // 人件費人員(重点分野)：2.TTCからの出向受入 年計の合計
    let yearPlanTotalE: number = 0;
    // 人件費人員(重点分野)：2.TTCからの出向受入 中計の合計
    let midTermPlanTotalE: number = 0;
    // 人件費人員(重点分野)：2.TTCからの出向受入 過去年計の合計
    let previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromTTC: number = 0;
    // 人件費人員(重点分野)：2.TTCからの出向受入 過去のグリッド回答情報が存在すれば合計値を計算する
    if (pastPersonnelPriorityDomainSecondmentFromTTCRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainSecondmentFromTTCRowData = pastPersonnelPriorityDomainSecondmentFromTTCRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );
      tempPersonnelPriorityDomainSecondmentFromTTCRowData.forEach((data: any) => {
        previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromTTC += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      });
    }
    // 人件費人員(重点分野)：2.TTCからの出向受入 グリッド回答情報が存在すれば合計値を計算する
    if (personnelPriorityDomainSecondmentFromTTCRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainSecondmentFromTTCRowData = personnelPriorityDomainSecondmentFromTTCRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );

      tempPersonnelPriorityDomainSecondmentFromTTCRowData.forEach((data: any) => {
        // 人件費人員(重点分野)：2.TTCからの出向受入 計画の合計を計算する
        previousYearPlanTotalE += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        // 人件費人員(重点分野)：2.TTCからの出向受入 実見の合計を計算する
        resultForecastTotalE += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        // 人件費人員(重点分野)：2.TTCからの出向受入 年計の合計を計算する
        yearPlanTotalE += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        // 人件費人員(重点分野)：2.TTCからの出向受入 中計の合計を計算する
        midTermPlanTotalE += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const personnelPriorityDomainSecondmentFromTTCGridTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[3] && undefined !== inputTabContent[3][1]
            ? useJudg.getText(inputTabContent[3][1]['gridTotalName'] as languageDataTypes)
            : '',
        // 計画
        previousYearPlan: previousYearPlanTotalE,
        // 実見
        resultForecast: resultForecastTotalE,
        // 年計
        yearPlan: yearPlanTotalE,
        // 中計
        midTermPlan: midTermPlanTotalE,
      };
      // 人件費人員(重点分野)：2.TTCからの出向受入 合計行をセットする
      setPersonnelPriorityDomainSecondmentFromTTCGridTotal(personnelPriorityDomainSecondmentFromTTCGridTotalRowData);
      let newRowData = _.cloneDeep(personnelPriorityDomainSecondmentFromTTCGridTotalRowData);
      newRowData.previousYearPlan = previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromTTC;
      // 合計に加える
      personnelPriorityDomainTotalRowData.push(newRowData);
      // データが存在しない場合
    } else {
      // 合計に加える
      // 空の行を行データとして使用する
      let defultPersonnelPriorityDomainTotalRowData: EnqueteAnswerInputEntity = {
        // 行ID
        id: 'defult',
        // 行名
        inputItem: '',
        // 前回の計画データ
        previousYearPlan: previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromTTC,
      };
      personnelPriorityDomainTotalRowData.push(defultPersonnelPriorityDomainTotalRowData);
    }

    //-------------------------------------------------------------
    // 人件費人員(重点分野)：3.2.以外の出向受入
    //-------------------------------------------------------------
    // 人件費人員(重点分野)：3.2.以外の出向受入　計画の合計
    let previousYearPlanTotalF: number = 0;
    // 人件費人員(重点分野)：3.2.以外の出向受入　実見の合計
    let resultForecastTotalF: number = 0;
    // 人件費人員(重点分野)：3.2.以外の出向受入　年計の合計
    let yearPlanTotalF: number = 0;
    // 人件費人員(重点分野)：3.2.以外の出向受入　中計の合計
    let midTermPlanTotalF: number = 0;
    // 人件費人員(重点分野)：3.2.以外の出向受入 過去年計の合計
    let previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromOther: number = 0;
    // 人件費人員(重点分野)：3.2.以外の出向受入 過去のグリッド回答情報が存在すれば合計値を計算する
    if (pastPersonnelPriorityDomainSecondmentFromOtherRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainSecondmentFromOtherRowData = pastPersonnelPriorityDomainSecondmentFromOtherRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );
      tempPersonnelPriorityDomainSecondmentFromOtherRowData.forEach((data: any) => {
        previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromOther += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      });
    }
    // 人件費人員(重点分野)：3.2.以外の出向受入 グリッド回答情報が存在すれば合計値を計算する
    if (personnelPriorityDomainSecondmentFromOtherRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainSecondmentFromOtherRowData = personnelPriorityDomainSecondmentFromOtherRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );

      tempPersonnelPriorityDomainSecondmentFromOtherRowData.forEach((data: any) => {
        // 人件費人員(重点分野)：3.2.以外の出向受入　計画の合計を計算する
        previousYearPlanTotalF += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        // 人件費人員(重点分野)：3.2.以外の出向受入　実見の合計を計算する
        resultForecastTotalF += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        // 人件費人員(重点分野)：3.2.以外の出向受入　年計の合計を計算する
        yearPlanTotalF += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        // 人件費人員(重点分野)：3.2.以外の出向受入　中計の合計を計算する
        midTermPlanTotalF += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const personnelPriorityDomainSecondmentFromOtherGridTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[3] && undefined !== inputTabContent[3][2]
            ? useJudg.getText(inputTabContent[3][2]['gridTotalName'] as languageDataTypes)
            : '',
        // 計画
        previousYearPlan: previousYearPlanTotalF,
        // 実見
        resultForecast: resultForecastTotalF,
        // 年計
        yearPlan: yearPlanTotalF,
        // 中計
        midTermPlan: midTermPlanTotalF,
      };
      // 人件費人員(重点分野)：3.2.以外の出向受入 合計行をセットする
      setPersonnelPriorityDomainSecondmentFromOtherGridTotal(personnelPriorityDomainSecondmentFromOtherGridTotalRowData);
      let newRowData = _.cloneDeep(personnelPriorityDomainSecondmentFromOtherGridTotalRowData);
      newRowData.previousYearPlan = previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromOther;
      // 合計に加える
      personnelPriorityDomainTotalRowData.push(newRowData);
      // データが存在しない場合
    } else {
      // 合計に加える
      // 空の行を行データとして使用する
      let defultPersonnelPriorityDomainTotalRowData: EnqueteAnswerInputEntity = {
        // 行ID
        id: 'defult',
        // 行名
        inputItem: '',
        // 前回の計画データ
        previousYearPlan: previousYearPlanTotalOfPersonnelPriorityDomainSecondmentFromOther,
      };
      personnelPriorityDomainTotalRowData.push(defultPersonnelPriorityDomainTotalRowData);
    }

    //-------------------------------------------------------------
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト
    //-------------------------------------------------------------
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 計画の合計
    let previousYearPlanTotalG: number = 0;
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 実見の合計
    let resultForecastTotalG: number = 0;
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 年計の合計
    let yearPlanTotalG: number = 0;
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 中計の合計
    let midTermPlanTotalG: number = 0;
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 過去年計の合計
    let previousYearPlanTotalOfPersonnelPriorityDomainDispatchEtc: number = 0;
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 過去のグリッド回答情報が存在すれば合計値を計算する
    if (pastPersonnelPriorityDomainDispatchEtcRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainDispatchEtcRowData = pastPersonnelPriorityDomainDispatchEtcRowData.filter((x) => x.id !== 'notConsolidated');
      // 過去のグリッド回答情報
      tempPersonnelPriorityDomainDispatchEtcRowData.forEach((data: any) => {
        // 合計値を計算する
        previousYearPlanTotalOfPersonnelPriorityDomainDispatchEtc += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      });
    }
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト グリッド回答情報が存在すれば合計値を計算する
    if (personnelPriorityDomainDispatchEtcRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempPersonnelPriorityDomainDispatchEtcRowData = personnelPriorityDomainDispatchEtcRowData.filter((x) => x.id !== 'notConsolidated');

      tempPersonnelPriorityDomainDispatchEtcRowData.forEach((data: any) => {
        // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 計画の合計を計算する
        previousYearPlanTotalG += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 実見の合計を計算する
        resultForecastTotalG += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 年計の合計を計算する
        yearPlanTotalG += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 中計の合計を計算する
        midTermPlanTotalG += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const personnelPriorityDomainDispatchEtcGridTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[3] && undefined !== inputTabContent[3][3]
            ? useJudg.getText(inputTabContent[3][3]['gridTotalName'] as languageDataTypes)
            : '',
        // 計画
        previousYearPlan: previousYearPlanTotalG,
        // 実見
        resultForecast: resultForecastTotalG,
        // 年計
        yearPlan: yearPlanTotalG,
        // 中計
        midTermPlan: midTermPlanTotalG,
      };
      // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト 合計行をセットする
      setPersonnelPriorityDomainDispatchEtcGridTotal(personnelPriorityDomainDispatchEtcGridTotalRowData);
      let newRowData = _.cloneDeep(personnelPriorityDomainDispatchEtcGridTotalRowData);
      newRowData.previousYearPlan = previousYearPlanTotalOfPersonnelPriorityDomainDispatchEtc;
      // 合計に加える
      personnelPriorityDomainTotalRowData.push(newRowData);
      // データが存在しない場合
    } else {
      // 合計に加える
      // 空の行を行データとして使用する
      let defultPersonnelPriorityDomainTotalRowData: EnqueteAnswerInputEntity = {
        // 行ID
        id: 'defult',
        // 行名
        inputItem: '',
        // 前回の計画データ
        previousYearPlan: previousYearPlanTotalOfPersonnelPriorityDomainDispatchEtc,
      };
      personnelPriorityDomainTotalRowData.push(defultPersonnelPriorityDomainTotalRowData);
    }
    // 人件費人員(重点分野)：合計をセットする
    setPersonnelPriorityDomainTotalRowData(personnelPriorityDomainTotalRowData);
    //-------------------------------------------------------------
    // 製造原価人員(重点分野)の合計
    //-------------------------------------------------------------
    let manufyearPlanurEmploymentPriorityDomainTotalRowData: EnqueteAnswerInputEntity[] = [];
    //-------------------------------------------------------------
    // 製造原価人員(重点分野)：1.正社員
    //-------------------------------------------------------------
    // 製造原価人員(重点分野)：1.正社員　計画の合計
    let previousYearPlanTotalH: number = 0;
    // 製造原価人員(重点分野)：1.正社員　実見の合計
    let resultForecastTotalH: number = 0;
    // 製造原価人員(重点分野)：1.正社員　年計の合計
    let yearPlanTotalH: number = 0;
    // 製造原価人員(重点分野)：1.正社員　中計の合計
    let midTermPlanTotalH: number = 0;
    // 製造原価人員(重点分野)：1.正社員　過去年計の合計
    let previousYearPlanTotalOfManufPriorityDomainFullTimeEmployee: number = 0;
    // 製造原価人員(重点分野)：1.正社員 過去グリッド回答情報が存在すれば合計値を計算する
    if (pastManufPriorityDomainFullTimeEmployeeRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempManufPriorityDomainFullTimeEmployeeRowData = pastManufPriorityDomainFullTimeEmployeeRowData.filter((x) => x.id !== 'notConsolidated');
      // 過去グリッド回答情報
      tempManufPriorityDomainFullTimeEmployeeRowData.forEach((data: any) => {
        // 合計値を計算する
        previousYearPlanTotalOfManufPriorityDomainFullTimeEmployee += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      });
    }
    // 製造原価人員(重点分野)：1.正社員 グリッド回答情報が存在すれば合計値を計算する
    if (manufyearPlanurPriorityDomainRegularEmployeeRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempManufPriorityDomainFullTimeEmployeeRowData = manufyearPlanurPriorityDomainRegularEmployeeRowData.filter(
        (x) => x.id !== 'notConsolidated',
      );

      tempManufPriorityDomainFullTimeEmployeeRowData.forEach((data: any) => {
        // 製造原価人員(重点分野)：1.正社員　計画の合計を計算する
        previousYearPlanTotalH += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        // 製造原価人員(重点分野)：1.正社員　実見の合計を計算する
        resultForecastTotalH += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        // 製造原価人員(重点分野)：1.正社員　年計の合計を計算する
        yearPlanTotalH += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        // 製造原価人員(重点分野)：1.正社員　中計の合計を計算する
        midTermPlanTotalH += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const manufyearPlanurPriorityDomainRegularEmployeeGridTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[4] && undefined !== inputTabContent[4][0]
            ? useJudg.getText(inputTabContent[4][0]['gridTotalName'] as languageDataTypes)
            : '',
        // 計画
        previousYearPlan: previousYearPlanTotalH,
        // 実見
        resultForecast: resultForecastTotalH,
        // 年計
        yearPlan: yearPlanTotalH,
        // 中計
        midTermPlan: midTermPlanTotalH,
      };
      // 製造原価人員(重点分野)：1.正社員 合計行をセットする
      setManufyearPlanurPriorityDomainRegularEmployeeGridTotal(manufyearPlanurPriorityDomainRegularEmployeeGridTotalRowData);
      let newRowData = _.cloneDeep(manufyearPlanurPriorityDomainRegularEmployeeGridTotalRowData);
      newRowData.previousYearPlan = previousYearPlanTotalOfManufPriorityDomainFullTimeEmployee;
      // 合計に加える
      manufyearPlanurEmploymentPriorityDomainTotalRowData.push(newRowData);
    } else {
      // 合計に加える
      let defultManufyearPlanurEmploymentPriorityDomainTotalRowData: EnqueteAnswerInputEntity = {
        id: 'defult',
        inputItem: '',
        previousYearPlan: previousYearPlanTotalOfManufPriorityDomainFullTimeEmployee,
      };
      manufyearPlanurEmploymentPriorityDomainTotalRowData.push(defultManufyearPlanurEmploymentPriorityDomainTotalRowData);
    }
    //-------------------------------------------------------------
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他
    //-------------------------------------------------------------
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　計画の合計
    let previousYearPlanTotalI: number = 0;
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　実見の合計
    let resultForecastTotalI: number = 0;
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　年計の合計
    let yearPlanTotalI: number = 0;
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　中計の合計
    let midTermPlanTotalI: number = 0;
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　過去年計の合計
    let previousYearPlanTotalOfManufyearPlanurPriorityDomainOtherEmployees: number = 0;
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 過去グリッド回答情報が存在すれば合計値を計算する
    if (pastManufPriorityDomainOtherRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempManufPriorityDomainOtherRowData = pastManufPriorityDomainOtherRowData.filter((x) => x.id !== 'notConsolidated');
      tempManufPriorityDomainOtherRowData.forEach((data: any) => {
        previousYearPlanTotalOfManufyearPlanurPriorityDomainOtherEmployees += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      });
    }
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 グリッド回答情報が存在すれば合計値を計算する
    if (manufyearPlanurPriorityDomainOtherEmployeesRowData !== undefined) {
      // Totalに含めない要素を除外する
      const tempManufPriorityDomainOtherRowData = manufyearPlanurPriorityDomainOtherEmployeesRowData.filter((x) => x.id !== 'notConsolidated');

      tempManufPriorityDomainOtherRowData.forEach((data: any) => {
        // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　計画の合計を計算する
        previousYearPlanTotalI += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
        // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　実見の合計を計算する
        resultForecastTotalI += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
        // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　年計の合計を計算する
        yearPlanTotalI += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
        // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他　中計の合計を計算する
        midTermPlanTotalI += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
      });

      const manufyearPlanurPriorityDomainOtherTotalRowData: EnqueteAnswerInputEntity = {
        id: 'sumTotal',
        inputItem:
          undefined !== inputTabContent[4] && undefined !== inputTabContent[4][1]
            ? useJudg.getText(inputTabContent[4][1]['gridTotalName'] as languageDataTypes)
            : '',
        // 計画
        previousYearPlan: previousYearPlanTotalI,
        // 実見
        resultForecast: resultForecastTotalI,
        // 年計
        yearPlan: yearPlanTotalI,
        // 中計
        midTermPlan: midTermPlanTotalI,
      };
      // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 合計行をセットする
      setManufyearPlanurPriorityDomainOtherEmployeesGridTotal(manufyearPlanurPriorityDomainOtherTotalRowData);
      let newRowData = _.cloneDeep(manufyearPlanurPriorityDomainOtherTotalRowData);
      newRowData.previousYearPlan = previousYearPlanTotalOfManufyearPlanurPriorityDomainOtherEmployees;
      // 合計に加える
      manufyearPlanurEmploymentPriorityDomainTotalRowData.push(newRowData);
    } else {
      // 合計に加える
      let defultManufyearPlanurEmploymentPriorityDomainTotalRowData: EnqueteAnswerInputEntity = {
        id: 'defult',
        inputItem: '',
        previousYearPlan: previousYearPlanTotalOfManufyearPlanurPriorityDomainOtherEmployees,
      };
      // 合計に加える
      manufyearPlanurEmploymentPriorityDomainTotalRowData.push(defultManufyearPlanurEmploymentPriorityDomainTotalRowData);
    }
    // 製造原価人員(重点分野)：合計をセットする
    setManufyearPlanurEmploymentPriorityDomainTotalRowData(manufyearPlanurEmploymentPriorityDomainTotalRowData);
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  /* eslint-disable */
  // 編集した値をdispatch
  useEffect(() => {
    changePersonnelEmploymentRowData(personnelEmploymentRowData);
    changePersonnelEmploymentResultAndPlanDiffForm(personnelEmploymentResultAndPlanDiffForm);
    changePersonnelEmploymentPlanAndPlanDiffForm(personnelEmploymentPlanAndPlanDiffForm);
    changePersonnelCorporateRowData(personnelCorporateRowData);
    changePersonnelCorporateOtherFormTextValue(personnelCorporateOtherFormTextValue);
    changePersonnelSalesRowData(personnelSalesRowData);
    changeManufyearPlanurEmploymentRowData(manufyearPlanurEmploymentRowData);
    changeManufyearPlanurEmploymentResultAndPlanDiffForm(manufyearPlanurEmploymentResultAndPlanDiffForm);
    changeManufyearPlanurEmploymentPlanAndPlanDiffForm(manufyearPlanurEmploymentPlanAndPlanDiffForm);
    // 人件費人員（重点分野別） 1.正社員 編集した値をdispatch
    changePersonnelPriorityDomainRegularEmployeeRowData(personnelPriorityDomainRegularEmployeeRowData);
    // 人件費人員（重点分野別） 2.TTCからの出向受入 編集した値をdispatch
    changePersonnelPriorityDomainSecondmentFromTTCRowData(personnelPriorityDomainSecondmentFromTTCRowData);
    // 人件費人員（重点分野別） 3.2以外の出向受入 編集した値をdispatch
    changePersonnelPriorityDomainSecondmentFromOtherRowData(personnelPriorityDomainSecondmentFromOtherRowData);
    // 人件費人員（重点分野別） 4.派遣、嘱託、パート、アルバイト 編集した値をdispatch
    changePersonnelPriorityDomainDispatchEtcRowData(personnelPriorityDomainDispatchEtcRowData);
    // 人件費人員（重点分野別） 年計-実見 編集した値をdispatch
    changePersonnelPriorityDomainResultAndPlanDiffForm(personnelPriorityDomainResultAndPlanDiffForm);
    // 人件費人員（重点分野別） 中計-年計 編集した値をdispatch
    changePersonnelPriorityDomainPlanAndPlanDiffForm(personnelPriorityDomainPlanAndPlanDiffForm);
    // 製造原価人員（重点分野別） 1.正社員 編集した値をdispatch
    changeManufyearPlanurPriorityDomainRegularEmployeeRowData(manufyearPlanurPriorityDomainRegularEmployeeRowData);
    // 製造原価人員（重点分野別） 2.1以外の従業員/派遣/その他 編集した値をdispatch
    changeManufyearPlanurPriorityDomainOtherEmployeesRowData(manufyearPlanurPriorityDomainOtherEmployeesRowData);
    // 製造原価人員（重点分野別） 年計-実見 編集した値をdispatch
    changeManufyearPlanurPriorityDomainResultAndPlanDiffForm(manufyearPlanurPriorityDomainResultAndPlanDiffForm);
    // 製造原価人員（重点分野別） 中計-年計 編集した値をdispatch
    changeManufyearPlanurPriorityDomainPlanAndPlanDiffForm(manufyearPlanurPriorityDomainPlanAndPlanDiffForm);
  }, [
    personnelEmploymentRowData,
    personnelEmploymentResultAndPlanDiffForm,
    personnelEmploymentPlanAndPlanDiffForm,
    personnelCorporateRowData,
    personnelCorporateOtherFormTextValue,
    personnelSalesRowData,
    manufyearPlanurEmploymentRowData,
    manufyearPlanurEmploymentResultAndPlanDiffForm,
    manufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 人件費人員（重点分野別）1.正社員の行データ
    personnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
    personnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別）3.2以外の出向受入の行データ
    personnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
    personnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別）年計 - 実見のデータ
    personnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別）中計 - 年計のデータ
    personnelPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別）1.正社員の行データ
    manufyearPlanurPriorityDomainRegularEmployeeRowData,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
    manufyearPlanurPriorityDomainOtherEmployeesRowData,
    // 製造原価人員（重点分野別）年計 - 実見のデータ
    manufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員（重点分野別）中計 - 年計のデータ
    manufyearPlanurPriorityDomainPlanAndPlanDiffForm,
  ]);
  /* eslint-enable */

  useEffect(() => {
    setHasErrorPersonnelEmploymentGrid(false);
    setHasErrorPersonnelEmploymentResultAndPlanDiffForm(false);
    setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(false);
    setHasErrorPersonnelEmploymentPlanAndPlanDiffForm(false);
    setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(false);
    setHasErrorPersonnelCorporateGrid(false);
    setHasErrorPersonnelGridNotMatchTotal(false);
    setHasErrorPersonnelCorporateGridTotalOver(false);
    setHasErrorPersonnelSalesGridTotalOver(false);
    setHasErrorOtherTextForm(false);
    // 人件費人員(重点分野)年計-実見で入力されたデータの形のチェック結果をfalseと設定する
    setHasErrorPersonnelPriorityDomainResultAndPlanDiffForm(false);
    // 人件費人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック結果をfalseと設定する
    setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(false);
    // 人件費人員(重点分野)中計-年計で入力されたデータの形のチェック結果をfalseと設定する
    setHasErrorPersonnelPriorityDomainPlanAndPlanDiffForm(false);
    // 人件費人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック結果をfalseと設定する
    setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(false);
    // 人件費人員(重点分野)：1.正社員の人員合計と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal(false);
    // 人件費人員(重点分野)：2.TTCからの出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal(false);
    // 人件費人員(重点分野)：3.2.以外の出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal(false);
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトと（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal(false);
    setHasErrorManufyearPlanurEmploymentGrid(false);
    setHasErrorManufyearPlanurEmploymentResultAndPlanDiffForm(false);
    setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm(false);
    setHasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm(false);
    setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(false);
    // 製造原価人員(重点分野)年計-実見で入力されたデータの形のチェック結果をfalseと設定する
    setHasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm(false);
    // 製造原価人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック結果をfalseと設定する
    setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(false);
    // 製造原価人員(重点分野)中計-年計で入力されたデータの形のチェック結果をfalseと設定する
    setHasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm(false);
    // 製造原価人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック結果をfalseと設定する
    setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(false);
    // 製造原価人員(重点分野)：1.正社員の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal(false);
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal(false);
    setPersonnelEmploymentTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    setCorporateGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    setSalesGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    // 人件費人員（重点分野別）1.正社員 合計行のデータ
    setPersonnelPriorityDomainRegularEmployeeGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    // 人件費人員（重点分野別）2.TTCからの出向受入 合計行のデータ
    setPersonnelPriorityDomainSecondmentFromTTCGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    // 人件費人員（重点分野別）3.2以外の出向受入 合計行のデータ
    setPersonnelPriorityDomainSecondmentFromOtherGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト 合計行のデータ
    setPersonnelPriorityDomainDispatchEtcGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    // 製造原価人員（重点分野別）1.正社員 合計行のデータ
    setManufyearPlanurPriorityDomainRegularEmployeeGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他 合計行のデータ
    setManufyearPlanurPriorityDomainOtherEmployeesGridTotal({ id: 'sumTotal', inputItem: '' } as EnqueteAnswerInputEntity);
    refreshAnswerData();
    // 過去回答データ取得
    refreshPastAnswerData();
  }, []); //eslint-disable-line

  // 参照値データ更新
  useEffect(() => {
    refreshPastRowData();
  }, [pastEnqueteAnswerData]); //eslint-disable-line

  // 回答データ更新
  useEffect(() => {
    refreshAnswerData();
  }, [enqueteAnswerData]); //eslint-disable-line

  // 合計値の更新
  useEffect(() => {
    setGridTotalDataByFirstLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personnelEmploymentRowData,
    personnelCorporateRowData,
    personnelSalesRowData,
    // 人件費人員（重点分野別）1.正社員の行データ
    personnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別）2.TTCからの出向受入の行データ
    personnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別）3.2以外の出向受入の行データ
    personnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの行データ
    personnelPriorityDomainDispatchEtcRowData,
    // 製造原価人員（重点分野別）1.正社員の行データ
    manufyearPlanurPriorityDomainRegularEmployeeRowData,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の行データ
    manufyearPlanurPriorityDomainOtherEmployeesRowData,
    // 人件費人員（重点分野別）1.正社員の過去行データ
    pastPersonnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別）2.TTCからの出向受入の過去行データ
    pastPersonnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別）3.2以外の出向受入の過去行データ
    pastPersonnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの過去行データ
    pastPersonnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別）1.正社員の過去行データ
    pastManufPriorityDomainFullTimeEmployeeRowData,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の過去行データ
    pastManufPriorityDomainOtherRowData,
  ]); //eslint-disable-line

  useEffect(() => {}, [respondentTargetData]);

  // タブ切り替え
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  // 人件費人員（重点分野別人員）タブ切り替え
  const handleChangeSubTabPersonnelPriorityDomain = (event: SyntheticEvent, newValue: string) => {
    setSubTabValuePersonnelPriorityDomain(newValue);
  };
  // 製造原価人員（重点分野別人員）タブ切り替え
  const handleChangeSubTabManufPriorityDomain = (event: SyntheticEvent, newValue: string) => {
    setSubTabValueManufPriorityDomain(newValue);
  };

  //-------------------------------------------------------------
  // 計画値取得(過去入力値の参照)
  //-------------------------------------------------------------
  // 過去回答データ取得更新
  const refreshPastAnswerData = async () => {
    if (respondentRequestData.previousRespondentRequestId !== null && respondentTargetData.organizationId) {
      const result: EnqueteAnswerEntity | AxiosResponse<any> | undefined = await enqueteAnswerInputApi.getPastAnswer(respondentTargetData.id);

      //エラー判定パターン定義
      const clientErrorPattern = /^4[0-9]{2}$/;
      const serverErrorPattern = /^5[0-9]{2}$/;
      if (
        result !== undefined &&
        result.status !== undefined &&
        !clientErrorPattern.test(String(result.status)) &&
        !serverErrorPattern.test(String(result.status))
      )
        setPastAnswer([result as EnqueteAnswerEntity]);
    } else {
      await setPastPersonnelEmploymentRowData(undefined);
      await setPastPersonnelCorporateRowData(undefined);
      await setPastPersonnelSalesRowData(undefined);
      await setPastManufyearPlanurEmploymentRowData(undefined);
      // 人件費人員（重点分野別）1.正社員　過去行データの初期値を設定する
      await setPastPersonnelPriorityDomainRegularEmployeeRowData(undefined);
      // 人件費人員（重点分野別）2.TTCからの出向受入　過去行データの初期値を設定する
      await setPastPersonnelPriorityDomainSecondmentFromTTCRowData(undefined);
      // 人件費人員（重点分野別）3.2以外の出向受入　過去行データの初期値を設定する
      await setPastPersonnelPriorityDomainSecondmentFromOtherRowData(undefined);
      // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト　過去行データの初期値を設定する
      await setPastPersonnelPriorityDomainDispatchEtcRowData(undefined);
      // 製造原価人員（重点分野別）1.正社員　過去行データの初期値を設定する
      await setPastManufPriorityDomainFullTimeEmployeeRowData(undefined);
      // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他　過去行データの初期値を設定する
      await setPastManufPriorityDomainOtherRowData(undefined);
    }
  };

  // 参照値データ更新
  const refreshPastRowData = async () => {
    if (pastEnqueteAnswerData.length === 0) return;

    // 過去のAnswerData呼び出し
    // TODO: id決めて格納位置が変化しても対応できるようにしたい(idの場所は用意済み)
    if (
      undefined !== pastEnqueteAnswerData[0] &&
      undefined !== pastEnqueteAnswerData[0].answerData &&
      undefined !== pastEnqueteAnswerData[0].answerData.answerInputData &&
      undefined !== pastEnqueteAnswerData[0].answerData.answerInputData.tabData
    ) {
      const newAnswerTabs: (
        | PersonnelEmploymentTabAnswerData
        | PersonnelFunctionTabAnswerData
        | ManufyearPlanurEmploymentTabAnswerData
        | PersonnelPriorityDomainTabAnswerData
        | ManufyearPlanurEmploymentPriorityDomainTabAnswerData
      )[] = pastEnqueteAnswerData[0].answerData.answerInputData.tabData as (
        | PersonnelEmploymentTabAnswerData
        | PersonnelFunctionTabAnswerData
        | ManufyearPlanurEmploymentTabAnswerData
        | PersonnelPriorityDomainTabAnswerData
        | ManufyearPlanurEmploymentPriorityDomainTabAnswerData
      )[];
      // 存在を確認できれば値をセットして存在しなければ未設定状態に戻す
      // TODO: undefinedでなく空配列([])に変更したい
      if (enqueteAnswerInputCheck(newAnswerTabs, 0, 0)) {
        const readPastPersonnelEmploymentRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[0].tabInputData[0]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastPersonnelEmploymentRowData(readPastPersonnelEmploymentRowData);
      } else {
        await setPastPersonnelEmploymentRowData(undefined);
      }

      if (enqueteAnswerInputCheck(newAnswerTabs, 1, 0)) {
        const readPastPersonnelCorporateRowData: EnqueteAnswerInputEntity[] = newAnswerTabs[1].tabInputData[0].data as EnqueteAnswerInputEntity[];
        await setPastPersonnelCorporateRowData(readPastPersonnelCorporateRowData);
      } else {
        await setPastPersonnelCorporateRowData(undefined);
      }

      if (enqueteAnswerInputCheck(newAnswerTabs, 1, 2)) {
        const readPastPersonnelSalesRowData: EnqueteAnswerInputEntity[] = newAnswerTabs[1].tabInputData[2].data as EnqueteAnswerInputEntity[];
        await setPastPersonnelSalesRowData(readPastPersonnelSalesRowData);
      } else {
        await setPastPersonnelSalesRowData(undefined);
      }

      if (enqueteAnswerInputCheck(newAnswerTabs, 2, 0)) {
        const readPastManufyearPlanurEmploymentRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[2].tabInputData[0]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastManufyearPlanurEmploymentRowData(readPastManufyearPlanurEmploymentRowData);
      } else {
        await setPastManufyearPlanurEmploymentRowData(undefined);
      }
      // 人件費人員（重点分野別）1.正社員の過去データを設定する
      if (enqueteAnswerInputCheck(newAnswerTabs, 3, 0)) {
        const readPastPersonnelPriorityDomainRegularEmployeeRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[0]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastPersonnelPriorityDomainRegularEmployeeRowData(readPastPersonnelPriorityDomainRegularEmployeeRowData);
      } else {
        await setPastPersonnelPriorityDomainRegularEmployeeRowData(undefined);
      }
      // 人件費人員（重点分野別）2.TTCからの出向受入の過去データを設定する
      if (enqueteAnswerInputCheck(newAnswerTabs, 3, 1)) {
        const readPastPersonnelPriorityDomainSecondmentFromTTCRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[1]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastPersonnelPriorityDomainSecondmentFromTTCRowData(readPastPersonnelPriorityDomainSecondmentFromTTCRowData);
      } else {
        await setPastPersonnelPriorityDomainSecondmentFromTTCRowData(undefined);
      }
      // 人件費人員（重点分野別）3.2以外の出向受入の過去データを設定する
      if (enqueteAnswerInputCheck(newAnswerTabs, 3, 2)) {
        const readPastPersonnelPriorityDomainSecondmentFromOtherRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[2]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastPersonnelPriorityDomainSecondmentFromOtherRowData(readPastPersonnelPriorityDomainSecondmentFromOtherRowData);
      } else {
        await setPastPersonnelPriorityDomainSecondmentFromOtherRowData(undefined);
      }
      // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイトの過去データを設定する
      if (enqueteAnswerInputCheck(newAnswerTabs, 3, 3)) {
        const readPastPersonnelPriorityDomainDispatchEtcRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[3]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastPersonnelPriorityDomainDispatchEtcRowData(readPastPersonnelPriorityDomainDispatchEtcRowData);
      } else {
        await setPastPersonnelPriorityDomainDispatchEtcRowData(undefined);
      }
      // 製造原価人員（重点分野別）1.正社員の過去データを設定する
      if (enqueteAnswerInputCheck(newAnswerTabs, 4, 0)) {
        const readPastManufPriorityDomainFullTimeEmployeeRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[4].tabInputData[0]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastManufPriorityDomainFullTimeEmployeeRowData(readPastManufPriorityDomainFullTimeEmployeeRowData);
      } else {
        await setPastManufPriorityDomainFullTimeEmployeeRowData(undefined);
      }
      // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他の過去データを設定する
      if (enqueteAnswerInputCheck(newAnswerTabs, 4, 1)) {
        const readPastManufPriorityDomainOtherRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[4].tabInputData[1]
          .data as EnqueteAnswerLoadInputEntity[];
        await setPastManufPriorityDomainOtherRowData(readPastManufPriorityDomainOtherRowData);
      } else {
        await setPastManufPriorityDomainOtherRowData(undefined);
      }
    } else {
      await setPastPersonnelEmploymentRowData(undefined);
      await setPastPersonnelCorporateRowData(undefined);
      await setPastPersonnelSalesRowData(undefined);
      await setPastManufyearPlanurEmploymentRowData(undefined);
      // 人件費人員(重点分野)：1.正社員
      await setPastPersonnelPriorityDomainRegularEmployeeRowData(undefined);
      // 人件費人員（重点分野別）2.TTCからの出向受入
      await setPastPersonnelPriorityDomainSecondmentFromTTCRowData(undefined);
      // 人件費人員（重点分野別）3.2以外の出向受入
      await setPastPersonnelPriorityDomainSecondmentFromOtherRowData(undefined);
      // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト
      await setPastPersonnelPriorityDomainDispatchEtcRowData(undefined);
      // 製造原価人員（重点分野別）1.正社員
      await setPastManufPriorityDomainFullTimeEmployeeRowData(undefined);
      // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他
      await setPastManufPriorityDomainOtherRowData(undefined);
    }
  };

  // 数値で保存している差異人数入力に対して+を付与して文字とする
  const diffInputValueAddPlus = (
    readPersonnelEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[],
  ): TempPersonnelSurveyDiffFormAnswerInputData[] => {
    let tempReadPersonnelEmploymentResultAndPlanDiffForm = _.cloneDeep(readPersonnelEmploymentResultAndPlanDiffForm);

    tempReadPersonnelEmploymentResultAndPlanDiffForm.forEach((item: TempPersonnelSurveyDiffFormAnswerInputData) => {
      if (item !== undefined && item !== null) {
        if (item.diffMemberInputValue !== null) {
          // +がついてなくて正の数だった場合は、+を付与して文字にする
          if (item.diffMemberInputValue.toString().indexOf('+') < 0 && Number(item.diffMemberInputValue) > 0) {
            item.diffMemberInputValue = '+' + item.diffMemberInputValue.toString();
          } else if (Number(item.diffMemberInputValue) === 0) {
            item.diffMemberInputValue = '';
          } else {
            item.diffMemberInputValue = item.diffMemberInputValue.toString();
          }
        } else {
          item.diffMemberInputValue = '';
        }
      }
    });
    return tempReadPersonnelEmploymentResultAndPlanDiffForm;
  };

  // 数値で保存している差異人数入力に対して+を付与して文字とする(重点分野)
  const diffInputValueAddPlusForPriorityDomain = (
    readPersonnelEmploymentResultAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[],
  ): TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] => {
    let tempReadPersonnelPriorityDomainResultAndPlanDiffForm = _.cloneDeep(readPersonnelEmploymentResultAndPlanDiffForm);

    tempReadPersonnelPriorityDomainResultAndPlanDiffForm.forEach((item: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData) => {
      if (item !== undefined && item !== null) {
        if (item.diffMemberInputValue !== null) {
          // +がついてなくて正の数だった場合は、+を付与して文字にする
          if (item.diffMemberInputValue.toString().indexOf('+') < 0 && Number(item.diffMemberInputValue) > 0) {
            item.diffMemberInputValue = '+' + item.diffMemberInputValue.toString();
          } else if (Number(item.diffMemberInputValue) === 0) {
            item.diffMemberInputValue = '';
          } else {
            item.diffMemberInputValue = item.diffMemberInputValue.toString();
          }
        } else {
          item.diffMemberInputValue = '';
        }
      }
    });
    return tempReadPersonnelPriorityDomainResultAndPlanDiffForm;
  };

  //-------------------------------------------------------------
  // 回答入力結果取得
  //-------------------------------------------------------------
  // 回答データ更新
  const refreshAnswerData = async () => {
    // Tabの入力項目を呼び出し
    // TODO: idで判定して動的にタブを作成したい
    if (undefined !== surveyDetailData.surveyFormData.formData && undefined !== surveyDetailData.surveyFormData.formData.tabData) {
      const newInputTabs = surveyDetailData.surveyFormData.formData.tabData.map((tabData: any) => {
        return tabData.tabFormData;
      });
      setInputTabContent(newInputTabs);
    }

    // AnswerData呼び出し
    // TODO: id決めて格納位置が変化しても対応できるようにしたい(idの場所は用意済み)
    if (enqueteAnswerData) {
      if (
        !isEmpty(enqueteAnswerData) &&
        undefined !== enqueteAnswerData.answerData &&
        undefined !== enqueteAnswerData.answerData.answerInputData &&
        undefined !== enqueteAnswerData.answerData.answerInputData.tabData
      ) {
        const newAnswerTabs: (
          | PersonnelEmploymentTabAnswerData
          | PersonnelFunctionTabAnswerData
          | ManufyearPlanurEmploymentTabAnswerData
          | PersonnelPriorityDomainTabAnswerData
          | ManufyearPlanurEmploymentPriorityDomainTabAnswerData
        )[] = enqueteAnswerData.answerData.answerInputData.tabData as (
          | PersonnelEmploymentTabAnswerData
          | PersonnelFunctionTabAnswerData
          | ManufyearPlanurEmploymentTabAnswerData
          | PersonnelPriorityDomainTabAnswerData
          | ManufyearPlanurEmploymentPriorityDomainTabAnswerData
        )[];

        // 存在を確認できれば値をセットして存在しなければ未設定状態に戻す
        if (enqueteAnswerInputCheck(newAnswerTabs, 0, 0)) {
          const readPersonnelEmploymentRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[0].tabInputData[0]
            .data as EnqueteAnswerLoadInputEntity[];
          await setPersonnelEmploymentRowData(readPersonnelEmploymentRowData);
        } else {
          await setPersonnelEmploymentRowData(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 0, 1)) {
          const readPersonnelEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] = newAnswerTabs[0].tabInputData[1]
            .data as TempPersonnelSurveyDiffFormAnswerInputData[];
          await setPersonnelEmploymentResultAndPlanDiffForm(diffInputValueAddPlus(readPersonnelEmploymentResultAndPlanDiffForm));
        } else {
          await setPersonnelEmploymentResultAndPlanDiffForm(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 0, 2)) {
          const readPersonnelEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] = newAnswerTabs[0].tabInputData[2]
            .data as TempPersonnelSurveyDiffFormAnswerInputData[];
          await setPersonnelEmploymentPlanAndPlanDiffForm(diffInputValueAddPlus(readPersonnelEmploymentResultAndPlanDiffForm));
        } else {
          await setPersonnelEmploymentPlanAndPlanDiffForm(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 1, 0)) {
          const readPersonnelCorporateRowData: EnqueteAnswerInputEntity[] = newAnswerTabs[1].tabInputData[0].data as EnqueteAnswerInputEntity[];
          await setPersonnelCorporateRowData(readPersonnelCorporateRowData);
        } else {
          await setPersonnelCorporateRowData(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 1, 1)) {
          const readOtherFormTextValue: string = newAnswerTabs[1].tabInputData[1].data as string;
          await setOtherFormTextValue(readOtherFormTextValue);
        } else {
          await setOtherFormTextValue(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 1, 2)) {
          const readPersonnelSalesRowData: EnqueteAnswerInputEntity[] = newAnswerTabs[1].tabInputData[2].data as EnqueteAnswerInputEntity[];
          await setPersonnelSalesRowData(readPersonnelSalesRowData);
        } else {
          await setPersonnelSalesRowData(undefined);
        }
        // 人件費人員(重点分野)：1.正社員 のデータ設定
        if (enqueteAnswerInputCheck(newAnswerTabs, 3, 0)) {
          const readPersonnelPriorityDomainRegularEmployeeRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[0]
            .data as EnqueteAnswerLoadInputEntity[];
          await setPersonnelPriorityDomainRegularEmployeeRowData(readPersonnelPriorityDomainRegularEmployeeRowData);
        } else {
          await setPersonnelPriorityDomainRegularEmployeeRowData(undefined);
        }
        // 人件費人員(重点分野)：2.TTCからの出向受入 のデータ設定
        if (enqueteAnswerInputCheck(newAnswerTabs, 3, 1)) {
          const readPersonnelPriorityDomainSecondmentFromTTCRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[1]
            .data as EnqueteAnswerLoadInputEntity[];
          await setPersonnelPriorityDomainSecondmentFromTTCRowData(readPersonnelPriorityDomainSecondmentFromTTCRowData);
        } else {
          await setPersonnelPriorityDomainSecondmentFromTTCRowData(undefined);
        }
        // 人件費人員(重点分野)：3.2以外の出向受入 のデータ設定
        if (enqueteAnswerInputCheck(newAnswerTabs, 3, 2)) {
          const readPersonnelPriorityDomainSecondmentFromOtherRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[2]
            .data as EnqueteAnswerLoadInputEntity[];
          await setPersonnelPriorityDomainSecondmentFromOtherRowData(readPersonnelPriorityDomainSecondmentFromOtherRowData);
        } else {
          await setPersonnelPriorityDomainSecondmentFromOtherRowData(undefined);
        }
        // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト のデータ設定
        if (enqueteAnswerInputCheck(newAnswerTabs, 3, 3)) {
          const readPersonnelPriorityDomainDispatchEtcRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[3].tabInputData[3]
            .data as EnqueteAnswerLoadInputEntity[];
          await setPersonnelPriorityDomainDispatchEtcRowData(readPersonnelPriorityDomainDispatchEtcRowData);
        } else {
          await setPersonnelPriorityDomainDispatchEtcRowData(undefined);
        }
        // 人件費人員(重点分野)：年計-実見
        if (enqueteAnswerInputCheck(newAnswerTabs, 3, 4)) {
          const readPersonnelPriorityDomainResultAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] = newAnswerTabs[3]
            .tabInputData[4].data as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
          await setPersonnelPriorityDomainResultAndPlanDiffForm(
            diffInputValueAddPlusForPriorityDomain(readPersonnelPriorityDomainResultAndPlanDiffForm),
          );
        } else {
          await setPersonnelPriorityDomainResultAndPlanDiffForm(undefined);
        }
        // 人件費人員(重点分野)：中計-年計
        if (enqueteAnswerInputCheck(newAnswerTabs, 3, 5)) {
          const readPersonnelPriorityDomainPlanAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] = newAnswerTabs[3]
            .tabInputData[5].data as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
          await setPersonnelPriorityDomainPlanAndPlanDiffForm(diffInputValueAddPlusForPriorityDomain(readPersonnelPriorityDomainPlanAndPlanDiffForm));
        } else {
          await setPersonnelPriorityDomainPlanAndPlanDiffForm(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 2, 0)) {
          const readManufyearPlanurEmploymentRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[2].tabInputData[0]
            .data as EnqueteAnswerLoadInputEntity[];
          await setManufyearPlanurEmploymentRowData(readManufyearPlanurEmploymentRowData);
        } else {
          await setManufyearPlanurEmploymentRowData(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 2, 1)) {
          const readManufyearPlanurEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] = newAnswerTabs[2].tabInputData[1]
            .data as TempPersonnelSurveyDiffFormAnswerInputData[];
          await setManufyearPlanurEmploymentResultAndPlanDiffForm(diffInputValueAddPlus(readManufyearPlanurEmploymentResultAndPlanDiffForm));
        } else {
          await setManufyearPlanurEmploymentResultAndPlanDiffForm(undefined);
        }

        if (enqueteAnswerInputCheck(newAnswerTabs, 2, 2)) {
          const readManufyearPlanurEmploymentResultAndPlanDiffForm: TempPersonnelSurveyDiffFormAnswerInputData[] = newAnswerTabs[2].tabInputData[2]
            .data as TempPersonnelSurveyDiffFormAnswerInputData[];
          await setManufyearPlanurEmploymentPlanAndPlanDiffForm(diffInputValueAddPlus(readManufyearPlanurEmploymentResultAndPlanDiffForm));
        } else {
          await setManufyearPlanurEmploymentPlanAndPlanDiffForm(undefined);
        }

        // 製造原価人員(重点分野)：1.正社員 のデータ設定
        if (enqueteAnswerInputCheck(newAnswerTabs, 4, 0)) {
          const readManufPriorityDomainFullTimeEmployeeRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[4].tabInputData[0]
            .data as EnqueteAnswerLoadInputEntity[];
          await setManufPriorityDomainFullTimeEmployeeRowData(readManufPriorityDomainFullTimeEmployeeRowData);
        } else {
          await setManufPriorityDomainFullTimeEmployeeRowData(undefined);
        }
        // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他 のデータ設定
        if (enqueteAnswerInputCheck(newAnswerTabs, 4, 1)) {
          const readManufPriorityDomainOtherRowData: EnqueteAnswerLoadInputEntity[] = newAnswerTabs[4].tabInputData[1]
            .data as EnqueteAnswerLoadInputEntity[];
          await setManufPriorityDomainOtherRowData(readManufPriorityDomainOtherRowData);
        } else {
          await setManufPriorityDomainOtherRowData(undefined);
        }
        // 製造原価人員(重点分野)：年計-実見
        if (enqueteAnswerInputCheck(newAnswerTabs, 4, 2)) {
          const readManufPriorityDomainResultAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] = newAnswerTabs[4]
            .tabInputData[2].data as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
          await setManufPriorityDomainResultAndPlanDiffForm(diffInputValueAddPlusForPriorityDomain(readManufPriorityDomainResultAndPlanDiffForm));
        } else {
          await setManufPriorityDomainResultAndPlanDiffForm(undefined);
        }
        // 製造原価人員(重点分野)：中計-年計
        if (enqueteAnswerInputCheck(newAnswerTabs, 4, 3)) {
          const readManufPriorityDomainPlanAndPlanDiffForm: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] = newAnswerTabs[4]
            .tabInputData[3].data as TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[];
          await setManufPriorityDomainPlanAndPlanDiffForm(diffInputValueAddPlusForPriorityDomain(readManufPriorityDomainPlanAndPlanDiffForm));
        } else {
          await setManufPriorityDomainPlanAndPlanDiffForm(undefined);
        }
      } else {
        await setPersonnelEmploymentRowData(undefined);
        await setPersonnelEmploymentResultAndPlanDiffForm(undefined);
        await setPersonnelEmploymentPlanAndPlanDiffForm(undefined);
        await setPersonnelCorporateRowData(undefined);
        await setOtherFormTextValue(undefined);
        await setPersonnelSalesRowData(undefined);
        await setPersonnelPriorityDomainRegularEmployeeRowData(undefined); // 人件費人員(重点分野)：1.正社員
        await setPersonnelPriorityDomainSecondmentFromTTCRowData(undefined); // 人件費人員(重点分野)：2.TTCからの出向受入
        await setPersonnelPriorityDomainSecondmentFromOtherRowData(undefined); // 人件費人員(重点分野)：3.2.以外の出向受入
        await setPersonnelPriorityDomainDispatchEtcRowData(undefined); // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイト
        await setPersonnelPriorityDomainResultAndPlanDiffForm(undefined); // 人件費人員(重点分野)：年計-実見
        await setPersonnelPriorityDomainPlanAndPlanDiffForm(undefined); // 人件費人員(重点分野)：中計-年計
        await setManufyearPlanurEmploymentRowData(undefined);
        await setManufyearPlanurEmploymentResultAndPlanDiffForm(undefined);
        await setManufyearPlanurEmploymentPlanAndPlanDiffForm(undefined);
        await setManufPriorityDomainFullTimeEmployeeRowData(undefined); // 製造原価人員(重点分野)：1.正社員
        await setManufPriorityDomainOtherRowData(undefined); // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他
        await setManufPriorityDomainResultAndPlanDiffForm(undefined); // 製造原価人員(重点分野)：年計-実見
        await setManufPriorityDomainPlanAndPlanDiffForm(undefined); // 製造原価人員(重点分野)：中計-年計
      }
    }
  };

  //-------------------------------------------------------------
  // タブ（上）ラベル名を取得する
  //-------------------------------------------------------------
  const getGridLable = (type: string): string => {
    let lable: any = {
      en: '',
      ja: '',
    };
    if (undefined !== surveyDetailData && undefined !== surveyDetailData.surveyFormData && undefined !== surveyDetailData.surveyFormData.formData) {
      const formData: any = surveyDetailData.surveyFormData.formData;
      // 人件費人員の場合
      if ('personnel' === type) {
        lable = formData.personnelTabTitle;
        // 製造原価人員の場合
      } else if ('manufyearPlanur' === type) {
        lable = formData.manufyearPlanurTabTitle;
      }
    }
    return useJudg.getText(lable);
  };

  return (
    <TabContext value={tabValue}>
      <Box component={'div'} style={{ overflow: 'hidden' }}>
        <Grid container spacing={3} style={{ marginLeft: '0px', marginTop: '24px', height: '30px', width: '1567px' }}>
          <Grid
            item
            sx={{ fontFamily: theme.typography.fontFamily }}
            style={{
              textAlign: 'center',
              ...topGridStyleSet('personnel', surveyDetailData, isJapanese, isLight),
            }}>
            {getGridLable('personnel')}
          </Grid>
          <Grid
            item
            sx={{ fontFamily: theme.typography.fontFamily }}
            style={{
              textAlign: 'center',
              marginRight: '16px',
              ...topGridStyleSet('manufyearPlanur', surveyDetailData, isJapanese, isLight),
            }}>
            {getGridLable('manufyearPlanur')}
          </Grid>
        </Grid>
      </Box>
      <Paper variant="elevation" square>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {/*人件費人員（雇用形態別）*/}
          <Tab
            style={{
              ...tabStyles(currentLang.value),
              ...topTabCustom(
                undefined !== surveyDetailData &&
                  undefined !== surveyDetailData.surveyFormData &&
                  undefined !== surveyDetailData.surveyFormData.formData &&
                  undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                  undefined !== surveyDetailData.surveyFormData.formData.tabData[0] &&
                  'tabStyle' in surveyDetailData.surveyFormData.formData.tabData[0]
                  ? surveyDetailData.surveyFormData.formData.tabData[0]['tabStyle']
                  : undefined,
                isLight,
              ),
            }}
            label={
              undefined !== surveyDetailData &&
              undefined !== surveyDetailData.surveyFormData &&
              undefined !== surveyDetailData.surveyFormData.formData &&
              undefined !== surveyDetailData.surveyFormData.formData.tabData &&
              undefined !== surveyDetailData.surveyFormData.formData.tabData[0] &&
              'tabTitle' in surveyDetailData.surveyFormData.formData.tabData[0]
                ? useJudg.getText(surveyDetailData.surveyFormData.formData.tabData[0]['tabTitle'] as languageDataTypes)
                : ''
            }
            value="PersonnelEmployment"
            sx={{ paddingLeft: 2 }}
            disabled={isBeforeRequestTerm}
          />

          {/*人件費人員（機能別）*/}
          <Tab
            style={{
              ...tabStyles(currentLang.value),
              ...topTabCustom(
                undefined !== surveyDetailData &&
                  undefined !== surveyDetailData.surveyFormData &&
                  undefined !== surveyDetailData.surveyFormData.formData &&
                  undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                  undefined !== surveyDetailData.surveyFormData.formData.tabData[1] &&
                  'tabStyle' in surveyDetailData.surveyFormData.formData.tabData[1]
                  ? surveyDetailData.surveyFormData.formData.tabData[1]['tabStyle']
                  : undefined,
                isLight,
              ),
              minWidth: '48px',
            }}
            label={
              undefined !== surveyDetailData &&
              undefined !== surveyDetailData.surveyFormData &&
              undefined !== surveyDetailData.surveyFormData.formData &&
              undefined !== surveyDetailData.surveyFormData.formData.tabData &&
              undefined !== surveyDetailData.surveyFormData.formData.tabData[1] &&
              'tabTitle' in surveyDetailData.surveyFormData.formData.tabData[1]
                ? useJudg.getText(surveyDetailData.surveyFormData.formData.tabData[1]['tabTitle'] as languageDataTypes)
                : ''
            }
            sx={{ paddingLeft: 2 }}
            value="PersonnelCorporate"
            disabled={isBeforeRequestTerm}
          />

          {/*人件費人員（重点分野別）*/}
          {surveyDetailData.surveyFormData.formData.tabData[3] && (
            <Tab
              style={{
                ...tabStyles(currentLang.value),
                ...topTabCustom(
                  undefined !== surveyDetailData &&
                    undefined !== surveyDetailData.surveyFormData &&
                    undefined !== surveyDetailData.surveyFormData.formData &&
                    undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                    undefined !== surveyDetailData.surveyFormData.formData.tabData[3] &&
                    'tabStyle' in surveyDetailData.surveyFormData.formData.tabData[3]
                    ? surveyDetailData.surveyFormData.formData.tabData[3]['tabStyle']
                    : undefined,
                  isLight,
                ),
              }}
              label={
                undefined !== surveyDetailData &&
                undefined !== surveyDetailData.surveyFormData &&
                undefined !== surveyDetailData.surveyFormData.formData &&
                undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                undefined !== surveyDetailData.surveyFormData.formData.tabData[3] &&
                'tabTitle' in surveyDetailData.surveyFormData.formData.tabData[3]
                  ? useJudg.getText(surveyDetailData.surveyFormData.formData.tabData[3]['tabTitle'] as languageDataTypes)
                  : ''
              }
              sx={{ paddingLeft: 2 }}
              value="PersonnelPriorityDomain"
              disabled={isBeforeRequestTerm}
            />
          )}

          {/*製造原価人員(雇用形態別)*/}
          <Tab
            style={{
              ...tabStyles(currentLang.value),
              ...topTabCustom(
                undefined !== surveyDetailData &&
                  undefined !== surveyDetailData.surveyFormData &&
                  undefined !== surveyDetailData.surveyFormData.formData &&
                  undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                  undefined !== surveyDetailData.surveyFormData.formData.tabData[2] &&
                  'tabStyle' in surveyDetailData.surveyFormData.formData.tabData[2]
                  ? surveyDetailData.surveyFormData.formData.tabData[2]['tabStyle']
                  : undefined,
                isLight,
              ),
            }}
            label={
              undefined !== surveyDetailData &&
              undefined !== surveyDetailData.surveyFormData &&
              undefined !== surveyDetailData.surveyFormData.formData &&
              undefined !== surveyDetailData.surveyFormData.formData.tabData &&
              undefined !== surveyDetailData.surveyFormData.formData.tabData[2] &&
              'tabTitle' in surveyDetailData.surveyFormData.formData.tabData[2]
                ? useJudg.getText(surveyDetailData.surveyFormData.formData.tabData[2]['tabTitle'] as languageDataTypes)
                : ''
            }
            sx={{ paddingLeft: 2 }}
            value="ManufyearPlanurEmployment"
            disabled={isBeforeRequestTerm}
          />

          {/*製造原価人員（重点分野別）*/}
          {surveyDetailData.surveyFormData.formData.tabData[4] && (
            <Tab
              style={{
                ...tabStyles(currentLang.value),
                ...topTabCustom(
                  undefined !== surveyDetailData &&
                    undefined !== surveyDetailData.surveyFormData &&
                    undefined !== surveyDetailData.surveyFormData.formData &&
                    undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                    undefined !== surveyDetailData.surveyFormData.formData.tabData[4] &&
                    'tabStyle' in surveyDetailData.surveyFormData.formData.tabData[4]
                    ? surveyDetailData.surveyFormData.formData.tabData[4]['tabStyle']
                    : undefined,
                  isLight,
                ),
              }}
              label={
                undefined !== surveyDetailData &&
                undefined !== surveyDetailData.surveyFormData &&
                undefined !== surveyDetailData.surveyFormData.formData &&
                undefined !== surveyDetailData.surveyFormData.formData.tabData &&
                undefined !== surveyDetailData.surveyFormData.formData.tabData[4] &&
                'tabTitle' in surveyDetailData.surveyFormData.formData.tabData[4]
                  ? useJudg.getText(surveyDetailData.surveyFormData.formData.tabData[4]['tabTitle'] as languageDataTypes)
                  : ''
              }
              sx={{ paddingLeft: 2 }}
              value="ManufyearPlanurPriorityDomain"
              disabled={isBeforeRequestTerm}
            />
          )}

          <Tab
            sx={{ paddingLeft: 2 }}
            style={{ ...tabStyles(currentLang.value), ...topTabCustom(undefined, isLight) }}
            label={t('enqueteAnswerInput.tab.responseConfirmationStatus')}
            value="EnqueteStatus"
            disabled={isBeforeRequestTerm}
          />
          <Tab
            style={{ ...tabStyles(currentLang.value), ...topTabCustom(undefined, isLight) }}
            label={t('enqueteAnswerInput.tab.changeRespondents')}
            sx={{ paddingLeft: 2 }}
            value="ChangeRespondents"
            disabled={!isAdmin && !isViewerAdmin && isAfterRequestTerm}
          />
        </TabList>
        {/*人件費人員（雇用形態別）*/}
        <TabPanel value="PersonnelEmployment" sx={{ position: 'relative' }}>
          {isBeforeRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'before'} />
          ) : (
            <Stack spacing={1}>
              <CreateUnderElementGridForm
                form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                rowData={personnelEmploymentRowData}
                pastRowData={pastPersonnelEmploymentRowData}
                setRowDataFunction={setPersonnelEmploymentRowData}
                setResultAndPlanDiffValue={setPersonnelEmploymentResultAndPlanDiffValue}
                setPlanAndPlanDiffValue={setPersonnelEmploymentPlanAndPlanDiffValue}
              />

              <br />

              <EnqueteAnswerCreateDiffForm
                form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][1] ? inputTabContent[0][1] : []}
                diffValue={personnelEmploymentResultAndPlanDiffValue}
                diffFormData={personnelEmploymentResultAndPlanDiffForm}
                isEmptyError={hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm}
                setDiffFormFunction={setPersonnelEmploymentResultAndPlanDiffForm}
                setErrorDiffFormFunction={setHasErrorPersonnelEmploymentResultAndPlanDiffForm}
              />

              <br />

              <EnqueteAnswerCreateDiffForm
                form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][2] ? inputTabContent[0][2] : []}
                diffValue={personnelEmploymentPlanAndPlanDiffValue}
                diffFormData={personnelEmploymentPlanAndPlanDiffForm}
                isEmptyError={hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm}
                setDiffFormFunction={setPersonnelEmploymentPlanAndPlanDiffForm}
                setErrorDiffFormFunction={setHasErrorPersonnelEmploymentPlanAndPlanDiffForm}
              />
            </Stack>
          )}
        </TabPanel>

        {/*人件費人員（機能別）*/}
        <TabPanel value="PersonnelCorporate">
          {isBeforeRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'before'} />
          ) : (
            <>
              <CreateTotalOnlyGrid
                form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                rowData={personnelEmploymentRowData}
                pastRowData={pastPersonnelEmploymentRowData}
              />

              <p style={{ color: 'red' }}>
                {hasErrorPersonnelGridNotMatchTotal ? <h5>{t('enqueteAnswerInput.message.existNotEqualInputTotal_1')}</h5> : ''}
              </p>

              <CreateCorporateGridForm
                form={undefined !== inputTabContent[1] && undefined !== inputTabContent[1][0] ? inputTabContent[1][0] : []}
                rowData={personnelCorporateRowData}
                pastRowData={pastPersonnelCorporateRowData}
                setRowDataFunction={setPersonnelCorporateRowData}
              />

              <EnqueteAnswerTextForm
                form={undefined !== inputTabContent[1] && undefined !== inputTabContent[1][1] ? inputTabContent[1][1] : []}
                setOtherFormFunction={setOtherFormTextValue}
                personnelCorporateOtherFormTextValue={personnelCorporateOtherFormTextValue}
              />

              <CreateSalesGridForm
                form={undefined !== inputTabContent[1] && undefined !== inputTabContent[1][2] ? inputTabContent[1][2] : []}
                rowData={personnelSalesRowData}
                pastRowData={pastPersonnelSalesRowData}
                setRowDataFunction={setPersonnelSalesRowData}
              />
            </>
          )}
        </TabPanel>

        {/*人件費人員（重点分野別人員）*/}
        <TabPanel value="PersonnelPriorityDomain" sx={{ position: 'relative' }}>
          {isBeforeRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'before'} />
          ) : (
            <Stack spacing={1}>
              <h4>{t('enqueteAnswerInput.personnelPriorityDomain.headline')}</h4>
              <TabContext value={subTabValuePersonnelPriorityDomain}>
                <Paper variant="elevation" square></Paper>
                <TabList onChange={handleChangeSubTabPersonnelPriorityDomain} variant="scrollable" aria-label="lab API tabs example">
                  {/*1.正社員*/}
                  <Tab
                    style={{
                      ...tabStyles(currentLang.value),
                      ...tabCustom(
                        subTabValuePersonnelPriorityDomain === 'personnelPriorityDomainFullTimeEmployee' &&
                          undefined !== inputTabContent[3] &&
                          undefined !== inputTabContent[3][0]
                          ? inputTabContent[3][0].dataStyle
                          : undefined,
                        isLight,
                      ),
                    }}
                    label={useJudg.getText(
                      inputTabContent[0] && inputTabContent[0][0] && inputTabContent[0][0].initialValue && inputTabContent[0][0].initialValue[0]
                        ? inputTabContent[0][0].initialValue[0].inputItem
                        : undefined,
                    )}
                    value="personnelPriorityDomainFullTimeEmployee"
                    sx={{ paddingLeft: 2 }}
                    disabled={isBeforeRequestTerm}
                  />
                  {/*2.TTCからの出向受入*/}
                  <Tab
                    style={{
                      ...tabStyles(currentLang.value),
                      ...tabCustom(
                        subTabValuePersonnelPriorityDomain === 'personnelPriorityDomainExpatFromTTC' &&
                          undefined !== inputTabContent[3] &&
                          undefined !== inputTabContent[3][1]
                          ? inputTabContent[3][1].dataStyle
                          : undefined,
                        isLight,
                      ),
                    }}
                    label={useJudg.getText(
                      inputTabContent[0] && inputTabContent[0][0] && inputTabContent[0][0].initialValue && inputTabContent[0][0].initialValue[1]
                        ? inputTabContent[0][0].initialValue[1].inputItem
                        : undefined,
                    )}
                    value="personnelPriorityDomainExpatFromTTC"
                    sx={{ paddingLeft: 2 }}
                    disabled={isBeforeRequestTerm}
                  />
                  {/*3.2以外の出向受入*/}
                  <Tab
                    style={{
                      ...tabStyles(currentLang.value),
                      ...tabCustom(
                        subTabValuePersonnelPriorityDomain === 'personnelPriorityDomainExpatOther' &&
                          undefined !== inputTabContent[3] &&
                          undefined !== inputTabContent[3][2]
                          ? inputTabContent[3][2].dataStyle
                          : undefined,
                        isLight,
                      ),
                    }}
                    label={useJudg.getText(
                      inputTabContent[0] && inputTabContent[0][0] && inputTabContent[0][0].initialValue && inputTabContent[0][0].initialValue[2]
                        ? inputTabContent[0][0].initialValue[2].inputItem
                        : undefined,
                    )}
                    value="personnelPriorityDomainExpatOther"
                    sx={{ paddingLeft: 2 }}
                    disabled={isBeforeRequestTerm}
                  />
                  {/*4.派遣、嘱託、パート、アルバイト*/}
                  <Tab
                    style={{
                      ...tabStyles(currentLang.value),
                      ...tabCustom(
                        subTabValuePersonnelPriorityDomain === 'personnelPriorityDomainTemporaryContractPartTime' &&
                          undefined !== inputTabContent[3] &&
                          undefined !== inputTabContent[3][3]
                          ? inputTabContent[3][3].dataStyle
                          : undefined,
                        isLight,
                      ),
                    }}
                    label={useJudg.getText(
                      inputTabContent[0] && inputTabContent[0][0] && inputTabContent[0][0].initialValue && inputTabContent[0][0].initialValue[3]
                        ? inputTabContent[0][0].initialValue[3].inputItem
                        : undefined,
                    )}
                    value="personnelPriorityDomainTemporaryContractPartTime"
                    sx={{ paddingLeft: 2 }}
                    disabled={isBeforeRequestTerm}
                  />
                </TabList>
                {/*1.正社員*/}
                <TabPanel value="personnelPriorityDomainFullTimeEmployee">
                  <>
                    {/* （参考）他タブでの入力情報 */}
                    <CreateReferenceAnotherGrid
                      // form定義
                      form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      // 行データ
                      rowData={personnelEmploymentRowData}
                      // 過去データ
                      pastRowData={pastPersonnelEmploymentRowData}
                      // 行データのインデックス
                      rowIndex={0}
                    />

                    <br />

                    {/* 重点分野別人員の入力欄 */}
                    <CreatePriorityDomainGrid
                      // form定義
                      form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3][0] ? inputTabContent[3][0] : []}
                      // 入力データ
                      rowData={personnelPriorityDomainRegularEmployeeRowData}
                      // 過去データ
                      pastRowData={pastPersonnelPriorityDomainRegularEmployeeRowData}
                      // 入力データの設定方法
                      setRowDataFunction={setPersonnelPriorityDomainRegularEmployeeRowData}
                      // 【バリデーション用】（参考）データのform定義
                      referenceForm={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      // 【バリデーション用】（参考）データの入力データ
                      referenceRowData={personnelEmploymentRowData}
                      // 【バリデーション用】（参考）データの過去データ
                      referencePastRowData={pastPersonnelEmploymentRowData}
                      // 【バリデーション用】（参考）データのデータINDEX（どの雇用形態データを利用するか）
                      referenceDataIndex={0}
                    />
                  </>
                </TabPanel>

                {/*2.TTCからの出向受入*/}
                <TabPanel value="personnelPriorityDomainExpatFromTTC">
                  <>
                    {/* （参考）他タブでの入力情報 */}
                    <CreateReferenceAnotherGrid
                      // form定義
                      form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      // 行データ
                      rowData={personnelEmploymentRowData}
                      // 過去データ
                      pastRowData={pastPersonnelEmploymentRowData}
                      // 行データのインデックス
                      rowIndex={1}
                    />
                    <br />

                    {/* 重点分野別人員の入力欄 */}
                    <CreatePriorityDomainGrid
                      form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3][1] ? inputTabContent[3][1] : []}
                      rowData={personnelPriorityDomainSecondmentFromTTCRowData}
                      pastRowData={pastPersonnelPriorityDomainSecondmentFromTTCRowData}
                      setRowDataFunction={setPersonnelPriorityDomainSecondmentFromTTCRowData}
                      referenceForm={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      referenceRowData={personnelEmploymentRowData}
                      referencePastRowData={pastPersonnelEmploymentRowData}
                      referenceDataIndex={1}
                    />
                  </>
                </TabPanel>
                {/*3.2以外の出向受入*/}
                <TabPanel value="personnelPriorityDomainExpatOther">
                  <>
                    {/* （参考）他タブでの入力情報 */}
                    <CreateReferenceAnotherGrid
                      // form定義
                      form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      // 行データ
                      rowData={personnelEmploymentRowData}
                      // 過去データ
                      pastRowData={pastPersonnelEmploymentRowData}
                      // 行データのインデックス
                      rowIndex={2}
                    />
                    <br />

                    {/* 重点分野別人員の入力欄 */}
                    <CreatePriorityDomainGrid
                      form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3][2] ? inputTabContent[3][2] : []}
                      rowData={personnelPriorityDomainSecondmentFromOtherRowData}
                      pastRowData={pastPersonnelPriorityDomainSecondmentFromOtherRowData}
                      setRowDataFunction={setPersonnelPriorityDomainSecondmentFromOtherRowData}
                      referenceForm={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      referenceRowData={personnelEmploymentRowData}
                      referencePastRowData={pastPersonnelEmploymentRowData}
                      referenceDataIndex={2}
                    />
                  </>
                </TabPanel>
                {/*4.派遣、嘱託、パート、アルバイト*/}
                <TabPanel value="personnelPriorityDomainTemporaryContractPartTime">
                  <>
                    {/* （参考）他タブでの入力情報 */}
                    <CreateReferenceAnotherGrid
                      // form定義
                      form={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      // 行データ
                      rowData={personnelEmploymentRowData}
                      // 過去データ
                      pastRowData={pastPersonnelEmploymentRowData}
                      // 行データのインデックス
                      rowIndex={3}
                    />
                    <br />

                    {/* 重点分野別人員の入力欄 */}
                    <CreatePriorityDomainGrid
                      form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3][3] ? inputTabContent[3][3] : []}
                      rowData={personnelPriorityDomainDispatchEtcRowData}
                      pastRowData={pastPersonnelPriorityDomainDispatchEtcRowData}
                      setRowDataFunction={setPersonnelPriorityDomainDispatchEtcRowData}
                      referenceForm={undefined !== inputTabContent[0] && undefined !== inputTabContent[0][0] ? inputTabContent[0][0] : []}
                      referenceRowData={personnelEmploymentRowData}
                      referencePastRowData={pastPersonnelEmploymentRowData}
                      referenceDataIndex={3}
                    />
                  </>
                </TabPanel>
              </TabContext>
              {/* 重点分野別人員の合計欄 */}
              <CreatePriorityDomainTotalGrid
                form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3].slice(0, 4) ? inputTabContent[3].slice(0, 4) : []}
                totalRowData={personnelPriorityDomainTotalRowData}
                setResultAndPlanDiffValue={setPersonnelPriorityDomainResultAndPlanDiffValue}
                setPlanAndPlanDiffValue={setPersonnelPriorityDomainPlanAndPlanDiffValue}
              />
              <br />
              {/*No.7：今年度実見に対する来年度年計の主な差異の人数と理由を入力してください。（入力は任意）*/}
              <EnqueteAnswerCreatePriorityDomainDiffForm
                form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3][4] ? inputTabContent[3][4] : []}
                diffValue={personnelPriorityDomainResultAndPlanDiffValue}
                diffFormData={personnelPriorityDomainResultAndPlanDiffForm}
                isEmptyError={hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm}
                setDiffFormFunction={setPersonnelPriorityDomainResultAndPlanDiffForm}
                setErrorDiffFormFunction={setHasErrorPersonnelPriorityDomainResultAndPlanDiffForm}
              />

              <br />

              {/*No.8：来年度年計に対する中計の主な差異の人数と理由を入力してください。（入力は任意）*/}
              <EnqueteAnswerCreatePriorityDomainDiffForm
                form={undefined !== inputTabContent[3] && undefined !== inputTabContent[3][5] ? inputTabContent[3][5] : []}
                diffValue={personnelPriorityDomainPlanAndPlanDiffValue}
                diffFormData={personnelPriorityDomainPlanAndPlanDiffForm}
                isEmptyError={hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm}
                setDiffFormFunction={setPersonnelPriorityDomainPlanAndPlanDiffForm}
                setErrorDiffFormFunction={setHasErrorPersonnelPriorityDomainPlanAndPlanDiffForm}
              />
            </Stack>
          )}
        </TabPanel>
        {/*製造原価人員(雇用形態別)*/}
        <TabPanel value="ManufyearPlanurEmployment">
          {isBeforeRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'before'} />
          ) : (
            <Stack spacing={1}>
              <CreateUnderElementGridForm
                form={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][0] ? inputTabContent[2][0] : []}
                rowData={manufyearPlanurEmploymentRowData}
                pastRowData={pastManufyearPlanurEmploymentRowData}
                setRowDataFunction={setManufyearPlanurEmploymentRowData}
                setResultAndPlanDiffValue={setManufyearPlanurEmploymentResultAndPlanDiffValue}
                setPlanAndPlanDiffValue={setManufyearPlanurEmploymentPlanAndPlanDiffValue}
              />

              <br />

              <EnqueteAnswerCreateDiffForm
                form={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][1] ? inputTabContent[2][1] : []}
                diffValue={manufyearPlanurEmploymentResultAndPlanDiffValue}
                diffFormData={manufyearPlanurEmploymentResultAndPlanDiffForm}
                isEmptyError={hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm}
                setDiffFormFunction={setManufyearPlanurEmploymentResultAndPlanDiffForm}
                setErrorDiffFormFunction={setHasErrorManufyearPlanurEmploymentResultAndPlanDiffForm}
              />

              <br />

              <EnqueteAnswerCreateDiffForm
                form={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][2] ? inputTabContent[2][2] : []}
                diffValue={manufyearPlanurEmploymentPlanAndPlanDiffValue}
                diffFormData={manufyearPlanurEmploymentPlanAndPlanDiffForm}
                isEmptyError={hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm}
                setDiffFormFunction={setManufyearPlanurEmploymentPlanAndPlanDiffForm}
                setErrorDiffFormFunction={setHasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm}
              />
            </Stack>
          )}
        </TabPanel>
        {/* 製造原価人員（重点分野別人員）*/}
        <TabPanel value="ManufyearPlanurPriorityDomain" sx={{ position: 'relative' }}>
          {isBeforeRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'before'} />
          ) : (
            <Stack spacing={1}>
              <h4>{t('enqueteAnswerInput.manufyearPlanurPriorityDomain.headline')}</h4>
              <TabContext value={subTabValueManufPriorityDomain}>
                <Paper variant="elevation" square></Paper>
                <TabList onChange={handleChangeSubTabManufPriorityDomain} variant="scrollable" aria-label="lab API tabs example">
                  {/*1.正社員*/}
                  <Tab
                    style={{
                      ...tabStyles(currentLang.value),
                      ...tabCustom(
                        subTabValueManufPriorityDomain === 'manufyearPlanurEmploymentPriorityDomainFull' &&
                          undefined !== inputTabContent[4] &&
                          undefined !== inputTabContent[4][0]
                          ? inputTabContent[4][0].dataStyle
                          : undefined,
                        isLight,
                      ),
                    }}
                    label={useJudg.getText(
                      inputTabContent[2] && inputTabContent[2][0] && inputTabContent[2][0].initialValue && inputTabContent[2][0].initialValue[0]
                        ? inputTabContent[2][0].initialValue[0].inputItem
                        : undefined,
                    )}
                    value="manufyearPlanurEmploymentPriorityDomainFull"
                    sx={{ paddingLeft: 2 }}
                    disabled={isBeforeRequestTerm}
                  />
                  {/*2.1以外の従業員/派遣/その他*/}
                  <Tab
                    style={{
                      ...tabStyles(currentLang.value),
                      ...tabCustom(
                        subTabValueManufPriorityDomain === 'manufyearPlanurEmploymentPriorityDomainOther' &&
                          undefined !== inputTabContent[4] &&
                          undefined !== inputTabContent[4][1]
                          ? inputTabContent[4][1].dataStyle
                          : undefined,
                        isLight,
                      ),
                    }}
                    label={useJudg.getText(
                      inputTabContent[2] && inputTabContent[2][0] && inputTabContent[2][0].initialValue && inputTabContent[2][0].initialValue[1]
                        ? inputTabContent[2][0].initialValue[1].inputItem
                        : undefined,
                    )}
                    value="manufyearPlanurEmploymentPriorityDomainOther"
                    sx={{ paddingLeft: 2 }}
                    disabled={isBeforeRequestTerm}
                  />
                </TabList>
                {/*1.正社員*/}
                <TabPanel value="manufyearPlanurEmploymentPriorityDomainFull">
                  <Stack>
                    <>
                      {/* （参考）他タブでの入力情報 */}
                      <CreateReferenceAnotherGrid
                        // form定義
                        form={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][0] ? inputTabContent[2][0] : []}
                        // 行データ
                        rowData={manufyearPlanurEmploymentRowData}
                        // 過去データ
                        pastRowData={pastManufyearPlanurEmploymentRowData}
                        // 行データのインデックス
                        // props.form.initialValueの何番目の行定義の利用するか指定
                        rowIndex={0}
                      />

                      <br />

                      {/* 重点分野別人員の入力欄 */}
                      <CreatePriorityDomainGrid
                        // form定義
                        form={undefined !== inputTabContent[4] && undefined !== inputTabContent[4][0] ? inputTabContent[4][0] : []}
                        // 入力データ
                        rowData={manufyearPlanurPriorityDomainRegularEmployeeRowData}
                        // 過去データ
                        pastRowData={pastManufPriorityDomainFullTimeEmployeeRowData}
                        // 入力データの設定方法
                        setRowDataFunction={setManufPriorityDomainFullTimeEmployeeRowData}
                        // 【バリデーション用】（参考）データのform定義
                        referenceForm={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][0] ? inputTabContent[2][0] : []}
                        // 【バリデーション用】（参考）データの入力データ
                        referenceRowData={manufyearPlanurEmploymentRowData}
                        // 【バリデーション用】（参考）データの過去データ
                        referencePastRowData={pastManufyearPlanurEmploymentRowData}
                        // 【バリデーション用】（参考）データのデータINDEX（どの雇用形態データを利用するか）
                        referenceDataIndex={0}
                      />
                    </>
                  </Stack>
                </TabPanel>

                {/*2.1以外の従業員/派遣/その他*/}
                <TabPanel value="manufyearPlanurEmploymentPriorityDomainOther">
                  <Stack>
                    <>
                      {/* （参考）他タブでの入力情報 */}
                      <CreateReferenceAnotherGrid
                        // form定義
                        form={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][0] ? inputTabContent[2][0] : []}
                        // 行データ
                        rowData={manufyearPlanurEmploymentRowData}
                        // 過去データ
                        pastRowData={pastManufyearPlanurEmploymentRowData}
                        // 行データのインデックス
                        // props.form.initialValueの何番目の行定義の利用するか指定
                        rowIndex={1}
                      />

                      <br />

                      {/* 重点分野別人員の入力欄 */}
                      <CreatePriorityDomainGrid
                        // form定義
                        form={undefined !== inputTabContent[4] && undefined !== inputTabContent[4][1] ? inputTabContent[4][1] : []}
                        // 入力データ
                        rowData={manufyearPlanurPriorityDomainOtherEmployeesRowData}
                        // 過去データ
                        pastRowData={pastManufPriorityDomainOtherRowData}
                        // 入力データの設定方法
                        setRowDataFunction={setManufPriorityDomainOtherRowData}
                        // 【バリデーション用】（参考）データのform定義
                        referenceForm={undefined !== inputTabContent[2] && undefined !== inputTabContent[2][0] ? inputTabContent[2][0] : []}
                        // 【バリデーション用】（参考）データの入力データ
                        referenceRowData={manufyearPlanurEmploymentRowData}
                        // 【バリデーション用】（参考）データの過去データ
                        referencePastRowData={pastManufyearPlanurEmploymentRowData}
                        // 【バリデーション用】（参考）データのデータINDEX（どの雇用形態データを利用するか）
                        referenceDataIndex={1}
                      />
                    </>
                  </Stack>
                </TabPanel>
              </TabContext>
              {/* 重点分野別人員の合計欄 */}
              <CreatePriorityDomainTotalGrid
                // 各行の定義を各グリッド定義から取得する
                form={undefined !== inputTabContent[4] && undefined !== inputTabContent[4].slice(0, 2) ? inputTabContent[4].slice(0, 2) : []}
                // 各行のデータは各グリッドの合計値から取得する
                totalRowData={manufyearPlanurEmploymentPriorityDomainTotalRowData}
                // 年計 - 実見の差異合計設定
                setResultAndPlanDiffValue={setManufyearPlanurEmploymentPriorityDomainResultAndPlanDiffValue}
                // 中計 - 年計の差異合計設定
                setPlanAndPlanDiffValue={setManufyearPlanurEmploymentPriorityDomainPlanAndPlanDiffValue}
              />
              <br />
              {/*No.13：今年度実見に対する来年度年計の主な差異の人数と理由を入力してください。（入力は任意）*/}
              <EnqueteAnswerCreatePriorityDomainDiffForm
                // form定義
                form={undefined !== inputTabContent[4] && undefined !== inputTabContent[4][2] ? inputTabContent[4][2] : []}
                // 差異合計
                diffValue={manufyearPlanurEmploymentPriorityDomainResultAndPlanDiffValue}
                // 入力データ
                diffFormData={manufyearPlanurPriorityDomainResultAndPlanDiffForm}
                // 必須チェックフラグ
                isEmptyError={hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm}
                // 入力データの設定方法
                setDiffFormFunction={setManufPriorityDomainResultAndPlanDiffForm}
                // バリデーションチェック(全体)の設定方法
                setErrorDiffFormFunction={setHasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm}
              />

              <br />

              {/*No.14：今年度実見に対する来年度年計の主な差異の人数と理由を入力してください。（入力は任意）*/}
              <EnqueteAnswerCreatePriorityDomainDiffForm
                // form定義
                form={undefined !== inputTabContent[4] && undefined !== inputTabContent[4][3] ? inputTabContent[4][3] : []}
                // 差異合計
                diffValue={manufyearPlanurEmploymentPriorityDomainPlanAndPlanDiffValue}
                // 入力データ
                diffFormData={manufyearPlanurPriorityDomainPlanAndPlanDiffForm}
                // 必須チェックフラグ
                isEmptyError={hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm}
                // 入力データの設定方法
                setDiffFormFunction={setManufPriorityDomainPlanAndPlanDiffForm}
                // バリデーションチェック(全体)の設定方法
                setErrorDiffFormFunction={setHasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm}
              />
            </Stack>
          )}
        </TabPanel>
        <TabPanel value="EnqueteStatus">
          {isBeforeRequestTerm ? <OutsideSurveyPeriodTypography requestStatus={'before'} /> : <EnqueteConfirmTab />}
        </TabPanel>
        <TabPanel value="ChangeRespondents">
          {!isAdmin && !isViewerAdmin && isAfterRequestTerm ? (
            <OutsideSurveyPeriodTypography requestStatus={'after'} />
          ) : (
            <ChangeRespondentComponent
              respondentRequestId={respondentRequestData.id}
              respondentTargetId={respondentTargetData.id}
              organizationId={respondentTargetData.organizationId}
            />
          )}
        </TabPanel>
      </Paper>
    </TabContext>
  );
};
