import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { AMOUNT_SOURCE_TYPE, CUSTOMER_STATUS, DefaultValue } from 'src/features/general/enquete-answer/store/seriesAnswerSlice';
import { useCalculateFiscalYear } from '../../hooks/useCalculateFiscalYear';

export const SwitchLabel = () => {
  const { t } = useTranslation();
  const { previousYear } = useCalculateFiscalYear();

  const switchLabelForCustomerStatus = (customerStatus: string) => {
    const message =
      customerStatus === CUSTOMER_STATUS.DELETE
        ? t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.deleted')
        : t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.outOfRange');
    return (
      <Label
        variant="ghost"
        color={customerStatus === CUSTOMER_STATUS.DELETE ? 'error' : 'warning'}
        sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', mb: '10px' }}>
        {message}
      </Label>
    );
  };

  const switchLabelForSourceType = (previousSourceType: string, currentSourceType: string) => {
    const array = previousSourceType === currentSourceType ? [previousSourceType] : [previousSourceType, currentSourceType];
    const sourceType = array.filter((elm) => elm !== AMOUNT_SOURCE_TYPE.ANSWERED);
    return sourceType.map((element, index) => {
      const message =
        element === AMOUNT_SOURCE_TYPE.JAST
          ? t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.jast')
          : element === AMOUNT_SOURCE_TYPE.WEST
          ? t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.west')
          : element === AMOUNT_SOURCE_TYPE.PRE_ANSWERED
          ? t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.usePastAnswer', { fiscalYear: previousYear })
          : '';
      const color =
        element === AMOUNT_SOURCE_TYPE.JAST || element === AMOUNT_SOURCE_TYPE.WEST
          ? 'success'
          : element === AMOUNT_SOURCE_TYPE.PRE_ANSWERED
          ? 'info'
          : 'default';
      if (message === '') {
        return null;
      }
      return (
        <Label
          key={`${element}-${index}`}
          variant="ghost"
          color={color}
          sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', mb: '10px' }}>
          {message}
        </Label>
      );
    });
  };

  const switchLabelForReceivablePayable = (payableBalance: number | null, receivableBalance: number | null, defaultValue?: DefaultValue) => {
    if (!defaultValue) return null;

    const notChangedReceivablePayable =
      // memo: 型定義上number | nullとなっているが入力されたpayableBalanceとreceivableBalanceがstring型になるため、比較する際には型変換が必要なので、==を使用
      // 債権債務必ず両方の値が連携されるとは限らないので、初期値がnullの際はfalseを返す
      // eslint-disable-next-line eqeqeq
      (defaultValue.payableBalance !== null && defaultValue.payableBalance == payableBalance) ||
      // eslint-disable-next-line eqeqeq
      (defaultValue.receivableBalance !== null && defaultValue.receivableBalance == receivableBalance);
    if (notChangedReceivablePayable) {
      return (
        <Label
          key={`${defaultValue?.customerCode}-receivable-payable`}
          variant="outlined"
          color="warning"
          sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', alignItems: 'left', justifyContent: 'left', mb: '10px' }}>
          {t('enqueteAnswerInput.seriesSurvey.tradeVolume.message.receivablePayable')}
        </Label>
      );
    }
    return null;
  };

  return { switchLabelForCustomerStatus, switchLabelForSourceType, switchLabelForReceivablePayable };
};
