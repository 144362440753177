import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EnqueteInfoEntity, useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import { setRequestStatus } from 'src/features/general/enquete-status/store/enqueteStatusSlice';
import { useAppDispatch } from 'src/redux/store';

export const useEnqueteInfoData = () => {
  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const surveyDetailId = params.get('surveyDetailId');
  const enqueteStatusPageApi = useEnqueteStatusPageApi();
  const dispatch = useAppDispatch();

  const [enqueteInfoData, setEnqueteInfoData] = useState<EnqueteInfoEntity | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllowed, setIsAllowed] = useState(true);

  //-------------------------------------------------------------
  // データの更新処理
  //-------------------------------------------------------------
  const refreshData = useCallback(async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      const infoData = await enqueteStatusPageApi.getEnqueteInfo(id);
      if (infoData && infoData.length > 0) {
        setEnqueteInfoData(infoData[0]);
        if (infoData[0]?.openedAt && infoData[0]?.closedAt) {
          dispatch(setRequestStatus({ openedAt: infoData[0]?.openedAt, closedAt: infoData[0]?.closedAt }));
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        setIsAllowed(false);
      }
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return { isLoading, isAllowed, surveyDetailId, respondentRequestId: id, enqueteInfoData, refreshData };
};
