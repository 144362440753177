import { FirstDataRenderedEvent, ICellRendererParams, ModelUpdatedEvent, ValueGetterParams } from 'ag-grid-community';
import { ColDef, GridOptions } from 'ag-grid-enterprise';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AggregateDetailRow } from 'src/api/useEnqueteStatusPageApi';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { currencyFormat } from 'src/utils/currencyFormat';
import { switchLabel } from 'src/utils/labelUtils';

export const useSummaryAggregateDetailColdef = (previousYear: string, currentYear: string) => {
  const headerHeight = 48; // default header height in px
  const rowHeight = 42;
  const groupHeaderHeight = 42;
  const maxGridHeight = 800; // max height of grid table

  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const { t } = useTranslation();
  const [gridHeight, setGridHeight] = useState<number>(maxGridHeight);

  // 初回グリッドがレンダーされた際に列幅自動調整
  const firstDataRendered = useCallback((event: FirstDataRenderedEvent) => {
    event.columnApi?.autoSizeAllColumns(false);
  }, []);

  const defaultColDef: ColDef = {
    flex: 1,
    sortable: true,
    resizable: true,
  };

  const getLangValue = (data: (AggregateDetailRow & { [key: string]: string | number }) | undefined, field: string, isLangJpn: boolean) => {
    const jpnField = `${field}NameJpn`;
    const engField = `${field}NameEng`;
    return data ? switchLabel(data[jpnField] as string, data[engField] as string, isLangJpn) : '';
  };

  const createLangValueGetter = useCallback(
    (field: string) => {
      return (params: ValueGetterParams<AggregateDetailRow>) => {
        if (!params.node?.group) return getLangValue(params.data, field, isLangJpn);
      };
    },
    [isLangJpn],
  );

  //ベースとなるColDef
  const colDef: ColDef<AggregateDetailRow>[] = [
    {
      headerName: t('enqueteStatus.summaryAggregate.company'),
      field: isLangJpn ? 'companyNameJpn' : 'companyNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('company'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.division'),
      field: isLangJpn ? 'divisionNameJpn' : 'divisionNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('division'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.sbu'),
      field: isLangJpn ? 'sbuNameJpn' : 'sbuNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('sbu'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.department'),
      field: isLangJpn ? 'departmentNameJpn' : 'departmentNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('department'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.group'),
      field: isLangJpn ? 'groupNameJpn' : 'groupNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('group'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.region'),
      field: isLangJpn ? 'regionNameJpn' : 'regionNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('region'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.country'),
      field: isLangJpn ? 'countryNameJpn' : 'countryNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('country'),
    },
    {
      headerName: t('common.customer'),
      field: isLangJpn ? 'customerNameJpn' : 'customerNameEng',
      enableRowGroup: true,
      valueFormatter: (params): string => (isLangJpn ? trimStockCompany(params.value) : params.value),
      valueGetter: createLangValueGetter('customer'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.customerRegion'),
      field: isLangJpn ? 'customerRegionNameJpn' : 'customerRegionNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('customerRegion'),
    },
    {
      headerName: t('enqueteStatus.summaryAggregate.customerCountry'),
      field: isLangJpn ? 'customerCountryNameJpn' : 'customerCountryNameEng',
      enableRowGroup: true,
      valueGetter: createLangValueGetter('customerCountry'),
    },
    {
      field: 'preSales',
      headerName: `FY${previousYear}(${t('enqueteStatus.summaryAggregate.sales')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    {
      field: 'currentSales',
      headerName: `FY${currentYear}(${t('enqueteStatus.summaryAggregate.sales')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    {
      field: 'prePurchase',
      headerName: `FY${previousYear}(${t('enqueteStatus.summaryAggregate.purchase')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    {
      field: 'currentPurchase',
      headerName: `FY${currentYear}(${t('enqueteStatus.summaryAggregate.purchase')})`,
      valueFormatter: (params) => currencyFormat(params.value),
      cellStyle: { textAlign: 'right' },
      minWidth: 200,
      aggFunc: 'sum',
    },
    { field: 'salesComment', headerName: t('enqueteStatus.summaryAggregate.salesComment'), minWidth: 150 },
    { field: 'purchaseComment', headerName: t('enqueteStatus.summaryAggregate.purchaseComment'), minWidth: 150 },
  ];

  const updateGridHeight = useCallback(
    (params: ModelUpdatedEvent) => {
      const rowCount = params.api.getDisplayedRowCount();
      const gridHeight = (rowCount + 1) * rowHeight + (headerHeight + groupHeaderHeight);
      setGridHeight(Math.min(gridHeight, maxGridHeight));
    },
    [setGridHeight],
  );

  const innerRenderer = (params: ICellRendererParams<AggregateDetailRow, string>) => {
    if (params.value) return params.value;
    const fieldName = params.node.field;
    if (fieldName?.includes('countryName')) {
      return t('enqueteStatus.summaryAggregate.noCuntoryName');
    }
    return t('enqueteStatus.summaryAggregate.empty');
  };

  const gridOptions: GridOptions<AggregateDetailRow[]> = {
    domLayout: 'normal',
    headerHeight,
    groupHeaderHeight,
    rowGroupPanelShow: 'always',
    autoGroupColumnDef: {
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer,
      },
    },
    onRowDataUpdated(event) {
      event.columnApi?.autoSizeAllColumns(false);
    },
    onColumnRowGroupChanged: (event) => {
      event.columnApi?.autoSizeAllColumns(false);
    },
    onModelUpdated: (event) => {
      event.columnApi?.autoSizeAllColumns(false);
      updateGridHeight(event);
    },
    onRowGroupOpened: (event) => {
      event.columnApi?.autoSizeAllColumns(false);
    },
    suppressAggFuncInHeader: true,
    minColWidth: 150,
  };

  return {
    defaultColDef,
    gridOptions,
    colDef,
    firstDataRendered,
    gridHeight,
  };
};
