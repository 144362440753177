import { Box } from '@material-ui/core';
import { Grid, Pagination } from '@mui/material';
import type { AgGridEvent, ColDef, ColGroupDef, ColumnState, GetContextMenuItems, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EnqueteConfirmListEntity } from 'src/api/useConfirmRequestApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import ThreeDotMenuRenderer from 'src/components/app-components/ThreeDotMenuRenderer';
import { LIST_STATE_KEY_PREFIX } from 'src/constants';
import { ENQUETE_ANSWER_PATH } from 'src/features/general/enquete-answer/routes/path';
import { useEnqueteConfirmListGrid } from 'src/features/general/enquete-confirm/components/pages/list/hooks/useEnqueteConfirmListGrid';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { formatterDateTime } from 'src/utils/formatDateTime';
import { switchLabel } from 'src/utils/labelUtils';
import EnqueteConfirmListToggleButtonGroup from './EnqueteConfirmListToggleButtonGroup';
import { EnqueteConfirmListUserTooltipBox, Props as UserTooltipBoxProps } from './EnqueteConfirmListUserTooltipBox';

export const EnqueteConfirmListGrid: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const { enqueteConfirmListData, confirmRequestStatus } = useAppSelector((state) => state.enqueteConfirm);
  const hook = useEnqueteConfirmListGrid();
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';
  const startRowPosition: number = 0;
  const startPagePosition: number = 1;

  const storageKey = 'EnqueteConfirmListGrid';
  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [useSortModel, setUseSortModel] = useState<ColumnState[]>([]);
  const [page, setPage] = useState<number>(1);
  // TODO setBlockSize使ってないのでuseStateを使用する必要がない。
  const [blockSize, setBlockSize] = useState<number>(100); // eslint-disable-line
  const [maxRow, setMaxRow] = useState<number>(1);
  const [isDestroyed, setIsDestroyed] = useState(false);

  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    //データ
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setCountLimitedRowData(filter, currentLang.code);
  }, []); //eslint-disable-line

  useEffect(() => {
    destroyGrid();
  }, [currentLang]); // eslint-disable-line

  useEffect(() => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};

    loadingConfirmRequestStatusListData(blockSize, (page - startPagePosition) * blockSize, useSortModel, filter, currentLang.code);
  }, [page, confirmRequestStatus, currentLang]); // eslint-disable-line

  //-------------------------------------------------------------
  // 選択肢の言語変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    setIsDestroyed(true);
    setTimeout(() => recreateGrid(), 0);
  };

  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  const setCountLimitedRowData = async (useFiletrModel: { [key: string]: any }, currentLang: string) => {
    setIsLoading(true);
    try {
      //件数データ取得
      const rowCount = await hook.countLimitedLoadList(confirmRequestStatus, useFiletrModel, currentLang);
      setMaxRow(rowCount !== undefined ? rowCount : 1);
    } finally {
      setIsLoading(false);
    }
  };

  // グリッドデータ読込
  const loadingConfirmRequestStatusListData = async (
    blockSize: number,
    startRow: number,
    useSortModel: any,
    useFiletrModel: { [key: string]: any },
    currentLang: string,
  ) => {
    setIsLoading(true);
    try {
      await hook.limitedLoadList(confirmRequestStatus, blockSize, startRow, useSortModel, useFiletrModel, currentLang);
    } finally {
      setIsLoading(false);
    }
  };

  const setChangePgae = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // ダブルクリック時の挙動処理
  const onRowDoubleClickedCallback = (e: any) => {
    navigate(ENQUETE_ANSWER_PATH.INPUT + e.data.respondentTargetId + '?confirmRequestId=' + e.data.id);
  };

  // 確認ステータス文言出力
  const valueFormatterByStatus = (params: ValueGetterParams | ITooltipParams) => {
    if (params.data.status === 'CONFIRMED') {
      return t('confirmStatus.confirmed');
    } else {
      return t('confirmStatus.unConfirmed');
    }
  };

  //日英切替時、値がなければもう片方の言語データを表示させる
  const existTranslateParams = (params: any, jpnFieldName: string, engFieldName: string) => {
    const jpnModeData: string = switchLabel(params.data[jpnFieldName] ?? '', params.data[engFieldName] ?? '', true);
    const engModeData: string = switchLabel(params.data[jpnFieldName] ?? '', params.data[engFieldName] ?? '', false);

    return isLangJpn ? jpnModeData : engModeData;
  };

  // TODO "currentLang.value === 'ja' ?"を"isLangJpn ?"に全て置換する
  // TODO 定義が縦に長く読みづらいのでfieldごとに分割して定義するか、定義ファイルを分けるのもありかもしれない
  // ColDef定義
  const coldef: (ColDef | ColGroupDef)[] = [
    {
      field: 'status',
      headerName: t('enqueteConfirm.confirmGridColDef.status'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.status'),
      minWidth: 150,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
        values: [t('confirmStatus.confirmed'), t('confirmStatus.unConfirmed')],
      },
      floatingFilter: true,
      valueGetter: valueFormatterByStatus,
      tooltipValueGetter: valueFormatterByStatus,
    },
    {
      field: currentLang.value === 'ja' ? 'labelJpn' : 'labelEng',
      headerName: t('enqueteConfirm.confirmGridColDef.type'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.type'),
      tooltipField: currentLang.value === 'ja' ? 'labelJpn' : 'labelEng',
      minWidth: 150,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.labelJpn ?? '', params.data.labelEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'titleJpn' : 'titleEng',
      headerName: t('enqueteConfirm.confirmGridColDef.title'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.title'),
      tooltipField: currentLang.value === 'ja' ? 'titleJpn' : 'titleEng',
      minWidth: 300,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.titleJpn ?? '', params.data.titleEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'companyNameJpn' : 'companyNameEng',
      headerName: t('organization.names.companyName'),
      headerTooltip: t('organization.names.companyName'),
      tooltipField: currentLang.value === 'ja' ? 'companyNameJpn' : 'companyNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.companyNameJpn ?? '', params.data.companyNameEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'divisionNameJpn' : 'divisionNameEng',
      headerName: t('organization.names.divisionName'),
      headerTooltip: t('organization.names.divisionName'),
      tooltipField: currentLang.value === 'ja' ? 'divisionNameJpn' : 'divisionNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.divisionNameJpn ?? '', params.data.divisionNameEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'sbuNameJpn' : 'sbuNameEng',
      headerName: t('organization.names.sbuName'),
      headerTooltip: t('organization.names.sbuName'),
      tooltipField: currentLang.value === 'ja' ? 'sbuNameJpn' : 'sbuNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.sbuNameJpn ?? '', params.data.sbuNameEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'departmentNameJpn' : 'departmentNameEng',
      headerName: t('organization.names.departmentName'),
      headerTooltip: t('organization.names.departmentName'),
      tooltipField: currentLang.value === 'ja' ? 'departmentNameJpn' : 'departmentNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.departmentNameJpn ?? '', params.data.departmentNameEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'groupNameJpn' : 'groupNameEng',
      headerName: t('organization.names.groupName'),
      headerTooltip: t('organization.names.groupName'),
      tooltipField: currentLang.value === 'ja' ? 'groupNameJpn' : 'groupNameEng',
      minWidth: 200,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => switchLabel(params.data.groupNameJpn ?? '', params.data.groupNameEng ?? '', currentLang.value === 'ja'),
    },
    {
      field: currentLang.value === 'ja' ? 'requesterName' : 'requesterNameEng',
      headerName: t('enqueteConfirm.confirmGridColDef.requester'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.requester'),
      minWidth: 170,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => existTranslateParams(params, 'requesterName', 'requesterNameEng'),
      tooltipValueGetter: (params) => existTranslateParams(params, 'requesterName', 'requesterNameEng'),
      tooltipComponentFramework: (cell: any) => {
        if (cell.location === 'header') {
          cell.reactContainer!.classList.add('ag-tooltip');
          return cell.value;
        }
        return <EnqueteConfirmListUserTooltipBox {...cell} />;
      },
      tooltipComponentParams: (cell: any): UserTooltipBoxProps => {
        if (cell.location === 'header') {
          return cell;
        }
        const data: EnqueteConfirmListEntity = cell.data;
        return {
          id: data.requesterId,
          bluePageEmailAddress: data.requesterBluePageEmailAddress,
          name: existTranslateParams(cell, 'requesterName', 'requesterNameEng'),
        };
      },
    },
    {
      field: 'requestedDate',
      headerName: t('enqueteConfirm.confirmGridColDef.requestedDate'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.requestedDate'),
      valueGetter: (params) => {
        return formatterDateTime(params.data.requestedDate) as string;
      },
      tooltipValueGetter: (params) => {
        return formatterDateTime(params.data.requestedDate) as string;
      },
      minWidth: 180,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: [
          {
            displayKey: 'inRange',
            displayName: 'inRange',
            numberOfInputs: 2,
            predicate: ([fv1, fv2]: [Date, Date], cellValue: string) => {
              if (cellValue == null) return true;
              var cellDate = new Date(cellValue);
              fv2.setDate(fv2.getDate() + 1);
              return cellDate.getTime() >= fv1.getTime() && cellDate.getTime() <= fv2.getTime();
            },
          },
        ],
        inRangeInclusive: true,
      },
      floatingFilter: true,
    },
    {
      field: 'requestComment',
      headerName: t('enqueteConfirm.confirmGridColDef.requestComment'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.requestComment'),
      tooltipField: 'requestComment',
      minWidth: 300,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
    },
    {
      field: currentLang.value === 'ja' ? 'confirmerName' : 'confirmerNameEng',
      headerName: t('enqueteConfirm.confirmGridColDef.confirmerName'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.confirmerName'),
      minWidth: 170,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
      valueGetter: (params) => existTranslateParams(params, 'confirmerName', 'confirmerNameEng'),
      tooltipValueGetter: (params) => existTranslateParams(params, 'confirmerName', 'confirmerNameEng'),
      tooltipComponentFramework: (cell: any) => {
        if (cell.location === 'header') {
          cell.reactContainer!.classList.add('ag-tooltip');
          return cell.value;
        }
        return <EnqueteConfirmListUserTooltipBox {...cell} />;
      },
      tooltipComponentParams: (cell: any): UserTooltipBoxProps => {
        if (cell.location === 'header') {
          return cell;
        }
        const data: EnqueteConfirmListEntity = cell.data;
        return {
          id: data.confirmerId,
          bluePageEmailAddress: data.confirmerBluePageEmailAddress,
          name: existTranslateParams(cell, 'confirmerName', 'confirmerNameEng'),
        };
      },
    },
    {
      field: 'confirmDate',
      headerName: t('enqueteConfirm.confirmGridColDef.confirmDate'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.confirmDate'),
      valueGetter: (params) => {
        return formatterDateTime(params.data.confirmDate) as string;
      },
      tooltipValueGetter: (params) => {
        return formatterDateTime(params.data.confirmDate) as string;
      },
      minWidth: 180,
      filter: 'date',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: [
          {
            displayKey: 'inRange',
            displayName: 'inRange',
            numberOfInputs: 2,
            predicate: ([fv1, fv2]: [Date, Date], cellValue: string) => {
              if (cellValue == null) return true;
              var cellDate = new Date(cellValue);
              fv2.setDate(fv2.getDate() + 1);
              return cellDate.getTime() >= fv1.getTime() && cellDate.getTime() < fv2.getTime();
            },
          },
        ],
      },
      floatingFilter: true,
    },
    {
      field: 'confirmComment',
      headerName: t('enqueteConfirm.confirmGridColDef.confirmComment'),
      headerTooltip: t('enqueteConfirm.confirmGridColDef.confirmComment'),
      tooltipField: 'confirmComment',
      minWidth: 250,
      width: 300,
      filter: 'text',
      filterParams: {
        suppressAndOrCondition: true,
        applyMiniFilterWhileTyping: true,
        filterOptions: ['contains'],
      },
      floatingFilter: true,
    },
    {
      field: '-',
      headerName: '',
      cellRenderer: ThreeDotMenuRenderer,
      editable: false,
      minWidth: 80,
      width: 50,
      filter: false,
      pinned: 'right',
    },
  ];

  const contextMenu: GetContextMenuItems = (params) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('enqueteConfirm.contextMenu.reference'),
        action: () => {
          navigate(ENQUETE_ANSWER_PATH.INPUT + params.node!.data.respondentTargetId + '?confirmRequestId=' + params.node!.data.id);
        },
      },
    ];

    return menuItems;
  };

  const setFilterSortLimitedRowData = () => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setCountLimitedRowData(filter, currentLang.code);
    setPage(startPagePosition);
    loadingConfirmRequestStatusListData(blockSize, startRowPosition, useSortModel, filter, currentLang.code);
  };

  const onFilterChangedCallback = (event: AgGridEvent) => {
    setFilterSortLimitedRowData();
  };

  const onSortChangedCallback = (event: AgGridEvent) => {
    setUseSortModel(event.columnApi.getColumnState());
    setFilterSortLimitedRowData();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box style={{ textAlign: 'right' }}>
          <EnqueteConfirmListToggleButtonGroup />
        </Box>
        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: '95vh',
            width: '100%',
          }}>
          <LoadableComponent isLoading={isLoading}>
            {!isDestroyed && (
              <StyledAgGrid
                coldef={coldef}
                rows={enqueteConfirmListData}
                contextMenu={contextMenu}
                onRowDoubleClickedCallback={onRowDoubleClickedCallback}
                onSortChangedCallback={onSortChangedCallback}
                onFilterChangedCallback={onFilterChangedCallback}
                storageKey={storageKey}
              />
            )}
            <Grid item xs={12} md={12}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination count={maxRow <= blockSize ? 1 : Math.ceil(maxRow / blockSize)} page={page} onChange={setChangePgae} />
              </div>
            </Grid>
          </LoadableComponent>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
};
