import { Autocomplete, FormControl, Grid, Skeleton, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

type Props = {
  isEditMode: boolean;
  isLoading: boolean;
  methods: UseFormReturn;
  inputCompany: string;
  companies: string[];
  setInputCompany: React.Dispatch<React.SetStateAction<string>>;
  rule: ((targetName: string, list: string[]) => object) | undefined;
  label: string;
};

export const CompanyGrid = (props: Props) => {
  const { isEditMode, isLoading, methods, inputCompany, companies, setInputCompany, rule, label } = props;
  const { setValue } = methods;

  return (
    <Grid item xs={12}>
      <FormControl fullWidth size="small">
        <Controller
          name="companyCode"
          control={methods.control}
          rules={rule ? rule(label, companies) : undefined}
          render={({ field, fieldState }) =>
            isLoading ? (
              <Skeleton variant="text" width={400} height={40} />
            ) : (
              <Autocomplete
                {...field}
                isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
                inputValue={inputCompany}
                options={companies}
                onChange={(event: SyntheticEvent, newValue: string | null) => {
                  setValue('companyCode', newValue);
                }}
                onInputChange={(e, newInputValue) => {
                  setInputCompany(newInputValue);
                }}
                disabled={isEditMode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    name="companyCode"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label={label}
                  />
                )}
              />
            )
          }
        />
      </FormControl>
    </Grid>
  );
};
