import { isAfter, isValid, setSeconds } from 'date-fns';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useTranslation } from 'react-i18next';
import {
  ANSWER_TYPE_NUMBER,
  ANSWER_TYPE_NUMBER_OF_DECIMAL_PLACES,
  ANSWER_TYPE_STRING,
  ENQUETE_TITLE,
  ENQUETE_TYPE,
  QUESTION_ITEM,
  QUESTION_TITLE,
  VALIDATION_ERROR_MESSAGE,
} from 'src/generalConstants';
import { RootState, useAppSelector } from 'src/redux/store/index';
import { conversionDateWithoutLocalHour } from 'src/utils/formatDateTime';

export const useValidator = (methods: UseFormReturn) => {
  const { t } = useTranslation();
  const questionItem = useAppSelector((state: RootState) => state.enqueteCreate.questionItem);

  const rulesOwnerOrganization = {
    required: {
      value: true,
      message: t('enqueteGeneralCommon.message.varidationErrorRequiredOwnerOrganization'),
    },
  };

  const rulesType = {
    required: t('enqueteGeneralCommon.message.varidationErrorRequiredType'),
    maxLength: {
      value: ENQUETE_TYPE.MAX_LENGTH,
      message: t('validateError.maxLength', { max: ENQUETE_TYPE.MAX_LENGTH }),
    },
    validate: {
      check: (value: any) => {
        if (typeof value === 'object') {
          if (value && value.labelJpn) {
            return value.labelJpn.length <= ENQUETE_TYPE.MAX_LENGTH && value.labelJpn.length > 0
              ? true
              : t('validateError.maxLength', { max: ENQUETE_TYPE.MAX_LENGTH });
          }
          return t('enqueteGeneralCommon.message.varidationErrorRequiredType');
        }
      },
      noAllWhiteSpace: (value: any) => {
        if (typeof value === 'object') {
          if (value && value.labelJpn) {
            return value.labelJpn.trim().length > 0 ? true : t('enqueteGeneralCommon.message.varidationErrorRequiredType');
          }
        } else {
          return value.trim().length > 0 ? true : t('enqueteGeneralCommon.message.varidationErrorRequiredType');
        }
        return true;
      },
    },
  };

  const rulesTitle = {
    required: t('enqueteGeneralCommon.message.varidationErrorRequiredTitle'),
    maxLength: {
      value: ENQUETE_TITLE.MAX_LENGTH,
      message: t('validateError.maxLength', { max: ENQUETE_TITLE.MAX_LENGTH }),
    },
  };

  const dateRules1: RegisterOptions = {
    required: {
      value: true,
      message: t('enqueteGeneralCommon.message.varidationErrorInputFromRequiredDate'),
    },
    validate: {
      isValidDate: (value: Date) => {
        return value instanceof Date && !isNaN(value.getTime()) ? true : `${t('validateError.format.datetime')}`;
      },
      isValidFuture: (value: Date) => {
        return isAfter(value, new Date()) || `${t('enqueteGeneralCommon.message.validationErrorInputFromIsValidFutureDate')}`;
      },
      isValidPeriod: (value: Date) => {
        const endDate = setSeconds(methods.getValues().closedAt, 0);
        methods.setValue('closedAt', endDate);
        // memo: value は required rule で null でないことが保証されるため、 as 句を利用しています
        if (endDate && setSeconds(value, 0).getTime() < endDate.getTime()) {
          // 相関チェックエラーのみクリアする
          if (methods.getFieldState('closedAt').error?.type === 'isValidPeriod') {
            methods.clearErrors('closedAt');
          }
          return true;
        } else {
          return `${t('enqueteGeneralCommon.message.varidationErrorInputFromIsValidPeriodDate')}`;
        }
      },
    },
  };

  const dateRules2: RegisterOptions = {
    required: {
      value: true,
      message: t('enqueteGeneralCommon.message.varidationErrorInputToRequiredDate'),
    },
    validate: {
      isValidDate: (value: Date) => {
        return value instanceof Date && !isNaN(value.getTime()) ? true : `${t('validateError.format.datetime')}`;
      },
      isValidPeriod: (value: Date) => {
        const startDate = setSeconds(methods.getValues().openedAt, 0);
        methods.setValue('openedAt', startDate);
        // memo: value は required rule で null でないことが保証されるため、 as 句を利用しています
        if (startDate && setSeconds(value, 0).getTime() > startDate.getTime()) {
          // 相関チェックエラーのみクリアする
          if (methods.getFieldState('openedAt').error?.type === 'isValidPeriod') {
            methods.clearErrors('openedAt');
          }
          return true;
        } else {
          return `${t('enqueteGeneralCommon.message.varidationErrorInputToIsValidPeriodDate')}`;
        }
      },
    },
  };

  const rulesTargetDate: RegisterOptions = {
    required: {
      value: true,
      message: t('enqueteGeneralCommon.message.varidationErrorInputFromRequiredDate'),
    },
    validate: {
      isValidDate: (value: string) => {
        return isValid(new Date(value)) || `${t('validateError.format.datetime')}`;
      },
    },
  };

  const rulesOrganizationBaseDate: RegisterOptions = {
    required: {
      value: true,
      message: t('validateError.required', { target: t('common.organizationBaseDate') }),
    },
    validate: {
      isValidDate: (value: string) => {
        return isValid(new Date(value)) || `${t('validateError.format.yyyymm')}`;
      },
      isValidOrganizationBaseDate: (value: string) => {
        return (
          (conversionDateWithoutLocalHour(new Date('2020-04-01')) <= new Date(value) && new Date(value) <= new Date()) ||
          `${t('enqueteGeneralCommon.message.varidationErrorInputToIsValidOrganizationBaseDate')}`
        );
      },
    },
  };

  const rulesAnswerUnit = {
    required: { value: true, message: t('enqueteGeneralCommon.message.varidationErrorRequiredAnswerUnit') },
  };

  const enqueteQuestionFormatRules = {
    required: { value: true, message: t('enqueteGeneralCommon.message.varidationErrorInputToRequiredFormat') },
  };

  const rulesDisclosureLevel = {
    required: { value: true, message: t('enqueteGeneralCommon.message.varidationErrorInputToRequiredDisclosureLevel') },
  };
  const rulesDisclosureLevelUpdateConfirm = {
    required: { value: true, message: t('enqueteGeneralCommon.message.varidationErrorInputToRequiredDisclosureLevelUpdateConfirm') },
  };

  const rulesTypeValidationErrorMessage = {
    required: t('enqueteCreate.validation.errorMessage.requiredErrorMessage'),
    maxLength: {
      value: VALIDATION_ERROR_MESSAGE.MAX_LENGTH,
      message: t('validateError.maxLength', { max: VALIDATION_ERROR_MESSAGE.MAX_LENGTH }),
    },
  };

  const rulesCreateQuestionTitle = {
    required: t('enqueteCreate.validation.errorMessage.requiredTitle'),
    maxLength: {
      value: QUESTION_TITLE.MAX_LENGTH,
      message: t('validateError.maxLength', { max: QUESTION_TITLE.MAX_LENGTH }),
    },
  };

  const rulesCreateQuestionNumberRange1: RegisterOptions = {
    required: { value: true, message: t('enqueteCreate.validation.errorMessage.requiredRangeAnswers') },
    pattern: {
      value: /^-?([1-9]\d*|0)(\.\d+)?$/,
      message: t('enqueteCreate.validation.errorMessage.numberRangeAnswers'),
    },
    min: {
      value: ANSWER_TYPE_NUMBER.MIN,
      message: t('enqueteCreate.validation.errorMessage.numberRangeAnswers'),
    },
    max: {
      value: ANSWER_TYPE_NUMBER.MAX,
      message: t('enqueteCreate.validation.errorMessage.numberRangeAnswers'),
    },
    validate: {
      isValidCorrect: (value: Number) => {
        const maxValue: Number = methods.getValues().setMax;
        methods.setValue('setMax', maxValue);
        if (value !== null && maxValue !== null) {
          if (Number(value) <= Number(maxValue)) {
            if (Number(maxValue) >= ANSWER_TYPE_NUMBER.MIN && Number(maxValue) <= ANSWER_TYPE_NUMBER.MAX) {
              methods.clearErrors('setMax');
            }
            return true;
          } else {
            return `${t('enqueteCreate.validation.errorMessage.numberRangeAnswersMinIsValid')}`;
          }
        }
      },
    },
  };

  const rulesCreateQuestionNumberRange2: RegisterOptions = {
    required: { value: true, message: t('enqueteCreate.validation.errorMessage.requiredRangeAnswers') },
    pattern: {
      value: /^-?([1-9]\d*|0)(\.\d+)?$/,
      message: t('enqueteCreate.validation.errorMessage.numberRangeAnswers'),
    },
    min: {
      value: ANSWER_TYPE_NUMBER.MIN,
      message: t('enqueteCreate.validation.errorMessage.numberRangeAnswers'),
    },
    max: {
      value: ANSWER_TYPE_NUMBER.MAX,
      message: t('enqueteCreate.validation.errorMessage.numberRangeAnswers'),
    },
    validate: {
      isValidCorrect: (value: Number) => {
        const minValue: Number = methods.getValues().setMin;
        methods.setValue('setMin', minValue);
        if (value !== null && minValue !== null) {
          if (Number(value) >= Number(minValue)) {
            if (Number(minValue) >= ANSWER_TYPE_NUMBER.MIN && Number(minValue) <= ANSWER_TYPE_NUMBER.MAX) {
              methods.clearErrors('setMin');
            }
            return true;
          } else {
            return `${t('enqueteCreate.validation.errorMessage.numberRangeAnswersMaxIsValid')}`;
          }
        }
      },
    },
  };

  const rulesCreateQuestionNumberOfDecimalPlaces: RegisterOptions = {
    pattern: {
      value: /^([1-9]\d*|0)$/,
      message: t('enqueteCreate.validation.errorMessage.numberOfDecimalPlaces'),
    },
    min: {
      value: ANSWER_TYPE_NUMBER_OF_DECIMAL_PLACES.MIN,
      message: t('enqueteCreate.validation.errorMessage.numberOfDecimalPlaces'),
    },
    max: {
      value: ANSWER_TYPE_NUMBER_OF_DECIMAL_PLACES.MAX,
      message: t('enqueteCreate.validation.errorMessage.numberOfDecimalPlaces'),
    },
  };

  const rulesCreateQuestionStringRange1: RegisterOptions = {
    required: { value: true, message: t('enqueteCreate.validation.errorMessage.requiredRangeAnswers') },
    pattern: {
      value: /^([1-9]\d*|0)$/,
      message: t('enqueteCreate.validation.errorMessage.stringRangeAnswers'),
    },
    min: {
      value: ANSWER_TYPE_STRING.MIN,
      message: t('enqueteCreate.validation.errorMessage.stringRangeAnswers'),
    },
    max: {
      value: ANSWER_TYPE_STRING.MAX,
      message: t('enqueteCreate.validation.errorMessage.stringRangeAnswers'),
    },
    validate: {
      isValidCorrect: (value: Number) => {
        const maxLengthValue: Number = methods.getValues().setMax;
        methods.setValue('setMax', maxLengthValue);
        if (value !== null && maxLengthValue !== null) {
          if (Number(value) <= Number(maxLengthValue)) {
            if (Number(maxLengthValue) >= ANSWER_TYPE_STRING.MIN && Number(maxLengthValue) <= ANSWER_TYPE_STRING.MAX) {
              methods.clearErrors('setMax');
            }
            return true;
          } else {
            return `${t('enqueteCreate.validation.errorMessage.stringRangeAnswersMinIsValid')}`;
          }
        }
      },
    },
  };

  const rulesCreateQuestionStringRange2: RegisterOptions = {
    required: { value: true, message: t('enqueteCreate.validation.errorMessage.requiredRangeAnswers') },
    pattern: {
      value: /^([1-9]\d*|0)$/,
      message: t('enqueteCreate.validation.errorMessage.stringRangeAnswers'),
    },
    min: {
      value: ANSWER_TYPE_STRING.MIN,
      message: t('enqueteCreate.validation.errorMessage.stringRangeAnswers'),
    },
    max: {
      value: ANSWER_TYPE_STRING.MAX,
      message: t('enqueteCreate.validation.errorMessage.stringRangeAnswers'),
    },
    validate: {
      isValidCorrect: (value: Number) => {
        const minLengthValue: Number = methods.getValues().setMin;
        methods.setValue('setMin', minLengthValue);
        if (value !== null && minLengthValue !== null) {
          if (Number(value) >= Number(minLengthValue)) {
            if (Number(minLengthValue) >= ANSWER_TYPE_STRING.MIN && Number(minLengthValue) <= ANSWER_TYPE_STRING.MAX) {
              methods.clearErrors('setMin');
            }
            return true;
          } else {
            return `${t('enqueteCreate.validation.errorMessage.stringRangeAnswersMaxIsValid')}`;
          }
        }
      },
    },
  };

  const rulesCreateQuestionItem = {
    required: t('enqueteCreate.validation.errorMessage.requiredQuestionItem'),
    maxLength: {
      value: QUESTION_ITEM.MAX_LENGTH,
      message: t('validateError.maxLength', { max: QUESTION_ITEM.MAX_LENGTH }),
    },
  };

  const rulesPatternRequire = {
    required: {
      value: true,
      message: t('enqueteCreate.validation.errorMessage.requiredanswerType'),
    },
  };

  const rulesNumberOfElement = {
    required: {
      value: true,
      message: t('enqueteCreate.validation.errorMessage.inputNumberOfElement'),
    },
    pattern: {
      value: /^([1-9]\d*|0)$/,
      message: t('enqueteCreate.validation.errorMessage.inputRangeOfElement'),
    },
    min: {
      value: QUESTION_ITEM.MIN_COUNT,
      message: t('enqueteCreate.validation.errorMessage.inputRangeOfElement'),
    },
    max: {
      value: QUESTION_ITEM.MAX_COUNT,
      message: t('enqueteCreate.validation.errorMessage.inputRangeOfElement'),
    },
    validate: {
      checkQuestionItemLength: () => {
        const checkValue = methods.getValues().checkboxRange;
        if (checkValue === null) return true;
        if (questionItem.length < Number(checkValue)) {
          return t('enqueteCreate.validation.errorMessage.inputRangeOfElementAndItemLength');
        }
        return true;
      },
    },
  };

  return {
    rulesOwnerOrganization,
    rulesType,
    rulesTitle,
    dateRules1,
    dateRules2,
    rulesAnswerUnit,
    enqueteQuestionFormatRules,
    rulesDisclosureLevel,
    rulesDisclosureLevelUpdateConfirm,
    rulesTypeValidationErrorMessage,
    rulesCreateQuestionTitle,
    rulesCreateQuestionNumberRange1,
    rulesCreateQuestionNumberRange2,
    rulesCreateQuestionNumberOfDecimalPlaces,
    rulesCreateQuestionStringRange1,
    rulesCreateQuestionStringRange2,
    rulesCreateQuestionItem,
    rulesPatternRequire,
    rulesNumberOfElement,
    rulesTargetDate,
    rulesOrganizationBaseDate,
  };
};
