import { DivisionRegionEditorDataTypes } from 'src/api/useVisibleConditionsApi';
import { VISIBLE_TYPE } from 'src/constants';

type OmitSelectedTypes = 'id';
export interface SelectedRowDataTypes extends Omit<DivisionRegionEditorDataTypes, OmitSelectedTypes> {}

export const initSelectedRowData: SelectedRowDataTypes = {
  email: '',
  bluePageEmailAddress: '',
  userNameJpn: '',
  userNameEng: '',
  userCompanyCode: '',
  userCompanyNameJpn: '',
  userCompanyNameEng: '',
  userCompanyAbbreviation: '',
  assignedCompanyNameJpn: '',
  assignedCompanyNameEng: '',
  userDivisionCode: '',
  userDivisionNameJpn: '',
  userDivisionNameEng: '',
  userSbuCode: '',
  userSbuNameJpn: '',
  userSbuNameEng: '',
  userDepartmentCode: '',
  userDepartmentNameJpn: '',
  userDepartmentNameEng: '',
  userGroupCode: '',
  userGroupNameJpn: '',
  userGroupNameEng: '',
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  sbuCode: '',
  sbuNameJpn: '',
  sbuNameEng: '',
  departmentCode: '',
  departmentNameJpn: '',
  departmentNameEng: '',
  groupCode: '',
  groupNameJpn: '',
  groupNameEng: '',
  regionCode: '',
  regionNameJpn: '',
  regionNameEng: '',
  countryCode: '',
  countryNameJpn: '',
  countryNameEng: '',
  accessRoleId: '',
  visibleType: VISIBLE_TYPE.SELECT,
};

// 検索用条件
export interface SearchConditonProps {
  divisionCode: string;
  sbuCode: string;
  departmentCode: string;
  groupCode: string;
  regionCode: string;
  countryCode: string;
}

// 検索用条件初期値
export const initSearchCondition: SearchConditonProps = {
  divisionCode: '',
  sbuCode: '',
  departmentCode: '',
  groupCode: '',
  regionCode: '',
  countryCode: '',
};

export const initDivisionRegionEditorData: DivisionRegionEditorDataTypes = {
  id: '',
  email: '',
  bluePageEmailAddress: '',
  userNameJpn: '',
  userNameEng: '',
  userCompanyCode: '',
  userCompanyNameJpn: '',
  userCompanyNameEng: '',
  userCompanyAbbreviation: '',
  assignedCompanyNameJpn: '',
  assignedCompanyNameEng: '',
  userDivisionCode: '',
  userDivisionNameJpn: '',
  userDivisionNameEng: '',
  userSbuCode: '',
  userSbuNameJpn: '',
  userSbuNameEng: '',
  userDepartmentCode: '',
  userDepartmentNameJpn: '',
  userDepartmentNameEng: '',
  userGroupCode: '',
  userGroupNameJpn: '',
  userGroupNameEng: '',
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  sbuCode: '',
  sbuNameJpn: '',
  sbuNameEng: '',
  departmentCode: '',
  departmentNameJpn: '',
  departmentNameEng: '',
  groupCode: '',
  groupNameJpn: '',
  groupNameEng: '',
  regionCode: '',
  regionNameJpn: '',
  regionNameEng: '',
  countryCode: '',
  countryNameJpn: '',
  countryNameEng: '',
  accessRoleId: '',
  visibleType: VISIBLE_TYPE.SELECT,
};
