import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeriesSurveyFormAggregationData } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

import { GetContextMenuItems, GetContextMenuItemsParams } from 'ag-grid-community';
import {
  SearchOrganizationAdditionalConditionTypes,
  useSearchOrganizationConditions,
} from 'src/features/general/enquete-create/hooks/useSearchOrganizationConditions';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { addSuffix, switchLabel } from 'src/utils/labelUtils';
import {
  SeriesEnqueteRowData,
  useEnqueteModal,
} from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteModal';
import { useSeriesEnqueteCreateModalSetting } from './useSeriesEnqueteCreateModalSetting';
import { useSeriesEnqueteCreateModalValidation } from './useSeriesEnqueteCreateModalValidation';

export const initSelectedRowData: SeriesEnqueteRowData = {
  divisionCode: '',
  divisionNameJpn: '',
  divisionNameEng: '',
  divisionOrganizationId: '',
  destinationSbuCode: '',
  destinationSbuNameJpn: '',
  destinationSbuNameEng: '',
  destinationSbuOrganizationId: '',
  sourceSbuCode: '',
  sourceSbuNameJpn: '',
  sourceSbuNameEng: '',
  sourceSbuOrganizationId: '',
};

export interface ModalSearchConditionProps {
  type: 'autocomplete';
  id: string;
  label: string;
  style: {
    xs: number;
  };
  disabled: boolean;
  props: SearchConditionAutoCompleteProps;
}

export interface SearchConditionAutoCompleteProps {
  value: string | null;
  list: {
    key: string;
    label: string;
  }[]; // ドロップダウンリストに表示する候補情報
  onChange: (value: string | undefined) => void; // ドロップダウンリスト変更時のコールバック
}

interface Props {
  targetData: SeriesEnqueteRowData | undefined;
  surveyDetailId: string;
}

export const useSeriesEnqueteCreateModalConditions = (props: Props) => {
  const { targetData, surveyDetailId } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();

  // 検索用リスト
  const { divisions, sbus, makeSbus } = useSearchOrganizationConditions({ surveyDetailId: surveyDetailId });
  const { isSourceValid, isDestinationValid, checkSelectedValue } = useSeriesEnqueteCreateModalValidation();

  const isEditMode = useAppSelector((state: RootState) => state.enqueteCreate.isEditSbuAggregationModal);

  const [selectedModalData, setSelectedModalData] = useState<SeriesEnqueteRowData>(initSelectedRowData);
  const [selectedModalRowData, setSelectedModalRowData] = useState<SeriesSurveyFormAggregationData[]>([]);
  const [isEnableSelect, setIsEnableSelect] = useState<boolean>(false);

  const { toViewData } = useEnqueteModal();
  const { isSave, isLoading, addRowData, deleteModalRaw, searchCondition, onClickSaveButton, onClose } = useSeriesEnqueteCreateModalSetting({
    targetData,
    selectedModalData,
    setSelectedModalData,
    selectedModalRowData,
    setSelectedModalRowData,
    setIsEnableSelect,
    makeSbus,
  });

  const labelPrefix = 'enqueteCreate.seriesEnquete';
  /**
   * オートコンプリートコールバック
   * SBUを設定時は相関バリデーションを行い、バリデーションエラーの場合はスナックバーを表示し、値のセットを行わない
   * @param key セットされたパラメタのkey名
   * @param value セットされた値
   * @param list セグメント
   */
  const handleAutoCompleteChange = async (
    key: string,
    value: string | undefined,
    list: SearchOrganizationAdditionalConditionTypes[],
  ): Promise<void> => {
    // 表示用データ抽出
    const cond = searchCondition;
    const selectedSegment = list.filter((v) => v.key === value);
    let addModalData = {} as SeriesEnqueteRowData;
    switch (key) {
      case 'division':
        cond.divisionCode = value ?? '';
        await makeSbus(value ?? undefined);
        addModalData = {
          ...selectedModalData,
          ...{
            divisionCode: value ?? '',
            divisionNameJpn: (value && selectedSegment[0]?.label?.jpn) ?? '',
            divisionNameEng: (value && selectedSegment[0]?.label?.eng) ?? '',
            divisionOrganizationId: (value && selectedSegment[0]?.id) ?? '',
            destinationSbuCode: '',
            destinationSbuNameJpn: '',
            destinationSbuNameEng: '',
            destinationSbuOrganizationId: '',
            sourceSbuCode: '',
            sourceSbuNameJpn: '',
            sourceSbuNameEng: '',
            sourceSbuOrganizationId: '',
          },
        };
        // モーダル用オブジェクトに格納
        setSelectedModalData(addModalData);
        break;
      case 'destination':
        cond.sbuCode = value ?? '';
        // バリデーションチェック
        if (isDestinationValid(value, selectedModalData, selectedModalRowData)) {
          setIsEnableSelect(false);
          return;
        }
        addModalData = {
          ...selectedModalData,
          ...{
            destinationSbuCode: value ?? '',
            destinationSbuNameJpn: (value && selectedSegment[0]?.label?.jpn) ?? '',
            destinationSbuNameEng: (value && selectedSegment[0]?.label?.eng) ?? '',
            destinationSbuOrganizationId: (value && selectedSegment[0]?.id) ?? '',
          },
        };
        // モーダル用オブジェクトに格納
        setSelectedModalData(addModalData);
        break;
      case 'source':
        cond.sbuCode = value ?? '';
        // バリデーションチェック
        if (isSourceValid(value, selectedModalData, selectedModalRowData)) {
          setIsEnableSelect(false);
          return;
        }
        addModalData = {
          ...selectedModalData,
          ...{
            sourceSbuCode: value ?? '',
            sourceSbuNameJpn: (value && selectedSegment[0]?.label?.jpn) ?? '',
            sourceSbuNameEng: (value && selectedSegment[0]?.label?.eng) ?? '',
            sourceSbuOrganizationId: (value && selectedSegment[0]?.id) ?? '',
          },
        };
        // モーダル用オブジェクトに格納
        setSelectedModalData(addModalData);
        break;
    }

    checkSelectedValue(addModalData, setIsEnableSelect);
  };

  /**
   * AutoComplete構成内容定義
   * @param key
   * @param style
   * @param value
   * @param multiLabelList
   * @param isDisable
   * @returns
   */
  const makeSearchConditionProp = (
    key: string,
    style: { xs: number },
    value: string | undefined | null,
    multiLabelList: SearchOrganizationAdditionalConditionTypes[],
    isDisable: boolean = false,
  ): ModalSearchConditionProps => {
    const selectedValue = value === undefined ? null : value;
    const list = multiLabelList.map((v) => {
      return {
        ...v,
        ...{
          label: addSuffix(switchLabel(v.label.jpn, v.label.eng, isJapanese), v.key),
        },
      };
    });

    return {
      id: key,
      label: t(`${labelPrefix}.${key}`),
      style,
      type: 'autocomplete',
      props: {
        value: selectedValue,
        list,
        onChange: (value) => {
          handleAutoCompleteChange(key, value, multiLabelList);
        },
      },
      disabled: isDisable,
    };
  };

  /**
   * 組織検索要素定義
   */
  const conditions: ModalSearchConditionProps[][] = [
    [
      makeSearchConditionProp('division', { xs: 12 }, selectedModalData.divisionCode, divisions, isEditMode),
      makeSearchConditionProp('destination', { xs: 6 }, selectedModalData.destinationSbuCode, sbus, isEditMode),
      makeSearchConditionProp('source', { xs: 6 }, selectedModalData.sourceSbuCode, sbus),
    ],
  ];

  /**
   * モーダルコンテキストメニュー
   * @param params
   * @returns
   */
  const contextMenuItems: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];
    return [
      {
        name: t('button.delete'),
        action: () => {
          if (params.node) deleteModalRaw(params.node.data);
        },
      },
    ];
  };

  const saveButtons = {
    name: isEditMode ? t('button.update') : t('button.save'),
    onclick: onClickSaveButton,
    disabled: isEditMode ? !isEnableSelect : !isSave,
  };

  const addButton = {
    name: t('button.add'),
    onClick: addRowData,
    disabled: !isEnableSelect,
  };

  const viewData = toViewData(selectedModalRowData, true);

  return {
    conditions,
    contextMenuItems,
    saveButtons,
    addButton,
    onClose,
    viewData,
    isLoading,
  };
};
