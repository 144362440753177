import { useCallback, useState } from 'react';
import { TemporaryOrganizationDataTypes } from 'src/api/survey-edit/useTemporaryOrganizationApi';
import { resetError, temporaryOrganizationSlice } from 'src/features/general/enquete-create/store/temporaryOrganizationSlice';
import { useDispatch } from 'src/redux/store';

export const useTemporaryOrganization = () => {
  const dispatch = useDispatch();

  const [editRowData, setEditRowData] = useState<TemporaryOrganizationDataTypes | null>(null);

  // データリフレッシュカウンター
  const updateRefreshCounter = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.updateRefreshCounter());
  }, []); // eslint-disable-line

  // モーダル オープン
  const openEditModal = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsOpenEditModal(true));
  }, []); // eslint-disable-line

  // モーダル クローズ
  const closeEditModal = useCallback(() => {
    setEditRowData(null);
    dispatch(resetError());
    dispatch(temporaryOrganizationSlice.actions.setIsOpenEditModal(false));
  }, []); // eslint-disable-line

  // ローディング開始
  const startLoading = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsLoading(true));
  }, []); // eslint-disable-line

  // ローディング終了
  const stopLoading = useCallback(() => {
    dispatch(temporaryOrganizationSlice.actions.setIsLoading(false));
  }, []); // eslint-disable-line

  return {
    editRowData,
    setEditRowData,
    updateRefreshCounter,
    openEditModal,
    closeEditModal,
    startLoading,
    stopLoading,
  };
};
