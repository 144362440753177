import { BonsaiRole } from 'src/@types/role';
import { RootState, useAppSelector } from 'src/redux/store';
import useAuth from './useAuth';

export const usePermission = () => {
  const { user } = useAuth();

  const surveyPermission = useAppSelector((state: RootState) => state.page.permission);

  // 以下useAppSelectorを分割代入({hoge} = useAppSelector...)で記述すると人事側の画面においてinputFormを変更したタイミングで
  // memory leakを起こしてしまい、白画面になるため、分割代入は行わないようにしてください。具体例は以下
  // const { confirmRequestData, respondentTargetData } = useAppSelector((state) => state.enqueteAnswer);
  const confirmRequestData = useAppSelector((state) => state.enqueteAnswer.confirmRequestData);
  // TODO 確認者の判定も本当はpermissionAPIに寄せたい

  const userRole = user ? user.role : [];
  const userEmail = user ? user.email : '';
  const isAdmin = userRole.includes(BonsaiRole.ADMIN);
  const isViewerAdmin = userRole.includes(BonsaiRole.VIEWER_ADMIN);
  const isCreator = userRole.includes(BonsaiRole.CREATOR);
  const isOrganizationEditor = userRole.includes(BonsaiRole.ORGANIZATION_EDITOR);
  const isCustomerEditor = userRole.includes(BonsaiRole.CUSTOMER_EDITOR);
  const isOverViewCreator = userRole.includes(BonsaiRole.OVERVIEW_CREATOR);
  const isViewSelectModal: boolean = isAdmin || isViewerAdmin || (isCreator && isOverViewCreator); // アンケート作成時のモーダル振り分けで使用
  const isBonsaiAdmin: boolean = isAdmin || isViewerAdmin;
  const isBonsaiCreator: boolean = isCreator || isOverViewCreator;

  const isSurveyOwner = surveyPermission?.isOwner ?? false;
  const isRespondentManager = surveyPermission?.isRespondentManager ?? false;

  const isConfirmor = confirmRequestData?.confirmerEmail === userEmail;
  const isOwnerOrAdmin = isAdmin || isViewerAdmin || isSurveyOwner;
  const isRespondentManagerOnly = !isAdmin && !isViewerAdmin && !isSurveyOwner && isRespondentManager;

  return {
    isAdmin,
    isViewerAdmin,
    isCreator,
    isOrganizationEditor,
    isCustomerEditor,
    isOverViewCreator,
    isLoadingPermission: false,
    isConfirmor,
    isViewSelectModal,
    isBonsaiAdmin,
    isBonsaiCreator,
    isOwnerOrAdmin,
    isRespondentManagerOnly,
    isAnswerReader: surveyPermission?.visibleConditionPermission?.isAnswerReadOnly,
    isAnswerEditor: surveyPermission?.visibleConditionPermission?.isAnswerEdit,
    isDivisionReadRep: surveyPermission?.visibleConditionPermission?.isDivisionReadRep,
    allowWriteDivisions: surveyPermission?.visibleConditionPermission?.allowWriteDivisions ?? [],
    isSurveyOwner,
    isRespondentManager,
    isRespondentPerson: surveyPermission?.isRespondentPerson ?? false,
  };
};
