import { CountryEntity, RankEntity, RegionEntity, useComplementaryOrganizationApi } from 'src/api/survey-edit/useComplementaryOrganizationApi';
import { useAppDispatch } from 'src/redux/store';
import { complementaryOrganizationSlice } from '../stores/complementaryOrganizationSlice';

export const useComplementaryOrganizationDetail = () => {
  const dispatch = useAppDispatch();
  const complementaryOrganizationApi = useComplementaryOrganizationApi();

  const getCountriesByRegionCode = async (regionCode: string) => {
    // ストアの初期化
    const countries: CountryEntity[] | undefined = await complementaryOrganizationApi.getCountriesByRegionCode(regionCode);
    if (countries === undefined) return;

    return countries;
  };

  const getRegions = async () => {
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.regionData([] as RegionEntity[]));

    const regions: RegionEntity[] | undefined = await complementaryOrganizationApi.getRegions();
    if (regions === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.regionData(regions));
    return regions;
  };

  const getRanks = async () => {
    // ストアの初期化
    dispatch(complementaryOrganizationSlice.actions.rankData([] as RankEntity[]));

    const ranks: RankEntity[] | undefined = await complementaryOrganizationApi.getRanks();
    if (ranks === undefined) return;

    // ストアの設定
    dispatch(complementaryOrganizationSlice.actions.rankData(ranks));
    return ranks;
  };

  return { getCountriesByRegionCode, getRanks, getRegions };
};
