import { Grid, Typography } from '@mui/material';
import RHFDeskTopDatePicker from './RHFDeskTopDatePicker';

type RHFDatePickerProps = {
  openDateLabel: string;
  closeDateLabel: string;
  openDateRules: Object;
  closeDateRules: Object;
  title: string;
  control: Object;
  defalutOpenDate: Date | undefined | null;
  defalutCloseDate: Date | undefined | null;
  openDateDisabled?: boolean;
  closeDateDisabled: boolean;
  openDateOnChange?: () => void;
};

const RHFDatePickers = ({
  openDateLabel,
  closeDateLabel,
  title,
  control,
  defalutOpenDate,
  defalutCloseDate,
  openDateRules,
  closeDateRules,
  openDateDisabled,
  closeDateDisabled,
  openDateOnChange,
}: RHFDatePickerProps) => {
  return (
    <Grid item container xs={12} md={12} spacing={1}>
      <Grid item container alignItems="center" justifyContent="center" xs={12} md={4} sx={{ mb: 1 }}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <RHFDeskTopDatePicker
              control={control}
              name="openedAt"
              label={openDateLabel}
              rules={openDateRules}
              defaultValue={defalutOpenDate}
              disabled={openDateDisabled}
              onChange={openDateOnChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFDeskTopDatePicker
              control={control}
              name="closedAt"
              label={closeDateLabel}
              rules={closeDateRules}
              defaultValue={defalutCloseDate}
              disabled={closeDateDisabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RHFDatePickers;
