import axios, { AxiosResponse } from 'axios';
import { subMinutes } from 'date-fns';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { SurveyInformationEntity } from 'src/@types/generalEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyHeaderEntity } from 'src/api/useSurveyHeaderApi';
import { apiGateway } from 'src/config';
import {
  AnswerStatus,
  BULK_CREATE_EMAIL_BAD_REQUEST,
  BULK_CREATE_EMAIL_ETC_ERROR,
  BULK_CREATE_EMAIL_NO_ACCESS,
  BULK_CREATE_EMAIL_NO_ADDRESS,
  DisclosureLevelUpdateConfirm,
  EXEC_TIME_OUT,
  OrgLevelType,
  ORG_LEVEL_TYPE,
} from 'src/constants';
import { NetworkAccessError } from 'src/errors/errors';
import { useBearerToken } from './useBearerToken';
import { OrganizationEntity } from './useOrganizationApi';
import { SurveyDetailEntity } from './useSurveyDetailApi';

export interface SurveyEditEntity {
  companyCode: string;
  rank: string;
  targetCompanyNameJpn: string;
  targetCompanyNameEng: string;
  targetDivisionCode: string;
  targetDivisionNameJpn: string;
  targetDivisionNameEng: string;
  targetSbuCode: string;
  targetSbuNameJpn: string;
  targetSbuNameEng: string;
  targetDepartmentCode: string;
  targetDepartmentNameJpn: string;
  targetDepartmentNameEng: string;
  targetGroupCode: string;
  targetGroupNameJpn: string;
  targetGroupNameEng: string;
  regionNameJpn: string;
  regionNameEng: string;
  countryCode: string;
  countryNameJpn: string;
  countryNameEng: string;
  userNameJpn: string;
  userNameEng: string;
  personCompanyCode: string;
  personCompanyNameJpn: string;
  personCompanyNameEng: string;
  personDivisionCode: string | null;
  personDivisionNameJpn: string | null;
  personDivisionNameEng: string | null;
  personSbuCode: string | null;
  personSbuNameJpn: string | null;
  personSbuNameEng: string | null;
  personDepartmentCode: string | null;
  personDepartmentNameJpn: string | null;
  personDepartmentNameEng: string | null;
  personGroupCode: string | null;
  personGroupNameJpn: string | null;
  personGroupNameEng: string | null;
  email: string;
  bluePageEmailAddress: string | null;
  respondentPersonId: string;
  respondentTargetId: string;
  targetOrgIsDeleted: boolean | null;
  targetOrgApplyStartDate: string;
  targetOrgApplyEndDate: string;
  organizationBaseDate: string;
  isOutOfBaseDate: boolean;
  respondentPersonIsDisabled: boolean | null;
  answerStatus?: AnswerStatus;
  assignedCompanyNameJpn: string | null;
  assignedCompanyNameEng: string | null;
  sendedAt: string | null | undefined;
}

export interface SurveyEditGridEntity extends SurveyEditEntity {
  serialNumber: string;
}

export interface SurveyEditRespondentEntity {
  id?: string;
  titleJpn: string;
  titleEng: string;
  openedAt: string;
  closedAt: string;
  createdBy?: string;
  updatedBy?: string | null;
  createdCompany?: string | null;
  createdDivision?: string | null;
  surveyDetail?: SurveyDetailEntity;
  surveyDetailId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CreateGeneralEnqueateEntity {
  surveyHeader: SurveyHeaderEntity;
  surveyDetail: SurveyDetailEntity;
  respondentRequest: RespondentRequestEntity;
}

export interface RequestPersonDataListEntity {
  organizationId: string;
  respondentPersons: {
    email: string | null;
    userNameJpn: string | null;
    userNameEng: string | null;
    assignedCompanyNameJpn: string | null;
    assignedCompanyNameEng: string | null;
    companyCode: string | null;
    sbuCode: string | null;
    divisionCode: string | null;
    groupCode: string | null;
    departmentCode: string | null;
  }[];
}

export interface DeleteRespondent {
  respondentPersonId: string;
}

// 回答者一括登録データエンティティ
export interface BulkCreateRespondentPersonsEntity {
  bulkCreateRespondentPersons: BulkCreateRespondentPersonEntity[];
}
export interface BulkCreateRespondentPersonEntity {
  userId: string;
  userNameJpn: string;
  userNameEng: string;
  email: string;
  bluePageEmailAddress: string | null;
  companyCode: string;
  assignedCompanyNameJpn: string | null;
  assignedCompanyNameEng: string | null;
  sbuCode: string | null;
  divisionCode: string | null;
  departmentCode: string | null;
  groupCode: string | null;
}

// 回答者一括登録データエンティティ（個人）
export interface BulkCreateRespondentTargetsEntity {
  bulkCreateRespondentTargets: BulkCreateRespondentTargetEntity[];
}
export interface BulkCreateRespondentTargetEntity {
  userId: string;
  userNameJpn: string;
  userNameEng: string;
  email: string;
  bluePageEmailAddress: string | null;
  companyCode: string;
  assignedCompanyNameJpn: string | null;
  assignedCompanyNameEng: string | null;
  sbuCode: string | null;
  divisionCode: string | null;
  departmentCode: string | null;
  groupCode: string | null;
}

export interface SearchCondition {
  orgLevelType: string;
  companyCode?: string;
  divisionCode?: string;
  sbuCode?: string;
  departmentCode?: string;
  groupCode?: string;
  rank?: string;
  countryCode?: string;
  regionCode?: string;
  isMultiSegment?: boolean;
}

/**
 * useSurveyEdit API
 *
 * @returns
 */
export const useSurveyEditApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getBearerToken } = useBearerToken();

  /**
   * 依頼先一覧データ取得
   *
   * @returns
   */
  const getList = async (id: string): Promise<SurveyEditEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      // memo:保守T BNS-439対応にてタイムアウト時間を5分に延長
      const result = await axios.get<SurveyEditEntity[] | undefined>(apiGateway + `/survey-edit/respondent/${id}`, {
        headers: {
          Authorization: token,
        },
        timeout: EXEC_TIME_OUT,
      });

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedGetSurveyList'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * ID条件でデータ取得
   *
   * @param respondentRequestId
   * @returns
   */
  const getListById = async (respondentRequestId: string): Promise<RespondentRequestEntity> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<RespondentRequestEntity | undefined>(`${apiGateway}/survey-edit/${respondentRequestId}`, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      if (result.data === undefined) throw new Error();
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new NetworkAccessError(error.response?.status);
      }
      throw error;
    }
  };

  /**
   * アンケート情報更新
   *
   * @param id
   * @param data
   * @returns
   */
  const setSurveyDataById = async (data: SurveyInformationEntity, disclosureLevelUpdateConfirm: DisclosureLevelUpdateConfirm): Promise<boolean> => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<SurveyInformationEntity | undefined>(
        apiGateway + '/survey-edit/header-detail/' + data.respondentRequestId,
        {
          updateDisclosureLevelDto: {
            surveyDetailId: data.surveyDetailId,
            disclosureLevel: data.disclosureLevel,
            disclosureLevelUpdateConfirm: disclosureLevelUpdateConfirm,
          },
          updateSurveyEditPageHeaderDto: {
            titleJpn: data.titleJpn,
            titleEng: data.titleEng,
            openedAt: new Date(data.openedAt),
            closedAt: new Date(data.closedAt),
            targetDate: new Date(data.targetDate ?? ''),
          },
        },
        {
          headers: {
            Authorization: token,
          },
          timeout: 60000,
        },
      );

      if (result === undefined) return false;

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedUpdateSurveyData'), { variant: 'error' });
      } else {
        throw error;
      }

      return false;
    }
  };

  /**
   * アンケート中止（依頼終了日時を強制的に現在日付の１分前に更新）します
   *
   * @param respondentRequestId
   * @returns
   */
  const surveyCancel = async (respondentRequestId: string): Promise<RespondentRequestEntity | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.put<RespondentRequestEntity | undefined>(
        apiGateway + '/survey-edit/header/' + respondentRequestId,
        { closedAt: subMinutes(new Date(), 1) },
        {
          headers: {
            Authorization: token,
          },
          timeout: 60000,
        },
      );

      return result?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedCancel'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 汎用アンケート新規作成
   *   isAxiosError=true: enqueueSnackbar, return undefined
   *   response.data=undefiend | null: throw Error
   *
   * @param RespondentRequestEntity
   * @returns 汎用アンケート新規作成結果
   */
  const setSurveyDataCreate = async (entity: RespondentRequestEntity): Promise<CreateGeneralEnqueateEntity | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.post<{
        surveyHeader: SurveyHeaderEntity;
        surveyDetail: SurveyDetailEntity;
        respondentRequest: RespondentRequestEntity;
        surveyOwnerEmails: string[];
      }>(
        apiGateway + '/survey-edit/create',
        {
          surveyHeader: entity.surveyDetail?.surveyHeader,
          surveyDetail: { surveyFormData: entity.surveyDetail?.surveyFormData, disclosureLevel: entity.surveyDetail?.disclosureLevel },
          respondentRequest: {
            titleJpn: entity.titleJpn,
            titleEng: entity.titleEng,
            targetDate: entity.targetDate,
            openedAt: entity.openedAt,
            closedAt: entity.closedAt,
            organizationBaseDate: entity.organizationBaseDate,
          },
          surveyOwnerEmails: entity.surveyOwnerEmails,
        },
        { headers: { Authorization: token }, timeout: 60000 },
      );
      if (!result.data) throw new Error(t('apiExceptionMessage.useSurveyEditApi.failedSetEnqueteDataCreate'));
      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedSetEnqueteDataCreate'), { variant: 'error' });
        return;
      }
      throw error;
    }
  };

  /**
   * 回答を依頼する宛先を登録するAPI
   *
   * @param requestPersonDataList
   * @returns
   */
  const addRequestPersonDataList = async (respondentRequestId: string, data: { respondentTargets: RequestPersonDataListEntity[] }) => {
    try {
      // 認証情報の取得
      const token = await getBearerToken();

      // データ登録
      await axios.post(apiGateway + `/survey-edit/multi-create/${respondentRequestId}`, data, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      // 戻り値
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRequestTargetApi.failedAddRespondent'), { variant: 'error' });
      } else {
        throw error;
      }

      return false;
    }
  };

  /**
   * アンケートの依頼者を削除
   */
  const deleteRespondent = async (RespondentPersonId: DeleteRespondent) => {
    try {
      // 認証情報の取得
      const token = await getBearerToken();

      await axios.put<DeleteRespondent[]>(apiGateway + '/survey-edit/remove-cascade/' + RespondentPersonId, null, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      // 戻り値
      return true;
    } catch (error) {
      enqueueSnackbar(t('apiExceptionMessage.useRespondentPersonApi.failedDeleteData'), { variant: 'error' });
      return false;
    }
  };

  /**
   * 宛先一括インポート用ファイルのエクスポート
   *
   * @param respondentRequestId
   * @param orgIds: 組織マスタのID群
   * @param lang
   * @returns
   */
  const download = async (respondentRequestId: string, orgIds: string[], lang: 'jpn' | 'eng'): Promise<boolean | undefined> => {
    try {
      const token = await getBearerToken();

      const res = await axios.post(
        `${apiGateway}/survey-edit/respondent/file/download/${respondentRequestId}`,
        { lang, orgIds },
        {
          responseType: 'blob',
          headers: { Authorization: token },
        },
      );
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'respondent.xlsx';
      a.click();
      a.remove();
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedExport'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答依頼メールを送信
   *
   * @param respondentRequestId
   * @param formData
   */
  const sendAnswerRequest = async (respondentRequestId: string, formData: FormData): Promise<boolean> => {
    try {
      const token = await getBearerToken();
      const result = await axios({
        url: `${apiGateway}/survey-edit/send-mail/${respondentRequestId}`,
        method: 'POST',
        data: formData,
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
      });

      if (result === undefined) return false;

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.message === 'E210002') {
          enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedTotalFileSizeExceeded'), { variant: 'error' });
        } else if (error.response?.data.message === 'E600100') {
          enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedEmptyRespondent'), { variant: 'error' });
        } else if (error.response?.data.message === 'E600110') {
          enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedEmptySurveyOwner'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedSendAnswerRequest'), { variant: 'error' });
        }
      } else {
        throw error;
      }

      return false;
    }
  };

  /**
   * ファイル エクスポート
   *
   * @param respondentRequestId
   * @param lang (バックエンド側で使用していないもよう[2023/02/24])
   * @returns
   */
  const exportFile = async (respondentRequestId: string, lang: 'jpn' | 'eng'): Promise<boolean> => {
    try {
      const token = await getBearerToken();
      await axios({
        url: apiGateway + '/survey-edit/respondent/download/' + respondentRequestId + '?lang=' + lang,
        method: 'POST',
        responseType: 'blob',
        headers: { Authorization: token },
      }).then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `respondent.xlsx`;
        a.click();
        a.remove();
      });

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRespondentRequestApi.failedExport'), { variant: 'error' });
      } else {
        throw error;
      }

      return false;
    }
  };

  /**
   * ファイル インポート
   *
   * @param respondentRequestId
   * @param file
   * @returns
   */
  const importFile = async (respondentRequestId: string, file: any): Promise<AxiosResponse<any> | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios({
        url: apiGateway + `/survey-edit/respondent/upload/${respondentRequestId}`,
        method: 'POST',
        data: file,
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      } else {
        throw error;
      }
    }
  };

  /**
   * アンケート詳細データ更新（１件）
   *
   * @param SurveyDetailEntity
   * @returns アンケート詳細データ更新　更新内容
   *
   */
  const setSurveyDetailDataUpdate = async (surveyDetailEntity: SurveyDetailEntity): Promise<any> => {
    const token = await getBearerToken();
    const result = await axios.put(
      apiGateway + '/survey-edit/survey-detail/' + surveyDetailEntity.id,
      {
        id: surveyDetailEntity.id,
        version: surveyDetailEntity.version,
        surveyFormData: surveyDetailEntity.surveyFormData,
        surveyHeaderId: surveyDetailEntity.surveyHeaderId,
        isDeleted: false,
      },
      {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      },
    );
    if (result === null) {
      throw axios.isAxiosError(t('apiExceptionMessage.useSurveyDetailApi.failedUpdateDetailData'));
    }
    return result;
  };

  /**
   * 回答者一括登録 API 呼び出し
   *
   * @param respondentTargetId 回答対象ID
   * @param data 回答者一括登録データエンティティの配列
   * @returns
   */
  const bulkCreate = async (respondentTargetId: string, data: BulkCreateRespondentPersonsEntity) => {
    try {
      const token = await getBearerToken();
      await axios.post(apiGateway + `/survey-edit/respondent-person/bulk/${respondentTargetId}`, data, {
        headers: { Authorization: token },
        timeout: 60000,
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRespondentPersonApi.failedBulkCreate'), { variant: 'error' });
      } else {
        throw error;
      }
      return false;
    }
  };

  /**
   * 仮組織データ ファイルエクスポート
   *
   * @param surveyDetailId
   * @param lang
   * @returns
   */
  const exportFileTemporaryOrganizations = async (surveyDetailId: string, lang: 'jpn' | 'eng'): Promise<boolean> => {
    try {
      const token = await getBearerToken();
      await axios({
        url: apiGateway + '/survey-edit/temporary-organizations/download/' + surveyDetailId + '?lang=' + lang,
        method: 'POST',
        responseType: 'blob',
        headers: { Authorization: token },
      }).then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `temporary_organization.xlsx`;
        a.click();
        a.remove();
      });

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedExportTemporaryOrganizations'), { variant: 'error' });
      } else {
        throw error;
      }

      return false;
    }
  };

  /**
   * 仮組織データ ファイルインポート
   *
   * @param surveyDetailId
   * @param file
   * @returns
   */
  const importFileTemporaryOrganizations = async (surveyDetailId: string, file: any): Promise<AxiosResponse<any> | undefined> => {
    try {
      // リクエスト
      const token = await getBearerToken();
      const response = await axios({
        url: apiGateway + `/survey-edit/temporary-organizations/upload/${surveyDetailId}`,
        method: 'POST',
        data: file,
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      } else {
        throw error;
      }
    }
  };

  /**
   * 回答者の一括削除を行う
   *
   * @param respondentRequestId 対象の回答依頼ID
   * @param respondentPersonIds 削除対象の回答者IDの配列
   * @returns boolean
   */
  const bulkDeleteRespondent = async (respondentRequestId: string, respondentPersonIds: string[]) => {
    try {
      const token = await getBearerToken();
      await axios.put(
        apiGateway + `/survey-edit/respondent/bulk-delete/${respondentRequestId}`,
        { respondentPersonIds },
        { headers: { Authorization: token }, timeout: 60000 },
      );
      enqueueSnackbar(t('message.deleted'), { variant: 'success' });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedUpdate', { target: t('common.respondent') }), { variant: 'error' });
      } else {
        throw error;
      }
      return false;
    }
  };

  /**
   * Emailアドレスによる回答者の一括登録を行う
   *
   * @param respondentRequestId 対象の回答依頼ID
   * @param emails 回答者として登録するユーザーのemailの配列
   * @returns boolean
   */
  const bulkCreateRespondentByEmail = async (respondentRequestId: string, emails: string[]) => {
    try {
      const token = await getBearerToken();
      await axios.post(
        apiGateway + `/survey-edit/respondent-person/bulk/email/${respondentRequestId}`,
        { emails },
        // memo:timeout時間は開発環境にて3000件一括登録時にタイムアウトしない時間を設定（3分）
        // memo:BNS-439対応のためタイムアウト時間を5分に設定。また、上限を5,000件に変更
        { headers: { Authorization: token }, timeout: EXEC_TIME_OUT },
      );
      enqueueSnackbar(t('message.register'), { variant: 'success' });
      return undefined;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedRegister', { target: t('common.respondent') }), { variant: 'error' });
        switch (error.response?.status.toString()) {
          case BULK_CREATE_EMAIL_BAD_REQUEST:
            return { status: BULK_CREATE_EMAIL_BAD_REQUEST, response: error.response };
          case BULK_CREATE_EMAIL_NO_ACCESS:
            return { status: BULK_CREATE_EMAIL_NO_ACCESS, response: error.response };
          case BULK_CREATE_EMAIL_NO_ADDRESS:
            return { status: BULK_CREATE_EMAIL_NO_ADDRESS, response: error.response };
          default:
            return { status: BULK_CREATE_EMAIL_ETC_ERROR, response: error.response };
        }
      } else {
        return { status: BULK_CREATE_EMAIL_ETC_ERROR, response: undefined };
      }
    }
  };

  /**
   * 回答者の回答対象組織を更新する（組織）
   *
   * @param respondentTargetId 変更元の回答対象ID
   * @param data 更新情報 (変更先の回答対象に設定される組織IDと回答者の配列のオブジェクト)
   * @returns
   */
  const updateRespondentTarget = async (respondentTargetId: string, data: { organizationId: string; respondentPersonIds: string[] }) => {
    try {
      const token = await getBearerToken();
      await axios.post(
        apiGateway + `/survey-edit/respondent/multi-update/${respondentTargetId}`,
        { ...data },
        { headers: { Authorization: token }, timeout: 60000 },
      );
      enqueueSnackbar(t('message.update'), { variant: 'success' });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useRequestTargetApi.failedUpdateRespondent'), { variant: 'error' });
      } else {
        throw error;
      }
      return false;
    }
  };

  const exportCustomer = async (respondentRequestId: string, lang: string) => {
    try {
      const token = await getBearerToken();
      await axios
        .get(apiGateway + '/survey-edit/download-customer-master/' + respondentRequestId + '?lang=' + lang, {
          responseType: 'blob',
          headers: { Authorization: token },
        })
        .then((res) => {
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `customer.xlsx`;
          a.click();
          a.remove();
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useEnqueteStatusPageApi.failedExport'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 仮組織を含む組織情報を取得する
   * @param surveyDetailId
   * @param SearchCondition
   * @returns Organization[]
   */
  const getOrganizationsIncludingTemporary = async (
    surveyDetailId: string,
    searchOrgData: SearchCondition,
  ): Promise<OrganizationEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<OrganizationEntity[] | undefined>(apiGateway + `/survey-edit/organizations/${surveyDetailId}`, {
        headers: { Authorization: token },
        params: { ...searchOrgData },
        timeout: 60000,
      });

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.organization') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  /**
   * 仮組織を含まない組織情報を取得する
   * @param surveyDetailId
   * @param isUseOrganizationBaseDate
   * @param orgLevelType
   * @param divisionCode
   * @param sbuCode
   * @param departmentCode
   * @returns Organization[]
   */
  const getOrganizationExcludeTemporary = async (
    surveyDetailId: string,
    orgLevelType: OrgLevelType = ORG_LEVEL_TYPE.COMPANY,
    isUseOrganizationBaseDate: boolean = true,
    divisionCode?: string,
    sbuCode?: string,
    departmentCode?: string,
  ): Promise<OrganizationEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<OrganizationEntity[] | undefined>(
        apiGateway + `/survey-edit/exclude-temporary-organizations/${surveyDetailId}`,
        {
          headers: { Authorization: token },
          params: { orgLevelType, isUseOrganizationBaseDate, divisionCode, sbuCode, departmentCode },
          timeout: 60000,
        },
      );

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.organization') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getList,
    getListById,
    setSurveyDataById,
    surveyCancel,
    setSurveyDataCreate,
    addRequestPersonDataList,
    deleteRespondent,
    download,
    sendAnswerRequest,
    exportFile,
    importFile,
    setSurveyDetailDataUpdate,
    bulkCreate,
    exportFileTemporaryOrganizations,
    importFileTemporaryOrganizations,
    bulkDeleteRespondent,
    bulkCreateRespondentByEmail,
    updateRespondentTarget,
    exportCustomer,
    getOrganizationsIncludingTemporary,
    getOrganizationExcludeTemporary,
  };
};
