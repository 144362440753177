import { DateTime } from 'luxon';

//日時データフォーマット
export const dateFormat: string = 'yyyy/MM/dd';
export const dateTimeFormat: string = 'yyyy/MM/dd HH:mm';
export const timeResetDateTimeFormat: string = 'yyyy/MM/dd 00:00';
export const yearMonthFormat: string = 'yyyy/MM';

//日時データをフォーマット化して返却
export const formatterDateTime = (date: string | null | undefined, dateFormat: string = dateTimeFormat) => {
  if (date === null || date === undefined || date === '') return '';

  return DateTime.fromISO(date).toFormat(dateFormat);
};

//文字列で返却
export const dateToString = (date: Date) => {
  return DateTime.fromJSDate(date).toString();
};

//UTC Date型データにタイムゾーン差分の時間付与（DBがDate型である場合の対処）
export const conversionDateWithLocalHour = (d: string | Date) => {
  const date = typeof d !== 'string' ? d : new Date(d);
  return DateTime.fromJSDate(date)
    .plus({ hours: DateTime.local().offset / 60 })
    .toJSDate();
};

//UTC Date型データのタイムゾーン差分の時間削除（DBがDate型である場合の対処）
export const conversionDateWithoutLocalHour = (d: string | Date) => {
  const date = typeof d !== 'string' ? d : new Date(d);
  return DateTime.fromJSDate(date)
    .minus({ hours: DateTime.local().offset / 60 })
    .toJSDate();
};

//UTC UTC時刻のstring型データに変換
//（DBがDate型である場合の対処。conversionDateWithLocalHour使用あとに、リロードなく継続して値を編集するためにUTCに戻す）
export const conversionUtcDate = (d: string) => {
  return DateTime.fromJSDate(new Date(d)).toUTC().toString();
};

//UTC UTC時刻のDate型データに変換
export const conversionUtcTypeDate = (d: string) => {
  return DateTime.fromJSDate(new Date(d)).toUTC().toJSDate();
};
