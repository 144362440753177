import { useSnackbar } from 'notistack5';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { HeaderBreadcrumbsProps } from 'src/components/HeaderBreadcrumbs';
import { EnquetePageComponent } from 'src/components/app-components/bonsai/EnquetePageComponent';
import { ENQUETE_REQUEST_PATH } from '../../enquete-request/routes/path';
import { EnqueteCreateLayout } from '../components/pages/create/components/EnqueteCreateLayout ';
import { TabValue } from '../constants';
import { getValidTabValue } from '../utils/getValidTabValue';

export interface EnqueteCreatePageProps {}

export interface NotificateStateTypes {
  duplicated?: boolean;
}

export const EnqueteCreatePage = () => {
  const { id } = useParams();
  const urlParams = useLocation().search;
  const tabParam: string | null = new URLSearchParams(urlParams).get('tab');
  const selectedTabValue: TabValue = getValidTabValue(tabParam);
  const linkState: unknown = useLocation().state;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const createdListTitle = t('enqueteRequest.pageTitle');
  const createTitle = t('enqueteCreate.addNewEnquete');
  const editTitle = t('enqueteCreate.pageTitle');
  const title = id ? editTitle : createTitle;
  const permissionParam = { respondentRequestId: id };

  const breadCrumbs: HeaderBreadcrumbsProps = {
    heading: title,
    links: [
      { name: 'TOP', href: '/top' },
      { name: createdListTitle, href: ENQUETE_REQUEST_PATH.INDEX },
    ],
    activeLast: true,
  };

  //通知
  const notificateDuplicateSurvey = () => {
    //useNavigateのステートを変数へ格納
    if (typeof linkState !== 'object' || linkState === null) return;

    const state = linkState as NotificateStateTypes;
    if (state.duplicated !== undefined && state.duplicated === true)
      enqueueSnackbar(t('enqueteCreate.message.duplicatedSurvey'), { variant: 'success' });
  };

  useEffect(() => {
    notificateDuplicateSurvey();
  }, []); //eslint-disable-line

  return (
    <EnquetePageComponent permissionParam={permissionParam}>
      <EnqueteCreateLayout id={id} pageTitle={title} breadCrumbs={breadCrumbs} selectTabValue={selectedTabValue} />
    </EnquetePageComponent>
  );
};
