import { ColDef, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import useSettings from 'src/hooks/useSettings';
import { RootState, useAppSelector } from 'src/redux/store';
import useTemporaryOrganizationGrid from '../../../tab-containers/shared-tabs/temporary-organization/hooks/useTemporaryOrganizationGrid';

export const useOutsideTemporaryOrganizationsGrid = () => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const { t } = useTranslation();

  const { defaultColDef, orgListWithCodeColdef } = useTemporaryOrganizationGrid(false);

  let duplicateWarnTemporaryOrganizationColDef: ColDef[] = [
    {
      field: 'invalidationReason',
      headerName: t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.title'),
      headerTooltip: t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.title'),
      minWidth: 100,
      tooltipValueGetter: (params: ITooltipParams) => {
        return params.data.invalidationReason === 3
          ? t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.temporaryOrganizationsRespondentNotSet')
          : t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.temporaryOrganizationsOffOrganizationBaseDate');
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.invalidationReason === 3
          ? t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.temporaryOrganizationsRespondentNotSet')
          : t('enqueteCreate.duplicationWarnMessageModal.invalidationReason.temporaryOrganizationsOffOrganizationBaseDate');
      },
    },
    ...orgListWithCodeColdef,
  ];

  // 3点ドット列を削除
  duplicateWarnTemporaryOrganizationColDef.pop();

  const isLoading = useAppSelector((state: RootState) => state.temporaryOrganization.isLoading);

  return {
    isLight,
    isLoading,
    defaultColDef,
    duplicateWarnTemporaryOrganizationColDef,
  };
};
