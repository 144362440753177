import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { getBearerToken } from 'src/utils/getBearerToken';
import { OrganizationEntity } from '../useOrganizationApi';

/**
 * useSurveyEditRespondent API
 *
 * @returns
 */
export const useSurveyEditRespondentApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * 取得キーワードを元に組織データから一致する組織を取得する
   * @param surveyDetailId
   * @param keyword
   * @returns Organization[]
   */
  const findOrganizationByKeyword = async (surveyDetailId: string, keyword?: string): Promise<OrganizationEntity[] | undefined> => {
    try {
      const token = await getBearerToken();
      const result = await axios.get<OrganizationEntity[] | undefined>(apiGateway + `/survey-edit/respondent/organizations/${surveyDetailId}`, {
        headers: { Authorization: token },
        params: { keyword },
        timeout: 60000,
      });

      if (result === undefined) return;

      return result.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.common.failedFetch', { target: t('common.organization') }), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    findOrganizationByKeyword,
  };
};
