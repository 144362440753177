import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useUserApi } from 'src/api/useUserApi';
import { DivisionRegionEditorDataTypes, ResponseVisibleConditionTypes } from 'src/api/useVisibleConditionsApi';
import { divisionRegionEditorSlice } from 'src/features/general/enquete-create/store/divisionRegionEditorSlice';
import { initDivisionRegionEditorData } from '../type/division-region-editor.type';

export const useDivisionRegionEditor = () => {
  const dispatch = useDispatch();
  const userApi = useUserApi();

  /***********************************************************************************
   * Functions
   ***********************************************************************************/
  // データリフレッシュカウンター
  const updateRefreshCounter = useCallback(() => {
    dispatch(divisionRegionEditorSlice.actions.updateRefreshCounter());
  }, []); // eslint-disable-line

  // モーダル オープン
  const openEditModal = useCallback(() => {
    dispatch(divisionRegionEditorSlice.actions.setIsOpenEditModal(true));
  }, []); // eslint-disable-line

  // モーダル クローズ
  const closeEditModal = useCallback(() => {
    dispatch(divisionRegionEditorSlice.actions.setIsOpenEditModal(false));
  }, []); // eslint-disable-line

  // ローディング開始
  const startLoading = useCallback(() => {
    dispatch(divisionRegionEditorSlice.actions.setIsLoading(true));
  }, []); // eslint-disable-line

  // ローディング終了
  const stopLoading = useCallback(() => {
    dispatch(divisionRegionEditorSlice.actions.setIsLoading(false));
  }, []); // eslint-disable-line

  // ユーザー名を解決
  const resoleveUserNames = async (dataList: ResponseVisibleConditionTypes[]) => {
    const baseRow = initDivisionRegionEditorData;
    let resolveList: DivisionRegionEditorDataTypes[] = [];

    const dataListChunks = _.chunk(dataList, 45);
    for await (let dataListChunk of dataListChunks) {
      const dataListChunkEMail = dataListChunk.map((dataListChunk) => dataListChunk.email);
      const userInfoList = await userApi.getByEmail(dataListChunkEMail, null);
      if (userInfoList) {
        for await (let userInfo of userInfoList) {
          const updateProperty = {
            userNameJpn: userInfo.fullNameJpn ? userInfo.fullNameJpn : '',
            userNameEng: userInfo.fullNameEng ? userInfo.fullNameEng : '',
            userCompanyCode: userInfo.companyCode ? userInfo.companyCode : '',
            userCompanyNameJpn: userInfo.companyNameJpn ? userInfo.companyNameJpn : '',
            userCompanyNameEng: userInfo.companyNameEng ? userInfo.companyNameEng : '',
            userCompanyAbbreviation: userInfo.companyAbbreviation ?? '',
            assignedCompanyNameJpn: userInfo.assignedCompanyNameJpn ? userInfo.assignedCompanyNameJpn : '',
            assignedCompanyNameEng: userInfo.assignedCompanyNameEng ? userInfo.assignedCompanyNameEng : '',
            userDivisionCode: userInfo.divisionCode ? userInfo.divisionCode : '',
            userDivisionNameJpn: userInfo.divisionNameJpn ? userInfo.divisionNameJpn : '',
            userDivisionNameEng: userInfo.divisionNameEng ? userInfo.divisionNameEng : '',
            userSbuCode: userInfo.sbuCode ? userInfo.sbuCode : '',
            userSbuNameJpn: userInfo.sbuNameJpn ? userInfo.sbuNameJpn : '',
            userSbuNameEng: userInfo.sbuNameEng ? userInfo.sbuNameEng : '',
            userDepartmentCode: userInfo.departmentCode ? userInfo.departmentCode : '',
            userDepartmentNameJpn: userInfo.departmentNameJpn ? userInfo.departmentNameJpn : '',
            userDepartmentNameEng: userInfo.departmentNameEng ? userInfo.departmentNameEng : '',
            userGroupCode: userInfo.groupCode ? userInfo.groupCode : '',
            userGroupNameJpn: userInfo.groupNameJpn ? userInfo.groupNameJpn : '',
            userGroupNameEng: userInfo.groupNameEng ? userInfo.groupNameEng : '',
          };

          const dataList = dataListChunk.filter((d) => d.email === userInfo.email);

          dataList.forEach((data) => {
            const resolve: DivisionRegionEditorDataTypes = {
              ...baseRow,
              ...data,
              ...updateProperty,
            };
            resolveList.push(resolve);
          });
        }
      }
    }
    return resolveList.length > 0 ? resolveList : [initDivisionRegionEditorData];
  };

  return { updateRefreshCounter, openEditModal, closeEditModal, startLoading, stopLoading, resoleveUserNames };
};
