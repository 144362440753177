import { subHours } from 'date-fns';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  ComplementaryOrganizationEntity,
  CountryEntity,
  OmitField,
  useComplementaryOrganizationApi,
} from 'src/api/survey-edit/useComplementaryOrganizationApi';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { OrgLevelType } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import { useAppSelector } from 'src/redux/store';
import { Country, Organization } from '../components/ComplementaryOrganizationDetail';
import { useComplementaryOrganizationDetail } from './useComplementaryOrganizationDetail';

export const ORG_LEVEL_TYPE = ['COMPANY', 'DIVISION', 'SBU', 'DEPARTMENT', 'GROUP'] as const;
export type TOrgLevelType = typeof ORG_LEVEL_TYPE[number];

export const useOrganizationDetail = (
  methods: UseFormReturn,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOptions: React.Dispatch<React.SetStateAction<string[]>>,
  setInputOrganization: React.Dispatch<React.SetStateAction<string>>,
  setCompanies: React.Dispatch<React.SetStateAction<string[]>>,
  setInputCompany: React.Dispatch<React.SetStateAction<string>>,
  organizationBaseDate: Date,
) => {
  const { getOrgLevelType, getComplementaryOrganizationDetail } = useComplementaryOrganizationApi();
  const [targetOrganizationList, setTargetOrganizationList] = useState<Organization[]>([]);
  const [targetCompanyList, setTargetCompanyList] = useState<Organization[]>([]);
  const [targetCountryList, setTargetCountryList] = useState<Country[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const companyType = new URLSearchParams(useLocation().search).get('companytype');
  const [isGroupCompany, setIsGroupCompany] = useState<boolean>(companyType === 'group');

  const { setValue, getValues } = methods;

  const hookDetail = useComplementaryOrganizationDetail();

  const regions = useAppSelector((state) => state.complementaryOrganization.regionData);
  const ranks = useAppSelector((state) => state.complementaryOrganization.rankData);
  const { currentLang } = useLocales();

  const changeUpperOrgLevelType = useCallback((_orgLevelType: TOrgLevelType) => {
    if (_orgLevelType === 'DIVISION') {
      return 'COMPANY';
    } else if (_orgLevelType === 'SBU') {
      return 'DIVISION';
    } else if (_orgLevelType === 'DEPARTMENT') {
      return 'SBU';
    } else if (_orgLevelType === 'GROUP') {
      return 'DEPARTMENT';
    }
    return 'COMPANY';
  }, []);

  const convertManagedOrgLevelType = useCallback((data: ComplementaryOrganizationEntity) => {
    if (data.groupCode) return 'GROUP';
    if (data.departmentCode) return 'DEPARTMENT';
    if (data.sbuCode) return 'SBU';
    return 'DIVISION';
  }, []);

  const regionsItems =
    Object.keys(regions).length === 0
      ? []
      : regions.map((x) => {
          return { key: x.regionCode || '', name: currentLang.value === 'ja' ? x.regionNameJpn || '' : x.regionNameEng || '' };
        });

  const rankItems =
    Object.keys(ranks).length === 0
      ? []
      : ranks.map((x) => {
          return { key: x.rank || '', name: x.rank || '' };
        });

  //表示用文字列作成
  const makeDisplayOrgData = useCallback(
    (org: Omit<OrganizationEntity, OmitField>) => {
      let strJpn = '';
      let strEng = '';

      if (org.divisionCode) {
        strJpn += `${org.divisionCode} : ${org.divisionNameJpn}`;
        strEng += `${org.divisionCode} : ${org.divisionNameEng}`;
      }
      if (org.sbuCode) {
        strJpn += ` - ${org.sbuCode} : ${org.sbuNameJpn}`;
        strEng += ` - ${org.sbuCode} : ${org.sbuNameEng}`;
      }
      if (org.departmentCode) {
        strJpn += ` - ${org.departmentCode} : ${org.departmentNameJpn}`;
        strEng += ` - ${org.departmentCode} : ${org.departmentNameEng}`;
      }
      if (org.groupCode) {
        strJpn += ` - ${org.groupCode} : ${org.groupNameJpn}`;
        strEng += ` - ${org.groupCode} : ${org.groupNameEng}`;
      }
      return currentLang.value === 'ja' ? { key: strJpn ? strJpn : '-', entity: org } : { key: strEng ? strEng : '-', entity: org };
    },
    [currentLang.value],
  );

  //表示用文字列作成
  const makeDisplayCountryData = useCallback(
    (country: CountryEntity) => {
      const strJpn = `${country.countryNameEng} (${country.countryNameJpn})`;
      const strEng = country.countryNameEng;

      return currentLang.value === 'ja' ? { key: strJpn, entity: country } : { key: strEng, entity: country };
    },
    [currentLang.value],
  );

  // 組織データ取得
  const getOrganizations = useCallback(
    async (orgLevelType: OrgLevelType, organizationBaseDate: Date, isS500Only: boolean = true) => {
      if (!orgLevelType || !organizationBaseDate) {
        return;
      }
      setIsLoading(true);
      // 組織情報の取得
      const orgs = await getOrgLevelType(orgLevelType, organizationBaseDate, isS500Only);
      if (!orgs) return setTargetOrganizationList([]);
      // 表示用文字列作成
      const list = orgs.map((org: OrganizationEntity) => makeDisplayOrgData(org));
      // 保存
      setTargetOrganizationList(list);
      setOptions(list.map((x: any) => x.key));

      // NOTE: 上位組織が会社（つまり「本部」の補完組織の場合、上位組織として「-」を表示する
      if (orgLevelType === 'COMPANY') {
        setValue('upperOrg', '-');
        setInputOrganization('-');
      } else {
        setValue('upperOrg', '');
        setInputOrganization('');
      }
      setIsLoading(false);
    },
    [getOrgLevelType, makeDisplayOrgData, setInputOrganization, setIsLoading, setOptions, setValue],
  );

  //表示用文字列作成
  const createCompanyKey = useCallback(
    (org: Omit<OrganizationEntity, OmitField>) => {
      let strJpn = '';
      let strEng = '';
      strJpn += `${org.companyCode} : ${org.companyNameJpn}`;
      strEng += `${org.companyCode} : ${org.companyNameEng}`;
      if (org.companyAbbreviation) {
        strJpn += `(${org.companyAbbreviation})`;
        strEng += `(${org.companyAbbreviation})`;
      }
      return currentLang.value === 'ja' ? strJpn : strEng;
    },
    [currentLang.value],
  );

  //表示用文字列作成
  const makeDisplayCompanyData = useCallback(
    (org: Omit<OrganizationEntity, OmitField>) => {
      return { key: createCompanyKey(org), entity: org };
    },
    [createCompanyKey],
  );

  const getCompanyOrganizations = useCallback(
    async (organizationBaseDate: Date) => {
      if (!organizationBaseDate) {
        return;
      }
      setIsLoading(true);
      // 組織情報の取得
      // グループに対する会社組織を取得する場合は豊通(S500を外す)
      const orgs = await getOrgLevelType(ORG_LEVEL_TYPE[0], organizationBaseDate, false);
      if (!orgs) return setTargetCompanyList([]);

      // 表示用文字列作成
      const list = orgs.map((org: OrganizationEntity) => makeDisplayCompanyData(org));
      // 保存
      setTargetCompanyList(list);
      setCompanies(list.map((x: any) => x.key));
      setValue('companyCode', '');
      setInputCompany('');
      setIsLoading(false);
    },
    [getOrgLevelType, makeDisplayCompanyData, setCompanies, setInputCompany, setIsLoading, setValue],
  );

  const setOrganizationDate = async (key: string) => {
    const upperOrgData = targetOrganizationList.find((x: Organization) => x.key === key)?.entity;
    if (upperOrgData) {
      setValue('applyStartDate', subHours(new Date(upperOrgData.applyStartDate), 9)); // UTC時間として登録する
      setValue('applyEndDate', subHours(new Date(upperOrgData.applyEndDate), 9)); // UTC時間として登録する
    }
  };

  const changeCountry = useCallback(
    async (regionCode: string) => {
      const results = (await hookDetail.getCountriesByRegionCode(regionCode)) || [];
      const countries = results.map((country) => makeDisplayCountryData(country));
      setValue('country', '');
      setTargetCountryList(countries);
      const options = countries.map((x: any) => x.key) || [];
      setCountriesOptions(options);
    },
    [hookDetail, makeDisplayCountryData],
  );

  const getDetail = useCallback(
    async (id: string) => {
      const data: ComplementaryOrganizationEntity | undefined = await getComplementaryOrganizationDetail(id);
      if (data !== undefined) {
        const dataOrgLevelType = data.orgLevelType || 'DIVISION';
        const isGroupCompany = dataOrgLevelType === 'COMPANY';
        setIsGroupCompany(isGroupCompany);

        if (isGroupCompany) {
          setValue('code', data.companyCode);
          setValue('jpn', data.companyNameJpn);
          setValue('eng', data.companyNameEng);
        } else if (dataOrgLevelType === 'DIVISION') {
          setValue('code', data.divisionCode);
          setValue('jpn', data.divisionNameJpn);
          setValue('eng', data.divisionNameEng);
        } else if (dataOrgLevelType === 'SBU') {
          setValue('code', data.sbuCode);
          setValue('jpn', data.sbuNameJpn);
          setValue('eng', data.sbuNameEng);
        } else if (dataOrgLevelType === 'DEPARTMENT') {
          setValue('code', data.departmentCode);
          setValue('jpn', data.departmentNameJpn);
          setValue('eng', data.departmentNameEng);
        } else if (dataOrgLevelType === 'GROUP') {
          setValue('code', data.groupCode);
          setValue('jpn', data.groupNameJpn);
          setValue('eng', data.groupNameEng);
        }
        setValue('regionCode', data.regionCode);
        setValue('updateData', data);
        await changeCountry(data.regionCode || '');

        const targetCountry: CountryEntity = {
          countryCode: data.countryCode,
          countryNameJpn: data.countryNameJpn,
          countryNameEng: data.countryNameEng,
          regionCode: data.regionCode,
        };

        setValue('rank', data.rank);

        const managedOrgLevelType = convertManagedOrgLevelType(data);

        setValue('managedOrgLevelType', managedOrgLevelType);

        if (isGroupCompany) {
          await getOrganizations(managedOrgLevelType, organizationBaseDate);
          await getCompanyOrganizations(organizationBaseDate);
        } else {
          setValue('orgLevelType', managedOrgLevelType);
          await getOrganizations(changeUpperOrgLevelType(dataOrgLevelType), organizationBaseDate);
        }

        const target = isGroupCompany
          ? { ...data }
          : {
              ...data,
              divisionCode: dataOrgLevelType === 'DIVISION' ? '' : data.divisionCode,
              sbuCode: dataOrgLevelType === 'SBU' ? '' : data.sbuCode,
              departmentCode: dataOrgLevelType === 'DEPARTMENT' ? '' : data.departmentCode,
              groupCode: '',
            };

        const selected = makeDisplayOrgData(target);
        setValue('upperOrg', selected.key);
        setInputOrganization(selected.key);
        const companyKey = createCompanyKey(data);
        setInputCompany(companyKey);
        setValue('companyCode', companyKey);
        setValue('applyStartDate', data.applyStartDate);
        setValue('applyEndDate', data.applyEndDate);
        const selectedCountry = makeDisplayCountryData(targetCountry).key;
        setValue('country', selectedCountry);
      }
    },
    [
      getComplementaryOrganizationDetail,
      setValue,
      changeCountry,
      makeDisplayCountryData,
      convertManagedOrgLevelType,
      makeDisplayOrgData,
      setInputOrganization,
      getOrganizations,
      organizationBaseDate,
      getCompanyOrganizations,
      setInputCompany,
      createCompanyKey,
      changeUpperOrgLevelType,
    ],
  );

  const onChangeOrganizationBaseDate = async (organizationBaseDate: Date) => {
    setIsLoading(true);
    setValue('jpn', ''); // 組織日本語名
    setValue('eng', ''); // 組織英語名
    setValue('upperOrg', null);
    setValue('applyStartDate', null); // 適用開始日
    setValue('applyEndDate', null); // 適用終了日
    getOrganizations(isGroupCompany ? getValues('managedOrgLevelType') : changeUpperOrgLevelType(getValues('orgLevelType')), organizationBaseDate);
    getCompanyOrganizations(organizationBaseDate);

    setIsLoading(false);
  };

  return {
    isGroupCompany,
    regionsItems,
    rankItems,
    targetCompanyList,
    targetCountryList,
    targetOrganizationList,
    countriesOptions,
    getDetail,
    setOrganizationDate,
    changeCountry,
    changeUpperOrgLevelType,
    onChangeOrganizationBaseDate,
    getOrganizations,
    getCompanyOrganizations,
  };
};
