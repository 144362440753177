import { addHours, endOfMonth } from 'date-fns';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ComplementaryOrganizationEntity,
  OmitField,
  RegionEntity,
  useComplementaryOrganizationApi,
} from 'src/api/survey-edit/useComplementaryOrganizationApi';
import { OrganizationEntity } from 'src/api/useOrganizationApi';
import { useAppSelector } from 'src/redux/store';
import { Country, Organization } from '../components/ComplementaryOrganizationDetail';
import { ORGANIZATION_FUTURE_PATH } from '../routes/path';

export const useOrganizationDetailSave = (
  methods: UseFormReturn,
  targetOrganizationList: Organization[],
  targetCountryList: Country[],
  targetCompanyList: Organization[],
  isGroupCompany: boolean,
) => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const { getValues } = methods;
  const regions = useAppSelector((state) => state.complementaryOrganization.regionData);

  const { createComplementaryOrganization, updateComplementaryOrganization } = useComplementaryOrganizationApi();

  //入力した値を組織種別でDBのどのカラムに追加するか設定
  const makeInputData = useCallback((code: string, jpn: string, eng: string, orgLevelType) => {
    if (orgLevelType === 'COMPANY') {
      // NOTE:
      //   orgLevelType === 'COMPANY' はグループ会社を登録/更新するケースである
      //   会社略称は画面上設定不可のため、ここで一律nullをセットしていたがグループ会社の場合はグループ会社がもつ略称をセットする。
      return { companyCode: code, companyNameJpn: jpn || eng, companyNameEng: eng || jpn };
    } else if (orgLevelType === 'DIVISION') {
      return { divisionCode: code, divisionNameJpn: jpn, divisionNameEng: eng };
    } else if (orgLevelType === 'SBU') {
      return { sbuCode: code, sbuNameJpn: jpn, sbuNameEng: eng };
    } else if (orgLevelType === 'DEPARTMENT') {
      return { departmentCode: code, departmentNameJpn: jpn, departmentNameEng: eng };
    } else if (orgLevelType === 'GROUP') {
      return { groupCode: code, groupNameJpn: jpn, groupNameEng: eng };
    }
  }, []);

  const onSubmit = async () => {
    const { code, jpn, eng, upperOrg, regionCode, country, managedOrgLevelType, ttc, organizationBaseDate, ...inputValue } = getValues();
    const upperOrgData = targetOrganizationList.find((x: Organization) => x.key === upperOrg)?.entity;
    const regionsData = regions.find((x: RegionEntity) => x.regionCode === regionCode) || {
      regionCode: '',
      regionNameJpn: '',
      regionNameEng: '',
    };
    const countryData = targetCountryList.find((x: Country) => x.key === country)?.entity;
    const company = targetCompanyList.find((x: Organization) => x.key === getValues('companyCode'))?.entity;
    // 送信用データを取得.更新の場合は登録データから取得する
    const sendData: Omit<OrganizationEntity, OmitField> = isEdit ? getValues('updateData') : upperOrgData;
    const inputCode = isGroupCompany && !isEdit ? company?.companyCode : code;
    const inputJpnName = isGroupCompany && !isEdit ? company?.companyNameJpn : jpn;
    const inputEngName = isGroupCompany && !isEdit ? company?.companyNameEng : eng;
    const applyStartDate = isEdit ? sendData.applyStartDate : addHours(new Date(inputValue.applyStartDate), 9);
    const data = {
      companyCode: sendData.companyCode,
      companyNameJpn: sendData?.companyNameJpn || sendData?.companyNameEng, // 要確認
      companyNameEng: sendData?.companyNameEng, // 要確認
      divisionCode: sendData?.divisionCode,
      divisionNameJpn: sendData?.divisionNameJpn,
      divisionNameEng: sendData?.divisionNameEng,
      sbuCode: sendData?.sbuCode,
      sbuNameJpn: sendData?.sbuNameJpn,
      sbuNameEng: sendData?.sbuNameEng,
      departmentCode: sendData?.departmentCode,
      departmentNameJpn: sendData?.departmentNameJpn,
      departmentNameEng: sendData?.departmentNameEng,
      groupCode: sendData?.groupCode,
      groupNameJpn: sendData?.groupNameJpn,
      groupNameEng: sendData?.groupNameEng,
      companyAbbreviation: isGroupCompany ? company?.companyAbbreviation : sendData?.companyAbbreviation,
      rank: inputValue.rank ?? '',
      orgLevelType: inputValue.orgLevelType ?? sendData.orgLevelType,
      applyStartDate: applyStartDate, // JSTに変換
      applyEndDate: endOfMonth(addHours(new Date(inputValue.applyEndDate), 9)) as unknown, // JSTに変換
      ...regionsData,
      ...countryData,
      ...makeInputData(inputCode, inputJpnName, inputEngName, inputValue.orgLevelType ?? sendData.orgLevelType),
    } as ComplementaryOrganizationEntity;
    try {
      if (id) {
        // 更新処理
        await updateComplementaryOrganization(id, data);
      } else {
        // 登録処理
        await createComplementaryOrganization(data);
      }
      navigate(`${ORGANIZATION_FUTURE_PATH.INDEX}`);
    } catch {
      // エラー時は画面遷移しない、スナックバーはAPIコール場所にて出力
    }
  };

  return { onSubmit };
};
