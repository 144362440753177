import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserEntity, useUserApi } from 'src/api/useUserApi';
import { SearchConditionProps } from 'src/components/app-components/bonsai/SearchModal';
import { SELECT_RESPONDENT_LINE_MAX_LENGTH } from 'src/constants';
import { SearchConditionList } from './useSearchConditionList';

interface Props {
  list: {
    companies: SearchConditionList[];
    countries: SearchConditionList[];
    divisions: SearchConditionList[];
    sbus: SearchConditionList[];
    departments: SearchConditionList[];
    groups: SearchConditionList[];
    bases: SearchConditionList[];
    jobTitles: SearchConditionList[];
    gradeJobTitles: SearchConditionList[];
  };
  methods: {
    makeBases: { (companyCode?: string): Promise<void> };
    makeJobTitles: { (companyCode?: string): Promise<void> };
    makeSbus: { (divisionCode?: string): Promise<void> };
    makeDepartments: { (divisionCode?: string, sbuCode?: string): Promise<void> };
    makeGroups: { (divisionCode?: string, sbuCode?: string, departmentCode?: string): Promise<void> };
    setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  };
  isOpen: boolean;
}

interface SearchCondition {
  keyword?: string;
  companyCode?: string;
  countryCode?: string;
  divisionCode?: string;
  sbuCode?: string;
  departmentCode?: string;
  groupCode?: string;
  baseCode?: string;
  jobTitleCode?: string;
  gradeJobTitleCode?: string;
}

export const useSearchPerson = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getByFilter } = useUserApi();
  const [condition, setCondition] = useState<SearchCondition>({});
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  // memo 依頼先登録ダイアログの表示上限に合わせる
  const emailsLimitCount = SELECT_RESPONDENT_LINE_MAX_LENGTH;

  const onChange = async (key: string, value: string | undefined) => {
    setIsSearchLoading(true);
    const cond = condition;
    switch (key) {
      case 'keyword':
        cond.keyword = value === '' ? undefined : value;
        break;
      case 'company':
        cond.companyCode = value;
        // memo: 会社コードによりリストの中身が変わる検索条件は値をリセットしておく
        cond.baseCode = undefined;
        cond.jobTitleCode = undefined;
        await props.methods.makeBases(cond.companyCode);
        await props.methods.makeJobTitles(cond.companyCode);
        break;
      case 'country':
        cond.countryCode = value;
        break;
      case 'division':
        cond.divisionCode = value;
        cond.sbuCode = undefined;
        cond.departmentCode = undefined;
        cond.groupCode = undefined;
        props.methods.makeSbus(cond.divisionCode);
        props.methods.makeDepartments(cond.divisionCode, cond.sbuCode);
        props.methods.makeGroups(cond.divisionCode, cond.sbuCode, cond.departmentCode);
        break;
      case 'sbu':
        cond.sbuCode = value;
        cond.departmentCode = undefined;
        cond.groupCode = undefined;
        props.methods.makeDepartments(cond.divisionCode, cond.sbuCode);
        props.methods.makeGroups(cond.divisionCode, cond.sbuCode, cond.departmentCode);
        break;
      case 'department':
        cond.departmentCode = value;
        cond.groupCode = undefined;
        props.methods.makeGroups(cond.divisionCode, cond.sbuCode, cond.departmentCode);
        break;
      case 'group':
        cond.groupCode = value;
        break;
      case 'base':
        cond.baseCode = value;
        break;
      case 'jobTitle':
        cond.jobTitleCode = value;
        break;
      case 'gradeJobTitle':
        cond.gradeJobTitleCode = value;
        break;
    }
    setCondition(cond);
    await getUsers(condition);
    props.methods.setIsSelected(false);
    setIsSearchLoading(false);
  };

  const getUsers = async (condition: SearchCondition) => {
    const res = await getByFilter({
      keyword: condition.keyword,
      companyCode: condition.companyCode,
      countryCode: condition.countryCode,
      divisionCode: condition.divisionCode,
      sbuCode: condition.sbuCode,
      departmentCode: condition.departmentCode,
      groupCode: condition.groupCode,
      storeCode: condition.baseCode,
      jobTitleCode: condition.jobTitleCode,
      gradeJobTitleCode: condition.gradeJobTitleCode,
    });
    setUsers(res ?? []);
    // memo: 依頼先登録ダイアログの表示上限 5,000件を超える場合警告のスナックバーを5秒表示する。
    if (res?.length !== undefined && res?.length >= emailsLimitCount) {
      enqueueSnackbar(
        t('enqueteCreate.selectRespondentPersonModal.alertMessage', {
          selectRespondentLineMaxLength: new Intl.NumberFormat('en-US').format(SELECT_RESPONDENT_LINE_MAX_LENGTH),
        }),
        {
          variant: 'warning',
          autoHideDuration: 5000,
        },
      );
    }
  };

  // 検索条件を定義
  const labelPrefix = 'enqueteCreate.selectRespondentPersonModal.condition';
  const makeSearchConditionProp = (
    key: string,
    style: { xs: number },
    value: string | undefined | null,
    list: { key: string; label: string }[],
  ): SearchConditionProps => {
    return {
      type: 'autocomplete',
      id: key,
      label: key === 'country' ? t('common.workingCountry') : t(`${labelPrefix}.${key}`),
      style,
      props: {
        value: value === undefined ? null : value,
        list,
        onChange: (value) => onChange(key, value),
      },
    };
  };

  // memo: 全社共通で利用可能な検索条件
  const conditions: SearchConditionProps[][] = [
    [
      {
        type: 'text',
        id: 'keyword',
        label: t(`${labelPrefix}.keyword`),
        style: { xs: 8 },
        props: {
          value: condition.keyword ?? '',
          onBlur: (value) => onChange('keyword', value),
        },
      },
    ],
    [
      makeSearchConditionProp('company', { xs: 8 }, condition.companyCode, props.list.companies),
      makeSearchConditionProp('country', { xs: 3 }, condition.countryCode, props.list.countries),
    ],
  ];

  // memo: 豊田通商 の場合、条件は全て利用可能となる前提
  if (condition.companyCode === 'S500') {
    conditions.push(
      [
        makeSearchConditionProp('division', { xs: 5 }, condition.divisionCode, props.list.divisions),
        makeSearchConditionProp('sbu', { xs: 5 }, condition.sbuCode, props.list.sbus),
      ],
      [
        makeSearchConditionProp('department', { xs: 5 }, condition.departmentCode, props.list.departments),
        makeSearchConditionProp('group', { xs: 5 }, condition.groupCode, props.list.groups),
      ],
      [
        makeSearchConditionProp('base', { xs: 3 }, condition.baseCode, props.list.bases),
        makeSearchConditionProp('jobTitle', { xs: 3 }, condition.jobTitleCode, props.list.jobTitles),
        makeSearchConditionProp('gradeJobTitle', { xs: 4 }, condition.gradeJobTitleCode, props.list.gradeJobTitles),
      ],
    );
  } else {
    // memo: 豊田通商 以外の場合、利用可能な条件はマスタからデータが取得できた場合のみ表示
    let conds = [];
    if (0 < props.list.bases.length) {
      conds.push(makeSearchConditionProp('base', { xs: 5 }, condition.baseCode, props.list.bases));
    }
    if (0 < props.list.jobTitles.length) {
      conds.push(makeSearchConditionProp('jobTitle', { xs: 5 }, condition.jobTitleCode, props.list.jobTitles));
    }
    if (0 < conds.length) {
      conditions.push(conds);
    }
  }

  return { isSearchLoading, users, conditions };
};
