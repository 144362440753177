import { useState } from 'react';
import { OrganizationEntity } from 'src/api/useOrganizationApi';

type MultiLanguageTypes = {
  jpn: string | null;
  eng: string | null;
};

type SearchOrganizationAdditionalConditionTypes = {
  key: string;
  label: MultiLanguageTypes;
  id: string;
};

/**
 * 組織情報のセレクトボックスを生成するために必要な情報を保持するカスタムフック
 * 組織情報を受け取り、それぞれのレベルの{key,label,id}の配列を生成する
 */
export const useSearchOrganizationOptions = () => {
  const [divisionOptions, setDivisionOptions] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [sbuOptions, setSbuOptions] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);
  const [groupOptions, setGroupOptions] = useState<SearchOrganizationAdditionalConditionTypes[]>([]);

  // 本部レベル情報取得
  const makeDivisionOptions = (divisions?: OrganizationEntity[]) => {
    const conditions =
      divisions?.map((e) => {
        return {
          key: e.divisionCode ?? '',
          label: { jpn: e.divisionNameJpn, eng: e.divisionNameEng },
          id: e.id,
        };
      }) ?? [];
    setDivisionOptions(conditions);
  };

  // SBUレベル情報を取得
  const makeSbuOptions = (sbus?: OrganizationEntity[]) => {
    const conditions =
      sbus?.map((e) => {
        return {
          key: e.sbuCode ?? '',
          label: { jpn: e.sbuNameJpn, eng: e.sbuNameEng },
          id: e.id,
        };
      }) ?? [];
    setSbuOptions(conditions);
  };

  // 部レベル情報取得
  const makeDepartmentOptions = (departments?: OrganizationEntity[]) => {
    const conditions =
      departments?.map((e) => {
        return {
          key: e.departmentCode ?? '',
          label: { jpn: e.departmentNameJpn, eng: e.departmentNameEng },
          id: e.id,
        };
      }) ?? [];
    setDepartmentOptions(conditions);
  };

  // グループレベル情報取得
  const makeGroupOptions = (groups?: OrganizationEntity[]) => {
    const conditions =
      groups?.map((e, i) => {
        return {
          key: e.groupCode ?? '',
          label: { jpn: e.groupNameJpn, eng: e.groupNameEng },
          id: e.id,
        };
      }) ?? [];
    setGroupOptions(conditions);
  };

  return {
    divisionOptions,
    sbuOptions,
    departmentOptions,
    groupOptions,
    makeDivisionOptions,
    makeSbuOptions,
    makeDepartmentOptions,
    makeGroupOptions,
  };
};
