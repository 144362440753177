import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { SurveyInformationEntity } from 'src/@types/generalEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { SurveyDetailEntity } from 'src/api/useSurveyDetailApi';
import { DeleteRespondent, SurveyEditEntity, useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { DisclosureLevelUpdateConfirm } from 'src/constants';
import { useAppDispatch } from 'src/redux/store';
import { enqueteCreateSlice } from '../store/enqueteCreateSlice';

export const useEnqueteCreateGrid = () => {
  const dispatch = useAppDispatch();
  const surveyEditApi = useSurveyEditApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * 宛先情報取得
   */
  const getRequestAnswerList = async (id: string) => {
    // 初期化値の定義
    const getApiData: SurveyEditEntity[] | undefined = await surveyEditApi.getList(id);
    // ストアの初期化
    dispatch(enqueteCreateSlice.actions.initRequestListData(getApiData || []));
  };

  /** 宛先一覧内の１行を削除 */
  const deleteRespondent = async (respondentPersonId: DeleteRespondent, respondentRequestId: string | undefined) => {
    const isSuccess = await surveyEditApi.deleteRespondent(respondentPersonId);

    if (isSuccess && respondentRequestId) {
      //リスト再取得
      await getRequestAnswerList(respondentRequestId);
    }
  };

  /**
   * アンケート情報保存
   */
  const saveEnqueteData = async (data: SurveyInformationEntity, disclosureLevelUpdateConfirm: DisclosureLevelUpdateConfirm) => {
    let result: boolean = false;

    result = await surveyEditApi.setSurveyDataById(data, disclosureLevelUpdateConfirm);

    if (result) {
      enqueueSnackbar(t('button.saved'), { variant: 'success' });
    }
    //最新のアンケート情報を取得する
    return await surveyEditApi.getListById(data.respondentRequestId);
  };

  // 回答者選択モーダルの開閉を指定
  const setIsOpenSelectPersonModal = (isOpen: boolean) => dispatch(enqueteCreateSlice.actions.setIsOpenSelectPersonModal(isOpen));

  // 回答対象変更モーダルの開閉の操作
  const setIsOpenChangeRespondentModal = (isOpen: boolean) => dispatch(enqueteCreateSlice.actions.setIsOpenChangeRespondentModal(isOpen));

  // 回答者選択モーダルのAddModeを指定
  const setIsOpenSelectPersonModalAddMode = (mode: boolean) => dispatch(enqueteCreateSlice.actions.setIsOpenSelectPersonModalAddMode(mode));

  /**
   * Excelインポートモーダルの開閉を指定
   *
   * @param isOpen
   */
  const setOpenImportFileModal = (isOpen: boolean) => dispatch(enqueteCreateSlice.actions.setOpenImportFileModal(isOpen));

  /**
   * アンケートデータ新規作成
   * POST:アンケート新規作成
   */
  const saveNewEnqueteData = async (entity: RespondentRequestEntity, type: 'general' | 'overview') => {
    try {
      const result = await surveyEditApi.setSurveyDataCreate(entity);
      if (result) enqueueSnackbar(t('message.save'), { variant: 'success' });
      return result;
    } catch (error) {
      enqueueSnackbar(t('apiExceptionMessage.useSurveyEditApi.failedSetEnqueteDataCreate'), { variant: 'error' });
    }
  };

  /**
   * アンケート詳細データ更新（１件）
   * PUT:アンケート詳細更新
   */
  const saveSurveyDetailData = async (surveyDetail: SurveyDetailEntity, message: string = 'message.save') => {
    try {
      const result = await surveyEditApi.setSurveyDetailDataUpdate(surveyDetail);
      enqueueSnackbar(t(message), { variant: 'success' });
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('apiExceptionMessage.useSurveyDetailApi.failedUpdateDetailData'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  return {
    getRequestAnswerList,
    deleteRespondent,
    saveEnqueteData,
    setIsOpenSelectPersonModal,
    setIsOpenChangeRespondentModal,
    setIsOpenSelectPersonModalAddMode,
    setOpenImportFileModal,
    saveNewEnqueteData,
    saveSurveyDetailData,
  };
};
