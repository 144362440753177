//
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnqueteConfirmListEntity } from 'src/api/useConfirmRequestApi';

export enum ConfirmRequestStatus {
  ALL = 'ALL', // 全て
  CONFIRMED = 'CONFIRMED', // 確認済
  REQUESTED = 'REQUESTED', // 未確認
}

export const enqueteConfirmSlice = createSlice({
  name: 'enqueteConfirm',
  initialState: {
    listData: [] as EnqueteConfirmListEntity[],
    enqueteConfirmListData: [] as EnqueteConfirmListEntity[],
    confirmRequestStatus: ConfirmRequestStatus.ALL,
  },
  reducers: {
    initEnqueteConfirmListData: (state, action: PayloadAction<EnqueteConfirmListEntity[]>) => {
      state.enqueteConfirmListData = action.payload;
    },
    handleConfirmRequestStatus: (state, action: PayloadAction<ConfirmRequestStatus>) => {
      state.confirmRequestStatus = action.payload;
    },
  },
});
