import { useSnackbar } from 'notistack5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkCreateRespondentPersonEntity, BulkCreateRespondentPersonsEntity, useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { UserEntity } from 'src/api/useUserApi';
import { AnswerUnit, ANSWER_UNIT, SELECT_RESPONDENT_LINE_MAX_LENGTH } from 'src/constants';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { initializeRespondentSetting } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import { dispatch, useAppSelector } from 'src/redux/store';

interface Props {
  respondentTargetId: string;
  respondentRequestId: string;
  answerUnit: AnswerUnit;
  getSelectedRows: () => UserEntity[];
}

const useRespondentPersonSave = (props: Props) => {
  const { respondentTargetId, respondentRequestId, answerUnit, getSelectedRows } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { bulkCreate, bulkCreateRespondentByEmail } = useSurveyEditApi();
  const { getRequestAnswerList } = useEnqueteCreateGrid();
  const [isClickAddRespondent, setIsClickAddRespondent] = useState<boolean>(false);

  // Store
  const listData = useAppSelector((state) => state.enqueteCreate.requestListData);
  const isOpenSelectPersonModalAddMode = useAppSelector((state) => state.enqueteCreate.isOpenSelectPersonModalAddMode);

  // memo 依頼先登録ダイアログの表示上限に合わせる
  const emailsLimitCount = SELECT_RESPONDENT_LINE_MAX_LENGTH;

  // 組織向けアンケート宛先追加
  // 依頼先一覧上部の宛先追加ボタンでアクセスした回答者選択ダイアログ内の追加処理
  const onClickForOrganizationAdd = async () => {
    const emails: string[] = [];

    for (const user of getSelectedRows()) {
      if (user.email) {
        emails.push(user.email);
      }
    }

    if (emailsLimitCount < emails.length) {
      enqueueSnackbar(t(`enqueteCreate.bulkCreateEmailModal.maxCount`, { max: emailsLimitCount.toLocaleString() }), { variant: 'warning' });
      return;
    }
    // memo: 同一メールアドレスが存在した場合は重複を削除してAPIをcallします
    await bulkCreateRespondentByEmail(respondentRequestId, [...new Set(emails)]);
    await getRequestAnswerList(respondentRequestId);
    dispatch(initializeRespondentSetting());
  };

  // 組織向けアンケート回答者選択
  // 依頼先一覧上で開いたコンテキストメニュー内の、“回答者選択”からアクセスした回答者選択ダイアログ内の追加処理
  const onClickForOrganization = async () => {
    const validateResult = { isExist: false, email: [] as string[] };
    const data = getSelectedRows().map((user) => {
      if (listData.some((e) => e.respondentTargetId === respondentTargetId && e.email === user.email)) {
        validateResult.isExist = true;
        validateResult.email.push(user.email ?? '');
      }
      return {
        userNameJpn: user.fullNameJpn ?? '',
        userNameEng: user.fullNameEng ?? '',
        email: user.email ?? '',
        bluePageEmailAddress: user.bluePageEmailAddress ?? '',
        companyCode: user.companyCode ?? '',
        assignedCompanyNameJpn: user.assignedCompanyNameJpn ?? '',
        assignedCompanyNameEng: user.assignedCompanyNameEng ?? '',
        sbuCode: user.sbuCode ?? '',
        divisionCode: user.divisionCode ?? '',
        departmentCode: user.departmentCode ?? '',
        groupCode: user.groupCode ?? '',
      } as BulkCreateRespondentPersonEntity;
    });
    if (validateResult.isExist) {
      enqueueSnackbar(t('message.alreadyRegisteredSelected'), { variant: 'warning' });
      return;
    }
    const datas: BulkCreateRespondentPersonsEntity = { bulkCreateRespondentPersons: data };
    const res = await bulkCreate(respondentTargetId, datas);
    if (res) {
      await getRequestAnswerList(respondentRequestId);
      dispatch(initializeRespondentSetting());
      enqueueSnackbar(t('button.added'), { variant: 'success' });
    }
  };

  // 個人向けアンケート宛先追加
  // 依頼先一覧上部の宛先追加ボタンでアクセスした回答者選択ダイアログ内の追加処理
  const onClickForPersonal = async () => {
    const validateResult = { isExist: false, email: [] as string[] };
    const emails: string[] = [];
    for (const user of getSelectedRows()) {
      if (listData.some((e) => e.email === user.email)) {
        validateResult.isExist = true;
        validateResult.email.push(user.email ?? '');
      }
      if (user.email) {
        emails.push(user.email);
      }
    }
    if (validateResult.isExist) {
      enqueueSnackbar(t('message.alreadyRegisteredSelected'), { variant: 'warning' });
      return;
    }

    // memo 保守T BNS-439対応にて上限を5,000件とします
    if (emailsLimitCount < emails.length) {
      enqueueSnackbar(t(`enqueteCreate.bulkCreateEmailModal.maxCount`, { max: emailsLimitCount.toLocaleString() }), { variant: 'warning' });
      return;
    }
    // memo: 同一メールアドレスが存在した場合は重複を削除してAPIをcallします
    await bulkCreateRespondentByEmail(respondentRequestId, [...new Set(emails)]);
    await getRequestAnswerList(respondentRequestId);
    dispatch(initializeRespondentSetting());
  };

  // 宛先追加処理 - 内容をアンケートの種類やどこからのアクションかで変更
  // グローバルステート“isOpenSelectPersonModalAddMode”は、ダイアログの呼び元で“setIsOpenSelectPersonModalAddMode”dispatchを使用して切替える
  const onClickAddRespondent = async () => {
    setIsClickAddRespondent(true);
    if (answerUnit === ANSWER_UNIT.ORGANIZATION) {
      isOpenSelectPersonModalAddMode ? await onClickForOrganizationAdd() : await onClickForOrganization();
    } else {
      await onClickForPersonal();
    }
    setIsClickAddRespondent(false);
  };

  return {
    onClickForOrganizationAdd,
    onClickForOrganization,
    onClickForPersonal,
    onClickAddRespondent,
    isClickAddRespondent,
  };
};

export default useRespondentPersonSave;
