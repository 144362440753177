import { useEffect, useState } from 'react';
import { useCountryApi } from 'src/api/useCountryApi';
import { CustomerSearchCondition, useCustomerApi } from 'src/api/useCustomerApi';
import { useRegionApi } from 'src/api/useRegionApi';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { switchLabel } from 'src/utils/labelUtils';

export type SearchConditionList = {
  key: string;
  label: string;
};

export const useSearchConditionList = (parentCustomerCondition: string[]) => {
  const { isJapanese } = useLocales();
  const { getCustomersByFilter } = useCustomerApi();
  const { getOnlySetIsoCountryCode } = useCountryApi();
  const { getAll: getAllRegions } = useRegionApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [parentCustomer, setParentCustomer] = useState<SearchConditionList[]>([]);
  const [countries, setCountries] = useState<SearchConditionList[]>([]);
  const [regions, setRegions] = useState<SearchConditionList[]>([]);

  // 引数に応じて表示される親会社プルダウンを制御、渡した配列の会社が表示される
  const makeParentCustomers = async () => {
    if (parentCustomerCondition.length === 0) return;
    const where: CustomerSearchCondition = {
      customerCode: parentCustomerCondition,
      isDeleted: false,
      limit: 100,
    };
    const customers = (await getCustomersByFilter(where)) ?? [];
    const conditions = customers.map((e) => {
      const label = switchLabel(e.customerNameJpn, e.customerNameEng, isJapanese);
      return {
        key: e.customerCode ?? '',
        label: isJapanese ? trimStockCompany(label) : label,
      };
    });
    setParentCustomer(conditions);
  };

  const makeCountries = async () => {
    const countries = (await getOnlySetIsoCountryCode()) ?? [];
    const conditions = countries.map((e, _i) => {
      return {
        key: e.countryIsoCode ?? '',
        label: isJapanese ? e.countryNameJpn ?? '' : e.countryNameEng ?? '',
      };
    });
    setCountries(conditions);
  };

  const makeRegions = async () => {
    const regions = (await getAllRegions()) ?? [];
    const conditions = regions.map((e, i) => {
      return {
        key: e.regionCode ?? '',
        label: isJapanese ? e.regionNameJpn ?? '' : e.regionNameEng ?? '',
      };
    });
    setRegions(conditions);
  };

  const init = async () => {
    await Promise.all([makeParentCustomers(), makeCountries(), makeRegions()]);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    // 言語が更新されたタイミングで再度リストを生成
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJapanese]);

  return {
    isLoading,
    parentCustomer,
    countries,
    regions,
  };
};
