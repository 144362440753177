import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GeneralEnquete } from 'src/@types/generalEnquete';
import { useEnqueteAnswerInputApi } from 'src/api/useEnqueteAnswerInputApi';
import { FORM_TYPE, REQUEST_STATUS, SURVEY_TYPE } from 'src/constants';
import useEnqueteAnswer from 'src/features/general/enquete-answer/hooks/useEnqueteAnswer';
import {
  SurveyDetailEntity,
  resetEnqueteData,
  setAfterRequestTerm,
  setBeforeRequestTerm,
  setEnqueteAnswerOpen,
  setRespondentRequestData,
  setRespondentTargetData,
  setSurveyDetailData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import { setInitGeneralQuestions } from 'src/features/general/enquete-answer/store/enqueteGeneralAnswerSlice';
import { usePermission } from 'src/hooks/usePermission';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';

const useEnqueteAnswerInputLayout = () => {
  const respondentTargetId = useParams().id;
  const confirmRequestId: string | null = new URLSearchParams(useLocation().search).get('confirmRequestId');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getRespondentTarget, getRespondentRequest, getSurveyDetail } = useEnqueteAnswerInputApi();
  const { fetchAnswer, fetchConfirmRequest, fetchConfirmHistories } = useEnqueteAnswer();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const respondentRequestData = useAppSelector((state) => state.enqueteAnswer.respondentRequestData);
  const surveyDetailData = useAppSelector((state) => state.enqueteAnswer.surveyDetailData);

  const { isAdmin, isSurveyOwner } = usePermission();

  const isGeneral = surveyDetailData.surveyHeader?.type === SURVEY_TYPE.GENERAL;
  const isSeries = surveyDetailData.surveyHeader?.formType === FORM_TYPE.SERIES;
  const isOverview = surveyDetailData.surveyHeader?.formType === FORM_TYPE.OVERVIEW;

  // todo: 依頼ステータスによる管理が複雑、as is to be を整理してグローバルステートを見直した方がよい
  const requestStatus = getRequestStatus(respondentRequestData.openedAt, respondentRequestData.closedAt);

  // 依頼前 条件：　bonsai-adminでないこと、アンケート管理者でないこと、依頼前であること
  dispatch(setBeforeRequestTerm(!isAdmin && !isSurveyOwner && (requestStatus === undefined || requestStatus === REQUEST_STATUS.BEFORE_REQUEST)));
  // 実施中　条件：　汎用・系列別・概況表はステータスを参照し、他だった場合は権限とステータスを参照していいます
  isGeneral || isSeries || isOverview
    ? dispatch(setEnqueteAnswerOpen(requestStatus === REQUEST_STATUS.IN_PROGRESS))
    : dispatch(setEnqueteAnswerOpen(isAdmin || isSurveyOwner || requestStatus === REQUEST_STATUS.IN_PROGRESS));
  // 完了 条件：　bonsai-adminでないこと、アンケート管理者でないこと、完了であること
  dispatch(setAfterRequestTerm(!isAdmin && !isSurveyOwner && requestStatus === REQUEST_STATUS.COMPLETE));

  const fetchRespondentTarget = async (respondentTargetId: string): Promise<void> => {
    const response = await getRespondentTarget(respondentTargetId);
    if (!response) throw new Error();
    dispatch(setRespondentTargetData(response));
  };

  const fetchRespondentRequest = async (respondentTargetId: string): Promise<void> => {
    const response = await getRespondentRequest(respondentTargetId);
    if (!response) throw new Error();
    dispatch(setRespondentRequestData(response));
  };

  const fetchSurveyDetail = async (respondentTargetId: string): Promise<SurveyDetailEntity | undefined> => {
    const response = await getSurveyDetail(respondentTargetId);
    if (!response) throw new Error();
    dispatch(setSurveyDetailData(response));
    return response;
  };

  const setAnswerData = async (respondentTargetId: string) => {
    let formData: GeneralEnquete;
    const surveyDetailData = await fetchSurveyDetail(respondentTargetId);
    const answerData = await fetchAnswer(respondentTargetId);
    if (answerData?.id) {
      await fetchConfirmHistories(answerData.id);
    }

    // 汎用アンケートのためのデータを作成する
    if (answerData) {
      formData = answerData.answerData.answerInputData.tabData[0] as GeneralEnquete;
    } else {
      if (!surveyDetailData) throw new Error();
      formData = surveyDetailData.surveyFormData.formData.tabData[0] as GeneralEnquete;
    }
    if (formData.questionsJpn && formData.questionsEng) {
      if (i18n.language === 'ja') {
        dispatch(setInitGeneralQuestions(formData.questionsJpn));
      } else {
        dispatch(setInitGeneralQuestions(formData.questionsEng));
      }
    }
  };

  const fetchEnqueteBaseData = async () => {
    if (!respondentTargetId) {
      // todo: snackbar ではなく画面全体でエラーを表示した方が望ましい、404ページとか
      enqueueSnackbar(t('enqueteAnswerInput.message.exceptionRespondentTarget'), { variant: 'error' });
      navigate('../../enquete-answer', { replace: true });
      return;
    }

    setIsLoading(true);
    try {
      await Promise.all([fetchRespondentTarget(respondentTargetId), fetchRespondentRequest(respondentTargetId), setAnswerData(respondentTargetId)]);
      // 確認依頼から遷移していれば confirmRequestId を元に ConfirmRequest を取得
      if (!confirmRequestId) return;

      await fetchConfirmRequest(confirmRequestId);
    } catch (error) {
      enqueueSnackbar(t('enqueteAnswerInput.message.exceptionRespondentTarget'), { variant: 'error' });
      navigate('../../enquete-answer', { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEnqueteBaseData();
    return () => {
      dispatch(resetEnqueteData());
      dispatch(setInitGeneralQuestions([]));
    };
  }, []); //eslint-disable-line

  return {
    isLoading,
  };
};

export default useEnqueteAnswerInputLayout;
