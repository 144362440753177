import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useTranslation } from 'react-i18next';
import { apiGateway } from 'src/config';
import { useAppDispatch } from 'src/redux/store';
import axiosErrorHandler from 'src/utils/axiosErrorHandler';
import { getBearerToken } from 'src/utils/getBearerToken';
import {
  ConfirmedRequestDto,
  EnqueteAnswerEntity,
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  enqueteAnswerSlice,
  TempPersonnelSurveyDiffFormAnswerInputData,
  TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData,
} from '../store/enqueteAnswerSlice';

export const useEnqueteAnswerGrid = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // 人件費人員(雇用形態別)のTotalを設定
  const setPersonnelEmploymentTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelEmploymentTotal(data));
  };
  // コーポレート人員のTotalを設定
  const setCorporateGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setCorporateGridTotal(data));
  };
  // コーポレート人員のTotalを設定
  const setSalesGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setSalesGridTotal(data));
  };
  // 人件費人員(重点分野)：1.正社員のTotalを設定
  const setPersonnelPriorityDomainRegularEmployeeGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainRegularEmployeeGridTotal(data));
  };
  // 人件費人員(重点分野)：2.TTCからの出向受入のTotalを設定
  const setPersonnelPriorityDomainSecondmentFromTTCGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainSecondmentFromTTCGridTotal(data));
  };
  // 人件費人員(重点分野)：3.2.以外の出向受入のTotalを設定
  const setPersonnelPriorityDomainSecondmentFromOtherGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainSecondmentFromOtherGridTotal(data));
  };
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトのTotalを設定
  const setPersonnelPriorityDomainDispatchEtcGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainDispatchEtcGridTotal(data));
  };
  // 製造原価人員(重点分野)：1.正社員のTotalを設定
  const setManufyearPlanurPriorityDomainRegularEmployeeGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurPriorityDomainRegularEmployeeGridTotal(data));
  };
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他のTotalを設定
  const setManufyearPlanurPriorityDomainOtherEmployeesGridTotal = (data: EnqueteAnswerInputEntity) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurPriorityDomainOtherEmployeesGridTotal(data));
  };
  // Modalの開閉を設定
  const setConfirmationCommentsModalMode = (isOpen: boolean) => dispatch(enqueteAnswerSlice.actions.setConfirmationCommentsModalMode(isOpen));
  // エラー状態設定
  const setHasError = (isError: boolean) => dispatch(enqueteAnswerSlice.actions.hasError(isError));
  const setHasErrorOtherTextForm = (isError: boolean) => dispatch(enqueteAnswerSlice.actions.hasErrorOtherTextForm(isError));
  // エラー状態設定：人件費人員(雇用形態別)
  const setHasErrorPersonnelEmploymentGrid = (isError: boolean) => dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelEmploymentGrid(isError));
  const setHasErrorPersonnelEmploymentResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelEmploymentResultAndPlanDiffForm(isError));
  const setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm(isError));
  const setHasErrorPersonnelEmploymentPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelEmploymentPlanAndPlanDiffForm(isError));
  const setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm(isError));
  // エラー状態設定：人件費人員(機能別)
  const setHasErrorPersonnelCorporateGrid = (isError: boolean) => dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelCorporateGrid(isError));
  const setHasErrorPersonnelGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelGridNotMatchTotal(isError));
  const setHasErrorPersonnelCorporateGridTotalOver = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelCorporateGridTotalOver(isError));
  const setHasErrorPersonnelSalesGridTotalOver = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelSalesGridTotalOver(isError));
  // エラー状態設定：人件費人員(重点分野)
  // 人件費人員(重点分野)年計-実見で入力されたデータの形のチェック
  const setHasErrorPersonnelPriorityDomainResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelPriorityDomainResultAndPlanDiffForm(isError));
  // 人件費人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
  const setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm(isError));
  // 人件費人員(重点分野)中計-年計で入力されたデータの形のチェック
  const setHasErrorPersonnelPriorityDomainPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelPriorityDomainPlanAndPlanDiffForm(isError));
  // 人件費人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
  const setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm(isError));
  // 人件費人員(重点分野)：1.正社員の人員合計と（参考）人件費人員（雇用形態別）の不一致エラーの設定
  const setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal(isError));
  // 人件費人員(重点分野)：2.TTCからの出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
  const setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal(isError));
  // 人件費人員(重点分野)：3.2.以外の出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
  const setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal(isError));
  // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトと（参考）人件費人員（雇用形態別）の不一致エラーの設定
  const setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal(isError));
  // エラー状態設定：製造原価人員(雇用形態別)
  const setHasErrorManufyearPlanurEmploymentGrid = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurEmploymentGrid(isError));
  const setHasErrorManufyearPlanurEmploymentResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurEmploymentResultAndPlanDiffForm(isError));
  const setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyManufyearPlanurEmploymentResultAndPlanDiffForm(isError));
  const setHasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm(isError));
  const setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm(isError));
  // エラー状態設定：製造原価人員(重点分野)
  // 製造原価人員(重点分野)年計-実見で入力されたデータの形のチェック
  const setHasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm(isError));
  // 製造原価人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
  const setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm(isError));
  // 製造原価人員(重点分野)中計-年計で入力されたデータの形のチェック
  const setHasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm(isError));
  // 製造原価人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
  const setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm(isError));
  // 製造原価人員(重点分野)：1.正社員の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
  const setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal(isError));
  // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
  const setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal = (isError: boolean) =>
    dispatch(enqueteAnswerSlice.actions.hasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal(isError));
  // ------------------------------- Answer API Start -------------------------------
  // 過去の回答入力内容を取得API(過去参照取得用)
  const setPastAnswer = async (pastAnswerData: EnqueteAnswerEntity[]): Promise<any> => {
    dispatch(enqueteAnswerSlice.actions.setPastEnqueteAnswerData(pastAnswerData));
  };
  // 回答入力内容を一時保存API
  const tempSaveAnswer = async (params: EnqueteAnswerEntity): Promise<any> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.post<EnqueteAnswerEntity>(apiGateway + '/enquete-answer-input/answer', params, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      dispatch(enqueteAnswerSlice.actions.setEnqueteAnswerData(response.data));
      enqueueSnackbar(t('message.tempSaved'), { variant: 'success' });
    } catch (error) {
      axiosErrorHandler(error, (message: string) => {
        enqueueSnackbar(t('message.exceptionTempSaved'), { variant: 'error' });
      });
    }
  };
  // 回答入力内容を保存API
  const saveAnswer = async (params: EnqueteAnswerEntity): Promise<any> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.post<EnqueteAnswerEntity>(apiGateway + '/enquete-answer-input/answer', params, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      dispatch(enqueteAnswerSlice.actions.setEnqueteAnswerData(response.data));
      params.status === 'ANSWERED'
        ? enqueueSnackbar(t('message.submitted'), { variant: 'success' })
        : enqueueSnackbar(t('message.save'), { variant: 'success' });
    } catch (error) {
      axiosErrorHandler(error, (message: string) => {
        enqueueSnackbar(t('message.exceptionSave'), { variant: 'error' });
      });
    }
  };

  // 回答入力内容を一時保存更新API
  const tempUpdateAnswer = async (params: EnqueteAnswerEntity, id: string): Promise<any> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.put<EnqueteAnswerEntity>(apiGateway + '/enquete-answer-input/answer/' + id, params, {
        headers: { Authorization: token },
        timeout: 60000,
      });

      dispatch(enqueteAnswerSlice.actions.setEnqueteAnswerData(response.data));
      enqueueSnackbar(t('message.tempSaved'), { variant: 'success' });
    } catch (error) {
      axiosErrorHandler(error, (message: string) => {
        enqueueSnackbar(t('message.exceptionTempSaved'), { variant: 'error' });
      });
    }
  };

  // 回答入力内容を更新API
  const updateAnswer = async (params: EnqueteAnswerEntity, id: string): Promise<any> => {
    try {
      const token = await getBearerToken();
      const response: AxiosResponse = await axios.put<EnqueteAnswerEntity>(apiGateway + '/enquete-answer-input/answer/' + id, params, {
        headers: {
          Authorization: token,
        },
        timeout: 60000,
      });

      dispatch(enqueteAnswerSlice.actions.setEnqueteAnswerData(response.data));
      params.status === 'ANSWERED'
        ? enqueueSnackbar(t('message.submitted'), { variant: 'success' })
        : enqueueSnackbar(t('message.save'), { variant: 'success' });
    } catch (error) {
      axiosErrorHandler(error, (message: string) => {
        enqueueSnackbar(t('message.exceptionUpdate'), { variant: 'error' });
      });
    }
  };
  // ------------------------------- Answer API END -------------------------------
  // ------------------------------- Confirm-Request API Start -------------------------------

  // 確認依頼完了API
  const sendConfirmRequestConfirmedMail = async (data: ConfirmedRequestDto): Promise<any> => {
    try {
      const token = await getBearerToken();
      const response = await axios.post(apiGateway + `/enquete-answer-input/confirm-request/confirm`, data, {
        headers: { Authorization: token },
      });
      enqueueSnackbar(t('message.update'), { variant: 'success' });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(t('message.exceptionUpdate'), { variant: 'error' });
        enqueueSnackbar(t('message.exceptionSend'), { variant: 'error' });
      } else {
        throw error;
      }
    }
  };

  // ------------------------------- Confirm-Request API END -------------------------------

  const editAfterSave = (params: boolean) => {
    dispatch(enqueteAnswerSlice.actions.editAfterSave(params));
  };
  const changePersonnelEmploymentRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelEmploymentRowData(params));
  };
  const changePersonnelEmploymentResultAndPlanDiffForm = (params: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelEmploymentResultAndPlanDiffForm(params));
  };
  const changePersonnelEmploymentPlanAndPlanDiffForm = (params: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelEmploymentPlanAndPlanDiffForm(params));
  };
  const changePersonnelCorporateRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelCorporateRowData(params));
  };
  const changePersonnelCorporateOtherFormTextValue = (params: string | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelCorporateOtherFormTextValue(params));
  };
  const changePersonnelSalesRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelSalesRowData(params));
  };
  const changeManufyearPlanurEmploymentRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurEmploymentRowData(params));
  };
  const changeManufyearPlanurEmploymentResultAndPlanDiffForm = (params: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurEmploymentResultAndPlanDiffForm(params));
  };
  const changeManufyearPlanurEmploymentPlanAndPlanDiffForm = (params: TempPersonnelSurveyDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurEmploymentPlanAndPlanDiffForm(params));
  };
  // 人件費人員（重点分野別） 1.正社員 編集した値をdispatch
  const changePersonnelPriorityDomainRegularEmployeeRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainRegularEmployeeRowData(params));
  };
  // 人件費人員（重点分野別） 2.TTCからの出向受入 編集した値をdispatch
  const changePersonnelPriorityDomainSecondmentFromTTCRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainSecondmentFromTTCRowData(params));
  };
  // 人件費人員（重点分野別） 3.2以外の出向受入 編集した値をdispatch
  const changePersonnelPriorityDomainSecondmentFromOtherRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainSecondmentFromOtherRowData(params));
  };
  // 人件費人員（重点分野別） 4.派遣、嘱託、パート、アルバイト 編集した値をdispatch
  const changePersonnelPriorityDomainDispatchEtcRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainDispatchEtcRowData(params));
  };
  // 人件費人員（重点分野別） 年計-実見 編集した値をdispatch
  const changePersonnelPriorityDomainResultAndPlanDiffForm = (params: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainResultAndPlanDiffForm(params));
  };
  // 人件費人員（重点分野別） 中計-年計 編集した値をdispatch
  const changePersonnelPriorityDomainPlanAndPlanDiffForm = (params: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setPersonnelPriorityDomainPlanAndPlanDiffForm(params));
  };
  // 製造原価人員（重点分野別） 1.正社員 編集した値をdispatch
  const changeManufyearPlanurPriorityDomainRegularEmployeeRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurPriorityDomainRegularEmployeeRowData(params));
  };
  // 製造原価人員（重点分野別） 2.1以外の従業員/派遣/その他 編集した値をdispatch
  const changeManufyearPlanurPriorityDomainOtherEmployeesRowData = (params: EnqueteAnswerLoadInputEntity[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurPriorityDomainOtherEmployeesRowData(params));
  };
  // 製造原価人員（重点分野別） 年計-実見 編集した値をdispatch
  const changeManufyearPlanurPriorityDomainResultAndPlanDiffForm = (
    params: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined,
  ) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurPriorityDomainResultAndPlanDiffForm(params));
  };
  // 製造原価人員（重点分野別） 中計-年計 編集した値をdispatch
  const changeManufyearPlanurPriorityDomainPlanAndPlanDiffForm = (params: TempPersonnelSurveyPriorityDomainDiffFormAnswerInputData[] | undefined) => {
    dispatch(enqueteAnswerSlice.actions.setManufyearPlanurPriorityDomainPlanAndPlanDiffForm(params));
  };

  return {
    setHasError,
    setHasErrorOtherTextForm,
    setHasErrorPersonnelEmploymentGrid,
    setHasErrorPersonnelEmploymentResultAndPlanDiffForm,
    setHasErrorEmptyPersonnelEmploymentResultAndPlanDiffForm,
    setHasErrorPersonnelEmploymentPlanAndPlanDiffForm,
    setHasErrorEmptyPersonnelEmploymentPlanAndPlanDiffForm,
    setHasErrorPersonnelCorporateGrid,
    setHasErrorPersonnelGridNotMatchTotal,
    setHasErrorPersonnelCorporateGridTotalOver,
    setHasErrorPersonnelSalesGridTotalOver,
    // 人件費人員(重点分野)年計-実見で入力されたデータの形のチェック
    setHasErrorPersonnelPriorityDomainResultAndPlanDiffForm,

    // 人件費人員(重点分野)：1.正社員の人員合計と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 人件費人員(重点分野)：2.TTCからの出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromTTCGridNotMatchTotal,
    // 人件費人員(重点分野)：3.2.以外の出向受入と（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainSecondmentFromOtherGridNotMatchTotal,
    // 人件費人員(重点分野)：4.派遣、嘱託、パート、アルバイトと（参考）人件費人員（雇用形態別）の不一致エラーの設定
    setHasErrorPersonnelPriorityDomainDispatchEtcGridNotMatchTotal,
    // 製造原価人員(重点分野)：1.正社員の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainRegularEmployeeGridNotMatchTotal,
    // 製造原価人員(重点分野)：2.1以外の従業員/派遣/その他の人員合計と（参考）製造原価人員（雇用形態別）の不一致エラーの設定
    setHasErrorManufyearPlanurPriorityDomainOtherEmployeesGridNotMatchTotal,
    // 人件費人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyPersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員(重点分野)中計-年計で入力されたデータの形のチェック
    setHasErrorPersonnelPriorityDomainPlanAndPlanDiffForm,
    // 人件費人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyPersonnelPriorityDomainPlanAndPlanDiffForm,
    setHasErrorManufyearPlanurEmploymentGrid,
    setHasErrorManufyearPlanurEmploymentResultAndPlanDiffForm,
    setHasErroEmptyrManufyearPlanurEmploymentResultAndPlanDiffForm,
    setHasErrorManufyearPlanurEmploymentPlanAndPlanDiffForm,
    setHasErrorEmptyManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 製造原価人員(重点分野)年計-実見で入力されたデータの形のチェック
    setHasErrorManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員(重点分野)年計-実見で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員(重点分野)中計-年計で入力されたデータの形のチェック
    setHasErrorManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員(重点分野)中計-年計で雇用形態、重点分野、差異理由、差異人数が全部で入力されているチェック
    setHasErrorEmptyManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
    saveAnswer,
    tempSaveAnswer,
    setPastAnswer,
    setPersonnelEmploymentTotal,
    setCorporateGridTotal,
    setSalesGridTotal,
    // 人件費人員（重点分野別）1.正社員　合計行データの設定方法
    setPersonnelPriorityDomainRegularEmployeeGridTotal,
    // 人件費人員（重点分野別）2.TTCからの出向受入　合計行データの設定方法
    setPersonnelPriorityDomainSecondmentFromTTCGridTotal,
    // 人件費人員（重点分野別）3.2以外の出向受入　合計行データの設定方法
    setPersonnelPriorityDomainSecondmentFromOtherGridTotal,
    // 人件費人員（重点分野別）4.派遣、嘱託、パート、アルバイト　合計行データの設定方法
    setPersonnelPriorityDomainDispatchEtcGridTotal,
    // 製造原価人員（重点分野別）1.正社員　合計行データの設定方法
    setManufyearPlanurPriorityDomainRegularEmployeeGridTotal,
    // 製造原価人員（重点分野別）2.1以外の従業員/派遣/その他　合計行データの設定方法
    setManufyearPlanurPriorityDomainOtherEmployeesGridTotal,
    updateAnswer,
    tempUpdateAnswer,
    setConfirmationCommentsModalMode,
    sendConfirmRequestConfirmedMail,
    // 編集した値をdispatch
    editAfterSave,
    changePersonnelEmploymentRowData,
    changePersonnelEmploymentResultAndPlanDiffForm,
    changePersonnelEmploymentPlanAndPlanDiffForm,
    changePersonnelCorporateRowData,
    changePersonnelCorporateOtherFormTextValue,
    changePersonnelSalesRowData,
    changeManufyearPlanurEmploymentRowData,
    changeManufyearPlanurEmploymentResultAndPlanDiffForm,
    changeManufyearPlanurEmploymentPlanAndPlanDiffForm,
    // 人件費人員（重点分野別） 1.正社員 編集した値をdispatch
    changePersonnelPriorityDomainRegularEmployeeRowData,
    // 人件費人員（重点分野別） 2.TTCからの出向受入 編集した値をdispatch
    changePersonnelPriorityDomainSecondmentFromTTCRowData,
    // 人件費人員（重点分野別） 3.2以外の出向受入 編集した値をdispatch
    changePersonnelPriorityDomainSecondmentFromOtherRowData,
    // 人件費人員（重点分野別） 4.派遣、嘱託、パート、アルバイト 編集した値をdispatch
    changePersonnelPriorityDomainDispatchEtcRowData,
    // 人件費人員（重点分野別） 年計-実見 編集した値をdispatch
    changePersonnelPriorityDomainResultAndPlanDiffForm,
    // 人件費人員（重点分野別） 中計-年計 編集した値をdispatch
    changePersonnelPriorityDomainPlanAndPlanDiffForm,
    // 製造原価人員（重点分野別） 1.正社員 編集した値をdispatch
    changeManufyearPlanurPriorityDomainRegularEmployeeRowData,
    // 製造原価人員（重点分野別） 2.1以外の従業員/派遣/その他 編集した値をdispatch
    changeManufyearPlanurPriorityDomainOtherEmployeesRowData,
    // 製造原価人員（重点分野別） 年計-実見 編集した値をdispatch
    changeManufyearPlanurPriorityDomainResultAndPlanDiffForm,
    // 製造原価人員（重点分野別） 中計-年計 編集した値をdispatch
    changeManufyearPlanurPriorityDomainPlanAndPlanDiffForm,
  };
};
