import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton } from '@mui/material';
import { Control, Controller, UseControllerProps } from 'react-hook-form';

type Props = {
  label: string;
  control: Control<any>;
  name: string;
  rules?: object;
  items: any;
  disabled?: boolean;
  onChange: (event: SelectChangeEvent<string>) => void | Promise<void>;
  defaultValue?: string;
  isLoading: boolean;
};

export const SelectControl = (props: UseControllerProps & Props) => {
  const { items, isLoading, control, onChange, rules, name, label, disabled } = props;

  return isLoading ? (
    <Skeleton variant="text" width={400} height={40} />
  ) : (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              label={label}
              disabled={disabled}
              {...field}
              onChange={async (event) => {
                field.onChange(event);
                await onChange(event);
              }}>
              {items.map((item: { key: string; name: string }) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
          </>
        )}
      />
    </FormControl>
  );
};
