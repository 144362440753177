import { Grid, Stack } from '@mui/material';
import { GridApi } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OverviewEnquete } from 'src/@types/overviewEnquete';
import Label from 'src/components/Label';
import { StyledBox } from 'src/components/app-components/StyledBox';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { ErrorTooltip } from 'src/components/app-components/StyledTooltip';
import { REQUEST_STATUS } from 'src/constants';
import { AlertModal } from 'src/features/general/enquete-answer/components/modal/select-trade-customer/components/AlertModal';
import {
  GridSetting,
  SelectCustomerModal,
} from 'src/features/general/enquete-answer/components/modal/select-trade-customer/components/SelectCustomerModal';
import { useGetSelectedRow } from 'src/features/general/enquete-answer/components/modal/select-trade-customer/hooks/useGetSelectedRow';
import { useTypeOverviewSurveyClickHandle } from 'src/features/general/enquete-answer/components/modal/select-trade-customer/hooks/useTypeOverviewSurveyClickHandle';
import {
  setQuestionDescriptionEng,
  setQuestionDescriptionJpn,
  toDisabledSaveButton,
  toEnabledSaveButton,
} from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import { trimStockCompany } from 'src/features/general/utils/trimStockCompany';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { RootState, dispatch, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { EnqueteDescriptionForm } from '../../../../../common/components/enquete-description-form/components/EnqueteDescriptionForm';
import { useOverviewQuestion } from '../hooks/useOverviewQuestion';
import OverviewInputEditBox from './OverviewInputEditBox';
import OverviewSetRateBox from './OverviewSetRateBox';
import { OverviewSetTargetOrgLevelTypeBox } from './OverviewSetTargetOrgLevelTypeBox';

const OverviewQuestionTab: React.FC = () => {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const isJapanese = currentLang.value === 'ja';
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const gridApi = useRef<GridApi | null>(null);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);

  const { onClickSaveData } = useOverviewQuestion();

  const isValidSaveButton = useAppSelector((state: RootState) => state.enqueteCreate.isValidSaveButton);
  const { enqueteData, additionalData, questionDescriptionJpn, questionDescriptionEng } = useAppSelector((state: RootState) => state.enqueteCreate);
  const isDeletedCustomer = useAppSelector((state: RootState) => state.enqueteCreate.isDeletedCustomer);
  const tabData = enqueteData?.surveyDetail?.surveyFormData?.formData.tabData[0] as OverviewEnquete;
  const [isChangeCustomer, setIsChangeCustomer] = useState<boolean>(false);
  const initDescriptionJpn = tabData?.questionDescriptionJpn;
  const initDescriptionEng = tabData?.questionDescriptionEng;
  const { isAdmin, isOverViewCreator, isSurveyOwner } = usePermission();
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);

  // 保存ボタンが押下できない条件の場合true
  const isDisabledSaveButton =
    !(isAdmin || isOverViewCreator || isSurveyOwner) ||
    REQUEST_STATUS.COMPLETE === requestStatus ||
    tabData?.customerNameJpn === undefined ||
    !isValidSaveButton ||
    isDeletedCustomer;

  // アンケート実施中に入力項目を追加、デフォルト設定されている値を変更した場合true
  const isChangedAdditionalData =
    additionalData?.purchaseFixedQuestions?.commercialFlow?.isAnswerTarget ||
    additionalData?.purchaseFixedQuestions?.mainCustomer?.isAnswerTarget ||
    additionalData?.purchaseFixedQuestions?.product?.isAnswerTarget ||
    additionalData?.salesFixedQuestions?.product?.isAnswerTarget ||
    additionalData?.salesFixedQuestions?.commercialFlow?.isAnswerTarget;
  const isEditInprogress = (): boolean => {
    if (REQUEST_STATUS.IN_PROGRESS !== requestStatus) return false;
    return !(
      isChangedAdditionalData ||
      additionalData?.purchaseAdditionalQuestions?.length > 0 ||
      additionalData?.salesAdditionalQuestions?.length > 0 ||
      additionalData?.otherAdditionalQuestions?.length > 0 ||
      initDescriptionJpn !== questionDescriptionJpn ||
      initDescriptionEng !== questionDescriptionEng
    );
  };

  const methods = useForm({
    mode: 'onChange',
  });

  const onClickSave = (data: FieldValues) => {
    onClickSaveData(enqueteData, data, isChangeCustomer);
  };

  // グリッドの設定
  const gridSettingCondition: GridSetting = {
    gridApi,
    isSingleSelection: true,
  };

  // 選択された取引先を取得する関数
  const { getSelectedRowElement } = useGetSelectedRow({ gridApi });

  // 取引先登録ロジック
  const { overviewSurveyOnClick, validOverviewOnClick } = useTypeOverviewSurveyClickHandle({
    setIsOpen,
    getSelectedRowElement,
    setIsAlertModalOpen,
    setIsChangeCustomer,
  });

  useEffect(() => {
    // バリデーションエラーの時の保存ボタン活性/非活性
    if (!methods.formState.isValid) {
      dispatch(toDisabledSaveButton());
    } else {
      dispatch(toEnabledSaveButton());
    }
  }, [methods.formState.isValid]);

  // TODO: api取得のuseEnqueteAnswerInputLayoutにてアンケートデータ取得時にdispatchをすれば、以下のコードが不要になる可能性があります。
  // この変更は概況表だけでなく、他機能でdispatchする必要がなくなります。
  useEffect(() => {
    if (!enqueteData.surveyDetailId) {
      dispatch(setQuestionDescriptionJpn(''));
      dispatch(setQuestionDescriptionEng(''));
    } else {
      dispatch(setQuestionDescriptionJpn(tabData?.questionDescriptionJpn));
      dispatch(setQuestionDescriptionEng(tabData?.questionDescriptionEng));
    }
  }, [tabData?.questionDescriptionEng, tabData?.questionDescriptionJpn, enqueteData.surveyDetailId]);

  const isDeletedLabel = () => {
    if (isDeletedCustomer) {
      return (
        <Label color="error" sx={{ lineHeight: 'normal', ml: 2, borderRadius: 0 }}>
          {t('enqueteCreate.overviewSurvey.deletedCustomer.label')}
        </Label>
      );
    }
  };
  return (
    <>
      <Stack spacing={1}>
        <Grid container direction={'row'} alignItems={'center'}>
          <EnqueteDescriptionForm />
          <Grid item xs={12}>
            <Label color="info" sx={{ lineHeight: 'normal', whiteSpace: 'normal', height: 'auto' }}>
              {t('enqueteStatus.overviewSurvey.message.customerDescription')}
            </Label>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={10}>
              <StyledBox>
                {t('common.customer')}：
                {tabData?.customerNameJpn ? (isJapanese ? trimStockCompany(tabData.customerNameJpn) : tabData.customerNameEng) : t('common.notSet')}
                {isDeletedLabel()}
              </StyledBox>
            </Grid>
            {/** 取引先を追加ボタン **/}
            <Grid item xs={2}>
              <StyledButton
                onClick={() => {
                  setIsOpen(true);
                }}
                children={t('button.addCustomer')}
                sx={{ marginTop: '8px' }}
                isDisabled={REQUEST_STATUS.BEFORE_REQUEST !== requestStatus}
              />
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <StyledBox>
              {/** 集計期間 **/}
              <Grid item sx={{ paddingBottom: '16px' }}>
                <OverviewSetRateBox methods={methods} />
              </Grid>
              {/** 集計対象単位 **/}
              <Grid item>
                <OverviewSetTargetOrgLevelTypeBox methods={methods} />
              </Grid>
            </StyledBox>
          </Grid>
          {/** 入力項目編集コンポーネント **/}
          <Grid item xs={12} sx={{ marginTop: '16px' }}>
            <OverviewInputEditBox />
          </Grid>
          {/** 保存ボタン **/}
          <Grid item xs={12} textAlign={'right'} justifySelf={'flex-end'}>
            <ErrorTooltip
              title={t('enqueteCreate.overviewSurvey.deletedCustomer.message') ?? ''}
              open={(isAdmin || isOverViewCreator || isSurveyOwner) && REQUEST_STATUS.BEFORE_REQUEST === requestStatus && isDeletedCustomer}
              placement="top"
              color="secondary"
              arrow>
              <span>
                <StyledButton
                  onClick={methods.handleSubmit(onClickSave)}
                  children={t('button.save')}
                  isDisabled={isDisabledSaveButton || isEditInprogress()}
                />
              </span>
            </ErrorTooltip>
          </Grid>
        </Grid>
      </Stack>

      {/** モーダル **/}
      <SelectCustomerModal isOpen={isOpen} setIsOpen={setIsOpen} gridSettingCondition={gridSettingCondition} onClick={validOverviewOnClick} />
      <AlertModal
        isOpen={isAlertModalOpen}
        onClose={() => {
          setIsAlertModalOpen(false);
        }}
        onClick={() => {
          overviewSurveyOnClick();
          setIsAlertModalOpen(false);
        }}
      />
    </>
  );
};
export default OverviewQuestionTab;
