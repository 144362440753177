import { Typography } from '@material-ui/core';
import { Box, Grid, Pagination } from '@mui/material';
import type {
  AgGridEvent,
  ColDef,
  ColGroupDef,
  ColumnState,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  RowDoubleClickedEvent,
} from 'ag-grid-community';
import { useSnackbar } from 'notistack5';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePermissionApi } from 'src/api/usePermissionApi';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { SurveyInformationModal } from 'src/components/app-components/bonsai/survey-information/components/SurveyInformationModal';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import StyledDialog from 'src/components/app-components/StyledDialog';
import { LIST_STATE_KEY_PREFIX } from 'src/constants';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { setPermission } from 'src/redux/slices/pageSlice';
import { dispatch, RootState, useAppSelector } from 'src/redux/store';
import { CreatedSurveyEntity, initCreatedSurvey, SurveyIdsTypes } from '../../../../../../../api/useCreatedSurvey';
import { ENQUETE_ANSWER_PATH } from '../../../../../enquete-answer/routes/path';
import { ENQUETE_CREATE_PATH } from '../../../../../enquete-create/routes/path';
import { ENQUETE_STATUS_PATH } from '../../../../../enquete-status/routes/path';
import { useEnqueteRequestListGrid } from '../../../../hooks/useEnqueteRequestListGrid';
import EnqueteRequestListColDef from '../../../modals/alert-personal-data/components/EnqueteRequestListColDef';

const initDeleteRowData: SurveyIdsTypes = {
  respondentRequestId: '',
  surveyDetailId: '',
};

export const EnqueteRequestListGrid: React.FC = (): React.ReactElement => {
  const listData: CreatedSurveyEntity[] = useAppSelector((state: RootState) => state.enqueteRequest.listData);
  const hook = useEnqueteRequestListGrid();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermission } = usePermissionApi();
  const { enqueueSnackbar } = useSnackbar();
  const { currentLang } = useLocales();
  const startRowPosition: number = 0;
  const startPagePosition: number = 1;

  const storageKey = 'EnqueteRequestListGrid';

  // 削除ダイアログ
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // 削除するRespondentRequestIdを格納
  const [deleteRowIds, setDeleteRowIds] = useState<SurveyIdsTypes>(initDeleteRowData);
  // ローディング
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // 複製モーダル
  const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState<boolean>(false);
  // RespondentRequestデータ格納
  const [enqueteData, setEnqueteData] = useState<CreatedSurveyEntity>(initCreatedSurvey);
  // グリッドソートデータ
  const [useSortModel, setUseSortModel] = useState<ColumnState[]>([]);
  // 現在のページ
  const [page, setPage] = useState<number>(1);
  // 1ページ表示数
  const [blockSize] = useState<number>(100); // eslint-disable-line
  // 最大行数
  const [maxRow, setMaxRow] = useState<number>(1);
  // グリッド表示削除フラグ
  const [isDestroyed, setIsDestroyed] = useState(false);

  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  // 削除対象
  const deleteListId = listData.find((v) => v.surveyDetailId === deleteRowIds.surveyDetailId);
  let deleteConfirmDialogContent;
  if (deleteListId) {
    const now = new Date();
    const open = new Date(deleteListId.openedAt);
    const close = new Date(deleteListId.closedAt);
    if (now < open && open < close) {
      deleteConfirmDialogContent = <Typography>{t('enqueteRequest.message.deleteConfirmDialogContentBefore')}</Typography>;
    } else if (open < now && now < close) {
      deleteConfirmDialogContent = <Typography color="error">{t('enqueteRequest.message.deleteConfirmDialogContentProgress')}</Typography>;
    } else if (open < now && close < now) {
      deleteConfirmDialogContent = <Typography color="error">{t('enqueteRequest.message.deleteConfirmDialogContentComplete')}</Typography>;
    }
  }
  //メッセージ定義
  const successCopyUrlMessage = t('enqueteRequest.message.successCopyUrl');
  const deleteConfirmDialogTitle = t('enqueteRequest.message.deleteConfirmDialogTitle');

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------

  // 初回起動
  useEffect(() => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setCountLimitedRowData(filter, currentLang.code);
  }, []); // eslint-disable-line

  useEffect(() => {
    destroyGrid();
  }, [currentLang]); // eslint-disable-line

  useEffect(() => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setLimitedRowData(blockSize, (page - startPagePosition) * blockSize, useSortModel, filter, currentLang.code);
  }, [page, currentLang]); // eslint-disable-line

  //-------------------------------------------------------------
  // 選択肢の言語変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    setIsDestroyed(true);
    setTimeout(() => recreateGrid(), 0);
  };

  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  // EnqueteRequestListColDef読み込み
  const coldef: (ColDef | ColGroupDef)[] = EnqueteRequestListColDef();

  //最大件数データ取得
  const setCountLimitedRowData = async (useFiletrModel: { [key: string]: any }, currentLang: string) => {
    setIsLoading(true);
    try {
      const rowCount = await hook.getCountLimitedLoadList(useFiletrModel, currentLang);
      setMaxRow(rowCount !== undefined ? rowCount : 1);
    } finally {
      setIsLoading(false);
    }
  };

  //グリッドデータ取得
  const setLimitedRowData = async (
    blockSize: number,
    startRow: number,
    useSortModel: any,
    useFiletrModel: { [key: string]: any },
    currentLang: string,
  ) => {
    setIsLoading(true);
    try {
      //データ取得
      await hook.getLimitedLoadList(blockSize, startRow, useSortModel, useFiletrModel, currentLang);
    } finally {
      setIsLoading(false);
    }
  };

  //ダブルクリック時の挙動定義
  const onRowDoubleClickedCallback = (event: RowDoubleClickedEvent) => {
    navigate(`${ENQUETE_STATUS_PATH.INDEX}/${event.data.id}?surveyDetailId=${event.data.surveyDetailId}`);
  };

  //URL取得
  const onClickCopyUrl = (id: string) => {
    navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + ENQUETE_ANSWER_PATH.INDEX);
    enqueueSnackbar(successCopyUrlMessage, {
      variant: 'success',
    });
  };

  //行を削除
  const onClickDelete = () => {
    hook.deleteRow(deleteRowIds);
    setIsOpen(false);
  };

  //コンテキストメニュー
  const contextMenu: GetContextMenuItems = (params: GetContextMenuItemsParams) => {
    if (params.node === null) return [];

    const menuItems = [
      {
        name: t('enqueteRequest.contextMenu.goToEnqueteStatus'),
        action: () => {
          navigate(`${ENQUETE_STATUS_PATH.INDEX}/${params.node?.data.id}?surveyDetailId=${params.node?.data.surveyDetailId}`);
        },
      },
      {
        name: t('enqueteRequest.contextMenu.editEnquete'),
        action: () => {
          navigate(`${ENQUETE_CREATE_PATH.EDIT}/${params.node?.data.id}`);
        },
      },
      {
        name: t('enqueteRequest.contextMenu.surveyDuplication'),
        action: async () => {
          //選択した行のアンケートデータを格納
          setEnqueteData(params.node?.data);
          await checkPermission({ surveyDetailId: params.node?.data.surveyDetailId }).then((result) => {
            dispatch(setPermission(result));
          });
          //アンケート複製編集モーダル オープン
          setIsOpenDuplicateModal(true);
        },
      },
      {
        name: t('enqueteRequest.contextMenu.copyUrl'),
        action: () => {
          onClickCopyUrl(params.node?.data.id);
        },
      },
      {
        name: t('enqueteRequest.contextMenu.delete'),
        action: () => {
          setDeleteRowIds({ respondentRequestId: params.node?.data.id, surveyDetailId: params.node?.data.surveyDetailId });
          setIsOpen(true);
        },
      },
    ];

    return menuItems;
  };

  const setFilterSortLimitedRowData = () => {
    const filterModel = localStorage.getItem(`${LIST_STATE_KEY_PREFIX.FILTER}${storageKey}`);
    const filter = filterModel ?? {};
    setCountLimitedRowData(filter, currentLang.code);
    setPage(startPagePosition);
    setLimitedRowData(blockSize, startRowPosition, useSortModel, filter, currentLang.code);
  };

  const onFilterChangedCallback = (event: AgGridEvent) => {
    setFilterSortLimitedRowData();
  };

  const onSortChangedCallback = (event: AgGridEvent) => {
    setUseSortModel(event.columnApi.getColumnState());
    setFilterSortLimitedRowData();
  };

  const setChangePgae = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box
            className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
            sx={{
              height: '95vh',
              width: '100%',
            }}>
            <LoadableComponent isLoading={isLoading}>
              {!isDestroyed && (
                <StyledAgGrid
                  coldef={coldef}
                  rows={listData}
                  contextMenu={contextMenu}
                  onRowDoubleClickedCallback={onRowDoubleClickedCallback}
                  onSortChangedCallback={onSortChangedCallback}
                  onFilterChangedCallback={onFilterChangedCallback}
                  storageKey={storageKey}
                />
              )}
              <Grid item xs={12} md={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Pagination count={maxRow <= blockSize ? 1 : Math.ceil(maxRow / blockSize)} page={page} onChange={setChangePgae} />
                </div>
              </Grid>
            </LoadableComponent>
          </Box>
        </Grid>
      </Grid>

      {/* 削除ダイアログ */}
      <StyledDialog
        dialogTitle={deleteConfirmDialogTitle}
        dialogContent={deleteConfirmDialogContent}
        isOpen={isOpen}
        disagreeCallback={() => setIsOpen(false)}
        agreeCallback={onClickDelete}
      />

      {/** アンケート複製編集モーダル */}
      <SurveyInformationModal
        sourceEnqueteData={enqueteData}
        setIsModalOpen={setIsOpenDuplicateModal}
        isModalOpen={isOpenDuplicateModal}
        modalTitle={t('enqueteRequest.surveyDuplicationModal.title')}
      />
    </>
  );
};
