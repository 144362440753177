import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SeriesAggregationData,
  SeriesAggregationSbuInfo,
  SeriesSurveyFormAggregationData,
  SeriesSurveyFormTabData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';

import { useSnackbar } from 'notistack5';
import { RootState, useAppSelector } from 'src/redux/store';
import { useEnqueteCommon } from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteCommon';
import {
  SeriesEnqueteRowData,
  useEnqueteModal,
} from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteModal';
import { useSeriesEnqueteCreateModal } from './useSeriesEnqueteCreateModal';
import { initSelectedRowData } from './useSeriesEnqueteCreateModalConditions';
import { useSeriesEnqueteCreateModalValidation } from './useSeriesEnqueteCreateModalValidation';

// 検索用条件
interface SearchConditionProps {
  divisionCode: string;
  sbuCode: string;
}

// 検索用条件初期値
export const initSearchCondition: SearchConditionProps = {
  divisionCode: '',
  sbuCode: '',
};

interface Props {
  targetData: SeriesEnqueteRowData | undefined;
  selectedModalData: SeriesEnqueteRowData;
  setSelectedModalData: Dispatch<SetStateAction<SeriesEnqueteRowData>>;
  selectedModalRowData: SeriesSurveyFormAggregationData[];
  setSelectedModalRowData: Dispatch<SetStateAction<SeriesSurveyFormAggregationData[]>>;
  setIsEnableSelect: Dispatch<SetStateAction<boolean>>;
  makeSbus: (divisionCode?: string) => Promise<void>;
}

export const useSeriesEnqueteCreateModalSetting = (props: Props) => {
  const { targetData, selectedModalData, setSelectedModalData, selectedModalRowData, setSelectedModalRowData, setIsEnableSelect, makeSbus } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isSave, setIsSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchCondition, setSearchCondition] = useState<SearchConditionProps>(initSearchCondition);
  const [beforeEditModalData, setBeforeEditModalData] = useState<SeriesEnqueteRowData>(initSelectedRowData);

  // 検索用リスト
  const { concatAndSort, sortSeriesSurveyFormDataList } = useEnqueteCommon();
  const { onRegister, onUpdate } = useSeriesEnqueteCreateModal({ selectedModalData, selectedModalRowData, beforeEditModalData });
  const { closeEditModal, modalNormalMode } = useEnqueteModal();
  const { isDuplication } = useSeriesEnqueteCreateModalValidation();

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const isEditMode = useAppSelector((state: RootState) => state.enqueteCreate.isEditSbuAggregationModal);

  /**
   * モーダルで選択したデータの整合性を確認し、正常であればモーダル追加リストに選択データを追加する
   * 整合性でエラーが出た場合はスナックバーを表示する
   */
  const addRowData = (): void => {
    const aggregationList = (enqueteData.surveyDetail.surveyFormData?.formData?.tabData[0] as SeriesSurveyFormTabData)?.aggregationList || [];
    // 選択済みデータと重複チェック
    if (isDuplication(selectedModalData, selectedModalRowData)) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadySelected'), { variant: 'warning' });
      return;
    }
    // 登録済みデータと重複チェック
    if (isDuplication(selectedModalData, aggregationList)) {
      enqueueSnackbar(t('enqueteCreate.seriesEnquete.modal.message.alreadyRegistered'), { variant: 'warning' });
      return;
    }
    // モーダルの追加データがなにもない場合
    if (selectedModalRowData.length === 0) {
      saveSelectedModalRowData(concatAndSort(selectedModalRowData, createData(selectedModalData)));
    } else {
      let isEdit = false;
      const data: SeriesSurveyFormAggregationData[] = [...selectedModalRowData];
      data.forEach((row) => {
        if (row.divisionCode === selectedModalData.divisionCode) {
          row.sbu.forEach((s) => {
            if (s.destination.sbuCode === selectedModalData.destinationSbuCode) {
              // 集約元だけ追加の場合
              s.source.push({
                sbuCode: selectedModalData.sourceSbuCode,
                sbuNameJpn: selectedModalData.sourceSbuNameJpn,
                sbuNameEng: selectedModalData.sourceSbuNameEng,
                organizationId: selectedModalData.sourceSbuOrganizationId,
              });
              isEdit = true;
            }
          });
          if (!isEdit) {
            // 集約先、集約元の追加の場合
            row.sbu.push({
              destination: {
                sbuCode: selectedModalData.destinationSbuCode,
                sbuNameJpn: selectedModalData.destinationSbuNameJpn,
                sbuNameEng: selectedModalData.destinationSbuNameEng,
                organizationId: selectedModalData.destinationSbuOrganizationId,
              },
              source: [
                {
                  sbuCode: selectedModalData.sourceSbuCode,
                  sbuNameJpn: selectedModalData.sourceSbuNameJpn,
                  sbuNameEng: selectedModalData.sourceSbuNameEng,
                  organizationId: selectedModalData.sourceSbuOrganizationId,
                },
              ],
            });
            isEdit = true;
          }
          saveSelectedModalRowData(sortSeriesSurveyFormDataList(data));
        }
        if (!isEdit) {
          // 本部が存在しないため新規追加
          saveSelectedModalRowData(concatAndSort(selectedModalRowData, createData(selectedModalData)));
        }
      });
    }
  };

  /**
   * モーダルデータを、DB保存用データに加工する
   * @param data モーダルデータ
   * @returns DB保存用データ
   */
  const createData = (data: SeriesEnqueteRowData) => {
    return {
      divisionCode: data.divisionCode,
      divisionNameJpn: data.divisionNameJpn,
      divisionNameEng: data.divisionNameEng,
      organizationId: data.divisionOrganizationId,
      sbu: [
        {
          destination: {
            sbuCode: data.destinationSbuCode,
            sbuNameJpn: data.destinationSbuNameJpn,
            sbuNameEng: data.destinationSbuNameEng,
            organizationId: data.destinationSbuOrganizationId,
          },
          source: [
            {
              sbuCode: data.sourceSbuCode,
              sbuNameJpn: data.sourceSbuNameJpn,
              sbuNameEng: data.sourceSbuNameEng,
              organizationId: data.sourceSbuOrganizationId,
            },
          ],
        },
      ],
    };
  };

  /**
   * モーダル内で追加したSBU集約情報の行を削除する
   * @param data 削除データ
   */
  const deleteModalRaw = (data: SeriesEnqueteRowData): void => {
    // 削除されない本部に紐づくデータの確認
    const noDeleteSbuInfo = selectedModalRowData.filter((v) => v.divisionCode !== data.divisionCode);
    const deleteSbuInfo: SeriesSurveyFormAggregationData | undefined = selectedModalRowData.find((v) => v.divisionCode === data.divisionCode);
    if (!deleteSbuInfo) return;

    const sbuInfo: SeriesAggregationSbuInfo[] = [];
    for (const sbu of deleteSbuInfo.sbu) {
      const sourceList: SeriesAggregationData[] = [];

      if (sbu.destination.sbuCode === data.destinationSbuCode) {
        // 削除する集約先の場合
        for (const source of sbu.source) {
          if (source.sbuCode !== data.sourceSbuCode) {
            sourceList.push(source); // // 削除されない集約元のみ追加
          }
        }
        // 削除しない集約元が存在する場合は集約先と集約元を追加
        if (sourceList.length > 0) {
          const destination = {
            sbuCode: sbu.destination.sbuCode,
            sbuNameJpn: sbu.destination.sbuCode,
            sbuNameEng: sbu.destination.sbuCode,
            organizationId: sbu.destination.organizationId,
          };
          sbuInfo.push({ destination: destination, source: sourceList });
        }
      } else {
        // 削除されない集約先であればそのまま追加
        sbuInfo.push(sbu);
      }
    }
    // 削除後の情報に集約先と集約元がなければ本部ごと削除
    if (sbuInfo.length === 0) {
      saveSelectedModalRowData(sortSeriesSurveyFormDataList([...noDeleteSbuInfo]));
      return;
    }

    const deletedAggregationData: SeriesSurveyFormAggregationData = {
      divisionCode: deleteSbuInfo.divisionCode,
      divisionNameJpn: deleteSbuInfo.divisionNameJpn,
      divisionNameEng: deleteSbuInfo.divisionNameEng,
      organizationId: selectedModalData.divisionOrganizationId,
      sbu: sbuInfo,
    };

    saveSelectedModalRowData(sortSeriesSurveyFormDataList([...noDeleteSbuInfo, deletedAggregationData]));
  };

  /**
   * モーダルデータを追加時のラッパー
   * Stateにsetし、保存ボタンの活性制御を行う
   * @param selectedModalRowData
   */
  const saveSelectedModalRowData = (selectedModalRowData: SeriesSurveyFormAggregationData[]) => {
    setSelectedModalRowData(selectedModalRowData);
    setIsSave(selectedModalRowData.length > 0);
  };

  /**
   * 設定された値の初期化を行う
   */
  const setInitState = () => {
    const createSbu = async () => {
      // モダールのオートコンプリートを設定する
      await makeSbus();
    };
    createSbu();
    setSearchCondition(initSearchCondition);
    setSelectedModalData(initSelectedRowData);
    setIsEnableSelect(false);
    modalNormalMode();
    setBeforeEditModalData(initSelectedRowData);
    saveSelectedModalRowData([]);
  };

  const onClickSaveButton = async () => {
    setIsLoading(true);
    isEditMode ? await onUpdate() : await onRegister();
    setInitState();
    setIsLoading(false);
  };

  const onClose = () => {
    closeEditModal();
    setInitState();
  };

  // 編集押下時のバインド
  useEffect(() => {
    if (!targetData || !isEditMode) return;
    const createSbu = async (targetData?: SeriesEnqueteRowData) => {
      // モダールのオートコンプリートを設定する
      setIsLoading(true);
      await makeSbus(targetData?.divisionCode ?? undefined);
      setIsLoading(false);
    };
    createSbu(targetData);
    setSelectedModalData(targetData);
    setBeforeEditModalData(targetData);
  }, [isEditMode]); // eslint-disable-line

  return {
    isSave,
    isLoading,
    addRowData,
    deleteModalRaw,
    searchCondition,
    onClickSaveButton,
    onClose,
  };
};
