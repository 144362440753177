import { Grid, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RHFDeskTopDatePicker from 'src/components/react-hook-form/RHFDeskTopDatePicker';
import { AnswerUnit, ANSWER_UNIT } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { yearMonthFormat } from 'src/utils/formatDateTime';

type OrganizationBaseDateFormProps = {
  methods: UseFormReturn;
  answerUnit: AnswerUnit;
};

export const OrganizationBaseDateForm = ({ methods, answerUnit }: OrganizationBaseDateFormProps) => {
  const { rulesOrganizationBaseDate } = useValidator(methods);
  const { t } = useTranslation();

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
      <Grid item xs={12} md={4}>
        <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }}>
          {t('enqueteCreate.editEnqueteModal.organizationBaseDate')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <RHFDeskTopDatePicker
          name="organizationBaseDate"
          label={t('enqueteCreate.editEnqueteModal.organizationBaseDate')}
          control={methods.control}
          mask="____/__"
          views={['year', 'month']}
          defaultValue={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
          openTo="year"
          rules={rulesOrganizationBaseDate}
          inputFormat={yearMonthFormat}
          type="month"
          disabled={answerUnit === ANSWER_UNIT.PERSONAL ? true : false}
        />
      </Grid>
    </Grid>
  );
};
