import { useSnackbar } from 'notistack5';
import { useEffect, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserEntity } from 'src/api/useUserApi';
import useAuth from 'src/hooks/useAuth';

export const useSurveyOwnerSelectBox = (methods: UseFormReturn, isActive?: boolean) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [isSelectOwnerModalOpen, setIsSelectOwnerModalOpen] = useState(false);
  const [isModalOpenedOnce, setIsModalOpenedOnce] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'surveyOwnerEmails',
  });

  methods.register('surveyOwnerEmails', {
    validate: (value) => {
      if (isActive === true) {
        return (value && value.length > 0) || t('enqueteCreate.editEnqueteModal.message.invalidNumberOfCoOwner');
      } else {
        return true;
      }
    },
  });

  useEffect(() => {
    // memo : バリデーションをトリガーするのはコンポーネントを表示するときのみ
    methods.trigger('surveyOwnerEmails');
  }, [isActive, methods]);

  const handleOnAdd = () => {
    setIsSelectOwnerModalOpen(true);
    setIsModalOpenedOnce(true);
  };

  const handleOnRemove = (id: number) => {
    remove(id);
  };

  const handleOnOkOnSelectOwnerModal = (coOwners: UserEntity[]) => {
    // memo : すでに追加したユーザーは除外
    if (coOwners.some((coOwner) => (fields as UserEntity[]).some((selectedOwner) => coOwner.email === selectedOwner.email))) {
      return enqueueSnackbar(t('enqueteAnswerInput.changeRespondents.addRespondentsModal.message.alreadySelected'), { variant: 'warning' });
    }
    append(coOwners);
    setIsSelectOwnerModalOpen(false);
  };

  const handleOnCloseOnSelectOwnerModal = () => {
    setIsSelectOwnerModalOpen(false);
  };

  const validateOnSelectOwnerModal = (coOwners: UserEntity[]) => {
    // memo : 登録者を含まないこと
    if (coOwners.some((coOwner) => coOwner.email === user?.email)) {
      return false;
    }
    // memo : すでに追加したユーザーを含まないこと
    if ((fields as UserEntity[]).some((selectedOwner) => coOwners.some((coOwner) => coOwner.email === selectedOwner.email))) {
      return false;
    }
    return true;
  };

  return {
    handleOnAdd,
    handleOnRemove,
    handleOnOkOnSelectOwnerModal,
    validateOnSelectOwnerModal,
    handleOnCloseOnSelectOwnerModal,
    isModalOpenedOnce,
    isSelectOwnerModalOpen,
    fields,
  };
};
