import { Autocomplete, Box, Button, Grid, Modal, Stack, SxProps, TextField, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import useSettings from 'src/hooks/useSettings';
import { RootState, useAppSelector } from 'src/redux/store';
import palette from 'src/theme/palette';
import { useEnqueteGrid } from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteGrid';
import { SeriesEnqueteRowData } from '../../../tab-containers/type-serires-survey/components/tabs/create-survey/hooks/useEnqueteModal';
import {
  ModalSearchConditionProps,
  SearchConditionAutoCompleteProps,
  useSeriesEnqueteCreateModalConditions,
} from '../hooks/useSeriesEnqueteCreateModalConditions';

export interface Props {
  surveyDetailId: string;
  targetData: SeriesEnqueteRowData | undefined;
}

export const SeriesEnqueteCreateModal: React.FC<Props> = memo((props) => {
  const { surveyDetailId, targetData } = props;
  const { t } = useTranslation();

  const isOpenEditModal = useAppSelector((state: RootState) => state.enqueteCreate.isOpenSbuAggregationModal);
  const isEditMode = useAppSelector((state: RootState) => state.enqueteCreate.isEditSbuAggregationModal);
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const modalStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 900,
    bgcolor: isLight ? palette.light.background.paper : palette.dark.background.paper,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const gridStyle: SxProps = {
    height: '50vh',
    width: '100%',
    transition: 'height 0.5s',
  };

  const { conditions, contextMenuItems, saveButtons, addButton, onClose, viewData, isLoading } = useSeriesEnqueteCreateModalConditions({
    targetData,
    surveyDetailId,
  });

  const title = t('enqueteCreate.seriesEnquete.modal.title');

  const { defaultColDef, colDef } = useEnqueteGrid();

  /**
   * 系列別SBU集約モーダル内の本部、SBUオートコンプリート機能の作成
   * @param condition
   * @returns
   */
  const renderAutoComplete = (condition: ModalSearchConditionProps) => {
    const props = condition.props as SearchConditionAutoCompleteProps;

    return (
      <Autocomplete
        id={condition.id}
        size="small"
        options={props.list}
        // memo: [Encountered two children with the same key] の発生を抑制するため、renderOptionを定義
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {option.label}
          </li>
        )}
        value={props.list.find((e) => e.key === props.value) || { key: '', label: '' }}
        // memo: [You can use the `isOptionEqualToValue` prop to customize the equality test.] 警告対策
        isOptionEqualToValue={(option, value) => (value.key === '' ? true : option.key === value.key)}
        onChange={(_, v) => props.onChange(v?.key)}
        renderInput={(params) => <TextField {...params} label={condition.label} />}
        disabled={condition.disabled}
      />
    );
  };

  return (
    <>
      <Modal open={isOpenEditModal} onClose={onClose}>
        <Box component={'div'} sx={modalStyle}>
          {/* modal title */}
          <Typography variant="h4" component={'h2'}>
            {title}
          </Typography>
          <Grid container component={'div'} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              {conditions.map((conditionList, pIndex) => (
                <Grid container key={pIndex} sx={{ mt: 1, mr: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {conditionList.map((condition, cIindex) => (
                    <Grid key={cIindex} item xs={condition.style.xs}>
                      {renderAutoComplete(condition)}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {!isEditMode && (
            <>
              <Grid container component={'div'} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Grid item xs={12} sx={{ mt: 1, mr: 1 }}>
                    <Stack direction={'row-reverse'}>
                      <Button variant="contained" onClick={addButton.onClick} disabled={addButton.disabled !== undefined && addButton.disabled}>
                        {addButton.name}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container sx={{ pt: 1.5 }}>
                <Grid item xs={12}>
                  <Box className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} sx={gridStyle}>
                    <StyledAgGrid defaultColDef={defaultColDef} coldef={colDef} rows={viewData} contextMenu={contextMenuItems} />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {/* modal bottom actions */}
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6} justifyContent={'left'}>
              <Stack direction={'row'} spacing={1}>
                <Button variant="outlined" onClick={onClose} color="error">
                  {t('button.close')}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={'row-reverse'}>
                <Button variant="contained" onClick={saveButtons.onclick} disabled={saveButtons.disabled}>
                  {saveButtons.name}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <StyledLoading isOpen={isLoading} />
    </>
  );
});
