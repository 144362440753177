import axios from 'axios';
import { useSnackbar } from 'notistack5';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationData, useNotificationApi } from 'src/api/useNotificationApi';

export const useNotificationEditList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<NotificationData[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const { deleteById, getAllListForAdmin } = useNotificationApi();
  const take = 10;

  const fetchNotifications = useCallback(
    async (page: number, take: number) => {
      setIsLoading(true);

      try {
        const response = await getAllListForAdmin(page, take);
        setData(response.data);
        setTotalCount(response.total);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status !== 403) {
            enqueueSnackbar(t('notifications.messages.failedToRetrieve'), { variant: 'error' });
          }
        }
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, getAllListForAdmin, t],
  );

  useEffect(() => {
    fetchNotifications(page, take);
  }, [fetchNotifications, page]);

  const deleteNotification = useCallback(
    async (id: string) => {
      if (!window.confirm(t('notifications.messages.deleteConfirmation'))) return;

      setIsLoading(true);

      try {
        await deleteById(id);
        // memo: 削除した場合は削除済みとするためページの移動はしない。
      } catch (error) {
        if (axios.isAxiosError(error)) {
          enqueueSnackbar(t('notifications.messages.failedToDelete'), { variant: 'error' });
        }
        setHasError(true);
      } finally {
        await fetchNotifications(page, take);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteById, enqueueSnackbar, fetchNotifications, page, t, totalCount],
  );

  return { isLoading, hasError, notifications: data, page, setPage, count: Math.ceil(totalCount / take), deleteNotification };
};
