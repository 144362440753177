import { Box, Grid } from '@mui/material';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import {
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  PersonnelSurveyGridFormData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerTranslation } from '../../../../hooks/useEnqueteAnswerTranslation';
import {
  DarkRowStyleByPersonnelSurvey,
  NotInputDarkRowStyleByPersonnelSurvey,
  NotInputRowStyleByPersonnelSurvey,
  RowStyleByPersonnelSurvey,
} from '../../../../utils/RowStyleByPersonnelSurvey';

//-------------------------------------------------------------
// Props
//-------------------------------------------------------------
export interface CreateReferenceAnotherGridProps {
  // form定義
  form: PersonnelSurveyGridFormData;
  // 行データ
  rowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 過去データ
  pastRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  // 行データのインデックス
  // props.form.initialValueの何番目の行定義の利用するか指定
  rowIndex: number;
}

//-------------------------------------------------------------
// 画面共通：（参考）他タブでの入力情報
//-------------------------------------------------------------
export const CreateReferenceAnotherGrid: React.FC<CreateReferenceAnotherGridProps> = (props) => {
  // テーマ設定
  const { themeMode } = useSettings();
  // テーマの初期値
  const isLight = themeMode === 'light';
  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  // ローカルの言語
  const { currentLang } = useLocales();
  const useJudg = useJudgmentCurrentLanguage();
  // 回答入力の言語処理
  const useAnswerTranslate = useEnqueteAnswerTranslation();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  // 列の定義
  const [colDef, setColDef] = useState<(ColDef | ColGroupDef)[]>([]);
  // 行データ
  const [rowData, setRowData] = useState<EnqueteAnswerInputEntity[] | undefined>(undefined);
  // ローディング
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // gridデストロイ
  const [isDestroyed, setIsDestroyed] = useState(false);

  //-------------------------------------------------------------
  // gridの各種設定情報
  //-------------------------------------------------------------
  const gridOptions: GridOptions = {
    // 行選択:複数行を選択可能
    rowSelection: 'multiple',
    // セル選択時行を選択しない
    suppressRowClickSelection: true,
    // 範囲を選択可能
    enableRangeSelection: true,
    // 範囲の操作処理
    enableFillHandle: true,
    // マウスダウン
    enterMovesDown: true,
    // 編集後マウスダウン
    enterMovesDownAfterEdit: true,
    // 行スタイル
    getRowStyle: isEnqueteAnswerOpen
      ? isLight
        ? RowStyleByPersonnelSurvey
        : DarkRowStyleByPersonnelSurvey
      : isLight
      ? NotInputRowStyleByPersonnelSurvey
      : NotInputDarkRowStyleByPersonnelSurvey,
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    // 列定義の更新
    refreshColDefData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnqueteAnswerOpen, isLight]);

  useEffect(() => {
    // 行データの更新
    refreshRowData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form, props.rowData, props.pastRowData, currentLang]);

  useEffect(() => {
    // gridデストロイ
    destroyGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLight, isEnqueteAnswerOpen]);

  //-------------------------------------------------------------
  // テーマ変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    // gridデストロイ
    setIsDestroyed(true);
    // タイムアウト
    setTimeout(() => recreateGrid(), 0);
  };

  // gird再生成
  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  //-------------------------------------------------------------
  // スタイル再設定
  //-------------------------------------------------------------
  const colDefReset = (orgColDef: ColDef[]) => {
    // データが存在しない場合のみ、列定義を戻す
    if (!props.form.data) {
      return orgColDef;
    }
    // 背景色(ライトモード)
    const backColorLight = props.form.initialValue[props.rowIndex].dataStyle?.backgroundColorLight;
    // 背景色(ダークモード)
    const backColorDark = props.form.initialValue[props.rowIndex].dataStyle?.backgroundColorDark;

    orgColDef.forEach((element) => {
      // 表示のみ、入力できない
      element.editable = false;
      // コラムが「previousYearPlan」の場合
      if (element.field === 'previousYearPlan') {
        // セルのスタイル
        element.cellStyle = {
          backgroundColor: isLight
            ? props.form.data[1].cellStyle?.backgroundColor
              ? props.form.data[1].cellStyle?.backgroundColor
              : '#fff'
            : '#0b0907',
          textAlign: 'end',
        };
        // コラムが「inputItem」ではない、かつ、背景色が存在する場合
      } else if (element.field !== 'inputItem' && backColorLight && backColorDark) {
        // セルのスタイル
        element.cellStyle = {
          backgroundColor: isLight ? backColorLight : backColorDark,
          textAlign: 'end',
        };
      }
    });
    return orgColDef;
  };

  //-------------------------------------------------------------
  // テーブルの更新
  //-------------------------------------------------------------
  const refreshColDefData = async () => {
    // ローディング画面を表示
    setIsLoading(true);
    // 列定義の作成
    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = useAnswerTranslate.formatColDef(props.form);
    await setColDef(colDefReset(enqueteAnswerColDef));
    // 0.1秒待つ
    await new Promise((resolve) => setTimeout(resolve, 100));

    // ローディング画面を非表示
    setIsLoading(false);
  };

  //-------------------------------------------------------------
  // gridの行データの作成
  //-------------------------------------------------------------
  const refreshRowData = async () => {
    // 列定義
    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = useAnswerTranslate.formatColDef(props.form);

    // ヘッダー名削除
    enqueteAnswerColDef.forEach((columnColDef: ColDef) => {
      if (columnColDef.field === 'inputItem') {
        columnColDef.headerName = '';
        columnColDef.headerTooltip = '';
      }
    });
    // 列定義の設定
    await setColDef(colDefReset(enqueteAnswerColDef));

    // FormJsonからグリッドの列項目を取得
    let baseData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.form.initialValue) {
      if (props.form.initialValue.length >= props.rowIndex) {
        baseData.push(props.form.initialValue[props.rowIndex]);
      }
    }

    // AnswerJsonから回答情報を取得する
    let answerRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.rowData) {
      if (props.rowData.length >= props.rowIndex) {
        answerRowData.push(props.rowData[props.rowIndex]);
      }
    }

    // FromとAnswerを合体させてRowDataを作成する。
    let tempRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== baseData && undefined !== answerRowData) {
      baseData.forEach((row: any) => {
        const tempAnswerRowData = answerRowData.filter((x) => x.id === row.id);
        const pushRowData: EnqueteAnswerInputEntity = {
          id: row.id as string,
          inputItem: useJudg.getText(row.inputItem as languageDataTypes),
          resultForecast: tempAnswerRowData[0] && tempAnswerRowData[0].resultForecast !== undefined ? tempAnswerRowData[0].resultForecast : undefined,
          yearPlan: tempAnswerRowData[0] && tempAnswerRowData[0].yearPlan !== undefined ? tempAnswerRowData[0].yearPlan : undefined,
          midTermPlan: tempAnswerRowData[0] && tempAnswerRowData[0].midTermPlan !== undefined ? tempAnswerRowData[0].midTermPlan : undefined,
        };

        tempRowData.push(pushRowData);
      });
    }

    // 過去参照値の取得
    let tempPastRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.pastRowData) {
      if (props.pastRowData.length >= props.rowIndex) {
        tempPastRowData.push(props.pastRowData[props.rowIndex]);
      }
    }

    // idと過去参照値の紐付きを作る。
    let pastYearPlans = new Map<string, number>();
    tempPastRowData.forEach((data: EnqueteAnswerLoadInputEntity) => {
      if (undefined !== data.yearPlan) {
        pastYearPlans.set(data.id, data.yearPlan);
      }
    });

    // RowDataの中身をコピーして編集できるようにする。
    let newRowData = _.cloneDeep(tempRowData);
    newRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data) {
        //　過去データが存在しない場合、0で設定
        const previousYearPlan = undefined !== pastYearPlans.get(data.id) ? pastYearPlans.get(data.id) : 0;
        data.previousYearPlan = previousYearPlan;
      }
    });

    //過去参照値データがなければ、undefinedに置換
    if (!props.pastRowData) {
      newRowData.forEach((data: EnqueteAnswerInputEntity) => {
        // 行id≠'sumTotal'のみを設定
        if (data !== undefined && data.id !== 'sumTotal') data.previousYearPlan = undefined;
      });
    }
    // 行データ設定
    await setRowData(newRowData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ pb: '6px' }} style={{ whiteSpace: 'pre-line' }}>
          {/* ラベル */}
          <h4>{useJudg.getText(props.form['referenceNotTotalHeadline'] as languageDataTypes)}</h4>
        </Box>
        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: '97px',
            width: '80%',
            transition: 'height 0.5s',
          }}>
          <LoadableComponent isLoading={isLoading}>
            {/* （参照）データグリッド */}
            {!isDestroyed && <StyledAgGrid coldef={colDef} rows={rowData} gridOptions={gridOptions} />}
          </LoadableComponent>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
};
