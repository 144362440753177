import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputRadioGroup from 'src/components/app-components/bonsai/survey-information/components/InputRadioGroup';
import { AnswerUnit, ANSWER_UNIT } from 'src/constants';
import { useValidator } from 'src/features/general/enquete-create/hooks/useValidator';
import { conversionDateWithLocalHour } from 'src/utils/formatDateTime';

type EnqueteAnserUnitFormProps = {
  methods: UseFormReturn;
  answerUnit: AnswerUnit;
};

export const EnqueteAnswerUnitForm = ({ methods, answerUnit }: EnqueteAnserUnitFormProps) => {
  const { rulesAnswerUnit } = useValidator(methods);
  const watchDisabled = methods.watch('labelJpn')?.id || !methods.watch('ownerOrganization')?.key ? true : false;
  const { t } = useTranslation();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === ANSWER_UNIT.PERSONAL) {
      // 組織基準日に現在日時を設定する
      methods.setValue('organizationBaseDate', conversionDateWithLocalHour(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
    }
  };

  return (
    <InputRadioGroup
      name={'enqueteAnswerUnit'}
      defaultValue={answerUnit}
      control={methods.control}
      rules={rulesAnswerUnit}
      getValues={methods.getValues}
      list={[
        { value: ANSWER_UNIT.ORGANIZATION, label: t('enqueteGeneralCommon.enqueteAnswerUnitOrganization') },
        { value: ANSWER_UNIT.PERSONAL, label: t('enqueteGeneralCommon.enqueteAnswerUnitPersonal') },
      ]}
      disabled={watchDisabled}
      handleOnChange={handleOnChange}
    />
  );
};
