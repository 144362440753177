import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question } from 'src/@types/generalEnquete';
import { RespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import EnqueteAnswerGeneralQuestionFormSwitcher from 'src/features/general/enquete-answer/components/tab-containers/type-general-question/components/tabs/question-and-answer/components/EnqueteAnswerGeneralQuestionFormSwitcher';
import { useEnqueteSave } from 'src/features/general/enquete-create/hooks/useEnqueteSave';
import { setSelectQuestionId } from 'src/features/general/store/generalPurposeQuestionPreviewEditSlice';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { useModalHandlers } from '../../../../hooks/useModalHandlers';
import { LANGUAGE_CODE } from '../constant/constant';
import { useTranslationDownload } from '../hooks/useTranslationDownload';
import { useTranslationUpload } from '../hooks/useTranslationUpload';

const EnqueteTranslationSupportForm: React.FC = () => {
  const { t } = useTranslation();
  const { onClickSaveData } = useEnqueteSave();
  const { onClickModalCloseTranslationSupport } = useModalHandlers();
  const methods = useForm({ mode: 'onChange' });
  const { handleOnChange, replaceTranslationQuestionsData, convertFileArrayToTranslationTable, readFileAndSetState } = useTranslationUpload(
    methods.setError,
    methods.clearErrors,
  );
  const { handleDownload, createDownloadingCSVData } = useTranslationDownload();
  const enqueteData: RespondentRequestEntity = useSelector((state: RootState) => state.enqueteCreate.respondentRequest);
  const questionsJpn: Question[] = useSelector((state: RootState) => state.enqueteCreate.questions);
  const questionsTranslationData = useSelector((state: RootState) => state.enqueteCreate.questionsTranslationData);
  const questionsTranslationDataArray = convertFileArrayToTranslationTable(questionsTranslationData);
  const questionsEng: Question[] = replaceTranslationQuestionsData(questionsJpn, questionsTranslationDataArray);

  useEffect(() => {
    dispatch(setSelectQuestionId(undefined));
    return () => {
      dispatch(setSelectQuestionId(undefined));
    };
  }, []);

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12} spacing={1} mb={2} mt={2}>
      <Grid item xs={6}>
        <Box component={'div'} sx={{ overflowY: 'auto', height: '650px' }}>
          {questionsEng &&
            questionsEng.length &&
            questionsEng.map((question, index: number) => {
              return (
                <EnqueteAnswerGeneralQuestionFormSwitcher
                  methods={methods}
                  question={question}
                  index={index}
                  key={question.id + index}
                  languageCode={LANGUAGE_CODE.ENGLISH}
                />
              );
            })}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Stack component="form" ml={2}>
          <Box component={'div'} sx={{ overflowY: 'auto', overflowX: 'hidden', height: '650px', width: '100%' }}>
            <Grid item container justifyContent="top" alignItems="top" xs={12} spacing={1} mb={2} mt={2}>
              <Grid item xs={8}>
                <Typography
                  variant="body1"
                  sx={{ wordBreak: 'keep-all', textAlign: 'left' }}
                  children={t('enqueteGeneralCommon.translationSupport.screen.title.transportFileDownload')}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  key="fileDownload"
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    handleDownload(createDownloadingCSVData(questionsJpn, questionsTranslationData));
                  }}
                  children={t('button.fileDownload')}
                  sx={{ textAlign: 'left' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="file"
                control={methods.control}
                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                  <Grid item container justifyContent="top" alignItems="top" xs={12} spacing={1} mb={2} mt={2}>
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        sx={{ wordBreak: 'keep-all', textAlign: 'left' }}
                        children={t('enqueteGeneralCommon.translationSupport.screen.title.transportFileUpload')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button variant="contained" component="label">
                        {t('button.fileUpload')}
                        <input
                          hidden
                          key="fileUpload"
                          type="file"
                          accept="text/csv"
                          onChange={(e) => {
                            onChange(e.target.files ? e.target.files[0] : '');
                            const file = handleOnChange(e);
                            file ? readFileAndSetState(file, questionsJpn) : methods.setValue('file', undefined);
                            e.target.value = '';
                          }}
                          ref={ref}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="error">
                        {error && error.message
                          ? error.message
                              .toString()
                              .split(/\r\n/)
                              .map((msg) => <Box key={msg}>{msg}</Box>)
                          : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
            {methods.watch('file') && !methods.formState.errors.file && (
              <Grid item container justifyContent="center" alignItems="center" xs={12} spacing={1} mb={2}>
                <Grid item xs={8}>
                  <Typography
                    variant="body1"
                    sx={{ wordBreak: 'keep-all', textAlign: 'left' }}
                    children={t('enqueteGeneralCommon.translationSupport.screen.title.uploadFileName')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'left' }}>
                    {/* show file name which set by Controller */}
                    {methods.watch('file')?.name}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item container justifyContent="center" alignItems="center" xs={12} spacing={1} mb={2}>
              <Grid item xs={8}>
                <Typography
                  variant="body1"
                  sx={{ wordBreak: 'keep-all', textAlign: 'left' }}
                  children={t('enqueteGeneralCommon.translationSupport.screen.title.transportExecute')}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  key="transportExecute"
                  variant="contained"
                  color="primary"
                  onClick={async (e) => {
                    await onClickSaveData(enqueteData);
                    onClickModalCloseTranslationSupport();
                  }}
                  children={t('button.save')}
                  disabled={!(methods.watch('file') && !methods.formState.errors.file)}
                  sx={{ textAlign: 'right' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mr: 3, width: '100%', textAlign: 'center' }}>
              <Button key="close" variant={'contained'} onClick={onClickModalCloseTranslationSupport} children={t('button.close')} color="error" />
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EnqueteTranslationSupportForm;
