import { Box, Grid } from '@mui/material';
import {
  CellPosition,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  TabToNextCellParams,
} from 'ag-grid-community';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import {
  EnqueteAnswerInputEntity,
  EnqueteAnswerLoadInputEntity,
  PersonnelSurveyGridFormData,
} from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useJudgmentCurrentLanguage from 'src/hooks/useJudgmentCurrentLanguage';
import useLocales, { languageDataTypes } from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { useAppSelector } from 'src/redux/store';
import { useEnqueteAnswerGrid } from '../../../../../../../hooks/useEnqueteAnswerGrid';
import { useEnqueteAnswerTranslation } from '../../../../hooks/useEnqueteAnswerTranslation';
import {
  DarkRowStyleByPersonnelSurvey,
  NotInputDarkRowStyleByPersonnelSurvey,
  NotInputRowStyleByPersonnelSurvey,
  RowStyleByPersonnelSurvey,
} from '../../../../utils/RowStyleByPersonnelSurvey';
import { GridInputGuideLine } from '../../common/components/GridInputGuideLine';

export interface CreateSalesGridFormProps {
  form: PersonnelSurveyGridFormData;
  rowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  pastRowData?: EnqueteAnswerLoadInputEntity[] | undefined;
  setRowDataFunction: Function;
}

export const CreateSalesGridForm: React.FC<CreateSalesGridFormProps> = (props) => {
  // テーマ設定
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  // Grid全体の高さを定義
  const gridHeight = undefined !== props.form.gridHeight ? props.form.gridHeight : '261px';

  // 言語情報
  const { currentLang } = useLocales();
  const { t } = useTranslation();
  const useJudg = useJudgmentCurrentLanguage();
  const useAnswerTranslate = useEnqueteAnswerTranslation();

  // エラー状態を取得
  const hasErrorPersonnelSalesGridTotalOver = useAppSelector((state) => state.enqueteAnswer.hasErrorPersonnelSalesGridTotalOver);
  // 人件費人員(機能別)：コーポレート人員+営業人員合計エラー状態を取得
  const hasErrorPersonnelGridNotMatchTotal = useAppSelector((state) => state.enqueteAnswer.hasErrorPersonnelGridNotMatchTotal);
  // アンケート期限情報を格納
  const isEnqueteAnswerOpen = useAppSelector((state) => state.enqueteAnswer.isEnqueteAnswerOpen);
  // 人件費人員(雇用形態別)のTotalを格納
  const personnelEmploymentTotal = useAppSelector((state) => state.enqueteAnswer.personnelEmploymentTotal);
  // コーポレート人員のTotalを格納
  const corporateGridTotal = useAppSelector((state) => state.enqueteAnswer.corporateGridTotal);
  const { setHasErrorPersonnelSalesGridTotalOver, setHasErrorPersonnelGridNotMatchTotal } = useEnqueteAnswerGrid();

  //-------------------------------------------------------------
  // useState
  //-------------------------------------------------------------
  const [colDef, setColDef] = useState<(ColDef | ColGroupDef)[]>([]);
  const [rowData, setRowData] = useState<EnqueteAnswerInputEntity[] | undefined>(undefined);
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [cellPosition, setCellPosition] = useState<CellPosition | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDestroyed, setIsDestroyed] = useState(false);

  const tabToNextCell = (params: TabToNextCellParams): CellPosition => {
    // 入力Cell情報取得
    const previousCell = params.previousCellPosition;
    // GridのColDef情報を取得
    const gridColDef = params.api.getColumnDefs();
    // ColDefのColIdを取得
    const gridRowColIds =
      undefined !== gridColDef
        ? gridColDef.map((_: ColDef, i) => {
            return undefined !== _.colId ? _.colId : '';
          })
        : [];
    // 入力CellのColIdを取得
    const colId = previousCell.column.getId();
    // ColIdより現在のIndexを取得
    const colIdIndex = undefined !== gridRowColIds ? gridRowColIds?.indexOf(colId) : 0;

    // const renderedRowCount = undefined !== gridRowColIds ? gridRowColIds.length : 0;
    // 移動先のColIdIndexを取得
    const nextColIdIndex = params.backwards ? colIdIndex - 1 : colIdIndex + 1;

    const lastRowIndex = previousCell.rowIndex;
    if (undefined !== gridRowColIds && gridRowColIds.length > 0) {
      const colKey = undefined !== gridRowColIds[nextColIdIndex] ? gridRowColIds[nextColIdIndex] : '';
      params.api.setFocusedCell(lastRowIndex, colKey);
    }
    const nextCell = null !== params.nextCellPosition ? params.nextCellPosition : previousCell;
    const result = {
      rowIndex: lastRowIndex,
      column: nextCell.column,
      rowPinned: nextCell.rowPinned,
    };

    setCellPosition(result);

    return result;
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    getRowStyle: isEnqueteAnswerOpen
      ? isLight
        ? RowStyleByPersonnelSurvey
        : DarkRowStyleByPersonnelSurvey
      : isLight
      ? NotInputRowStyleByPersonnelSurvey
      : NotInputDarkRowStyleByPersonnelSurvey,
    tabToNextCell,
  };

  // セル編集後の処理
  const onCellValueChanged = (params: CellValueChangedEvent): void => {
    // 編集したらエラー解除
    if (hasErrorPersonnelSalesGridTotalOver || hasErrorPersonnelGridNotMatchTotal) {
      setHasErrorPersonnelSalesGridTotalOver(false);
      setHasErrorPersonnelGridNotMatchTotal(false);
    }
    // rawDataを更新するとフォーカスが外れるためいったんフォーカスを記憶
    const cellPosition = params.api.getFocusedCell();

    // タブ移動を１度リセットする
    setCellPosition(undefined);

    let displayedData: any = [];
    params.api?.forEachNode((node: any) => {
      const data: any = Object.assign({}, node.data);
      const keepRowData = {
        id: data.id,
        resultForecast: data.resultForecast !== undefined ? data.resultForecast : undefined,
        yearPlan: data.yearPlan !== undefined ? data.yearPlan : undefined,
        midTermPlan: data.midTermPlan !== undefined ? data.midTermPlan : undefined,
      };
      displayedData.push(keepRowData);
    });
    // 追加しているTotal行を除外する
    displayedData = displayedData.filter((x: any) => x.id !== 'sumTotal');
    props.setRowDataFunction(displayedData);
    // rawDataが更新された後でフォーカスを元に戻す
    setTimeout(() => {
      params.api.setFocusedCell(cellPosition!.rowIndex, cellPosition!.column);
    }, 100);
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  // TODO:新規作成を考慮してformを監視対象にしてRowを作成する
  useEffect(() => {
    refreshColDefData();
  }, [isEnqueteAnswerOpen]); //eslint-disable-line

  useEffect(() => {
    refreshRowData();
  }, [props.form, props.rowData, props.pastRowData, currentLang, corporateGridTotal]); //eslint-disable-line

  useEffect(() => {
    destroyGrid();
  }, [isLight, isEnqueteAnswerOpen]); //eslint-disable-line

  //-------------------------------------------------------------
  // テーマ変更を反映させる為、グリッドを再描画する
  //-------------------------------------------------------------
  const destroyGrid = () => {
    setIsDestroyed(true);
    setTimeout(() => recreateGrid(), 0);
  };

  const recreateGrid = () => {
    setIsDestroyed(false);
  };

  //-------------------------------------------------------------
  // テーブルの更新
  //-------------------------------------------------------------
  const refreshColDefData = async () => {
    setIsLoading(true);

    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = await useAnswerTranslate.formatColDef(props.form);

    await setColDef(enqueteAnswerColDef);
    await new Promise((resolve) => setTimeout(resolve, 100)); // 0.1秒待つ

    setIsLoading(false);
  };

  const refreshRowData = async () => {
    if (gridApi && cellPosition) {
      gridApi.setFocusedCell(cellPosition.rowIndex, cellPosition.column.getColId());
    }

    const enqueteAnswerColDef: (ColDef | ColGroupDef)[] = await useAnswerTranslate.formatColDef(props.form);
    await setColDef(enqueteAnswerColDef);

    // FormJsonからグリッドの列項目を取得
    let baseData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.form.initialValue) {
      props.form.initialValue.forEach((row: any) => {
        baseData.push(row);
      });
    }

    // AnswerJsonから回答情報を取得する
    let answerRowData: EnqueteAnswerLoadInputEntity[] = [];
    if (undefined !== props.rowData) {
      props.rowData.forEach((row: any) => {
        answerRowData.push(row);
      });
    }

    // FromとAnswerを合体させてRowDataを作成する。
    let tempRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== baseData && undefined !== answerRowData) {
      baseData.forEach((row: any) => {
        const tempAnswerRowData = answerRowData.filter((x) => x.id === row.id);
        const pushRowData: EnqueteAnswerInputEntity = {
          id: row.id as string,
          inputItem: row.inputItem as string,
          resultForecast:
            tempAnswerRowData[0] && tempAnswerRowData[0].resultForecast !== undefined && tempAnswerRowData[0].resultForecast !== null
              ? tempAnswerRowData[0].resultForecast
              : undefined,
          yearPlan:
            tempAnswerRowData[0] && tempAnswerRowData[0].yearPlan !== undefined && tempAnswerRowData[0].yearPlan !== null
              ? tempAnswerRowData[0].yearPlan
              : undefined,
          midTermPlan:
            tempAnswerRowData[0] && tempAnswerRowData[0].midTermPlan !== undefined && tempAnswerRowData[0].midTermPlan !== null
              ? tempAnswerRowData[0].midTermPlan
              : undefined,
        };
        const _row = useAnswerTranslate.formatRowData(pushRowData);

        tempRowData.push(_row as EnqueteAnswerInputEntity);
      });
    }
    // 追加しているTotal行を除外する
    tempRowData = tempRowData.filter((x) => x.id !== 'sumTotal');

    // 過去参照値の取得
    let tempPastRowData: EnqueteAnswerInputEntity[] = [];
    if (undefined !== props.pastRowData) {
      props.pastRowData.forEach((row: any) => {
        tempPastRowData.push(row);
      });
    }
    // 追加しているTotal行を除外する
    tempPastRowData = tempPastRowData.filter((x) => x.id !== 'sumTotal');

    // idと過去参照値の紐付きを作る。
    let pastYearPlans = new Map<string, number>();
    tempPastRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data.yearPlan) {
        pastYearPlans.set(data.id, data.yearPlan);
      }
    });

    // RowDataの中身をコピーして編集できるようにする。
    let newRowData = _.cloneDeep(tempRowData);
    newRowData.forEach((data: EnqueteAnswerInputEntity) => {
      if (undefined !== data) {
        const previousYearPlan = undefined !== pastYearPlans.get(data.id) ? pastYearPlans.get(data.id) : 0;
        data.previousYearPlan = previousYearPlan;
        // 営業項目のインシデント確保
        if (props.form.tabValue === 'personnelCorporate') {
          const tenpInputItem = data.inputItem as string;
          data.inputItem = tenpInputItem.replace('　　', '');
          data.inputItem = '　　' + data.inputItem;
        }
      }
    });

    let previousYearPlanTotal: number = 0;
    let resultForecastTotal: number = 0;
    let yearPlanTotal: number = 0;
    let midTermPlanTotal: number = 0;

    newRowData.forEach((data: any) => {
      previousYearPlanTotal += isNaN(Number(data['previousYearPlan'])) ? 0 : Number(data['previousYearPlan']);
      resultForecastTotal += isNaN(Number(data['resultForecast'])) ? 0 : Number(data['resultForecast']);
      yearPlanTotal += isNaN(Number(data['yearPlan'])) ? 0 : Number(data['yearPlan']);
      midTermPlanTotal += isNaN(Number(data['midTermPlan'])) ? 0 : Number(data['midTermPlan']);
    });

    // 意図的に０を入力するケースに対応
    let resultForecastTotalEmptyFlg: boolean = true;
    let yearPlanTotalEmptyFlg: boolean = true;
    let midTermPlanTotalEmptyFlg: boolean = true;

    newRowData.forEach((data: any) => {
      resultForecastTotalEmptyFlg = resultForecastTotalEmptyFlg && isNaN(Number(data['resultForecast'] ?? NaN)) ? true : false;
      yearPlanTotalEmptyFlg = yearPlanTotalEmptyFlg && isNaN(Number(data['yearPlan'] ?? NaN)) ? true : false;
      midTermPlanTotalEmptyFlg = midTermPlanTotalEmptyFlg && isNaN(Number(data['midTermPlan'] ?? NaN)) ? true : false;
    });

    if (resultForecastTotal === 0 && resultForecastTotalEmptyFlg) {
      const resultForecastTotalA = isNaN(Number(personnelEmploymentTotal['resultForecast'])) ? 0 : Number(personnelEmploymentTotal['resultForecast']);
      const resultForecastTotalB = isNaN(Number(corporateGridTotal['resultForecast'])) ? 0 : Number(corporateGridTotal['resultForecast']);
      resultForecastTotal = resultForecastTotalA - resultForecastTotalB;
    }
    if (yearPlanTotal === 0 && yearPlanTotalEmptyFlg) {
      const yearPlanTotalA = isNaN(Number(personnelEmploymentTotal['yearPlan'])) ? 0 : Number(personnelEmploymentTotal['yearPlan']);
      const yearPlanTotalB = isNaN(Number(corporateGridTotal['yearPlan'])) ? 0 : Number(corporateGridTotal['yearPlan']);
      yearPlanTotal = yearPlanTotalA - yearPlanTotalB;
    }
    if (midTermPlanTotal === 0 && midTermPlanTotalEmptyFlg) {
      const middlePlanTotalA = isNaN(Number(personnelEmploymentTotal['midTermPlan'])) ? 0 : Number(personnelEmploymentTotal['midTermPlan']);
      const middlePlanTotalB = isNaN(Number(corporateGridTotal['midTermPlan'])) ? 0 : Number(corporateGridTotal['midTermPlan']);
      midTermPlanTotal = middlePlanTotalA - middlePlanTotalB;
    }

    const totalRowData: EnqueteAnswerInputEntity = {
      id: 'sumTotal',
      inputItem: useJudg.getText(props.form['gridTotalName'] as languageDataTypes),
      previousYearPlan: previousYearPlanTotal,
      resultForecast: resultForecastTotal,
      yearPlan: yearPlanTotal,
      midTermPlan: midTermPlanTotal,
    };
    newRowData.unshift(totalRowData);

    //過去参照値データがなければ、undefinedに置換
    if (!props.pastRowData) {
      newRowData.forEach((data: EnqueteAnswerInputEntity) => {
        if (data !== undefined && data.id !== 'sumTotal') data.previousYearPlan = undefined;
      });
    }

    await setRowData(newRowData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ pb: '2px' }}>
          <h4>
            {useJudg.getText(props.form['headline'] as languageDataTypes)}
            {props.form.gridInputGuideLine !== undefined && <GridInputGuideLine guideLineData={props.form.gridInputGuideLine} />}
          </h4>
        </Box>

        <Box
          className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
          sx={{
            height: gridHeight,
            width: '80%',
            transition: 'height 0.5s',
          }}>
          <LoadableComponent isLoading={isLoading}>
            {!isDestroyed && (
              <StyledAgGrid
                coldef={colDef}
                rows={rowData}
                gridOptions={gridOptions}
                onCellValueChanged={onCellValueChanged}
                onGridReady={onGridReady}
              />
            )}
          </LoadableComponent>
        </Box>

        <p style={{ color: 'red' }}>{hasErrorPersonnelSalesGridTotalOver ? <h5>{t('enqueteAnswerInput.message.existInputTotalOver_2')}</h5> : ''}</p>
      </Grid>
      <Grid item xs={12} md={12} />
    </Grid>
  );
};
