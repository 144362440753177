import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SurveyDetailPermissionEntity } from 'src/api/usePermissionApi';

export const pageSlice = createSlice({
  name: 'page',
  initialState: {
    permission: undefined as SurveyDetailPermissionEntity | undefined,
  },
  reducers: {
    setPermission: (state, action: PayloadAction<SurveyDetailPermissionEntity | undefined>) => {
      state.permission = action.payload;
    },
  },
});

export const { setPermission } = pageSlice.actions;
