import { Grid, Tooltip } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack5';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageEntity, ErrorReportEntity } from 'src/api/useEnqueteStatusPageApi';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { AnswerUnit, ANSWER_UNIT, FORM_TYPE, REQUEST_STATUS } from 'src/constants';
import { initializeRespondentSetting } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { dispatch, RootState, useAppSelector } from 'src/redux/store';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { useEnqueteCreateGrid } from '../../../../../hooks/useEnqueteCreateGrid';
import { useEnqueteSelectAddress } from '../../../../../hooks/useEnqueteSelectAddress';
import BulkCreateEmailModal from '../../../../modals/bulk-create-email/components/BulkCreateEmailModal';
import useBulkCreateEmailModal from '../../../../modals/bulk-create-email/hooks/useBulkCreateEmailModal';
import { SelectRespondentAndExportModal } from '../../../../modals/select-respondent-export/components/SelectRespondentAndExportModal';
import { SelectRespondentModal, SelectRespondentModalResultRowData } from '../../../../modals/select-respondent/components/SelectRespondentModal';
import useEnqueteCreateTopActionButtonGroup from '../hooks/useEnqueteCreateTopActionButtonGroup';
import ErrorReportContent from './ErrorReportContent';

interface PageComponentProps {
  surveyDetailId: string;
  answerUnit?: AnswerUnit;
}

export const EnqueteCreateTopActionButtonGroup: React.FC<PageComponentProps> = (props) => {
  const { t } = useTranslation();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const { isRespondentManagerOnly, isLoadingPermission } = usePermission();

  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  //日英モード判定
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  //Hooks
  const createGridHook = useEnqueteCreateGrid();
  const { addRequestListData, validateRequestListData } = useEnqueteSelectAddress();
  const surveyEditApit = useSurveyEditApi();
  const {
    id,
    inProgress,
    startProcess,
    stopProcess,
    selectedRowCount,
    isOpenSelectAndExportModal,
    openSelectAddressModal,
    setIsOpenSelectAndExportModal,
    setOpenSelectAddressModal,
    goToResponseRequestPage,
    onClickBulkDelete,
  } = useEnqueteCreateTopActionButtonGroup();
  const { isOpen, openModal, closeModal } = useBulkCreateEmailModal();

  //ステート
  const [errorReport, setErrorReport] = useState<ErrorMessageEntity[] | []>([]);
  const [isOpenErrorNotificationModal, setIsOpenErrorNotificationModal] = useState<boolean>(false);

  //回答者選択モーダル OKボタン押下
  const onClickOkCallback = (selectedRowData: SelectRespondentModalResultRowData[]) => {
    addRequestListData(enqueteData.id, selectedRowData);
    setOpenSelectAddressModal(false);
  };

  //エクスポート処理
  const onClickExportData = async () => {
    if (id === undefined) return;

    startProcess();
    await surveyEditApit.exportFile(id, isLangJpn ? 'jpn' : 'eng');
    stopProcess();
  };

  //インポート ファイル選択ダイアログ表示
  const onClickOpenImportData = () => {
    if (inputRef.current === null) return;

    inputRef.current.click();
  };

  // インポート処理
  const handleImportExcel = async (e: ChangeEvent<any>) => {
    //FormData定義
    let formData = new FormData();

    //ファイル情報が未定義であれば終了
    if (inputRef.current !== null && inputRef.current?.files?.length === 0 && inputRef.current?.files !== null) return;
    //Filesデータがあれば、FileDataに格納
    if (inputRef.current !== null && inputRef.current?.files !== null) {
      formData.append('file', inputRef.current?.files[0]);
    }

    //id(RespondentRequestId)がなければ中断、あればインポート
    if (id === undefined) return;
    startProcess();

    //インポート
    const res: AxiosResponse<any> | undefined = await surveyEditApit.importFile(id, formData);

    //エラー分岐
    const clientErrorPattern = /^4[0-9]{2}$/;
    const serverErrorPattern = /^5[0-9]{2}$/;
    if (res !== undefined && res.status !== undefined && res.status === 400) {
      const error: ErrorReportEntity = res.data;
      setErrorReport(error.message);
      setIsOpenErrorNotificationModal(true);
    } else if (res !== undefined && res.status !== undefined && res.status === 406) {
      enqueueSnackbar(t('message.exceptionInvalidId'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && clientErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && serverErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedServerSide'), { variant: 'error' });
    } else {
      //グリッド情報更新
      createGridHook.getRequestAnswerList(id);
      dispatch(initializeRespondentSetting());
      //エラーレスポンスリセット
      setErrorReport([]);
      //スナックバー通知
      enqueueSnackbar(t('errorReport.message.succeedImport'), { variant: 'success' });
    }

    stopProcess();
  };

  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const isPersonnelSurvey = enqueteData.surveyDetail?.surveyHeader?.formType === FORM_TYPE.SPECIAL;
  const isPersonalEnquete = props.answerUnit === ANSWER_UNIT.PERSONAL;
  const isComplete = requestStatus === REQUEST_STATUS.COMPLETE;
  const isDisabledBulkOperationButton = selectedRowCount === 0;
  return (
    <>
      <Grid container item spacing={1}>
        {/** 左サイドボタン */}
        <Grid item container xs={3} direction={'row'} alignItems={'flex-end'} spacing={1}>
          <Grid item>
            <StyledButton onClick={goToResponseRequestPage} isDisabled={isDisabledBulkOperationButton || isRespondentManagerOnly}>
              {selectedRowCount > 0 ? selectedRowCount + (isLangJpn ? '名 ' : ' Persons ') : ''}
              {t('enqueteCreate.responseRequestButton')}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton onClick={onClickBulkDelete} isDisabled={isDisabledBulkOperationButton || isComplete} color="error">
              {t('button.delete')}
            </StyledButton>
          </Grid>
        </Grid>

        {/** 右サイドボタン */}
        <Grid item container xs={9} alignItems="center" direction={'row'} justifyContent={'flex-end'} spacing={1} textAlign="right">
          <Grid item container xs={12} direction={'row'} justifyContent={'flex-end'} textAlign="right" spacing={1}>
            {!isPersonalEnquete && (
              <>
                <Tooltip title={t('enqueteCreate.tooltip.selectRespondentAndExport') ?? ''}>
                  <Grid
                    item
                    children={
                      <StyledButton
                        onClick={() => {
                          setIsOpenSelectAndExportModal(true);
                        }}
                        isDisabled={isComplete}>
                        {t('enqueteCreate.selectAndExportTitle')}
                      </StyledButton>
                    }
                  />
                </Tooltip>
                <Grid
                  item
                  children={
                    <StyledButton onClick={onClickOpenImportData} isDisabled={isComplete}>
                      {t('enqueteCreate.importFileTitle')}
                      <input
                        type="file"
                        value=""
                        hidden
                        ref={inputRef}
                        onChange={handleImportExcel}
                        onClick={(e) => {
                          if (inputRef.current !== null && inputRef.current?.files !== null) {
                            inputRef.current.files = null;
                          }
                        }}
                        accept={'.xlsx'}
                        disabled={isComplete}
                      />
                    </StyledButton>
                  }
                />
                <Grid
                  item
                  children={
                    <StyledButton onClick={() => setIsOpenErrorNotificationModal(true)} isDisabled={isComplete}>
                      {t('errorReport.title')}
                    </StyledButton>
                  }
                />
              </>
            )}
          </Grid>
          <Grid item container direction={'row'} xs={12} justifyContent={'flex-end'} textAlign="right" spacing={1}>
            <Grid
              item
              children={
                <StyledButton onClick={() => openModal()} isDisabled={isComplete}>
                  {t('enqueteCreate.bulkCreateEmailTitle')}
                </StyledButton>
              }
            />
            <Grid
              item
              children={
                <StyledButton
                  onClick={() => {
                    if (isPersonnelSurvey) {
                      setOpenSelectAddressModal(true);
                    } else {
                      createGridHook.setIsOpenSelectPersonModal(true);
                      createGridHook.setIsOpenSelectPersonModalAddMode(!isPersonalEnquete);
                    }
                  }}
                  isDisabled={isComplete}>
                  {t('enqueteCreate.addRespondentTitle')}
                </StyledButton>
              }
            />
            <Tooltip title={t('enqueteCreate.tooltip.exportFile') ?? ''}>
              <Grid item children={<StyledButton onClick={onClickExportData}>{t('enqueteCreate.exportFileTitle')}</StyledButton>} />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      {/** ローディング */}
      <StyledLoading isOpen={inProgress || isLoadingPermission} />

      {/** 回答対象選択モーダル */}
      {isOpenSelectAndExportModal && (
        <SelectRespondentAndExportModal surveyDetailId={props.surveyDetailId} onClose={() => setIsOpenSelectAndExportModal(false)} />
      )}

      {/** 回答者選択モーダル 人員計画調査専用 */}
      {openSelectAddressModal && (
        <SelectRespondentModal
          surveyDetailId={props.surveyDetailId}
          open={openSelectAddressModal}
          onClose={() => {
            setOpenSelectAddressModal(false);
          }}
          onValidation={validateRequestListData}
          onOk={(result) => onClickOkCallback(result)}
        />
      )}

      {/* アドレス一括登録モーダル */}
      <BulkCreateEmailModal isOpen={isOpen} onClose={closeModal} />

      {/** エラーレポートモーダル */}
      <StyledModal
        isOpen={isOpenErrorNotificationModal}
        onCloseFunc={() => setIsOpenErrorNotificationModal(false)}
        modalTitle={t('errorReport.title')}
        children={<ErrorReportContent errorReport={errorReport} onCloseFunc={() => setIsOpenErrorNotificationModal(false)} />}
      />
    </>
  );
};
