import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { ComplementaryOrganizationEntity, RankEntity, RegionEntity } from '../../../../api/survey-edit/useComplementaryOrganizationApi';
export const complementaryOrganizationSlice = createSlice({
  name: 'complementaryOrganization',
  initialState: {
    listData: [] as ComplementaryOrganizationEntity[],
    regionData: [] as RegionEntity[],
    rankData: [] as RankEntity[],
  },
  reducers: {
    reset: (state) => {
      state.listData = [];
    },
    initListData: (state, action: PayloadAction<ComplementaryOrganizationEntity[]>) => {
      state.listData = action.payload;
    },
    deleteRowData: (state, action: PayloadAction<string>) => {
      const listData = current(state.listData);
      state.listData = listData.filter((rowData) => rowData.id !== action.payload);
    },
    regionData: (state, action: PayloadAction<RegionEntity[]>) => {
      state.regionData = action.payload;
    },
    rankData: (state, action: PayloadAction<RankEntity[]>) => {
      state.rankData = action.payload;
    },
  },
});

export const { reset, initListData, deleteRowData } = complementaryOrganizationSlice.actions;
