import { RespondentRequestEntity as apiRespondentRequestEntity } from 'src/api/useRespondentRequestApi';
import { RespondentRequestEntity } from 'src/features/general/enquete-answer/store/enqueteAnswerSlice';
import useLocales from 'src/hooks/useLocales';
import useStatus from 'src/hooks/useStatus';
import { RootState, useAppSelector } from 'src/redux/store';
import { conversionDateWithoutLocalHour, conversionUtcDate, dateToString, formatterDateTime, yearMonthFormat } from 'src/utils/formatDateTime';
import { getResponseOrganizationInfo } from 'src/utils/getResponseOrganizationInfo';

export const useEnqueteAnswerHeaderInfo = (respondentRequestData: (RespondentRequestEntity | apiRespondentRequestEntity) | undefined) => {
  const { toStringAnswerStatus } = useStatus();
  const { isJapanese } = useLocales();

  const enqueteAnswer = useAppSelector((state: RootState) => state.enqueteAnswer.enqueteAnswerData);
  const enqueteAnswerStatus = toStringAnswerStatus(enqueteAnswer?.status);

  const enqueteTitle = undefined !== respondentRequestData ? (isJapanese ? respondentRequestData.titleJpn : respondentRequestData.titleEng) : '';

  const requestStatusTerm =
    undefined !== respondentRequestData && 'openedAt' in respondentRequestData
      ? `${formatterDateTime(respondentRequestData.openedAt)} 〜 ${formatterDateTime(respondentRequestData.closedAt)}`
      : '';

  const organizationBaseDate = respondentRequestData?.organizationBaseDate
    ? formatterDateTime(conversionUtcDate(dateToString(conversionDateWithoutLocalHour(respondentRequestData?.organizationBaseDate))), yearMonthFormat)
    : '';

  const respondentTargetData = useAppSelector((state) => state.enqueteAnswer.respondentTargetData);
  const organization = respondentTargetData.organization ?? {};
  const enqueteAnswerOrganization = getResponseOrganizationInfo(organization, isJapanese);

  return {
    enqueteAnswerStatus,
    enqueteTitle,
    requestStatusTerm,
    enqueteAnswerOrganization,
    organizationBaseDate,
  };
};
