import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSurveyEditApi } from 'src/api/useSurveyEditApi';
import { BULK_CREATE_EMAIL_BAD_REQUEST, BULK_CREATE_EMAIL_ETC_ERROR, BULK_CREATE_EMAIL_NO_ACCESS, BULK_CREATE_EMAIL_NO_ADDRESS } from 'src/constants';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { initializeRespondentSetting } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import { dispatch } from 'src/redux/store';

const useBulkCreateEmailModal = () => {
  const { id: respondentRequestId } = useParams();
  const { getRequestAnswerList } = useEnqueteCreateGrid();
  const { bulkCreateRespondentByEmail } = useSurveyEditApi();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const toArrayEmailsString = (emails: string) => {
    return emails.split('\n');
  };

  const onClick = async (enterEmails: string[], errEmails: string[]) => {
    if (respondentRequestId) {
      // memo: 同一メールアドレスが存在した場合は重複を削除してAPIをcallします
      let emails: string[] = [...new Set(enterEmails)].filter((email) => !errEmails.includes(email));
      let ret;
      if (emails?.length > 0) {
        ret = await bulkCreateRespondentByEmail(respondentRequestId, emails);
      } else {
        return undefined;
      }
      // memo: 登録後の最新状態を再取得し、一覧部の表示をリフレッシュする
      dispatch(initializeRespondentSetting());
      await getRequestAnswerList(respondentRequestId);
      let responseNoAddressError: string[] = [];
      let responseOrganizationError: string[] = [];
      switch (ret?.status) {
        case undefined:
          return undefined;
        // memo:BULK_CREATE_EMAIL_BAD_REQUESTの場合、defaultと同様の戻り値とする
        case BULK_CREATE_EMAIL_BAD_REQUEST:
          return {
            status: BULK_CREATE_EMAIL_BAD_REQUEST,
            responseNoAddressError: undefined,
            responseOrganizationError: undefined,
          };

        case BULK_CREATE_EMAIL_NO_ACCESS:
          return {
            status: BULK_CREATE_EMAIL_NO_ACCESS,
            responseNoAddressError: undefined,
            responseOrganizationError: undefined,
          };

        case BULK_CREATE_EMAIL_NO_ADDRESS:
          dispatch(initializeRespondentSetting());
          if (ret.response?.data.message[0].length > 0) responseNoAddressError.push(ret.response?.data.message[0].toString().replace(/,/g, '\n'));
          if (ret.response?.data.message[1].length > 0) {
            responseOrganizationError.push(ret.response?.data.message[1].toString().replace(/,/g, '\n'));
          }
          return {
            status: BULK_CREATE_EMAIL_NO_ADDRESS,
            responseNoAddressError: responseNoAddressError.toString().replace(/,/g, '\n'),
            responseOrganizationError: responseOrganizationError.toString().replace(/,/g, '\n'),
          };
        // memo:BULK_CREATE_EMAIL_NO_ADDRESSの場合、defaultと同様の戻り値とする
        case BULK_CREATE_EMAIL_ETC_ERROR:
          return {
            status: BULK_CREATE_EMAIL_ETC_ERROR,
            responseNoAddressError: undefined,
            responseOrganizationError: undefined,
          };
        default:
          return {
            status: BULK_CREATE_EMAIL_ETC_ERROR,
            responseNoAddressError: undefined,
            responseOrganizationError: undefined,
          };
      }
    }
  };

  return {
    isOpen,
    openModal,
    closeModal,
    onClick,
    toArrayEmailsString,
  };
};

export default useBulkCreateEmailModal;
