import { useEffect, useState } from 'react';
import { useCountryApi } from 'src/api/useCountryApi';
import { useRegionApi } from 'src/api/useRegionApi';

type MultiLanguageTypes = {
  jpn: string | null;
  eng: string | null;
};

type SearchOrganizationMultiConditionTypes = {
  key: string;
  label: MultiLanguageTypes;
};

export const useSearchRegionCountryConditions = () => {
  const { getOnlySetNumberCountryCode } = useCountryApi();
  const { getAll: getAllRegions } = useRegionApi();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<SearchOrganizationMultiConditionTypes[]>([]);
  const [regions, setRegions] = useState<SearchOrganizationMultiConditionTypes[]>([]);

  // Portal 極情報取得
  const makeRegions = async () => {
    const regions = (await getAllRegions()) ?? [];
    const conditions = regions.map((e) => {
      return {
        key: e.regionCode ?? '',
        label: { jpn: e.regionNameJpn, eng: e.regionNameEng },
      };
    });
    setRegions(conditions);
  };

  // Portal 国情報取得
  const makeCountries = async () => {
    const countries = (await getOnlySetNumberCountryCode()) ?? [];
    const conditions = countries.map((e) => {
      return {
        key: e.countryCode ?? '',
        label: { jpn: e.countryNameJpn, eng: e.countryNameEng },
      };
    });
    setCountries(conditions);
  };

  //初期化
  const init = async () => {
    await Promise.all([makeRegions(), makeCountries()]);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    regions,
    countries,
  };
};
