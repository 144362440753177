import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SurveyInformationEntity } from 'src/@types/generalEnquete';
import { DISCLOSURE_LEVEL, DISCLOSURE_LEVEL_UPDATE_CONFIRM, FORM_TYPE, SURVEY_TYPE } from 'src/constants';
import { useEnqueteCreateGrid } from 'src/features/general/enquete-create/hooks/useEnqueteCreateGrid';
import { setEnqueteData } from 'src/features/general/enquete-create/store/enqueteCreateSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector, useDispatch } from 'src/redux/store';
import { conversionDateWithLocalHour, dateToString } from 'src/utils/formatDateTime';
import { getRequestStatus } from 'src/utils/getRequestStatus';
import { setEditEnqueteData } from '../utils/setEditEnqueteData';
import { DisclousureLevelForm } from './DisclousureLevelForm';
import { DisclousureLevelUpdateConfirmForm } from './DisclousureLevelUpdateConfirmForm';
import { EnqueteTargetDateForm } from './EnqueteTargetDateForm';
import { RespondentRequestForm } from './RespondentRequestForm';

type SurveyEditFormProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methods: UseFormReturn;
};

export const SurveyEditForm: React.FC<SurveyEditFormProps> = (props) => {
  const { setIsModalOpen, methods } = props;
  const { t } = useTranslation();
  const { isJapanese } = useLocales();
  const dispatch = useDispatch();
  const { saveEnqueteData } = useEnqueteCreateGrid();
  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);
  const requestStatus = getRequestStatus(enqueteData.openedAt, enqueteData.closedAt);
  const tempEnqueteData: SurveyInformationEntity = setEditEnqueteData(enqueteData);

  //初回実行
  useEffect(() => {
    methods.setValue('targetDate', tempEnqueteData.targetDate, { shouldValidate: true });
    methods.setValue('disclosureLevel', tempEnqueteData.disclosureLevel === null ? DISCLOSURE_LEVEL.PRIVATE : tempEnqueteData.disclosureLevel);
    methods.setValue('disclosureLevelUpdateConfirm', DISCLOSURE_LEVEL_UPDATE_CONFIRM.NO);
    tempEnqueteData &&
      Object.entries(tempEnqueteData).forEach(([key, value]) => {
        if (value) {
          if (['titleJpn', 'titleEng'].includes(key)) {
            methods.setValue(key, value);
          } else if (['openedAt', 'closedAt'].includes(key)) {
            methods.setValue(key, new Date(value));
          }
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //保存
  const onClickSaveData = async (data: FieldValues) => {
    // targetDate を条件により取得する。
    const getTargetDate = (): string | null => {
      if (tempEnqueteData.type === SURVEY_TYPE.GENERAL || tempEnqueteData.formType === FORM_TYPE.OVERVIEW) {
        return data.openedAt.toISOString();
      }
      if (data.targetDate !== null) {
        return dateToString(conversionDateWithLocalHour(data.targetDate));
      }
      return null;
    };
    const newEnqueteData: SurveyInformationEntity = {
      ...tempEnqueteData,
      disclosureLevel: data.disclosureLevel === DISCLOSURE_LEVEL.PRIVATE ? null : data.disclosureLevel,
      titleJpn: data.titleJpn,
      titleEng: data.titleEng,
      openedAt: data.openedAt.toISOString(),
      closedAt: data.closedAt.toISOString(),
      targetDate: getTargetDate(),
    };

    //アンケート情報をAPI経由で保存(Nullでなければ、対象年月にタイムゾーン差分を追加)
    const result = await saveEnqueteData(newEnqueteData, data.disclosureLevelUpdateConfirm);

    //アンケート更新された情報を画面に反映
    dispatch(setEnqueteData({ ...result }));
    //モーダルを閉じる
    setIsModalOpen(false);
  };

  return (
    <form>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
        {/** アンケート種別 */}
        <Grid item container justifyContent="center" alignItems="center" xs={12} md={12} spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" sx={{ wordBreak: 'keep-all', textAlign: 'center' }} children={t('enqueteCreate.editEnqueteModal.label')} />
          </Grid>
          <Grid item xs={12} md={8}>
            {isJapanese ? tempEnqueteData.labelJpn : tempEnqueteData.labelEng}
          </Grid>
        </Grid>

        {/** アンケートタイトル 依頼期間 */}
        <RespondentRequestForm enqueteData={tempEnqueteData} requestStatus={requestStatus} methods={methods} />

        {/** 対象年月 */}
        {tempEnqueteData.type !== SURVEY_TYPE.GENERAL && tempEnqueteData.formType !== FORM_TYPE.OVERVIEW && (
          <EnqueteTargetDateForm methods={methods} targetDate={tempEnqueteData.targetDate} />
        )}

        {/* Data-Insight公開区分 */}
        <DisclousureLevelForm methods={methods} disclosureLevel={tempEnqueteData.disclosureLevel} />
        {/* 公開区分更新確認 */}
        <DisclousureLevelUpdateConfirmForm methods={methods} />

        <Grid item xs={12} md={1} sx={{ mt: 3, mr: 3, width: '100%', textAlign: 'right' }}>
          {/* キャンセルボタン */}
          <Button variant={'contained'} onClick={() => setIsModalOpen(false)} children={t('button.cancel')} color="error" />
          {/* 保存ボタン */}
          <Button
            variant={'contained'}
            onClick={methods.handleSubmit(onClickSaveData)}
            children={t('button.save')}
            sx={{ ml: 2 }}
            disabled={!methods.formState.isValid}
          />
        </Grid>
      </Grid>
    </form>
  );
};
