import { Button, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import Label from 'src/components/Label';
import RHFTextField from 'src/components/react-hook-form/RHFTextField';
import {
  BULK_CREATE_EMAIL_BAD_REQUEST,
  BULK_CREATE_EMAIL_ETC_ERROR,
  BULK_CREATE_EMAIL_NO_ACCESS,
  BULK_CREATE_EMAIL_NO_ADDRESS,
  SELECT_RESPONDENT_LINE_MAX_LENGTH,
} from 'src/constants';
import useBulkCreateEmailModal from '../hooks/useBulkCreateEmailModal';

export type FormValues = {
  emails: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const BulkCreateEmailModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit, reset, setValue, trigger } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { onClick, toArrayEmailsString } = useBulkCreateEmailModal();
  const tKey = 'enqueteCreate.bulkCreateEmailModal';
  const rowLength = 8;
  const [errMessage, setErrMessage] = useState<string>('');
  const [isStyledLoading, setIsStyledLoading] = useState(false);

  const storageKey = 'BulkCreateEmailModal';
  const enterEmailsKey = 'EnterEmails';
  const errMessageKey = 'ErrMessage';
  // RespondentRequestID取得
  const { id } = useParams();
  const rrId = id ?? id;
  const savedLocalStorage = (enterEmails: string[], errMessage: string) => {
    localStorage.setItem(`${storageKey}${enterEmailsKey}${rrId}`, JSON.stringify(enterEmails.length > 0 ? enterEmails : []));
    localStorage.setItem(`${storageKey}${errMessageKey}${rrId}`, errMessage ? JSON.stringify(errMessage) : '');
  };

  const dispLocalstrageData = () => {
    const enterEmailsString: string | null = localStorage.getItem(`${storageKey}${enterEmailsKey}${rrId}`);
    const enterEmailsParse: string[] = enterEmailsString ? JSON.parse(enterEmailsString) : [];
    setValue('emails', enterEmailsParse.join(',').replace(/,/g, '\n'));

    const errMessageString = localStorage.getItem(`${storageKey}${errMessageKey}${rrId}`);
    setErrMessage(errMessageString ? JSON.parse(errMessageString) : '');
  };

  useEffect(() => {
    dispLocalstrageData();
  }, []); // eslint-disable-line

  return (
    <>
      <StyledModal isOpen={isOpen} modalTitle={t(`${tKey}.title`)} onCloseFunc={onClose} mode="BulkCreateEmailModal">
        <Grid container xs={12} spacing={1} alignItems="flex-end" style={{ height: '75px' }}>
          <Grid item xs={6}>
            <Label
              children={t(`${tKey}.explanation`)}
              color="info"
              sx={{ whiteSpace: 'pre-line', height: 'auto', lineHeight: 'normal', p: 1, mb: 2 }}
            />
          </Grid>
          <Grid item xs={6}>
            {t(`${tKey}.bulkCreateEmailError`)}{' '}
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Grid container sx={{ mt: 2 }}>
              <RHFTextField
                name="emails"
                label={''}
                control={control}
                rows={rowLength}
                multiline={true}
                fullWidth
                placeholder={t(`${tKey}.placeholder`)}
                rules={{
                  required: { value: true, message: t('validateError.required', { target: t('common.email') }) },
                  validate: {
                    isValidEmails: (email: string) => {
                      const emails = toArrayEmailsString(email);
                      const isValidEmail = (email: string) => {
                        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
                      };
                      for (const email of emails) {
                        if (!isValidEmail(email)) return t('validateError.format.email');
                      }
                      return true;
                    },
                    isOverEmailsCount: (email: string) => {
                      const emails = toArrayEmailsString(email);
                      if (SELECT_RESPONDENT_LINE_MAX_LENGTH < emails.length)
                        return t(`${tKey}.maxCount`, { max: SELECT_RESPONDENT_LINE_MAX_LENGTH.toLocaleString() });
                      return true;
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container sx={{ mt: 2 }}>
              <RHFTextField
                name="errEmails"
                label={''}
                control={control}
                rows={rowLength}
                multiline={true}
                fullWidth
                placeholder={''}
                value={errMessage}
                inputProps={{ style: { color: 'red' }, readOnly: true }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={1} sx={{ mt: 3, mr: 3, width: '100%', textAlign: 'right' }}>
          <Grid container>
            <Grid item xs={6} justifyContent={'left'}>
              <Stack direction={'row'} spacing={1}>
                <Button
                  variant={'contained'}
                  onClick={() => {
                    onClose();
                  }}
                  children={t('button.cancel')}
                  color="error"
                />
              </Stack>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              {errMessage ? (
                <Stack direction={'row-reverse'}>
                  <Button
                    variant={'contained'}
                    onClick={handleSubmit(async (data: FormValues) => {
                      setIsStyledLoading(true);
                      const emailsList = toArrayEmailsString(data.emails);
                      const errEmails = toArrayEmailsString(errMessage);
                      const emails: string[] = emailsList.filter((email) => !errEmails.includes(email));
                      const result = await onClick(emails, errEmails);

                      let errMessageNoAddressError: string[] = [];
                      let errMessageRetrievalError: string[] = [];
                      const indexOfStrng = t(`${tKey}.error.mailAddressOrganizationRetrievalError`).split('\n');
                      const indexRetrievalError = errEmails.indexOf(indexOfStrng[0]);

                      switch (indexRetrievalError) {
                        case -1:
                          errMessageNoAddressError = errEmails;
                          break;
                        default:
                          // memo:
                          // 以下の処理は、登録エラー欄にメールアドレスを追加します
                          // 追加する際、以下の２つに分けられます
                          // 　・Marsポータル、bluePageに未登録のメールアドレス　（表示欄の上に表示します）
                          // 　・組織情報解決が出来ないメールアドレス　（表示欄の下に表示します）
                          // ローカルストレージには、両方をmergeした文字列として格納されており、メールアドレスを追加する場合は分離する必要があります
                          // 分離するキーは、組織解決が出来ない場合の文字列で行っており、日本語/英語とも2行です
                          // このため、組織解決が出来ない文字列を取得するためには、 indexRetrievalError + 2行 とする必要があり、sliceの引数を「 + 2」しています
                          errMessageNoAddressError = errEmails.slice(0, indexRetrievalError);
                          errMessageRetrievalError = errEmails.slice(indexRetrievalError + 2);
                          break;
                      }

                      switch (result?.status) {
                        case undefined:
                          trigger('emails');
                          savedLocalStorage([], errMessage);
                          reset();
                          break;

                        case BULK_CREATE_EMAIL_BAD_REQUEST:
                          savedLocalStorage(emailsList, errMessage);
                          break;

                        case BULK_CREATE_EMAIL_NO_ACCESS:
                          trigger('emails');
                          reset();
                          break;

                        case BULK_CREATE_EMAIL_NO_ADDRESS:
                          if (result.responseNoAddressError) {
                            errMessageNoAddressError.push(...toArrayEmailsString(result.responseNoAddressError));
                          }
                          if (result.responseOrganizationError) {
                            errMessageRetrievalError.push(...toArrayEmailsString(result.responseOrganizationError));
                          }
                          if (errMessageRetrievalError.length > 0)
                            errMessageRetrievalError.unshift(t(`${tKey}.error.mailAddressOrganizationRetrievalError`));

                          let localStrageErrMessage: string = '';
                          const appendErrors = (errors: string[]) => {
                            if (errors.length > 0) {
                              localStrageErrMessage += (localStrageErrMessage === '' ? '' : '\n') + errors.join('\n');
                            }
                          };
                          appendErrors(errMessageNoAddressError);
                          appendErrors(errMessageRetrievalError);

                          setErrMessage(localStrageErrMessage);
                          savedLocalStorage(emailsList, localStrageErrMessage);
                          break;

                        case BULK_CREATE_EMAIL_ETC_ERROR:
                          savedLocalStorage(emailsList, errMessage);
                          break;

                        default:
                          savedLocalStorage(emailsList, errMessage);
                          break;
                      }
                      dispLocalstrageData();
                      setIsStyledLoading(false);
                    })}
                    children={t(`${tKey}.button.RegisterExcludingUnregistrableAddresses`)}
                    sx={{ ml: 2 }}
                    disabled={!formState.isValid}
                  />
                </Stack>
              ) : undefined}{' '}
              <Stack direction={'row-reverse'}>
                <Button
                  variant={'contained'}
                  onClick={handleSubmit(async (data: FormValues) => {
                    setIsStyledLoading(true);
                    const emailsList = toArrayEmailsString(data.emails);
                    const result = await onClick(emailsList, []);
                    let errMessageNewNoAddressError: string[] = [];
                    let errMessageNewRetrievalError: string[] = [];

                    switch (result?.status) {
                      case undefined:
                        savedLocalStorage([], '');
                        reset();
                        onClose();
                        break;

                      case BULK_CREATE_EMAIL_BAD_REQUEST:
                        savedLocalStorage(emailsList, errMessage);
                        break;

                      case BULK_CREATE_EMAIL_NO_ACCESS:
                        trigger('emails');
                        reset();
                        break;

                      case BULK_CREATE_EMAIL_NO_ADDRESS:
                        if (result.responseNoAddressError) errMessageNewNoAddressError.push(...toArrayEmailsString(result.responseNoAddressError));
                        if (result.responseOrganizationError)
                          errMessageNewRetrievalError.push(
                            t(`${tKey}.error.mailAddressOrganizationRetrievalError`),
                            ...toArrayEmailsString(result.responseOrganizationError),
                          );

                        let resistErrMessage = errMessageNewNoAddressError.join('\n');
                        if (resistErrMessage && errMessageNewRetrievalError.length > 0)
                          resistErrMessage = resistErrMessage + '\n' + errMessageNewRetrievalError.join('\n');
                        if (!resistErrMessage && errMessageNewRetrievalError.length > 0) resistErrMessage = errMessageNewRetrievalError.join('\n');

                        setErrMessage(resistErrMessage);
                        savedLocalStorage(emailsList, resistErrMessage);
                        break;

                      case BULK_CREATE_EMAIL_ETC_ERROR:
                        savedLocalStorage(emailsList, errMessage);
                        break;

                      default:
                        savedLocalStorage(emailsList, errMessage);
                        break;
                    }
                    dispLocalstrageData();
                    setIsStyledLoading(false);
                  })}
                  children={t('button.register')}
                  sx={{ ml: 2 }}
                  disabled={!formState.isValid}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </StyledModal>

      <StyledLoading isOpen={isStyledLoading} />
    </>
  );
};

export default BulkCreateEmailModal;
