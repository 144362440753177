import { faDownload, faUpload, faUpLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Chip, Grid, IconButton, SxProps, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack5';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerEntity } from 'src/api/useAnswerApi';
import { EnqueteInfoEntity, ErrorMessageEntity, ErrorReportEntity, useEnqueteStatusPageApi } from 'src/api/useEnqueteStatusPageApi';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { StyledModal } from 'src/components/app-components/StyledModal';
import { FORM_TYPE, SENT_STATUS, SURVEY_TYPE } from 'src/constants';
import { NetworkAccessError } from 'src/errors/errors';
import useLocales from 'src/hooks/useLocales';
import { usePermission } from 'src/hooks/usePermission';
import { useUser } from 'src/hooks/useUser';
import { dateFormat, formatterDateTime } from 'src/utils/formatDateTime';
import { getOrganizationString } from 'src/utils/getOrganizationString';
import { useEnqueteStatusList } from '../../../../../hooks/useEnqueteStatusList';
import ErrorReportContent from '../../../../../modals/error-report/ErrorReportModal';

interface Props {
  respondentRequestId: string | null;
  surveyDetailId: string;
  enqueteInfoData: EnqueteInfoEntity | null;
  refreshData: () => void;
}

export const EnqueteInfoForm: React.FC<Props> = (props) => {
  const { respondentRequestId, surveyDetailId, enqueteInfoData, refreshData } = props;
  const { currentLang } = useLocales();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const pageApi = useEnqueteStatusPageApi();
  const pageHooks = useEnqueteStatusList();
  const { enqueueSnackbar } = useSnackbar();
  const { userNameJpn, userNameEng } = useUser();

  const { isAdmin, isSurveyOwner, isAnswerReader } = usePermission();

  const isLangJpn = currentLang.value === 'ja';
  const [isOpenErrorNotificationModal, setIsOpenErrorNotificationModal] = useState<boolean>(false);
  const [errorReport, setErrorReport] = useState<ErrorMessageEntity[] | []>([]);
  const [isAvailableAnswer, getIsAvailableAnswer] = useState<boolean>(true);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isDataLinkageButtonDisabled, setIsDataLinkageButtonDisabled] = useState<boolean>(true);
  //-------------------------------------------------------------
  // 回答状況の取得
  //-------------------------------------------------------------
  const getRequestAnswerRatio = (data: EnqueteInfoEntity | null) => {
    if (!data) return '';
    return `${data.answeredCount !== null ? data.answeredCount : '0'} / ${data.requestedCount !== null ? data.requestedCount : '0'}`;
  };

  //-------------------------------------------------------------
  // 依頼期間情報を取得
  //-------------------------------------------------------------
  const getRequestTerm = (data: EnqueteInfoEntity | null) => {
    if (!data) return '';
    const openedAt = formatterDateTime(data.openedAt) as string;
    const closedAt = formatterDateTime(data.closedAt) as string;

    return `${openedAt} 〜 ${closedAt}`;
  };

  //-------------------------------------------------------------
  // 回答結果をエクスポート
  //-------------------------------------------------------------
  const handleExportExcel = async () => {
    if (respondentRequestId) {
      setInProgress(true);

      //エクスポート
      await pageApi.exportAnswerResult(respondentRequestId, isLangJpn ? 'jpn' : 'eng');

      setInProgress(false);
    }
  };

  //-------------------------------------------------------------
  // Data-Insightデータ連携
  //-------------------------------------------------------------
  const handleSentDataInsight = async () => {
    if (surveyDetailId) {
      try {
        //Data-Insightデータ連携
        await pageApi.sentDataInsight(surveyDetailId);
        //回答状況更新の為に再取得
        refreshData();
      } catch (error) {
        if (error instanceof NetworkAccessError) {
          enqueueSnackbar(t('apiExceptionMessage.useEnqueteStatusPageApi.failedDataInsightSent'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
        }
      }
    }
  };

  //-------------------------------------------------------------
  // 回答をインポート
  //-------------------------------------------------------------
  const handleImportExcel = async (e: ChangeEvent<any>) => {
    //FormData定義
    let formData = new FormData();

    //ファイル情報が未定義であれば終了
    if (inputRef.current !== null && inputRef.current?.files?.length === 0 && inputRef.current?.files !== null) return;
    //Filesデータがあれば、FileDataに格納
    if (inputRef.current !== null && inputRef.current?.files !== null) {
      formData.append('file', inputRef.current?.files[0]);
    }

    //ユーザー名を格納
    formData.append('userNameJpn', userNameJpn);
    formData.append('userNameEng', userNameEng);

    //RespondentRequestIdがなければ中断、あればインポート
    if (respondentRequestId === null) return;
    setInProgress(true);

    //インポート
    const res: AnswerEntity | AxiosResponse<any> | undefined = await pageApi.importAnswer(respondentRequestId, formData);

    //エラー分岐
    const clientErrorPattern = /^4[0-9]{2}$/;
    const serverErrorPattern = /^5[0-9]{2}$/;
    if (res !== undefined && res.status !== undefined && res.status === 400) {
      const error: ErrorReportEntity = res.data;
      setErrorReport(error.message);
      setIsOpenErrorNotificationModal(true);
    } else if (res !== undefined && res.status !== undefined && res.status === 406) {
      enqueueSnackbar(t('message.exceptionInvalidId'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && clientErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedClientSide'), { variant: 'error' });
    } else if (res !== undefined && res.status !== undefined && serverErrorPattern.test(String(res.status))) {
      enqueueSnackbar(t('message.exceptionFailedServerSide'), { variant: 'error' });
    } else {
      //グリッド情報更新
      pageHooks.incrementUpdateCounter();
      //エラーレスポンスリセット
      setErrorReport([]);
      //スナックバー通知
      enqueueSnackbar(t('errorReport.message.succeedImport'), { variant: 'success' });
      //回答状況更新の為に再取得
      refreshData();
    }

    setInProgress(false);
  };

  //-------------------------------------------------------------
  // ファイル選択ダイアログ表示
  //-------------------------------------------------------------
  const openDialog = () => {
    if (inputRef.current === null) return;

    inputRef.current.click();
  };

  //-------------------------------------------------------------
  // 回答が可能であるか判定
  //-------------------------------------------------------------
  const judgmentAvailableAnswer = async () => {
    if (!respondentRequestId) return;
    if (enqueteInfoData === null) return;

    //依頼期間が有効か（依頼期間が過ぎていないか）
    const currentDateTimeMilliSec = new Date().getTime();
    const openDateTimeMilliSec = new Date(enqueteInfoData.openedAt).getTime();
    const closeDateTimeMilliSec = new Date(enqueteInfoData.closedAt).getTime();
    const isAvailableStatus = openDateTimeMilliSec <= currentDateTimeMilliSec && currentDateTimeMilliSec <= closeDateTimeMilliSec;

    //回答することができるか判定
    getIsAvailableAnswer(isAvailableStatus || isSurveyOwner || isAdmin);

    // Data-Insightへ連携ボタンの可視制御判定
    setIsDataLinkageButtonDisabled(
      !!(isAdmin && enqueteInfoData?.sentStatus === SENT_STATUS.PROCESSING) ||
        (isSurveyOwner && (enqueteInfoData?.sentStatus === SENT_STATUS.PROCESSING || enqueteInfoData?.sentStatus === SENT_STATUS.SENT_FAILED)) ||
        (!isAdmin && !isSurveyOwner),
    );
  };

  //-------------------------------------------------------------
  // useEffect
  //-------------------------------------------------------------
  useEffect(() => {
    judgmentAvailableAnswer();
  }, [enqueteInfoData, isSurveyOwner]); //eslint-disable-line

  //-------------------------------------------------------------
  // Styles
  //-------------------------------------------------------------
  const headerStyle: SxProps = {
    width: '19em',
  };
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head" sx={headerStyle} children={t('enqueteStatus.pageHeader.surveyTitle')} />
                <TableCell children={isLangJpn ? enqueteInfoData?.titleJpn : enqueteInfoData?.titleEng} />
              </TableRow>
              <TableRow>
                <TableCell variant="head" sx={headerStyle} children={t('enqueteStatus.pageHeader.answerStatus')} />
                <TableCell children={getRequestAnswerRatio(enqueteInfoData)} />
              </TableRow>
              <TableRow>
                <TableCell variant="head" sx={headerStyle} children={t('enqueteStatus.pageHeader.requestingOrganization')} />
                <TableCell children={getOrganizationString(enqueteInfoData, isLangJpn)} />
              </TableRow>
              <TableRow>
                <TableCell variant="head" sx={headerStyle} children={t('enqueteStatus.pageHeader.surveyRequestTerm')} />
                <TableCell children={getRequestTerm(enqueteInfoData)} />
              </TableRow>
              <TableRow>
                <TableCell variant="head" sx={headerStyle}>
                  {t('enqueteStatus.enqueteIntegrationStatus')}
                  <Tooltip
                    title={t('enqueteStatus.tooltip.dataInsightLabelStatusExplanationButtonText')
                      .split('\n')
                      .map((text) => (
                        <Box key={text}>{text}</Box>
                      ))}>
                    <IconButton>
                      <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ color: 'red' }}>
                  {enqueteInfoData?.sentStatus === SENT_STATUS.UN_SENT && <Chip label={t('enqueteStatus.sentStatus.unSent')} color="info" />}
                  {enqueteInfoData?.sentStatus === SENT_STATUS.PROCESSING && (
                    <Chip label={t('enqueteStatus.sentStatus.processing')} color="warning" />
                  )}
                  {enqueteInfoData?.sentStatus === SENT_STATUS.SENT && (
                    <Chip
                      label={
                        t('enqueteStatus.sentStatus.sent') +
                        '(' +
                        DateTime.fromISO(enqueteInfoData?.sentAt ?? String(new Date())).toFormat(dateFormat) +
                        ')'
                      }
                      color="success"
                    />
                  )}
                  {enqueteInfoData?.sentStatus === SENT_STATUS.SENT_FAILED && (
                    <>
                      <Chip label={t('enqueteStatus.sentStatus.sentFailed')} color="error" />
                      <Tooltip
                        title={t('enqueteStatus.tooltip.dataInsightLinkageStatusSescription')
                          .split('\n')
                          .map((text) => (
                            <Box key={text}>{text}</Box>
                          ))}>
                        <IconButton>
                          <HelpIcon color="primary" sx={{ pt: '0px', pb: '0px', cursor: 'pointer' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item container xs={6} alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ textAlign: 'right' }}>
          <Grid item>
            <StyledButton onClick={handleExportExcel} sx={{ marginTop: '10px', marginLeft: '5px' }} startIcon={<FontAwesomeIcon icon={faDownload} />}>
              {t('enqueteStatus.menu.excelExport')}
            </StyledButton>

            {enqueteInfoData?.surveyType === SURVEY_TYPE.SPECIAL &&
              (enqueteInfoData?.formType === FORM_TYPE.SPECIAL ||
                (enqueteInfoData?.formType === FORM_TYPE.OVERVIEW && (isSurveyOwner || isAdmin))) && (
                <>
                  <StyledButton
                    onClick={openDialog}
                    sx={{ marginLeft: '4px', marginTop: '10px' }}
                    isDisabled={!isAvailableAnswer || isAnswerReader}
                    startIcon={<FontAwesomeIcon icon={faUpload} />}>
                    {t('enqueteStatus.menu.excelImport')}
                    <input
                      type="file"
                      value=""
                      hidden
                      ref={inputRef}
                      onChange={handleImportExcel}
                      onClick={(e) => {
                        if (inputRef.current !== null && inputRef.current?.files !== null) {
                          inputRef.current.files = null;
                        }
                      }}
                      accept={'.xlsx'}
                    />
                  </StyledButton>
                  <StyledButton
                    onClick={() => setIsOpenErrorNotificationModal(true)}
                    sx={{ marginLeft: '4px', marginTop: '10px' }}
                    isDisabled={!isAvailableAnswer || isAnswerReader}>
                    {t('errorReport.title')}
                  </StyledButton>
                </>
              )}
            <Tooltip
              title={t('enqueteStatus.tooltip.dataInsightLinkageStatusExplanationButtonText')
                .split('\n')
                .map((text) => (
                  <Box key={text}>{text}</Box>
                ))}>
              <span>
                <StyledButton
                  onClick={handleSentDataInsight}
                  sx={{ marginTop: '10px', marginLeft: '5px' }}
                  isDisabled={isDataLinkageButtonDisabled}
                  startIcon={<FontAwesomeIcon icon={faUpLong} />}>
                  {t('button.dataLinkage')}
                </StyledButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      {/** エラーレポートモーダル */}
      <StyledModal
        isOpen={isOpenErrorNotificationModal}
        onCloseFunc={() => setIsOpenErrorNotificationModal(false)}
        modalTitle={t('errorReport.title')}>
        <ErrorReportContent errorReport={errorReport} onCloseFunc={() => setIsOpenErrorNotificationModal(false)} />
      </StyledModal>

      {/** Excel処理ローディング */}
      <StyledLoading isOpen={inProgress} />
    </>
  );
};
