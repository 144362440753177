import { Box } from '@mui/material';
import React from 'react';
import { DuplicateWarnSurveyVisibleCondition } from 'src/@types/DuplicateWarnMessage';
import { LoadableComponent } from 'src/components/app-components/bonsai/LoadableComponent';
import { StyledAgGrid } from 'src/components/app-components/StyledAgGrid';
import { useOutsideSurveyVisibleConditionsGrid } from '../hooks/useOutsideSurveyVisibleConditionsGrid';

interface Props {
  outsideSurveyVisibleConditions: DuplicateWarnSurveyVisibleCondition[];
}

const OutsideSurveyVisibleConditionsGrid: React.FC<Props> = (props) => {
  const { outsideSurveyVisibleConditions } = props;
  const { isLight, isLoading, defaultColDef, duplicateWarnSurveyVisibleConditionColDef } = useOutsideSurveyVisibleConditionsGrid();

  return (
    <Box
      component={'div'}
      sx={{ width: '100%', height: '500px' }}
      className={isLight ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'}
      data-testid={'aggrid'}>
      <LoadableComponent isLoading={isLoading} zIndex={1000}>
        <StyledAgGrid defaultColDef={defaultColDef} coldef={duplicateWarnSurveyVisibleConditionColDef} rows={outsideSurveyVisibleConditions} />
      </LoadableComponent>
    </Box>
  );
};

export default OutsideSurveyVisibleConditionsGrid;
