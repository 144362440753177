import { initSurveyDetail, SurveyDetailEntity } from './useSurveyDetailApi';

export interface RespondentRequestEntity {
  id: string;
  titleJpn: string;
  titleEng: string;
  targetDate: string | null;
  requestedDate: string | null;
  openedAt: string;
  closedAt: string;
  createdOrganizationId: string;
  previousRespondentRequestId: string | null;
  organizationBaseDate: string;
  RespondentRequestEntity: string | null;
  surveyDetail: SurveyDetailEntity;
  surveyDetailId: string;
  surveyOwnerEmails: string[];
  createdBy?: string;
  updatedBy?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export const initRespondentRequestData: RespondentRequestEntity = {
  id: '',
  titleJpn: '',
  titleEng: '',
  openedAt: '',
  closedAt: '',
  targetDate: '',
  requestedDate: '',
  createdOrganizationId: '',
  previousRespondentRequestId: '',
  organizationBaseDate: '',
  RespondentRequestEntity: '',
  surveyDetail: initSurveyDetail,
  surveyDetailId: '',
  surveyOwnerEmails: [],
};
