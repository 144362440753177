import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { AdditionalQuestion, OverviewEnquete, PurchaseFixedQuestions, SalesFixedQuestions } from 'src/@types/overviewEnquete';
import { useCustomerApi } from 'src/api/useCustomerApi';
import {
  setInitOverviewQuestions,
  setNotConvertPurchaseAmount,
  setNotConvertSalesAmount,
} from 'src/features/general/enquete-answer/store/enqueteOverviewAnswerSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';

export const useEnqueteAnswer = () => {
  const { surveyDetailData, enqueteAnswerData, respondentTargetData } = useAppSelector((state) => state.enqueteAnswer);
  const answerOverviewEnquete = enqueteAnswerData.answerData?.answerInputData?.tabData[0] as OverviewEnquete;
  const overviewEnquete = surveyDetailData.surveyFormData.formData.tabData[0] as OverviewEnquete;

  const { getCustomerTransaction } = useCustomerApi();
  const dispatch = useAppDispatch();

  const initOverviewEnquete = async (setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    let initOverviewEnquete = cloneDeep(overviewEnquete);
    // 未回答であれば初期値提案を行う
    if (undefined === enqueteAnswerData?.status) {
      const customerTransaction = await getCustomerTransaction(respondentTargetData.id);
      if (customerTransaction && customerTransaction.purchaseList.length !== 0) {
        // 0番目に日本円があるため
        initOverviewEnquete.purchaseFixedAmount.answer = customerTransaction.purchaseList[0].amount;
        // 0番目を除いた円換算不可のものをStoreに保存
        customerTransaction.purchaseList.splice(0, 1);
        dispatch(setNotConvertPurchaseAmount(customerTransaction.purchaseList));
      }
      if (customerTransaction && customerTransaction?.salesList.length !== 0) {
        // 0番目に日本円があるため
        initOverviewEnquete.salesFixedAmount.answer = customerTransaction?.salesList[0].amount;
        // 0番目を除いた円換算不可のものをStoreに保存
        customerTransaction.salesList.splice(0, 1);
        dispatch(setNotConvertSalesAmount(customerTransaction.salesList));
      }
    }
    // アンケート内容を初期セット
    if (answerOverviewEnquete) {
      // 回答データが存在する場合はsurveyDetailDataとAnswerDataをマージして回答を作成
      const createArray = (answerAddQuestion: AdditionalQuestion[], enqueteAddQuestion: AdditionalQuestion[]): AdditionalQuestion[] => {
        const newData: AdditionalQuestion[] = [...answerAddQuestion];
        enqueteAddQuestion.forEach((question) => {
          const existData = newData.find((data) => data.titleJpn === question.titleJpn);
          if (!existData) {
            newData.push(question);
          }
        });
        return newData;
      };
      // 買い取引の固定質問データの作成
      const purchaseFixedQuestion: PurchaseFixedQuestions = {
        product: {
          answer: answerOverviewEnquete.purchaseFixedQuestions.product.answer,
          isAnswerTarget:
            answerOverviewEnquete.purchaseFixedQuestions.product.isAnswerTarget || overviewEnquete.purchaseFixedQuestions.product.isAnswerTarget,
        },
        mainCustomer: {
          answer: answerOverviewEnquete.purchaseFixedQuestions.mainCustomer.answer,
          isAnswerTarget:
            answerOverviewEnquete.purchaseFixedQuestions.mainCustomer.isAnswerTarget ||
            overviewEnquete.purchaseFixedQuestions.mainCustomer.isAnswerTarget,
        },
        commercialFlow: {
          answer: answerOverviewEnquete.purchaseFixedQuestions.commercialFlow.answer,
          isAnswerTarget:
            answerOverviewEnquete.purchaseFixedQuestions.commercialFlow.isAnswerTarget ||
            overviewEnquete.purchaseFixedQuestions.commercialFlow.isAnswerTarget,
        },
      };
      // 売り取引の固定質問データの作成
      const salesFixedQuestion: SalesFixedQuestions = {
        product: {
          answer: answerOverviewEnquete.salesFixedQuestions.product.answer,
          isAnswerTarget:
            answerOverviewEnquete.salesFixedQuestions.product.isAnswerTarget || overviewEnquete.salesFixedQuestions.product.isAnswerTarget,
        },
        commercialFlow: {
          answer: answerOverviewEnquete.salesFixedQuestions.commercialFlow.answer,
          isAnswerTarget:
            answerOverviewEnquete.salesFixedQuestions.commercialFlow.isAnswerTarget ||
            overviewEnquete.salesFixedQuestions.commercialFlow.isAnswerTarget,
        },
      };

      initOverviewEnquete = {
        ...overviewEnquete,
        purchaseFixedAmount: answerOverviewEnquete?.purchaseFixedAmount,
        purchaseFixedQuestions: purchaseFixedQuestion,
        purchaseAdditionalQuestions: createArray(answerOverviewEnquete?.purchaseAdditionalQuestions, overviewEnquete?.purchaseAdditionalQuestions),
        salesFixedAmount: answerOverviewEnquete?.salesFixedAmount,
        salesFixedQuestions: salesFixedQuestion,
        salesAdditionalQuestions: createArray(answerOverviewEnquete?.salesAdditionalQuestions, overviewEnquete?.salesAdditionalQuestions),
        otherFixedTopic: answerOverviewEnquete?.otherFixedTopic,
        otherAdditionalQuestions: createArray(answerOverviewEnquete?.otherAdditionalQuestions, overviewEnquete?.otherAdditionalQuestions),
      };
    }

    dispatch(setInitOverviewQuestions(initOverviewEnquete));
    setIsLoading(false);
  };

  return {
    initOverviewEnquete,
  };
};
