import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack5';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SurveyEditEntity } from 'src/api/useSurveyEditApi';
import Label from 'src/components/Label';
import { StyledButton } from 'src/components/app-components/StyledButton';
import { StyledLoading } from 'src/components/app-components/StyledLoading';
import { TAB_VALUE } from 'src/features/general/enquete-create/constants';
import { SelectedRespondentListEntity } from 'src/features/general/enquete-create/store/respondentSettingSlice';
import useLocales from 'src/hooks/useLocales';
import { RootState, useAppSelector } from 'src/redux/store';
import { formatterDateTime } from 'src/utils/formatDateTime';
import { ENQUETE_CREATE_PATH } from '../../../../routes/path';
import SendConfirmationModal from '../../../modals/send-confirmation/components/SendConfirmationModal';
import useRequestMailForm from '../hooks/useRequestMailForm';
import RespondentListGrid from './RespondentListGrid';
import ShowAttachedFileComponent from './ShowAttachedFileComponent';

export interface ResponseRequestMailLayoutProps {
  respondentList: SelectedRespondentListEntity;
  setSelectedRowDataToStore: () => void;
}

const ResponseRequestMailLayout: React.FC<ResponseRequestMailLayoutProps> = (props) => {
  const { respondentList, setSelectedRowDataToStore } = props;
  const navigate = useNavigate();
  const { respondentRequestId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { currentLang } = useLocales();
  const isLangJpn = currentLang.value === 'ja';

  const {
    isLoadStore,
    mailFormData,
    attachedFiles,
    validationError,
    onChangeMailForm,
    addAttachedFile,
    setAttachedFiles,
    checkEmptyForm,
    checkExistError,
  } = useRequestMailForm();

  const inputRef = useRef<HTMLInputElement>(null);
  const attachedFileLimit: number = 10;

  //許可する拡張子
  const uploadExtensions: string = '.txt, .pdf, .doc, .docx, .xls, .xlsx, .xlsm, .ppt, .pptx, .jpg, .jpeg, .gif, .png, .msg';
  //画面表示用
  const showUploadExtensions: string = uploadExtensions.replace(/\./g, '').replace(/, /g, '、');

  const enqueteData = useAppSelector((state: RootState) => state.enqueteCreate.enqueteData);

  // 送信対象を AgGrid で表示するための加工後リスト
  const rowData = Object.values(respondentList) as SurveyEditEntity[];

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

  const init = async () => {
    //リロード時のみ、useNavigateのステートをリセット
    window.history.replaceState({}, document.title);
  };

  //送信確認
  const sendingConfirmation = () => {
    //エラーがあった場合、処理停止
    if (checkEmptyForm() || checkExistError()) {
      enqueueSnackbar(t('message.exceptionNotResolvedError'), { variant: 'error' });
      return;
    }

    //依頼リストが空だった場合、処理停止
    if (Object.keys(respondentList).length === 0) {
      enqueueSnackbar(t('enqueteCreate.responseRequest.message.emptyRespondentList'), { variant: 'error' });
      return;
    }

    setIsOpenConfirmModal(true);
  };

  //ファイル選択ダイアログ表示
  const openDialog = () => {
    if (inputRef.current === null) return;
    inputRef.current.click();
  };

  //アンケート編集画面へ遷移
  const goToSurveyEditPage = () => {
    //ページ離脱時に、選択行をStoreに保存し直す
    setSelectedRowDataToStore();
    navigate(`${ENQUETE_CREATE_PATH.EDIT}/${respondentRequestId}?tab=${TAB_VALUE.RESPONDENT}`);
  };

  useEffect(() => {
    init();
  }, []); //eslint-disable-line

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ pb: 5 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ whiteSpace: 'pre-line' }}>
            <h6>{t('enqueteCreate.enqueteTitle')}</h6>
            <Typography variant="body1" sx={{ fontSize: 14 }} children={isLangJpn ? enqueteData.titleJpn : enqueteData.titleEng} />
          </Grid>
          <Grid item xs={12} md={4} sx={{ whiteSpace: 'pre-line' }}>
            <h6>{t('enqueteCreate.enqueteRequestTermTitle')}</h6>
            <Typography
              variant="body1"
              sx={{ fontSize: 14 }}
              children={`${formatterDateTime(enqueteData.openedAt)} 〜 ${formatterDateTime(enqueteData.closedAt)}`}
            />
          </Grid>

          {/***** ボタンエリア *****/}
          <Grid item xs={12} md={4}>
            <Stack direction={'row-reverse'} spacing={2}>
              <StyledButton onClick={sendingConfirmation}>{t('button.send')}</StyledButton>
              <StyledButton onClick={goToSurveyEditPage} color="error">
                {t('button.back')}
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>

        {/***** メールタイトル *****/}
        <Stack spacing={1}>
          <Typography variant="h6" children={t('enqueteCreate.responseRequest.subjectFormTitle')} />
          <TextField
            error={validationError.subject.required}
            helperText={<>{validationError.subject.required && t('enqueteCreate.responseRequest.message.requiredForm')}</>}
            variant="outlined"
            size="small"
            InputProps={{
              name: 'subject',
              value: mailFormData ? mailFormData.subject : '',
            }}
            onChange={onChangeMailForm}
          />
        </Stack>

        {/***** メール本文 *****/}
        <Stack>
          <Typography variant="h6" children={t('enqueteCreate.responseRequest.contentFormTitle')} />
          <TextField
            error={validationError.content.required}
            helperText={<>{validationError.content.required && t('enqueteCreate.responseRequest.message.requiredForm')}</>}
            multiline
            rows={8}
            variant="outlined"
            size="small"
            InputProps={{
              name: 'content',
              value: mailFormData ? mailFormData.content : '',
            }}
            onChange={onChangeMailForm}
            sx={{ mt: 1 }}
          />
          <Box>
            <Label
              role="message"
              color="warning"
              variant="ghost"
              sx={{ whiteSpace: 'pre-line', lineHeight: 0.8, py: 1 }}
              children={t('enqueteCreate.responseRequest.message.guidAboutSurveyUrlLink')}
            />
          </Box>
        </Stack>

        {/***** ファイル添付 *****/}
        <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} columnSpacing={2}>
          <ShowAttachedFileComponent attachedFiles={attachedFiles} setAttachedFiles={setAttachedFiles} />

          {attachedFiles.length < attachedFileLimit && (
            <>
              <Grid item sx={{ mb: 1 }}>
                <StyledButton onClick={openDialog} fullwidth={false}>
                  {t('button.attachedFile')}
                </StyledButton>
              </Grid>
              <input
                type="file"
                value=""
                hidden
                ref={inputRef}
                onChange={(e) => {
                  const result = addAttachedFile(e, uploadExtensions);
                  if (!result) {
                    enqueueSnackbar(t('enqueteCreate.responseRequest.message.notAllowedFileExtensionError'), { variant: 'error' });
                  }
                }}
                onClick={(e) => {
                  if (inputRef.current !== null && inputRef.current?.files !== null) {
                    inputRef.current.files = null;
                  }
                }}
                accept={uploadExtensions}
              />
            </>
          )}
        </Grid>

        {/***** ファイル添付説明文 *****/}
        <Stack spacing={1}>
          <Typography variant="body2" children={t('enqueteCreate.responseRequest.attachedFileNote')} />
          <Typography variant="body2" children={`${t('enqueteCreate.responseRequest.attachedFileExtensionsHead')}${showUploadExtensions}`} />
        </Stack>

        <RespondentListGrid rowData={rowData} />
      </Stack>

      {/***** 送信確認ダイアログ *****/}
      <SendConfirmationModal
        isOpenConfirmModal={isOpenConfirmModal}
        onCloseConfirmModal={() => {
          setIsOpenConfirmModal(false);
        }}
        mailFormData={mailFormData}
        rowData={rowData}
        attachedFiles={attachedFiles}
      />

      {/** ローディング */}
      <StyledLoading isOpen={isLoadStore} />
    </>
  );
};

export default ResponseRequestMailLayout;
